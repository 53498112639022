import type { ChangeEvent, RefObject } from "react";
import { useEffect, useState } from "react";
import type { MultipleChoiceValueType } from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

export type UseBrandModelFilter = {
    searchQuery: string;
    selectedBrand: MultipleChoiceValueType | null;
    searchActive: boolean;
    panelPage: 0 | 1;
    clearSearch: () => void;
    onSelectBrand: (brand: MultipleChoiceValueType) => void;
    onSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

/**
 * Used in BrandModelFilter.
 */
const useBrandModelFilter = (inputRef: RefObject<HTMLInputElement>): UseBrandModelFilter => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedBrand, setSelectedBrand] = useState<MultipleChoiceValueType | null>(null);
    const [searchActive, setSearchActive] = useState<boolean>(false);
    const [panelPage, setPanelPage] = useState<0 | 1>(0);

    // When this component is rendered it will be rendered after a user clicked a "search" button.
    // Focus the input as that makes for good UX in this case.
    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, []);

    const clearSearch = (): void => {
        // Always reset the search query.
        setSearchQuery("");

        // Code below would animate a slide back into a brand list when the user removes the brand filter.
        // However it would be the unfiltered brand selection list instead of the brand dropdown that renders by default.
        // Disable this for now and wait for what we will do with the default brand selection as it would be inconsistent now.
        // if (selectedBrand && !searchQuery) {
        //     // If the brand is selected and there is no searchquery go back to the previous page, remove current brand;
        //     setPanelPage(0);
        //     setSelectedBrand(null);
        // } else

        if (!(selectedBrand && searchQuery)) {
            // Complete reset of the component (no search query and no brand selected)
            setPanelPage(0);
            setSearchActive(false);
            setSelectedBrand(null);
        }
    };

    const onSelectBrand = (brand: MultipleChoiceValueType): void => {
        // When a brand is selected move to the next page and reset the search bar.
        setPanelPage(1);
        setSelectedBrand(brand);
        setSearchQuery("");
    };

    const onSearchInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(event.target.value);
        // Only "activate" search if there are at least 2 characters.
        setSearchActive(event.target.value.length > 1);
    };

    return { searchQuery, selectedBrand, searchActive, panelPage, clearSearch, onSelectBrand, onSearchInputChange };
};
export default useBrandModelFilter;
