import type {
    CarFilterApiType,
    etaType,
    CarFilterModelType,
    CarFilterModelErrorType,
    CarFilterErrorCarType,
} from "../../../../../common-deprecated/types/FilterApiType";
import type {
    FilterConfigBaseType,
    FilterConfigValueLabelType,
    MultipleChoiceValueType,
    SentenceConfigType,
} from "../constants/filterConfigConstants";
import { defaultSentenceConfig, HYBRID_CLASSIFICATION } from "../constants/filterConfigConstants";
import { getTagContent, getWeeksFromNow } from "../../../../../common-deprecated/utils";
import type { ErrorLogType } from "../../../../../common-deprecated/types/CommonTypes";
import { DEFAULT_PARENT_ID } from "../constants/filterConstants";

/**
 * Check if a valueLabelConfig object is configured properly. Will return an array of errors if any issues are found.
 */
export const checkValueLabelConfig = (
    valueLabelConfig: FilterConfigValueLabelType,
    filterId: string,
    requireValue: boolean = true,
): string[] => {
    const errors: string[] = [];
    if (valueLabelConfig.maxValueText === "{value}") {
        errors.push(`"Maximum Value Text" localization not found for filter ${filterId}.`);
    }
    if (valueLabelConfig.minValueText === "{value}") {
        errors.push(`"Minimum Value Text" localization not found for filter ${filterId}.`);
    }
    if (requireValue && valueLabelConfig.value === "{value}") {
        errors.push(`"Slider Text" localization not found for filter ${filterId}.`);
    }
    return errors;
};
/**
 * Round the min and max values based on the compare mode.
 */
export const roundMinMax = (step: number, value: number, compareMode: "Plus" | "Minus" | "Equal" = "Equal"): number =>
    // Use toFixed to prevent JS rounding errors.
    Number(((compareMode === "Plus" ? Math.floor(value / step) : Math.ceil(value / step)) * step).toFixed(2));

// helper types for the car filter formatters, useful for TS typing.
export const carFilterModelIsValid = <T>(
    model: CarFilterModelType<T> | CarFilterModelErrorType,
): model is CarFilterModelType<T> => !(model as CarFilterModelErrorType)._error;

export const carFilterCarIsValid = <T>(car: CarFilterErrorCarType | T): car is T =>
    !(car as unknown as CarFilterModelErrorType)._error;

/**
 * Retrieve options for the "car type" filter.
 */
export const getCarTypeValues = <T extends Partial<{ [labelKey: string]: string }>>(
    labels: T,
    data: CarFilterApiType,
    defaultSelect: boolean = true,
): MultipleChoiceValueType[] => {
    const carTypeOptions: MultipleChoiceValueType[] = data.classifications
        // Only have classifications available if at least one model has it.
        .filter((classification) =>
            data.models
                .filter(carFilterModelIsValid)
                .find((model) => model.classifications && model.classifications.includes(classification.id)),
        )
        .sort((a, b) => a.index - b.index)
        .map((classification) => ({
            id: classification.id,
            label: classification.name,
            selected: defaultSelect,
            selectable: true,
            externalIdentifier: classification.internalCode,
            ...(classification.parentId !== DEFAULT_PARENT_ID && { parentId: classification.parentId }),
        }));

    // Add the "hybrid" car type if it is defined in Tridion.
    if (labels.filterHybridClassification) {
        carTypeOptions.push({
            id: HYBRID_CLASSIFICATION,
            label: labels.filterHybridClassification,
            selected: defaultSelect,
            selectable: true,
        });
    }

    return carTypeOptions;
};

export const getFuelTypeValues = (
    fuelTypeOrder: string[],
    data: CarFilterApiType,
    defaultSelect: boolean = true,
    pluginLabel?: string, // grade-explorer doesn't support this fuelType
): MultipleChoiceValueType[] => {
    const fuelTypes = data.models
        .filter(carFilterModelIsValid)
        .reduce((types: { id: string; name: string }[], model) => {
            const engine =
                model.engines &&
                model.engines.find((modelEngine) => !types.map((type) => type.name).includes(modelEngine.fuel));
            if (engine) types.push({ id: engine.fuelCode, name: engine.fuel });
            return types;
        }, []);

    const fuelOrder = fuelTypeOrder.map((id) => id.toString());
    const filters = fuelTypes.map((fuelType) => ({
        id: fuelType.id,
        label: fuelType.name,
        selected: defaultSelect,
        selectable: true,
    }));

    // Add custom plugin filter in case there a car has a plugin engine
    const pluginEngineExists = data.models
        .filter(carFilterModelIsValid)
        .some((model) => model.engines && model.engines.some((engine) => engine.plugin));
    if (pluginEngineExists && pluginLabel) {
        filters.push({
            id: "plugin",
            label: pluginLabel,
            selected: defaultSelect,
            selectable: true,
        });
    }

    return filters
        .filter((value, index, array) => array.findIndex((fuelType) => fuelType.id === value.id) === index) // Filter out duplicate fuelTypes, see OR-9513.
        .sort((first, second) => {
            const firstIndex = fuelOrder.indexOf(first.id);
            const secondIndex = fuelOrder.indexOf(second.id);
            return firstIndex - secondIndex;
        });
};

export const getTransmissionTypeValues = (
    data: CarFilterApiType,
    defaultSelect: boolean = false,
): MultipleChoiceValueType[] => {
    const transmissions = data.models
        .filter(carFilterModelIsValid)
        .reduce((types: { id: string; name: string }[], model) => {
            const transmission =
                model.transmissions &&
                model.transmissions.find(
                    (modelTransmission) => !types.map((type) => type.id).includes(modelTransmission.id),
                );
            if (transmission) types.push({ id: transmission.id, name: transmission.name });
            return types;
        }, []);

    const filters = transmissions.map((transmission) => ({
        id: transmission.id,
        label: transmission.name,
        selected: defaultSelect,
        selectable: true,
    }));

    return filters;
};

export const getWheeldriveTypeValues = (
    data: CarFilterApiType,
    defaultSelect: boolean = false,
): MultipleChoiceValueType[] => {
    const wheeldrives = data.models
        .filter(carFilterModelIsValid)
        .reduce((types: { id: string; name: string }[], model) => {
            const wheeldrive =
                model.wheeldrives &&
                model.wheeldrives.find((modelWheeldrive) => !types.map((type) => type.id).includes(modelWheeldrive.id));
            if (wheeldrive) types.push({ id: wheeldrive.id, name: wheeldrive.name });
            return types;
        }, []);

    const filters = wheeldrives.map((wheeldrive) => ({
        id: wheeldrive.id,
        label: wheeldrive.name,
        selected: defaultSelect,
        selectable: true,
    }));

    return filters;
};

/**
 * Get the sentence configuration based on the template string.
 * Filter can also be "more" as this function is also used for parsing the "and more..." sentence template configs.
 */
export const formatSentenceTemplate = (
    template: string,
    filterId: string,
    filterIsMultipleChoice: boolean = false,
): { config: SentenceConfigType; errors: string[] } => {
    const errors: string[] = [];
    const filterTemplate = getTagContent(filterId.toUpperCase(), template);

    if (!filterTemplate) {
        errors.push(
            `No sentence config found for filter: ${filterId}. Add a [${filterId.toUpperCase()}] tag to the sentence config`,
        );
        return { config: { ...defaultSentenceConfig }, errors };
    }

    // Match the string between the opening tag and the value tag.
    const leftLabelRegex = new RegExp(`\\[${filterId.toUpperCase()}](.*?)\\[VALUE]`, "i").exec(template);
    // Match the string after a closing (any)value tag and the filter closing tag, making sure the closing (any)value tag isnt followed by another tag.
    const rightLabelRegex = new RegExp(
        `(\\[\\/ANYVALUE]|\\[\\/VALUE])(?!\\[)(.*)\\[\\/${filterId.toUpperCase()}]`,
        "i",
    ).exec(template);

    const parsedValues: SentenceConfigType = {
        leftLabel: leftLabelRegex ? leftLabelRegex[1] : "",
        rightLabel: rightLabelRegex ? rightLabelRegex[2] : "",
        valueLabel: getTagContent("VALUE", filterTemplate),
        anyLabel: getTagContent("ANYVALUE", filterTemplate),
        valueSeparator: getTagContent("SEPARATOR", template),
        finalValueSeparator: getTagContent("FINALSEPARATOR", template),
        mergeIdenticalValues: false,
    };

    if (!parsedValues.leftLabel && !parsedValues.rightLabel) {
        errors.push(
            `No left or right label defined. Add at least one label to the left or right of [VALUE] in filter ${filterId}`,
        );
    }

    // Validate multiple choice specific sentence configuration.
    if (filterIsMultipleChoice) {
        if (!parsedValues.anyLabel) {
            errors.push(
                `No [ANY] label found for filter ${filterId}. Add an [ANY]anyLabel[/ANY] tag to the [${filterId.toUpperCase()}] tag`,
            );
        }
        if (!parsedValues.valueSeparator) {
            errors.push("No [SEPARATOR] tag found. Add a [SEPARATOR],[/SEPARATOR] tag to the sentenceConfig label");
        }
    }

    return { config: { ...defaultSentenceConfig, ...parsedValues }, errors };
};

type ReducerType<T extends FilterConfigBaseType> = { [index: string]: T } & { errors: ErrorLogType[] };
/**
 * Apply configuration from sentenceTemplate to filter configurations.
 * Propagating a reducer will require an any cast as getting the types right for both grade explorer and car-filter reducer was too complex.
 *
 * @returns a string of formatting errors, if any.
 */
export const parseSentenceTemplate = <T extends FilterConfigBaseType>(
    keys: string[],
    reducer: ReducerType<T>,
    sentenceTemplate: string,
): string[] => {
    let sentenceConfigErrors: string[] = [];

    keys.filter((key) => reducer[key] && reducer[key].show)
        .sort((a, b) => {
            const aIndex = sentenceTemplate.indexOf(`[${a.toUpperCase()}]`);
            const bIndex = sentenceTemplate.indexOf(`[${b.toUpperCase()}]`);

            // Make sure that values not found are rendered last.
            if (aIndex === -1) return 1;
            if (bIndex === -1) return -1;

            return aIndex - bIndex;
        })
        .forEach((key, index) => {
            const { config, errors } = formatSentenceTemplate(sentenceTemplate, key);
            reducer[key].sentenceConfig = config;
            reducer[key].index = index;
            if (errors.length) sentenceConfigErrors = errors.concat(errors);
        });

    return sentenceConfigErrors;
};

export const getMaxAndMinEtaValues = (data: CarFilterApiType): { min: number; max: number } => {
    // filter the models that give an error and loop trough the rest
    const etaValues = data.models.filter(carFilterModelIsValid).reduce((acc, model) => {
        const cars = model.cars.filter(carFilterCarIsValid);
        // Push all the eta Values to one array
        cars.forEach((car) => car.eta && acc.push(car.eta));

        return acc;
    }, [] as etaType[]);

    // check all the min values of the ETAs and get the lowest value
    const minValue = Math.min(
        ...etaValues
            .map(({ min }) => getWeeksFromNow(new Date(`${String(min?.eta)}`)))
            .filter((eta) => !Number.isNaN(eta)),
    );
    // check all the max values of the ETAs and get the hightest value
    const maxValue = Math.max(
        ...etaValues
            .map(({ max }) => getWeeksFromNow(new Date(`${String(max?.eta)}`)))
            .filter((eta) => !Number.isNaN(eta)),
    );

    return {
        min: minValue,
        max: maxValue,
    };
};

export const getMinMaxPriceValueFromResults = (
    data: CarFilterApiType,
    type: "min" | "max",
    financeOption: "cash" | "monthly",
    exclVATModelIds: string[],
): number => {
    const mathFunction = type === "min" ? Math.min : Math.max;
    const initialValue = type === "min" ? Number.MAX_SAFE_INTEGER : 0;
    const roundFunction = type === "min" ? Math.floor : Math.ceil; // round to make sure all results can be accessed

    // Loop over models & cars, using above values & functions to get the min/max cash/monthly price
    const result = data.models.filter(carFilterModelIsValid).reduce((currentValue, model) => {
        const exclVat = exclVATModelIds.includes(model.id);
        const modelMin = model.cars.filter(carFilterCarIsValid).reduce((currentModelValue, car) => {
            let value: number;
            // Get cash/monthly prices, same logic as in formatModelCar
            if (financeOption === "cash") {
                // use incl/excl vat discounted prices
                value = (exclVat ? car.price?.netWithDiscount : car.price?.listWithDiscount) || currentModelValue;
            } else {
                // use (promo) monthly rate
                value = Number(
                    car.finance?.rate?.promo?.monthlyPayment?.value ||
                        car.finance?.rate?.monthlyPayment?.value ||
                        String(currentModelValue),
                );
            }
            return mathFunction(currentModelValue, value);
        }, initialValue);
        return mathFunction(currentValue, modelMin);
    }, initialValue);

    // default to min=0 when no cars have any prices (glen)
    return result === Number.MAX_SAFE_INTEGER ? 0 : roundFunction(result);
};

/**
 * Filter the models and cars with errors. This makes our flow and map life a lot cleaner and easier.
 * Also filter out grades where visibility is 0 (OR-5244)
 * @param dataModels
 * @returns filtered Models without errors
 */
export const filterCarFilterModels = <T>(
    dataModels: (CarFilterModelType<T> | CarFilterModelErrorType)[],
): (Omit<CarFilterModelType<T>, "cars"> & {
    cars: T[];
})[] => {
    return dataModels
        .filter(carFilterModelIsValid<T>)
        .map((dataModel) => ({
            ...dataModel,
            cars: dataModel.cars.filter(carFilterCarIsValid<T>),
            grades: dataModel.grades.filter((grade) => grade.visibility !== 0),
        }))
        .sort((a, b) => a.index - b.index);
};
