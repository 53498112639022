/**
 * General price formatter helpers used throughout OR Price components.
 */
import { formatNumber, formatPrice } from "./Globalize";
import type { CommonFinanceInfoType, PriceConfigType } from "./types/CommonTypes";
import { getTagContent } from "./utils";
import { FlexMatrixPriceType } from "./constants";
import type { CommonSettingsType } from "./settings/fetchCommonSettings";
import Debug from "./Debug";
import { getIntlLocale } from "./utils/i18nUtils";
import { getTizenVersion } from "../shared-logic/features/retailer/utils/utils";

/**
 * Helper method to determine if the license fee should be added to the cash price
 */
export const calculatePriceInclLicenseFee = (
    cashPrice: number,
    priceConfig: PriceConfigType,
    licenseFee: number,
): number => {
    if (priceConfig.priceFormat !== "") {
        return cashPrice + licenseFee;
    }
    return cashPrice;
};

export type KeyValueArrayType<T extends string> = { key: T; value: string }[];
export const replaceKeysInFormat = <T extends string>(
    availableReplacements: Partial<Record<T, string>>,
    format: string,
): KeyValueArrayType<T> => {
    // Loop through all the possible replacements.
    // If a tag is found in the priceFormat and we have a value for it, format and add to the result array.
    // Also make sure the returned array contains the same sort order
    // For sorting it's best also to ignore casing of the format as the casing is also ignored in the reduce function (see getTagContent -> getTagRegExp)
    const lowerCaseFormat = format.toLowerCase();
    return (Object.keys(availableReplacements) as T[])
        .sort((a, b) => lowerCaseFormat.indexOf(a.toLowerCase()) - lowerCaseFormat.indexOf(b.toLowerCase()))
        .reduce((acc, replacementKey) => {
            const replacementValue = availableReplacements[replacementKey];

            if (!replacementKey || replacementValue === undefined) return acc;

            // Search for [key]...[/key]
            const sectionResult = getTagContent(replacementKey, format);
            if (sectionResult && availableReplacements[replacementKey]) {
                // Tag found for which we have a replacement value.
                // Update the {value} the replacement data and add it to the items to be rendered.
                const valueTag = sectionResult.replace("{value}", replacementValue);
                acc.push({
                    key: replacementKey,
                    value: valueTag,
                });
            }
            return acc;
        }, [] as KeyValueArrayType<T>);
};

/**
 * Get the labels to be rendered in a monthly price component.
 * These labels are based on the financeInfo object and the priceFormat which contains flexibility matrix config.
 */
export enum CommonMonthlyPriceLabelKey {
    Price = "price",
    PromoPrice = "promoPrice",
    LeasingPrice = "leasingPrice",
    Term = "term",
    ProductName = "productName",
    Taeg = "taeg",
    Tan = "tan",
    ResidualValue = "residualValue",
    DownPayment = "downPayment",
    Mileage = "mileage",
    TotalAmountFinanced = "totalAmountFinanced",
    Disclaimer = "disclaimer",
}

export enum CommonMonthlyFinanceInfoLabelKey {
    Term = "term",
    ProductName = "productName",
    Taeg = "taeg",
    Tan = "tan",
    ResidualValue = "residualValue",
    DownPayment = "downPayment",
    Mileage = "mileage",
    TotalAmountFinanced = "totalAmountFinanced",
    AmountFinanced = "amountFinanced",
    TotalCreditWithFees = "totalCreditWithFees",
    LastInstalment = "lastInstalment",
    Disclaimer = "disclaimer",
    Installments = "installments",
}

export type FormatMonthlyPriceType = {
    labels: KeyValueArrayType<CommonMonthlyPriceLabelKey>;
    hasPromotion: boolean;
    disclaimer: string;
};

/**
 * This method is used in the uscPriceUtils formatMonthlyFinancePrice functions, this might require a close look
 * TODO refactor ticket: UC-384
 */
export const formatNumberString = (input: string, cultureName: string): string => {
    // The some components use a preformatted string, so a Number case might result in NaN
    if (Number.isNaN(Number(input))) {
        return input;
    }
    // Note that Globalize also supports percentages (p) but that renders the percentage character as well, potentially causing regressions at a lot of places.
    // n2 will format a number to 2 decimals
    return formatNumber(Number(input), cultureName, "n2");
};

export const getSecondaryPrice = (commonSettings: CommonSettingsType, value: number): number => {
    const { currencyMultiplier } = commonSettings;

    return value * currencyMultiplier;
};

/**
 * Used to format prices according to the given locale
 * Allows a fallback to the old Globalize function if needed.
 */
export const formatPriceIntl = (
    commonSettings: CommonSettingsType,
    value: number | string,
    intlOptions?: Intl.NumberFormatOptions,
    secondaryCurrency?: boolean,
    fallback?: boolean,
    forceCurrencyDecimals?: number,
): { primaryPrice: string; secondaryPrice?: string } => {
    if (fallback) {
        return {
            primaryPrice: formatPrice(value, commonSettings.culture.name, secondaryCurrency),
        };
    }

    if (typeof value === "string") value = Number(value);
    const { currencyCode, currencyDecimals, secondaryCurrencyCode } = commonSettings.intl;
    const intlLocale = getIntlLocale(commonSettings.culture.name);
    const maximumFractionDigits = forceCurrencyDecimals || currencyDecimals || 0;

    try {
        const intlPriceFormatter = (intlCurrencyCode: string): Intl.NumberFormat =>
            new Intl.NumberFormat(intlLocale, {
                style: "currency",
                currency: intlCurrencyCode,
                currencyDisplay: "narrowSymbol",
                // Older chromium versions (like v85 on Tizen 6.5) throw an error if min value is larger than max value
                // Since browsers have locale specific defaults for prices, it's safer to always define both
                minimumFractionDigits: maximumFractionDigits,
                maximumFractionDigits,
                ...intlOptions,
            });

        const primaryPrice = replaceUnsupportedCurrencyChars(intlPriceFormatter(currencyCode).format(value));

        // If secondary currency is enabled, we need to format the price twice:
        // once for the primary currency (configured inside AEM OSGI) and once for the secondary (local, configurable in i18n) currency
        if (secondaryCurrency && secondaryCurrencyCode) {
            const secondaryPrice = intlPriceFormatter(secondaryCurrencyCode).format(
                getSecondaryPrice(commonSettings, value),
            );

            return { primaryPrice, secondaryPrice };
        }

        return { primaryPrice };
    } catch (e) {
        Debug.error(e as string);
        return { primaryPrice: "" };
    }
};

type CharCodeFallbackType = Record<number, { replacement: string; tizen4Only?: boolean }>;

const charCodeFallbacks: CharCodeFallbackType = {
    8384: { replacement: "сом" }, // (symbol for Kyrgyz som) is fairly new and thus not supported on some systems. Force fallback to сом. https://www.fileformat.info/info/unicode/char/20c0/index.htm
    8382: { replacement: "ლ", tizen4Only: true }, // (symbol for Georgian lari) is not supported on Tizen 4. Force fallback to ლ. https://www.fileformat.info/info/unicode/char/20be/index.htm
};

const replaceUnsupportedCurrencyChars = (formattedPrice: string): string =>
    formattedPrice
        .split("")
        .map((char) => {
            const charCode = char.charCodeAt(0);
            const fallback = charCodeFallbacks[charCode];
            if (fallback && (!fallback.tizen4Only || (getTizenVersion() ?? Infinity) < 5)) return fallback.replacement;
            return char;
        })
        .join("");

/**
 * Formats financing monthly prices, ideally this should not be used for anything else (like insurance in the past)
 * !!Never use this function directly, use the one from uscPriceUtils/ncPriceUtils/aphPriceUtils instead!!
 */
export const formatMonthlyPrice = (
    monthlyPrice: number,
    financeInfo: CommonFinanceInfoType,
    commonSettings: CommonSettingsType,
    priceFormat: string,
    financeInfoReplacements: Record<CommonMonthlyFinanceInfoLabelKey, string>,
    priceFormatting: (priceExclPromo: string) => Partial<Record<CommonMonthlyPriceLabelKey, string>>,
    rateWithoutPromotions?: number,
    useIntl?: boolean,
): FormatMonthlyPriceType => {
    const priceInclPromo = formatPrice(monthlyPrice, commonSettings.culture.name);

    // If there is no difference between the monthly and the rateWithoutPromotions it means no online promotions are applicable
    const hasOnlineMonthlyRatePromotions = !!(rateWithoutPromotions && monthlyPrice !== rateWithoutPromotions);
    let priceExclPromo: string;

    if (useIntl) {
        priceExclPromo = formatPriceIntl(commonSettings, monthlyPrice).primaryPrice;
    } else {
        priceExclPromo = formatPrice(monthlyPrice, commonSettings.culture.name);
    }

    const formattedPrices = priceFormatting(priceExclPromo);

    // priceFormat for monthly rates looks like this:
    // [price]{value} / Months<br>[/price][term]{value} Term[/term]...
    // availableReplacements contains a mapping of the tag and the value (if any) that needs to be replace in the tag.
    const availableReplacements: Partial<Record<CommonMonthlyPriceLabelKey, string>> = {
        ...financeInfoReplacements,
        ...formattedPrices,
        // only fill in the promoPrice when there are actually online monthly promotions
        promoPrice: hasOnlineMonthlyRatePromotions ? priceInclPromo : "",
    };

    const result: FormatMonthlyPriceType = {
        labels: replaceKeysInFormat(availableReplacements, priceFormat),
        hasPromotion: hasOnlineMonthlyRatePromotions,
        disclaimer: "",
    };

    const disclaimerContent = getTagContent("disclaimer", priceFormat);
    if (disclaimerContent && financeInfo.disclaimer) result.disclaimer = disclaimerContent;

    return result;
};

export const hideDiscountInfo = (priceConfig: PriceConfigType): boolean => {
    // See OR-5317 for AEM implementation.
    return (
        priceConfig.priceType === FlexMatrixPriceType.InclHideDiscountInfo ||
        priceConfig.priceType === FlexMatrixPriceType.ExclHideDiscountInfo ||
        !!priceConfig.strikethroughDisabled
    );
};
