import React from "react";
import loadable from "@loadable/component";

const UsedCompareV2 = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-compare-used-aem-toyota" */
        "../../../compare/components/toyota/IntegratedUsedCompare"
    );
    return component.default;
});

const IntegratedUsedCompareWrapper = (): JSX.Element => {
    return <UsedCompareV2 />;
};

export default IntegratedUsedCompareWrapper;
