import styled, { css } from "styled-components";
import type { MaxWidthProps, WidthProps, SpaceProps, TextAlignProps, HeightProps } from "styled-system";
import { width, height, space, textAlign, maxWidth } from "styled-system";
import type { BreakPointValuesType } from "../../themes/common";
import { getBreakpoint, getDirection } from "../../themes/common";

const BORDER_RADIUS_MODAL = 5;

/** @deprecated */
export const Wrapper = styled.section.attrs((props) => ({
    role: props.role,
}))<{ contained?: boolean; rounded?: boolean; isAuthor?: boolean }>`
    display: block;
    position: ${({ isAuthor }) => (isAuthor ? "absolute" : "fixed")};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* Prevent Chrome on Windows from adding a focus outline. For details, see
    https://github.com/twbs/bootstrap/pull/10951. */
    outline: 0;
    z-index: ${({ theme }) => theme.zIndex.modal};
    /* We deliberately don't use \`-webkit-overflow-scrolling: touch;\` due to a
    gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
    See also https://github.com/twbs/bootstrap/issues/17695 */
    ${({ contained }) =>
        contained &&
        css`
            margin: 0;
            @media ${getBreakpoint("up", "lg")} {
                margin: 24px;
            }
            @media ${getBreakpoint("up", "xl")} {
                margin: 40px;
            }
        `};

    ${({ rounded }) =>
        rounded &&
        css`
            margin: 12px;
            @media ${getBreakpoint("up", "md")} {
                margin: 24px;
            }
            @media ${getBreakpoint("up", "xl")} {
                margin: 40px;
            }
        `}

    ${({ isAuthor }) =>
        isAuthor &&
        css`
            margin: 0 !important;
        `}
`;

/** @deprecated */
export const Header = styled.div<WidthProps & SpaceProps & TextAlignProps>`
    padding: 24px;
    font-size: 1.6rem;
    line-height: 3rem;
    background: #f0f0f0; /* Specific grey color, to match GFI modals */
    ${width};
    ${space};
    ${textAlign};
`;

// The classname is needed for salesman / billboard scrolling
/** @deprecated */
export const Body = styled.div.attrs((props) => ({
    id: "or-modalview",
    role: "cpdqm_ignore",
    className: `${props.className || ""} or-modal-body`,
}))<{ large?: boolean; headerHeight?: number } & WidthProps & SpaceProps & TextAlignProps>`
    width: 100%;
    min-height: auto;
    max-height: ${({ headerHeight }) =>
        headerHeight
            ? `calc(100vh - (${headerHeight}px + 25px))`
            : "calc(100vh - 100px)"}; /* 100px = height of modal header (75) + spacing modal wrapper (0) + spacing modal Body (25) + mb (0) */
    padding: 24px;
    font-size: 1.6rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.color.grey4};
    overflow-x: hidden;
    overflow-y: auto;
    ${width}
    ${space}
    ${textAlign}
    p {
        text-indent: 0;
    }
    @media ${getBreakpoint("up", "lg")} {
        min-height: ${({ large }) => (large ? "calc(100vh - 88px - 80px)" : "auto")};
        max-height: calc(
            100vh - 192px
        ); /* 192px = height of modal header (75) + spacing modal wrapper (0) + spacing modal Body (25) + modal footer (92) + mb (20) */
    }
`;

type DialogProps = {
    active?: boolean;
    contained?: boolean;
    rounded?: boolean;
    isAuthor?: boolean;
    size?: BreakPointValuesType;
} & HeightProps &
    MaxWidthProps;

/** @deprecated */
export const Dialog = styled.div<DialogProps>`
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none; /* allow clicks to pass through for custom click handling to close modal */
    background-color: ${({ theme }) => theme.color.lightest};
    transform: translate(0, 100%);
    transition:
        opacity 0.1s,
        transform 0.35s;
    opacity: 0;
    z-index: ${({ theme }) => theme.zIndex.modal};
    ${({ contained }) =>
        contained &&
        css`
            display: flex;
            top: 50%;
            ${getDirection("left")}: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
        `};

    ${({ active, contained, theme }) =>
        active &&
        css`
            opacity: 1;
            transform: ${contained
                ? theme.isRTL
                    ? "translate(50%, -50%)"
                    : "translate(-50%, -50%)"
                : "translate(0,0)"};
        `};

    ${({ rounded }) =>
        rounded &&
        css`
            ${Header} {
                border-top-left-radius: ${BORDER_RADIUS_MODAL}px;
                border-top-right-radius: ${BORDER_RADIUS_MODAL}px;
            }
            ${Content} {
                border-radius: ${BORDER_RADIUS_MODAL}px;
            }
            ${Body} {
                border-bottom-left-radius: ${BORDER_RADIUS_MODAL}px;
                border-bottom-right-radius: ${BORDER_RADIUS_MODAL}px;
            }
        `}

    ${({ size }) =>
        size === "sm" &&
        css`
            max-width: 500px;
        `};
    ${({ size }) =>
        size === "md" &&
        css`
            max-width: 768px;
        `};
    ${({ size }) =>
        size === "lg" &&
        css`
            max-width: 1140px;
        `};
    ${({ size }) =>
        size === "xl" &&
        css`
            max-width: 1440px;
        `};

    ${height};
    ${maxWidth};

    ${({ isAuthor }) =>
        isAuthor &&
        css`
            align-items: flex-start;
            margin-top: 50px;
        `}
`;

/** @deprecated */
export const Content = styled.div<HeightProps>`
    position: relative;
    width: 100%;
    max-height: 100%;
    /* counteract the pointer-events: none; in the .modal-dialog */
    pointer-events: auto;
    background-clip: padding-box;
    background-color: ${({ theme }) => theme.color.lightest};
    /* Remove focus outline from opened modal */
    outline: 0;
    transform: translate(0%, 0%);
    ${height};
`;

/** @deprecated */
export const Title = styled.h2<TextAlignProps>`
    margin: 0;
    text-align: ${getDirection("left")};
    text-transform: none;
    ${textAlign};
`;

/** @deprecated */
export const Actions = styled.div`
    width: 100%;
    padding: 24px;
`;

/** @deprecated */
export const FooterActions = styled.div`
    display: flex;
    flex-direction: column;

    a,
    button + a, button {
        &:not(:last-child) {
            margin-bottom: 10px;

            @media ${getBreakpoint("up", "md")} {
                margin-${getDirection("right")}: 10px;
                margin-bottom: 0;
            }
        }
    }

    @media ${getBreakpoint("up", "md")} {
        flex-direction: row;
        justify-content: flex-end;
    }
`;

/** @deprecated */
export const Footer = styled.div`
    padding: 24px;
`;

/** @deprecated */
export const ContentHeader = styled.header<SpaceProps>`
    ${space};
`;

/** @deprecated */
export const ContentBody = styled.div`
    img {
        border-radius: 4px;
    }
`;

/** @deprecated */
export const Close = styled.div<{ small?: boolean }>`
    position: absolute;
    top: 23px;
    color: ${({ theme }) => theme.color.iconNeutral};
    ${getDirection("right")}: 12px;
    cursor: pointer;
    z-index: 1070;
    ${({ small }) =>
        small &&
        css`
            top: 12px;
        `};

    svg {
        color: ${({ theme }) => theme.colors.grey5};
    }
`;

/** @deprecated */
export const Overlay = styled.div<{ isAuthor?: boolean }>`
    position: ${({ isAuthor }) => (isAuthor ? "absolute" : "fixed")};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
`;
