import type { RefObject, SyntheticEvent } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "../../../../common-deprecated/hooks";
import { useBreakpoint } from "../../../../common-deprecated/themes/common";
import type { UseSliderType } from "../../../../common-deprecated/hooks/useSlider";
import useSlider from "../../../../common-deprecated/hooks/useSlider";
import { useIsomorphicLayoutEffect } from "../../../../common-deprecated/hooks/useIsomorphicLayoutEffect";

type UseResultImageSliderType = {
    sliderProps: UseSliderType;
    wrapperLinkProps: {
        onClick: (event: SyntheticEvent) => void;
        onMouseEnter: (event: SyntheticEvent) => void;
        onMouseLeave: (event: SyntheticEvent) => void;
        onTouchStart: (event: SyntheticEvent) => void;
    };
    activeImage: number;
    showLeftArrow: boolean;
    showRightArrow: boolean;
    renderSlider: boolean;
    loadSliderImages: boolean;
    enableSliderCssTransitions: boolean;
    wrapEvent: <T extends SyntheticEvent<Element, Event>>(
        eventFn: (event: T) => void,
        alwaysPreventDefault?: boolean,
    ) => (event: T) => void;
};

/**
 * Used in ResultImageSlider.
 */
const useResultImageSlider = (
    viewportRef: RefObject<HTMLDivElement>,
    sliderRef: RefObject<HTMLDivElement>,
    images: { src: string; srcSet?: string }[],
    urlTrack?: () => void,
    interactionTrack?: (src: string) => void,
): UseResultImageSliderType => {
    const [sliderWidth, setSliderWidth] = useState<number>(0);
    const [viewportWidth, setViewportWidth] = useState<number>(0);
    const [loadSliderImages, setLoadSliderImages] = useState<boolean>(false);
    const [renderSlider, setRenderSlider] = useState<boolean>(false);
    const [activeImage, setActiveImage] = useState<number>(0);
    // useSlider has enableCssTransition which is used for the same thing, but we have additional logic for it.
    const [enableSliderCssTransitions, setEnableSliderCssTransitions] = useState<boolean>(true);

    const interactionTracked = useRef<boolean>(false);
    const windowWidth = useWindowDimensions().width;

    // For breakpoints where the slider is fixed we can init the slider immediately.
    // In those cases we cannot have the buggy width resize behaviour as the window shouldnt resize much as "more filters" is fixed.
    const initSliderByDefault = useBreakpoint("down", "lg");
    useEffect(() => {
        if (initSliderByDefault && !renderSlider) setRenderSlider(true);
    }, [initSliderByDefault]);

    // The slider width needs to be propagated to useSlider, so keep track of it.
    const checkWidths = useCallback(() => {
        if (viewportRef.current && sliderRef.current) {
            const { width } = viewportRef.current.getBoundingClientRect();
            if (width !== viewportWidth || width * images.length !== sliderWidth) {
                setViewportWidth(width);
                setSliderWidth(width * images.length);
            }
        }
    }, [images.length, sliderRef, sliderWidth, viewportRef, viewportWidth, windowWidth]);
    useIsomorphicLayoutEffect(() => checkWidths(), [checkWidths]);

    // Retrieve all props for slider usage.
    const sliderProps = useSlider(sliderWidth, viewportWidth, viewportWidth);
    const { sliderX, isScrolling, leftArrowEnabled, rightArrowEnabled } = sliderProps;

    // This function is used as a helper to be able to execute setLoadSliderImages together with another action from useSlider.
    const wrapEvent =
        <T extends SyntheticEvent<Element, Event>>(
            eventFn: (event: T) => void,
            alwaysPreventDefault: boolean = false,
        ) =>
        (event: T) => {
            setLoadSliderImages(true);
            eventFn(event);

            // Add the preventParentScroll prop to the dataset of the event.
            // This enables that CarFilterResults component to determine if a child slider has been started, so it knows to not start scrolling.
            (event.target as HTMLDivElement).dataset.preventParentScroll = "true";

            // alwaysPreventDefault should be true with events that don't initiate scrolling but should preventDefault anyway like a button click.
            if (isScrolling.current || alwaysPreventDefault) {
                event.preventDefault();
                event.stopPropagation();

                if (interactionTrack && !interactionTracked.current) {
                    interactionTrack(images[activeImage].src);
                    interactionTracked.current = true;
                }
            }
        };

    // We also want to show arrows when the slider is not active, so add a manual check in addition to the useSlider props.
    const showLeftArrow = leftArrowEnabled || (images.length > 0 && activeImage !== 0);
    const showRightArrow = rightArrowEnabled || (images.length > 0 && activeImage !== images.length - 1);

    // Save the active image in state, based on calculating the slider position.
    useEffect(() => {
        const activeIndex = sliderX === 0 ? 0 : Math.round(Math.abs(sliderX / (sliderWidth / images.length)));
        if (activeIndex !== activeImage) setActiveImage(activeIndex);
    }, [sliderX, activeImage, sliderWidth, images.length]);

    // ----------------------------------------------------------------------
    // Wrapper Link Props
    // ----------------------------------------------------------------------

    const onClick = (event: SyntheticEvent): void => {
        // Prevent redirection when the slider is still scrolling.
        // Similar behaviour to the click preventDefaults of the items in the slider.
        // Still needs a separate preventDefault() here even though stopPropagation is used because of how click events work...
        if (isScrolling.current) event.preventDefault();
        else if (urlTrack) urlTrack();
    };
    const onMouseEnter = (): void => {
        // Mouse enters, make sure useSlider still has the correct dimensions and set renderSlider to true.
        // Enabling CSS transitions on the next frame makes sure that we don't get a "ghost slide" if the useSlider hook recalculates dimensions.
        setRenderSlider(true);
        checkWidths();
        window.requestAnimationFrame(() => {
            setEnableSliderCssTransitions(true);
        });
    };
    const onMouseLeave = (): void => {
        // Mouse leaves, hide the slider and also disable css transitions (see onMouseEnter for reason why)
        const leaveFn = (): void => {
            setEnableSliderCssTransitions(false);
            setRenderSlider(false);
        };
        // Prevent the slider from resetting when the mouse leaves the area but the user is still dragging.
        if (isScrolling.current) window.addEventListener("mouseup", leaveFn, { once: true });
        else leaveFn();
    };
    const onTouchStart = (): void => {
        // Enable the slider if that isn't the case yet (for example ipad landscape, for mobile devices it should be true by default).
        // Note that the slider is never disabled for touch devices as we have no way of doing a "mouseleave" check to do optional rendering of the slider.
        // Because of this, a "ghost swipe" can happen the first time a user interacts and the width of the cars has changed as useSlider readjusts based on the new widths.
        if (!renderSlider) setRenderSlider(true);
        checkWidths();
    };
    const wrapperLinkProps = {
        onClick,
        onMouseEnter,
        onMouseLeave,
        onTouchStart,
    };

    return {
        sliderProps,
        wrapperLinkProps,
        activeImage,
        showLeftArrow,
        showRightArrow,
        renderSlider,
        loadSliderImages,
        enableSliderCssTransitions,
        wrapEvent,
    };
};
export default useResultImageSlider;
