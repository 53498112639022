import React from "react";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import { IconWrapper } from "../../../styles/v2/toyota/globals/IconWrapper";
import ModalBaseView from "./ModalBaseView";
import type { ModalContentType } from "../../../utils/modalConstants";
import type { ModalViewType } from "../../../types/CommonTypes";
import { useCommonLabel } from "../../../utils/commonLabels";

// Basic modal that shows some plaintext or html content
const ContentModal = (props: ModalViewType<ModalContentType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { content, useHTML = false, onClose, title, footer } = modalSettings;

    const closeLabel = useCommonLabel("close");

    // combine both manually set close handler and common modal close handler
    const closeHandler = (): void => {
        close();
        if (onClose) {
            onClose();
        }
    };

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={closeHandler} show={show} size="md">
            <Modal.Header>
                {title && <Modal.Title>{title}</Modal.Title>}
                <Modal.Button type="button" aria-label={closeLabel} onClick={closeHandler}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {useHTML && typeof content === "string" ? (
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                    content
                )}
                {footer && (
                    <>
                        <br />
                        {footer}
                    </>
                )}
            </Modal.Body>
        </ModalBaseView>
    );
};

export default ContentModal;
