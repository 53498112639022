import React from "react";
import * as Styles from "./df-car-result/styles/UsedCarResultStyles";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { Ellipsis } from "../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import { IconCheckmark, IconTradeIn } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";

type CompareButtonType = {
    onClick: () => void;
    isActive?: boolean;
};

const CompareButton = ({ onClick, isActive }: CompareButtonType): JSX.Element => {
    const compareLabel = useCarFilterLabel("carFilterResultCompare");

    return (
        <Styles.Button
            onClick={(evt) => {
                evt.stopPropagation();
                onClick();
            }}
            isActive={isActive}
        >
            <IconWrapper aria-hidden="true">{isActive ? <IconCheckmark /> : <IconTradeIn />}</IconWrapper>
            <Ellipsis maxWidth="100%">{compareLabel}</Ellipsis>
        </Styles.Button>
    );
};

export default CompareButton;
