/**
 * TypeScript compatible version of Boolean
 * Usage: myArrayWithPossibleNullOrUndefinedValues.filter(TsBoolean)
 * @param value - Any type
 * @returns The true if value is defined, false if value is null or undefined
 */
export const TsBoolean = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined;

/**
 * TypeScript compatible version of Object.values
 * Usage: TsObjectEntries(myObject)
 * @param obj - Record<key, value>
 * @returns A typed version of Object.entries (key & value pairs are perserved)
 */
export const TsObjectEntries = <T extends object>(obj: T): [keyof T, T[keyof T]][] => {
    return Object.entries(obj) as [keyof T, T[keyof T]][];
};

/**
 * This type was provided by redux but was removed since v5, so it was added in our types
 */
export type DeepPartial<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};
