import type { CarFilterDispatchType, CarFilterStateType } from "../redux/store";
import type { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { StockCarFilterId, UsedCarFilterId, UscContext } from "../../shared-logic/types/UscCommonTypes";
import {
    resetMultiFilter,
    resetPriceFilter,
    resetSlider,
    setDealer,
    setNoSimilarCarsFound,
    setPrice,
    setPriceActive,
    setSelectMultiFilter,
    setSimilarCarAttempt,
    setSliderValue,
} from "../redux/actions/CarFiltersActions";
import type { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import { SimilarCarFilterId } from "./constants/similarCarConstants";
import { getRoundedFilterValue } from "../../../shared-logic/features/filters/utils/filters";

export const configureSimilarCarFilter = (
    dispatch: CarFilterDispatchType,
    carFilterSettings: CarFilterSettingsType,
    carFilters: CarFiltersReducerType,
    useDealerLocation?: boolean, // This will always add the dealer location in the final filter config. Added for Retailer integration
): void => {
    const { similarCar, currentFilter } = carFilters;

    const similarCarData = similarCar.carData;

    if (!similarCarData) return;

    const contextIsUsed = currentFilter === UscContext.Used;

    if (useDealerLocation) dispatch(setDealer(UsedCarFilterId.Location, similarCarData.dealer));

    // This function assumes the carFilter storage is empty.
    carFilterSettings.similarCarsConfig[carFilters.currentFilter].forEach((config) => {
        // To keep things simple we'll use a manual switch and set the according filter.
        switch (config.filterId) {
            case SimilarCarFilterId.Model: {
                dispatch(
                    setSelectMultiFilter(
                        [similarCarData.modelId],
                        true,
                        contextIsUsed ? UsedCarFilterId.Model : StockCarFilterId.Model,
                    ),
                );
                break;
            }

            case SimilarCarFilterId.Brand: {
                const hasModelConfig = carFilterSettings.similarCarsConfig[carFilters.currentFilter].some(
                    (conf) => conf.filterId === SimilarCarFilterId.Model,
                );

                // If a model-level match is already configured we dont need to set brand as model is more specific.
                if (!hasModelConfig) {
                    dispatch(
                        setSelectMultiFilter(
                            [similarCarData.brandId],
                            true,
                            contextIsUsed ? UsedCarFilterId.Brand : StockCarFilterId.Brand,
                        ),
                    );
                }
                break;
            }

            case SimilarCarFilterId.FuelType: {
                dispatch(
                    setSelectMultiFilter(
                        [similarCarData.fuelTypeId],
                        true,
                        contextIsUsed ? UsedCarFilterId.FuelType : StockCarFilterId.FuelType,
                    ),
                );
                break;
            }

            case SimilarCarFilterId.Transmission: {
                const filterId = contextIsUsed ? UsedCarFilterId.Transmission : StockCarFilterId.Transmission;
                dispatch(setSelectMultiFilter([similarCarData.transmissionId], true, filterId));
                break;
            }

            case SimilarCarFilterId.Year: {
                const currentYear = new Date().getFullYear();

                if (config.value && similarCarData.year) {
                    const value = Number(config.value);
                    if (!Number.isNaN(value) && value > 0) {
                        const minValue = similarCarData.year - value;
                        const maxValue = Math.min(similarCarData.year + value, currentYear);
                        dispatch(setSliderValue("min", minValue, UsedCarFilterId.Year));
                        dispatch(setSliderValue("max", maxValue, UsedCarFilterId.Year));
                    }
                }

                break;
            }

            case SimilarCarFilterId.Location: {
                // Unsupported for now, we don't have straightforward support for a range around a dealer yet.
                break;
            }

            case SimilarCarFilterId.Price: {
                const filterId = contextIsUsed ? UsedCarFilterId.Price : StockCarFilterId.Price;
                if (config.value) {
                    const value = Number(config.value);
                    const { minValue, maxValue, step } = carFilters[filterId].cash;

                    if (!Number.isNaN(value) && value > 0 && value < 100) {
                        const minRoundedValue = getRoundedFilterValue(
                            similarCarData.price * (1 - value / 100),
                            minValue,
                            maxValue,
                            step,
                        );
                        const maxRoundedValue = getRoundedFilterValue(
                            similarCarData.price * (1 + value / 100),
                            minValue,
                            maxValue,
                            step,
                        );
                        dispatch(setPrice(filterId, "min", minRoundedValue, "cash"));
                        dispatch(setPrice(filterId, "max", maxRoundedValue, "cash"));
                        dispatch(setPriceActive(filterId, "cash"));
                    }
                }
                break;
            }
            case SimilarCarFilterId.Mileage: {
                if (config.value && similarCarData.mileage) {
                    const value = Number(config.value);
                    const { minValue, maxValue, step } = carFilters[UsedCarFilterId.Mileage];
                    if (!Number.isNaN(value) && value > 0 && value < 100) {
                        const minMileageValue = getRoundedFilterValue(
                            similarCarData.mileage * (1 - value / 100),
                            minValue,
                            maxValue,
                            step,
                        );

                        const maxMileageValue = getRoundedFilterValue(
                            similarCarData.mileage * (1 + value / 100),
                            minValue,
                            maxValue,
                            step,
                        );

                        dispatch(setSliderValue("min", minMileageValue, UsedCarFilterId.Mileage));
                        dispatch(setSliderValue("max", maxMileageValue, UsedCarFilterId.Mileage));
                    }
                }
                break;
            }
        }
    });
};

/**
 * Remove a filter generated by similar car config to try to get more results.
 * This is used for the fallback logic which will try to change the similar car logic to be less specific when no results are found
 *
 * When filtersUpdated is true the carFilters have been updated.
 *
 * ⚠️ Word of warning: this logic is becoming quite complex so if this ever gets more complicated consider reworking some of the similar car concepts.
 */
export const generaliseSimilarCarsFilter = (
    dispatch: CarFilterDispatchType,
    getState: () => CarFilterStateType,
): { filtersUpdated: boolean } => {
    const { carFilters, carFilterSettings } = getState();
    const contextIsUsed = carFilters.currentFilter === UscContext.Used;
    const similarCarsConfig = carFilterSettings.similarCarsConfig[carFilters.currentFilter];

    // Determine the next similar car filter to be disabled.
    // Start from the last values in the similar cars config and work our way up (least important constraint => most important constraint).
    const filterToBeDisabledIndex = similarCarsConfig.length - 1 - carFilters.similarCar.fallbackAttempts;
    const filterToBeDisabled = similarCarsConfig[filterToBeDisabledIndex]?.filterId;

    // Do not disable the last filter, return false to imply we dont need to continue the loop.
    // Should be an edge case as this implies there are zero similar cars even with a single constraint.
    if (filterToBeDisabledIndex === 0) {
        dispatch(setNoSimilarCarsFound(true));
        return { filtersUpdated: false };
    }

    // FilterId found, disable it and increase the similarCarAttempt value afterwards.
    switch (filterToBeDisabled) {
        case SimilarCarFilterId.Model: {
            dispatch(resetMultiFilter(contextIsUsed ? UsedCarFilterId.Model : StockCarFilterId.Model));

            break;
        }

        case SimilarCarFilterId.Brand: {
            dispatch(resetMultiFilter(contextIsUsed ? UsedCarFilterId.Brand : StockCarFilterId.Brand));
            break;
        }

        case SimilarCarFilterId.FuelType: {
            dispatch(resetMultiFilter(contextIsUsed ? UsedCarFilterId.FuelType : StockCarFilterId.FuelType));
            break;
        }

        case SimilarCarFilterId.Transmission: {
            const filterId = contextIsUsed ? UsedCarFilterId.Transmission : StockCarFilterId.Transmission;
            dispatch(resetMultiFilter(filterId));
            break;
        }

        case SimilarCarFilterId.Year: {
            dispatch(resetSlider(UsedCarFilterId.Year));
            break;
        }

        case SimilarCarFilterId.Price: {
            const filterId = contextIsUsed ? UsedCarFilterId.Price : StockCarFilterId.Price;
            dispatch(resetPriceFilter(filterId));
            break;
        }
        case SimilarCarFilterId.Mileage: {
            dispatch(resetSlider(UsedCarFilterId.Mileage));
            break;
        }
    }

    dispatch(setSimilarCarAttempt(carFilters.similarCar.fallbackAttempts + 1));

    return { filtersUpdated: true };
};
