import styled, { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { theme } from "../theme/toyotaTheme";
import * as Flex from "../globals/Flex";

const Preloader = styled.div<{ delayedVisibility?: boolean; size?: number; hasLabel?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    &::before,
    &::after {
        position: absolute;
        top: ${({ hasLabel }) => (hasLabel ? "calc(50% - 15px)" : "50%")};
        left: 50%;
        width: ${(props) => props.size || 45}px;
        height: ${(props) => props.size || 45}px;
        /* stylelint-disable */
        margin-top: calc(-${(props) => (props.size || 45) / 2}px);
        margin-left: calc(-${(props) => (props.size || 45) / 2}px);
        /* stylelint-enable */
        content: "";
        background-clip: padding-box;
        border-top: 2px solid ${theme.colors.primaryRed};
        border-right: 2px solid ${theme.colors.primaryRed};
        border-radius: 50%;

        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    &::before {
        border-top: 2px solid rgba(229, 0, 0, 0.8);
        border-bottom: 2px solid ${theme.colors.grey2};
        border-left: 2px solid ${theme.colors.grey2};
        animation-name: spin-loader;
        z-index: 10;
    }

    &::after {
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 1px solid rgba(229, 0, 0, 0.8);
        animation-name: spin-loader;
        z-index: 20;
    }
    @keyframes spin-loader {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* Use this boolean when you want the showing of the loader to be delayed a half second */
    /* Useful for stopping the loader flashing when an item loads instantly */
    ${({ delayedVisibility }) =>
        delayedVisibility !== undefined &&
        css`
            @keyframes fadeInCutOut {
                0% {
                    opacity: 0;
                }
                99% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }

            ${delayedVisibility
                ? css`
                      animation: fadeInCutOut 0.3s ease-in;
                  `
                : css`
                      opacity: 0;
                  `};
        `};
`;

export const Wrapper = styled(Flex.Col)<{ isBoxed?: boolean }>`
    display: flex;
    position: ${({ isBoxed }) => (isBoxed ? "absolute" : "relative")};
    top: ${({ isBoxed }) => (isBoxed ? "50%" : "auto")};
    left: ${({ isBoxed }) => (isBoxed ? "50%" : 0)};
    right: ${({ isBoxed }) => (isBoxed ? "auto" : 0)};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${({ isBoxed }) => (isBoxed ? "210px" : "100%")};
    height: ${({ isBoxed }) => (isBoxed ? "200px" : "auto")};
    background-color: ${({ isBoxed }) => (isBoxed ? theme.colors.primaryWhite : "transparent")};
    border-radius: ${({ isBoxed }) => (isBoxed ? "4px" : 0)};
    transform: ${({ isBoxed }) => (isBoxed ? "translate(-50%, -50%)" : "translate(0)")};
    z-index: ${({ isBoxed }) => (isBoxed ? theme.zIndex.modal : "auto")};
    &::after {
        display: block;
        width: 100%;
        height: 50vh;
        content: "";
    }
`;

export const Percentage = styled.span<{ hasLabel?: boolean }>`
    position: absolute;
    top: ${({ hasLabel }) => (hasLabel ? "calc(50% - 15px)" : "50%")};
    left: 50%;
    width: 90px;
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    text-align: center;
    color: ${theme.colors.primaryRed};
    transform: translate(-50%, -50%);
`;

export const Label = styled.div`
    position: absolute;
    bottom: 30px;
    font-family: ${theme.fonts.base};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.grey4};
`;

export const PreLoaderFullScreen = styled(Preloader)`
    height: 80vh;
`;

export const PreLoaderWrapper = styled.div<{ hasBorder?: boolean; hasButtonHeight?: boolean } & SpaceProps>`
    position: relative;
    height: 100%;
    ${space};

    ${({ hasButtonHeight }) =>
        hasButtonHeight &&
        css`
            min-width: 115px;
            height: 46px;
        `};

    ${({ hasBorder }) =>
        hasBorder &&
        css`
            padding: 8px;
            border: 1px solid ${theme.colors.grey2};
        `};
`;

export const PreloaderBackdrop = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.85);
`;

export default Preloader;
