import styled, { css } from "styled-components";
import type { DisplayProps, SpaceProps, ColorProps, TextAlignProps } from "styled-system";
import { display, space, color, textAlign } from "styled-system";
import { getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

/**
 * Utility class for re-using typography styles in other components.
 */

export const fontTypes = ["regular", "semiBold"];

export type TextStyleType = {
    fontType?: (typeof fontTypes)[number];
    isSmall?: boolean;
    isPdf?: boolean;
};

const textStyles = css<TextStyleType>`
    font-family: ${theme.fonts.base};

    ${(props) =>
        props.fontType === "regular" &&
        `
        font-family: ${theme.fonts.regular};
    `};

    ${(props) =>
        props.fontType === "semiBold" &&
        `
        font-family: ${theme.fonts.semiBold};
    `};
`;

export const h1Style = css<TextStyleType>`
    ${textStyles};
    font-size: 3.4rem;
    line-height: 4rem;

    @media ${getBreakpoint("up", "lg")} {
        ${(props) =>
            !props.isSmall &&
            !props.isPdf &&
            css`
                font-size: 4rem;
                line-height: 4.8rem;
            `};
    }

    ${(props) =>
        props.isPdf &&
        css`
            font-size: 1.4rem;
            line-height: 2rem;
        `};
`;

export const h2Style = css<TextStyleType>`
    ${textStyles};
    font-size: 2.8rem;
    line-height: 3.2rem;
    @media ${getBreakpoint("up", "lg")} {
        ${(props) =>
            !props.isSmall &&
            css`
                font-size: 3.4rem;
                line-height: 4rem;
            `};
    }

    ${(props) =>
        props.isPdf &&
        css`
            font-size: 1.2rem;
            line-height: 1.6rem;
        `};
`;

export const h3Style = css<TextStyleType>`
    ${textStyles};
    font-size: 2.2rem;
    line-height: 2.8rem;
    @media ${getBreakpoint("up", "lg")} {
        ${(props) =>
            !props.isSmall &&
            css`
                font-size: 2.8rem;
                line-height: 3.2rem;
            `};
    }

    ${(props) =>
        props.isPdf &&
        css`
            font-size: 1rem;
            line-height: 1;
        `};
`;

export const h4Style = css<TextStyleType>`
    ${textStyles};
    font-size: 2rem;
    line-height: 2.4rem;
    @media ${getBreakpoint("up", "lg")} {
        ${(props) =>
            !props.isSmall &&
            css`
                font-size: 2.2rem;
                line-height: 2.8rem;
            `};
    }
`;

export const h5Style = css<TextStyleType>`
    ${textStyles};
    font-size: 2rem;
    line-height: 2.4rem;
`;

export const h6Style = css<TextStyleType>`
    ${textStyles};
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

export const bodyStyle = css<TextStyleType>`
    ${textStyles};
    font-size: 1.6rem;
    line-height: 2.4rem;

    ${(props) =>
        props.isPdf &&
        css`
            font-size: 0.8rem;
            line-height: 1.2rem;
        `};

    ${(props) =>
        props.isSmall &&
        css`
            font-size: 1.3rem;
            line-height: 2rem;
        `};

    ${(props) =>
        props.isPdf &&
        props.isSmall &&
        css`
            font-size: 0.7rem;
            line-height: 1.2rem;
        `};
`;

export const headerBodyStyle = css`
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

export const legalStyle = css<TextStyleType>`
    ${textStyles};
    font-size: 1.3rem;
    line-height: 2rem;

    ${(props) =>
        props.isPdf &&
        css`
            font-size: 0.6rem;
            line-height: 1rem;
        `};
`;

export const legalHeadingStyle = css`
    font-family: ${theme.fonts.semiBold};
    font-size: 1.3rem;
    line-height: 2rem;
    text-transform: uppercase;
`;

export const ctaStyle = css<TextStyleType>`
    ${(props) =>
        props.isPdf &&
        css`
            font-size: 0.7rem;
            line-height: 1rem;
        `};
`;

export const badgeStyle = css<TextStyleType>`
    ${(props) =>
        props.isPdf &&
        css`
            font-size: 0.6rem;
            line-height: 1rem;
        `};
`;

/**
 * Typography styles
 */

export const HEADING_TYPES = [
    "alpha",
    "base",
    "beta",
    "delta",
    "epsilon",
    "gamma",
    "milli-headline",
    "nano",
    "sub-beta",
    "sub-epsilon",
    "zeta",
];

export const STYLE_TYPES = ["text", "headline"];

type HeadingType = {
    headingType?: (typeof HEADING_TYPES)[number];
    styleType?: (typeof STYLE_TYPES)[number];
};

export const H1 = styled.h1.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    text-transform: none !important;
    ${space};
`;

export const H2 = styled.h2.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps & TextAlignProps>`
    text-transform: none !important;
    ${space};
    ${textAlign}
`;

export const H3 = styled.h3.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
`;

export const H4 = styled.h4.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps & DisplayProps>`
    ${display};
    ${space};
`;

export const H5 = styled.h5.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
`;

export const H6 = styled.h6.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `t-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps & DisplayProps>`
    ${display};
    ${space};
`;

export const Strong = styled.strong.attrs(() => ({
    className: "t-base-headline",
}))<{ isDark?: boolean } & SpaceProps>`
    ${space};

    ${({ isDark }) =>
        isDark &&
        css`
            color: ${theme.colors.primaryWhite};
        `};
`;

export const Text = styled.span.attrs<HeadingType>((props) => ({
    className: `t-${props.headingType || "base"}-${props.styleType ? props.styleType : "text"}`,
}))<{ noUppercase?: boolean } & HeadingType & SpaceProps & ColorProps>`
    ${space};
    ${color};

    ${({ noUppercase }) =>
        noUppercase &&
        css`
            text-transform: none !important;
        `};
`;

export const SmallText = styled.span.attrs(() => ({
    className: "t-milli-text",
}))<SpaceProps & ColorProps>`
    ${space};
    ${color};
`;

export const SmallHeading = styled.span.attrs(() => ({
    className: "t-milli-headline",
}))<{ noUppercase?: boolean } & SpaceProps & DisplayProps>`
    ${display};
    ${space};

    ${({ noUppercase }) =>
        noUppercase &&
        css`
            text-transform: none !important;
        `};
`;

export const BodyTypeName = styled.span<SpaceProps>`
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.grey7};
    ${space};
`;

export const ModelName = styled.span<SpaceProps>`
    display: inline-block;
    font-size: 2.8rem;
    ${space};
`;

export const GradeName = styled.span<SpaceProps>`
    display: inline-block;
    font-size: 2.8rem;
    color: ${theme.colors.grey7};
    ${space};
`;
