import { CERTIFICATION_WLTP, CERTIFICATION_CORRELATED_NEDC, CERTIFICATION_NEDC } from "../constants";
import { wltpValueIsKey } from "../utils";
import type { ParsedSiteSettingsLabelsType } from "../settings/SiteSettingsType";

// These are labels coming from site settings that are used in multiple components
export const T1Labels = {
    readMore: "",
    readLess: "",
    "T1.readMore": "",
    "T1.readLess": "",

    // disclaimers
    "T1.disclaimerWltpShort": "",
    "T1.disclaimerNedc": "",
    "T1.disclaimerCorrelatedNedc": "",
    "T1.disclaimerWltp": "",
    "T1.disclaimerWltpElectric": "",
    disclaimerEv: "",

    // hybrid tag labels (part of T1 labels, but can be overwritten from build settings as well)
    hybrid: "", // "Hybrid"
    hybridAvailable: "", // "Hybrid available"
    fuelCell: "", // "Fuel cell"
    electric: "", // "Electric"
    plugin: "", // "Plugin Hybrid"
    diesel: "", // Diesel
    petrol: "", // Petrol
    multipleEngineOptions: "", // Multiple engine options
    quotes: "", //  "Quotes"
    ecoBonusLabel: "",
    ecoBonusTooltip: "",
};

export type T1LabelType = keyof typeof T1Labels;

export const WLTPLabelsMap: Record<number, T1LabelType> = {
    [CERTIFICATION_WLTP]: "T1.disclaimerWltp" || "T1.disclaimerWltpElectric",
    [CERTIFICATION_CORRELATED_NEDC]: "T1.disclaimerCorrelatedNedc",
    [CERTIFICATION_NEDC]: "T1.disclaimerNedc",
};

// See FetchSettings.js -> parseLabels for the old untyped Build implementation
export const extractT1Labels = (siteSettings: ParsedSiteSettingsLabelsType): Record<T1LabelType, string> => {
    return {
        ...T1Labels,
        readMore: siteSettings.labels.readMore as string,
        readLess: siteSettings.labels.readLess as string,
        "T1.readMore": siteSettings.labels["T1.readMore"] as string,
        "T1.readLess": siteSettings.labels["T1.readLess"] as string,

        "T1.disclaimerNedc": wltpValueIsKey(siteSettings.labels.disclaimerNedc as string, "T1.disclaimerNedc"),
        "T1.disclaimerCorrelatedNedc": wltpValueIsKey(
            siteSettings.labels.disclaimerCorrelatedNedc as string,
            "T1.disclaimerCorrelatedNedc",
        ),
        "T1.disclaimerWltp": wltpValueIsKey(siteSettings.labels.disclaimerWltp as string, "T1.disclaimerWltp"),
        "T1.disclaimerWltpElectric": wltpValueIsKey(
            siteSettings.labels.disclaimerWltpElectric as string,
            "T1.disclaimerWltpElectric",
        ),
        disclaimerEv: siteSettings.labels.disclaimerEv as string,

        hybrid: siteSettings.labels.hybrid as string,
        hybridAvailable: siteSettings.labels.hybridAvailable as string,
        fuelCell: siteSettings.labels.fuelCell as string,
        electric: siteSettings.labels.electric as string,
        plugin: siteSettings.labels.plugin as string,
        diesel: siteSettings.labels.diesel as string,
        petrol: siteSettings.labels.petrol as string,
        multipleEngineOptions: siteSettings.labels.multipleEngineOptions as string,
        quotes: siteSettings.labels.quotes as string,
        ecoBonusLabel: siteSettings.labels.ecoBonusLabel as string,
        ecoBonusTooltip: siteSettings.labels.ecoBonusTooltip as string,
    };
};
