import React from "react";
import { shallowEqual } from "react-redux";
import * as Styles from "./styles/DualCurrencyDisclaimerStyles";
import { useCommonSelector } from "../../../../common-deprecated/redux/commonStore";
import { hasDualCurrency } from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { dualCurrencyDisclaimerSelector } from "../../../../shared-logic/utils/dualCurrencyDisclaimerUtils";

type DualCurrencyDisclaimerType = {
    variantOutline?: boolean;
    isPdf?: boolean;
};

const DualCurrencyDisclaimer = ({ variantOutline, isPdf }: DualCurrencyDisclaimerType): JSX.Element | null => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const label = useCommonSelector(dualCurrencyDisclaimerSelector, shallowEqual);

    if (!hasDualCurrency(commonSettings, false)) return null;

    return (
        <Styles.Wrapper isSmall variantOutline={variantOutline} isPdf={isPdf}>
            {label}
        </Styles.Wrapper>
    );
};

export default DualCurrencyDisclaimer;
