import styled, { css } from "styled-components";
import type { MaxWidthProps } from "styled-system";
import { maxWidth } from "styled-system";

/**
 * Ellipsis is a utility component that will proper truncate a string to one line
 * @example
 * export const Title = styled(Type.H2)`
 *   font-family: ${theme.fonts.regular};
 *   font-size: 2rem;
 *   ${ellipsisStyle};
 * `;
 */
export const EllipsisStyle = css<MaxWidthProps>`
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    ${maxWidth};
`;

export const Ellipsis = styled.span<MaxWidthProps>`
    ${EllipsisStyle};
`;
