import React, { memo, useMemo } from "react";
import CarFilterBase from "./CarFilterBase";
import { ComponentRegistryProvider } from "./componentRegistry";
import FilterSavedCars from "./filters/FilterSavedCars";
import MobileStaticFilterBar from "./active-filters/top-bar/MobileStaticFilterBar";
import StaticFilterBar from "./active-filters/top-bar/StaticFilterBar";
import UsedCarResult from "./car-results/df-car-result/UsedCarResult";
import IntegratedUsedCompareWrapper from "./IntegratedUsedCompareWrapper";
import CarResultsContainerWrapper from "./car-results/CarResultsContainerWrapper";
import PageFinanceDisclaimer from "../../../shared-components/toyota/page-finance-disclaimer/PageFinanceDisclaimer";
import { CAR_FILTER_ID } from "../../utils/constants/filterConstants";
import type { AdditionalModalsType } from "../../../../common-deprecated/hooks/useStatus";
import useStatus from "../../../../common-deprecated/hooks/useStatus";
import { EnvironmentEnum } from "../../../../common-deprecated/utils";
import { MODAL_VEHICLE_FOR_SALE_ID } from "../../../shared-logic/utils/modalConstants";
import { getUscExtraQueryBuilderOptions } from "../../../shared-logic/utils/uscUtils";
import { MODAL_FILTER_CONFIG_HELPER } from "../../utils/modalConstants";
import { useCarFilterSelector } from "../../redux/store";
import { useModalMap } from "../../../../common-deprecated/containers/ModalProvider";
import { aemToyotaCarFilterModalMap } from "./floating-elements/modals/modals";
import TopFilters from "./top-filters/TopFilters";

const CarFilter = (): JSX.Element => {
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);

    const componentRegistry = useMemo(
        () => ({
            FilterSavedCars,
            MobileStaticFilterBar,
            StaticFilterBar,
            UsedCarResult,
            IntegratedUsedCompareWrapper,
            CarResultsContainerWrapper,
            PageFinanceDisclaimer,
            ScrollIndicatorUp: memo(() => null),
            TopFilters,
        }),
        [],
    );

    const additionalModals: AdditionalModalsType =
        commonSettings.environment !== EnvironmentEnum.Production
            ? [
                  { key: "f", settings: { type: MODAL_FILTER_CONFIG_HELPER } },
                  {
                      key: "i",
                      settings: {
                          type: MODAL_VEHICLE_FOR_SALE_ID,
                          detailPageUrl: carFilterSettings.urlDetailPage[currentFilter],
                          landingPageUrl: carFilterSettings.urlLandingPage[currentFilter],
                      },
                  },
              ]
            : [];

    const extraQueryBuilderOptions = getUscExtraQueryBuilderOptions(commonSettings);
    useStatus(commonSettings, "Car Filter", [], additionalModals, extraQueryBuilderOptions);
    useModalMap(aemToyotaCarFilterModalMap);

    return (
        <ComponentRegistryProvider registry={componentRegistry}>
            {/* This id should always be on top as this is used by GFI to navigate/scroll to the component. */}
            <div id={CAR_FILTER_ID}>
                <CarFilterBase />
            </div>
        </ComponentRegistryProvider>
    );
};

export default CarFilter;
