import type { PropsWithChildren, RefObject } from "react";
import React, { memo } from "react";
import type { SpacingType, TextVariants } from "./Text.styles";
import TextVariant from "./Text.styles";
import type { UscColorType } from "../Variables.types";

export type TextVariantsType = keyof typeof TextVariants;

export type TextProps = {
    tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "div" | "dt" | "dd";
    variant?: TextVariantsType;
    mobileVariant?: TextVariantsType;
    color?: UscColorType;
    spacing?: SpacingType;
    id?: string;
    skeleton?: boolean;
    ref?: RefObject<HTMLDivElement>;
    center?: boolean;
};

const Text: React.FC<PropsWithChildren<TextProps>> = (props) => {
    const {
        tag = "span",
        variant = "body-book",
        mobileVariant,
        color,
        spacing,
        id,
        skeleton,
        center,
        ref,
        children,
        ...rest
    } = props;

    return (
        <TextVariant
            as={tag}
            id={id}
            variant={variant}
            mobileVariant={mobileVariant}
            color={color}
            spacing={spacing}
            center={center}
            skeleton={skeleton}
            ref={ref}
            {...rest}
        >
            {children}
        </TextVariant>
    );
};

export default memo(Text);
