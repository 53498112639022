import React from "react";
import type { ToyotaIconVariantType, UscIconVariantType } from "./Icon.types";
import { ToyotaIconVariants, UscIconVariants } from "./Icon.types";

const IconSizeProps = { small: 20, medium: 40, large: 80 };

export type IconProps = {
    variant: UscIconVariantType | ToyotaIconVariantType;
    iconSize?: keyof typeof IconSizeProps;
} & React.HTMLProps<SVGSVGElement>;

const Icon: React.FC<IconProps> = ({ variant, iconSize = "small" }) => {
    // Check if the svg icon is available in our USC icon set
    if (UscIconVariants[variant as UscIconVariantType]) {
        const sizeProps = { width: IconSizeProps[iconSize], height: IconSizeProps[iconSize], viewBox: "0 0 20 20" };
        const SvgIcon = UscIconVariants[variant as UscIconVariantType];

        return <SvgIcon {...sizeProps} aria-hidden="true" />;
    }

    // Else return a icon from the Toyota icon font
    return (
        <span
            className={`dxp-icon icon-${ToyotaIconVariants[variant as ToyotaIconVariantType]}-${iconSize}`}
            aria-hidden="true"
        />
    );
};

export default Icon;
