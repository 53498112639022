import type { NotificationType } from "../../types/CommonTypes";

export const SET_NOTIFICATION = "notification/set" as const;
export const ADD_NOTIFICATION = "notification/add" as const;
export const REMOVE_NOTIFICATION = "notification/remove" as const;
export const FADE_NOTIFICATION = "notification/fade" as const;
export const READY_NOTIFICATION = "notification/ready" as const;

type SetNotificationType = { type: typeof SET_NOTIFICATION; notification: NotificationType | null };
type AddNotificationType = { type: typeof ADD_NOTIFICATION; notifications: NotificationType[] };
type RemoveNotificationType = { type: typeof REMOVE_NOTIFICATION; notification: NotificationType };
type FadeNotificationType = { type: typeof FADE_NOTIFICATION; notifications: NotificationType[] };
type ReadyNotificationType = { type: typeof READY_NOTIFICATION; notifications: NotificationType[] };

/**
 * @deprecated
 * This will overwrite any existing notifications.
 * Use addNotification instead.
 *  */
export const setNotification = (notification: NotificationType | null): SetNotificationType => ({
    type: SET_NOTIFICATION,
    notification,
});

export const addNotification = (notifications: NotificationType[]): AddNotificationType => ({
    type: ADD_NOTIFICATION,
    notifications,
});

export const removeNotification = (notification: NotificationType): RemoveNotificationType => ({
    type: REMOVE_NOTIFICATION,
    notification,
});

export const fadeNotification = (notifications: NotificationType[]): FadeNotificationType => ({
    type: FADE_NOTIFICATION,
    notifications,
});

export const readyNotification = (notifications: NotificationType[]): ReadyNotificationType => ({
    type: READY_NOTIFICATION,
    notifications,
});

export type NotificationActionsType =
    | SetNotificationType
    | AddNotificationType
    | RemoveNotificationType
    | FadeNotificationType
    | ReadyNotificationType;
