import type { DefaultTheme } from "styled-components";
import type { TridionThemeType } from "./common";

const color = {
    primary: "#FF0022", // Corporate default red
    primaryHover: "#D90022", // Hover state from default red
    lightest: "#FFF", // Primary white
    dark: "#282830", // Corporate default black / Primary grey
    grey1: "#EFF0F0", // TME Grey 6 - secondary background; used for background for shapes, cards and components
    grey2: "#CECFD0", // TME Grey 5 - tertiary background; used for dividers as well of hover states for secondary backgrounds
    grey3: "#ADADB0", // TME Grey 4 - used as primary icon colour in all designs
    grey4: "#6A6B70", // TME Grey 2 - Text links, used for article backgrounds
    grey5: "#494950", // TME Grey 1 - Paragraphs
    grey6: "#8B8C90", // TME Grey 3
    grey7: "#6C7073",
    grey8: "#F0F0F0",
    transparentGrey1: "rgba(239,240,240,0.65)", // TME Transparent Grey 6 - #EFF0F0 65%
    transparentGrey2: "rgba(106,107,112,0.10)", // TME Transparent Grey 2 - #6A6B70 65% - Used over bright background or photos
    transparentGrey3: "rgba(73,73,80,0.65)", // TME Transparent Grey 1 - #494950 10% - Used over dark background or photo's
    success: "#4CAF50", // Secondary colour - green
    info: "#3F51B5", // Secondary colour - Indigo
    iconNeutral: "#ADADB0", // Primary Icon Colour (TME Grey 4)
    hybrid: "#00A0F0", // Secondary colour - hybrid
    fuelCell: "#3F51B5", // secondary colour - Indigo
    orange: "#FF9900", // secondary colour
    teal: "#009688", // secondary colour - teal
    deepPurple: "#673AB7", // secondary colour - deep purple
    cyan: "#00BCD4", // secondary colour - Cyan
    border: "#DDD",
};

const fontFamily = {
    light: "", // There is no light font in Toyota, but needed for Lexus
    book: '"T1 Body Font",Arial,Helvetica,sans-serif',
    regular: '"T1 Body Font Regular",Arial,Helvetica,sans-serif',
    bold: '"T1 Body Font Bold",Arial,Helvetica,sans-serif',
    displayRegular: '"T1 Body Font Regular",Arial,Helvetica,sans-serif',
    displayBold: '"T1 Body Font Bold",Arial,Helvetica,sans-serif',
};

const textColor = {
    muted: color.grey3,
    button: "#202020",
};

const spacer = 24; //default spacer, used for containers, rows and cols

const space = [0, 6, 12, 24, 48, 65, 80];

const breakpoints = {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
};

const zIndex = {
    popup: 700,
    fixed: 800,
    sticky: 900,
    overlay: 1055, // pageoverlayer inpage
    modalBackdrop: 1059, // overlay drop background
    modal: 1060, // overlayDrop
    /* Below I added some specific z-index from T1-style
            I added this so we can override this (e.g.: `z-index: ${variablename} + 1`) in the stying when there are some overlap issues.
         */
    galleryNavHorizontal: 4,
    flipbook: 5,
    materialCloseButton: 10,
    compareScrollContainer: 665,
    compareMiniEditMenuSelection: 666,
    loaderLazy: 900,
    pageoverlayerInpage: 1000,
    miniCcDropdownMenu: 1001,
    navBar: 1010,
    pageoverlayerToolbar: 1040,
    expansionContent: 1050,
    ctabar: 1051,
    navSeconary: 1052,
    navPrimary: 1053,
    navbarMyToyota: 1054,
    pageoverlayerExpansion: 1056,
    mobileBasketFooter: 1057,
    materialbox: 1060,
    bubble: 1062,
    loaderFull: 1070,
    autocomplete: 1101,
    eprivacyDisclaimer: 1102, // should always have highest priority
};

const gradeListItemWidth = 285;

/* component specific */

/* COMPARE */

const compare: DefaultTheme["compare"] = {
    header: {
        height: "100px",
        background: "#020202",
        borderRadius: "8px 8px 0 0",
        borderRadiusExpanded: "0 0 8px 8px",
        color: color.lightest,
    },
    bar: {
        titleFontFamily: fontFamily.regular,
        titleFontSize: "1.5rem",
        titleLineHeight: "inherit",
        titleColor: color.lightest,
        mainTitleColor: color.lightest,
        subTitleColor: color.grey4,
        dividerColor: "#585D5F",
    },
    entry: {
        titleFontFamily: fontFamily.regular,
        titleFontSize: "1.3rem",
        titleLineHeight: "2rem",
        titleColor: "#585D5F",
        valueFontFamily: fontFamily.bold,
        valueColor: color.dark,
        valueFontSize: "2.2rem",
        valueSuffixColor: color.dark,
        valueSuffixFontSize: "1.4rem",
    },
    section: {
        titleFontFamily: fontFamily.displayBold,
        titleFontSize: "3.2rem",
        titleColor: color.dark,
        titleTextTransform: "none",
    },
    specs: {
        labelFontFamily: fontFamily.regular,
        labelFontSize: "1.3rem",
        descriptionFontSize: "1.3rem",
        descriptionFontColour: color.grey5,
        descriptionFontStyle: "italic",
        labelLineHeight: "2rem",
        labelColor: color.grey4,
        labelMargin: "0",
        valueFontFamily: fontFamily.bold,
        valueFontSize: "1.6rem",
        valueColor: color.dark,
        valueFontLight: fontFamily.light,
    },
    swatches: {
        borderRadius: "50%",
    },
};

export const toyotaTheme: TridionThemeType = {
    isLexus: false,
    isRTL: false, // This will be overwritten on component init.
    noContainerSpacing: false,
    breakpoints,
    color,
    colors: color, // Match styled-system colors implementation.
    fontFamily,
    fonts: fontFamily, // Match styled-system font implementation.
    gradeListItemWidth,
    textColor,
    spacer,
    space,
    zIndex,
    compare,
};
