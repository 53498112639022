import styled, { css } from "styled-components";
import type { WidthProps } from "styled-system";
import { width } from "styled-system";
import { theme } from "../theme/toyotaTheme";
import { bodyStyle } from "./Type";
import { ToggleIconWrapper } from "./IconWrapper";
import { getDirection } from "../../../../themes/common";

export const Input = styled.input`
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: ${theme.space[2]}px 20px;
    text-align: start;
    text-overflow: ellipsis;
    padding-${getDirection("right")}: 56px; /* Spacing to avoid overlap with toggle icon */
    color: ${theme.colors.primaryBlack};
    white-space: nowrap;
    background-color: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    box-sizing: border-box;
    overflow: hidden;
    
    ${bodyStyle};

    &:focus {
        box-shadow: none;
    }

    &:focus-visible {
        user-select: none;
        outline: 0;
        box-shadow: 0 0 0 2px ${theme.colors.outline};
    }

    &:read-only {
        user-select: none;
    }

    &[aria-expanded="true"] {
        border-color: ${theme.colors.grey4};
    }

`;

export const IconWrapper = styled(ToggleIconWrapper)`
    display: flex;
    position: absolute;
    top: 0;
    ${getDirection("right")}: 0;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    font-size: 1.9rem;
    line-height: 5.6rem;
    color: ${theme.colors.primaryBlack};
    cursor: pointer;
`;

export const Option = styled.div`
    padding: 12px 20px;
    color: ${theme.colors.primaryBlack};
    cursor: pointer;

    &:hover {
        background-color: ${theme.colors.grey1};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.grey2};
    }
`;

export const Listbox = styled.div`
    display: block;
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    height: 0;
    max-height: 200px;
    padding: 0;
    text-align: start;
    background: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.25s ease-out;
    visibility: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndex.dropdown};

    ${Option} {
        transition: opacity 0.5s;
        opacity: 0;
    }

    @supports (max-height: fit-content) {
        max-height: fit-content;
    }
`;

export const Slider = styled.div`
    display: block;
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    height: 0;
    max-height: 125px;
    padding: 20px ${theme.space[3]}px;
    background: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    transition: height 0.25s ease-out;
    visibility: hidden;
    z-index: ${theme.zIndex.dropdown};
`;

export const Box = styled.div<{ fitContent?: boolean; noSpacing?: boolean; noPadding?: boolean }>`
    display: block;
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    height: 0;
    max-height: 300px;
    padding: ${theme.space[3]}px;
    text-align: start;
    background: ${theme.colors.primaryWhite};
    border: 1px solid ${theme.colors.grey2};
    transition: height 0.25s ease-out;
    visibility: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndex.materialbox};

    ${({ fitContent }) =>
        fitContent &&
        css`
            @supports (max-height: fit-content) {
                max-height: fit-content;
            }
        `};

    ${({ noSpacing }) =>
        noSpacing &&
        css`
            padding: 0;
            overflow: hidden;
        `};
`;

/*** Combobox
 * @example
 * 
    <label for="combobox-label">Label</label>
    <Combobox.Wrapper>
        <Combobox.Input type="text" id="combobox-label" role="combobox" aria-owns="IUAON9Bb" aria-autocomplete="list" aria-expanded="false" data-active-option="option1" aria-activedescendant="option1">
        <Combobox.IconWrapper>
            <IconChevronDown aria-hidden="true" data-trigger="single" />
        </Combobox.IconWrapper>
        <Combobox.Listbox id="IUAON9Bb" role="listbox">
            <Combobox.Option role="option" id="option1">Option 1</Combobox.Option>
            <Combobox.Option role="option" id="option2">Option 2</Combobox.Option>
            <Combobox.Option role="option" id="option3">Option 3</Combobox.Option>
        </Combobox.Listbox>
    </Combobox.Wrapper>
 */
export const Wrapper = styled.div<WidthProps>`
    display: inline-block;
    position: relative;
    ${width};

    ${Input}[aria-expanded="true"] ~ ${Listbox} {
        height: auto;
        border-color: ${theme.colors.grey4};
        transform: scaleY(1);
        visibility: visible;

        ${Option} {
            opacity: 1;
        }

        @supports (height: fit-content) {
            height: fit-content;
        }
    }
    ${Input}[aria-expanded="true"] ~ ${Slider} {
        height: 125px;
        border-color: ${theme.colors.grey4};
        visibility: visible;
    }
    ${Input}[aria-expanded="true"] ~ ${Box} {
        height: fit-content;
        border-color: ${theme.colors.grey4};
        visibility: visible;
    }
`;
