import type { TradeInAemExternalDataType } from "../reducers/TradeInAemExternalDataReducer";
import type { FinanceOptionType } from "../../../../types/CommonTypes";
import type { TradeInNotificationTypeEnum } from "../../utils/tradeInAemUtilTypes";

export const TRADE_IN_AEM_SET_EXT_DATA = "tradeInAem/setExtData";
export type TradeInAemSetExtData = { type: typeof TRADE_IN_AEM_SET_EXT_DATA; data: TradeInAemExternalDataType };
export const setTradeInAemExtData = (data: TradeInAemExternalDataType): TradeInAemSetExtData => ({
    type: TRADE_IN_AEM_SET_EXT_DATA,
    data,
});

export const TRADE_IN_AEM_CLEAR_EXT_DATA = "tradeInAem/clearExtData";
export type TradeInAemClearExtData = { type: typeof TRADE_IN_AEM_CLEAR_EXT_DATA };
export const clearTradeInAemExtData = (): TradeInAemClearExtData => ({
    type: TRADE_IN_AEM_CLEAR_EXT_DATA,
});

export const TRADE_IN_AEM_SET_EXT_DATA_FAILED = "tradeInAem/setFailed";
export type TradeInAemSetExtDataFailed = { type: typeof TRADE_IN_AEM_SET_EXT_DATA_FAILED; failed: boolean };
export const setTradeInAemExtDataFailed = (failed: boolean): TradeInAemSetExtDataFailed => ({
    type: TRADE_IN_AEM_SET_EXT_DATA_FAILED,
    failed,
});
export const TRADE_IN_AEM_SET_INCOMPATIBLE = "tradeInAem/setIncompatible";
export type TradeInAemSetIncompatible = { type: typeof TRADE_IN_AEM_SET_INCOMPATIBLE; incompatible: boolean };
export const setTradeInAemIncompatible = (incompatible: boolean): TradeInAemSetIncompatible => ({
    type: TRADE_IN_AEM_SET_INCOMPATIBLE,
    incompatible,
});
export const TRADE_IN_AEM_SET_SHOW_MODAL = "tradeInAem/setShowModal";
export type TradeInAemSetShowModal = { type: typeof TRADE_IN_AEM_SET_SHOW_MODAL; showModal: boolean };
export const setTradeInAemShowModal = (showModal: boolean): TradeInAemSetShowModal => ({
    type: TRADE_IN_AEM_SET_SHOW_MODAL,
    showModal,
});

export const TRADE_IN_AEM_SET_NOTIFICATION = "tradeInAem/setNotification";
export type TradeInAemSetNotification = {
    type: typeof TRADE_IN_AEM_SET_NOTIFICATION;
    financeType: FinanceOptionType;
    show: boolean;
    notificationType?: TradeInNotificationTypeEnum | null;
    isError?: boolean;
};
export const setTradeInNotification = (
    type: FinanceOptionType,
    show: boolean,
    notificationType?: TradeInNotificationTypeEnum | null,
    isError?: boolean,
): TradeInAemSetNotification => ({
    type: TRADE_IN_AEM_SET_NOTIFICATION,
    financeType: type,
    show,
    notificationType,
    isError,
});

export type TradeInAemExternalDataActions =
    | TradeInAemSetExtData
    | TradeInAemClearExtData
    | TradeInAemSetExtDataFailed
    | TradeInAemSetShowModal
    | TradeInAemSetNotification
    | TradeInAemSetIncompatible;
