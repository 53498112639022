import React from "react";
import TopFiltersContainer from "./active-filters/top-filters/TopFiltersContainer";
import CarResultsContainer from "./car-results/CarResultsContainer";
import * as Container from "../../../../common-deprecated/styles/v2/toyota/globals/Container";
import { useCarFilterSelector } from "../../redux/store";
import useCarFilter from "../../hooks/useCarFilter";
import PageDisclaimers from "./common/PageDisclaimers";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";
import Background from "../../../../common-deprecated/styles/v2/toyota/utilities/Background";
import { useComponentRegistry } from "./componentRegistry";
import GlobalStyle from "../../../shared-components/toyota/styles/Global.styles";
import DualCurrencyDisclaimer from "../../../shared-components/toyota/dual-currency-disclaimer/DualCurrencyDisclaimer";

const CarFilter = (): JSX.Element => {
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const resultsLoaded = useCarFilterSelector((state) => state.carResults.initialized);
    const hideTopFilters = carFilterSettings.hideTopFilters[currentFilter];
    const { noContainerSpacing } = commonSettings.layoutOptions;

    useCarFilter();

    const MobileStaticFilterBar = useComponentRegistry("MobileStaticFilterBar");
    const StaticFilterBar = useComponentRegistry("StaticFilterBar");
    const UsedCompareV2 = useComponentRegistry("IntegratedUsedCompareWrapper");
    const CarResultsContainerWrapper = useComponentRegistry("CarResultsContainerWrapper");
    const PageFinanceDisclaimer = useComponentRegistry("PageFinanceDisclaimer");
    const ScrollIndicatorUp = useComponentRegistry("ScrollIndicatorUp");

    return (
        <>
            <GlobalStyle />
            {currentFilter !== UscContext.Stock && (
                // If compare is ever enabled for stock cars, make sure to also re-enable compare data fetch.
                <UsedCompareV2 />
            )}

            {!hideTopFilters && (
                <Background backgroundColor="grey2">
                    <TopFiltersContainer />
                </Background>
            )}

            <PageFinanceDisclaimer />

            <Background backgroundColor="grey1">
                <StaticFilterBar />
                <MobileStaticFilterBar />

                <Container.default
                    maxWidth="1600px"
                    px={noContainerSpacing ? "0px" : { _: "24px !important", xxxl: "0 !important" }}
                >
                    <DualCurrencyDisclaimer />
                </Container.default>

                <CarResultsContainerWrapper>
                    <CarResultsContainer />
                </CarResultsContainerWrapper>
            </Background>

            <PageDisclaimers />

            <ScrollIndicatorUp recheckKey={String(resultsLoaded)} />
        </>
    );
};

export default CarFilter;
