import React from "react";
import { shallowEqual } from "react-redux";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import FilterCheckInput from "../../common/FilterCheckInput";
import { setSelectMultiFilter } from "../../../../redux/actions/CarFiltersActions";
import { UsedCarFilterId } from "../../../../../shared-logic/types/UscCommonTypes";
import * as Image from "../../../../../../common-deprecated/styles/toyota/Image";
import * as Styles from "./styles/EcoLabelFilterStyles";
import { ecoLabelIconMap } from "../../../../../shared-logic/utils/uscEcoUtils";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../utils/tracking";
import { useDispatch } from "../../../../../../shared-logic/redux/store";

// EcoLabel is a standard multiple choice filter, but the eco labels shown are dynamic.
const EcoLabelFilter = (): JSX.Element | null => {
    const filter = useCarFilterSelector((state) => state.carFilters[UsedCarFilterId.EcoLabel]);
    const resourcePath = useCarFilterSelector((state) => state.commonSettings.resourcePath);
    const country = useCarFilterSelector((state) => state.commonSettings.country);
    const trackParams = useCarFilterSelector(
        getAemCarFilterTrackParamsSelector(UsedCarFilterId.EcoLabel),
        shallowEqual,
    );
    const dispatch = useDispatch<CarFilterDispatchType>();

    return (
        <>
            {filter.values
                .sort((a, b) => {
                    // Sort eco labels based on the map above as the sort order from DF doesn't make sense and is not reliable.
                    const aIndex = ecoLabelIconMap[country]?.findIndex((iconMap) => iconMap.id === a.id) || 0;
                    const bIndex = ecoLabelIconMap[country]?.findIndex((iconMap) => iconMap.id === b.id) || 0;
                    return aIndex - bIndex;
                })
                .map((val) => {
                    const icon = ecoLabelIconMap[country].find((iconMap) => iconMap.id === val.id)?.icon;
                    const selectFilter = (): void => {
                        dispatch(setSelectMultiFilter([val.id], !val.selected, UsedCarFilterId.EcoLabel));
                        trackAemMultipleChoiceFilter(trackParams, filter.values, val.id);
                    };
                    return (
                        <Styles.EcoLabelFilter key={val.id}>
                            <FilterCheckInput
                                checked={val.selected}
                                label={val.label}
                                onChange={selectFilter}
                                id={val.id}
                                resultCount={val.resultCount}
                            />
                            {!!icon && (
                                <Image.Fluid
                                    onClick={selectFilter}
                                    src={`${resourcePath}/toyota/img/car-filter/${icon}`}
                                />
                            )}
                        </Styles.EcoLabelFilter>
                    );
                })}
        </>
    );
};

export default EcoLabelFilter;
