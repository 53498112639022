import styled, { css } from "styled-components";
import type { SpaceProps, MaxWidthProps } from "styled-system";
import { space, maxWidth } from "styled-system";
import { getBreakpoint } from "../../themes/common";
import { theme } from "../v2/toyota/theme/toyotaTheme";

// useful for displaying a placeholder in the same spot as a fluid image
export const Wrapper = styled.div<{ square?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${theme.colors.grey1};

    &::after {
        display: block;
        padding-bottom: ${({ square }) => (square ? "100%" : 0)};
        content: "";
    }
`;

export const Image = styled.img<SpaceProps & MaxWidthProps>`
    max-width: 100%;
    ${space};
    ${maxWidth};
`;

type FluidPropsType = { setMaxWidth?: boolean; fadeInAnimation?: boolean };

export const Fluid = styled(Image)<FluidPropsType>`
    width: 100%;

    @media ${getBreakpoint("up", "lg")} {
        /* TODO Why is this 874px? Seems oddly specific for such a general purpose style component. Consider moving this into styled-system. */
        width: ${({ setMaxWidth }) => (setMaxWidth ? "874px" : "100%")};
    }

    ${({ fadeInAnimation }) =>
        fadeInAnimation &&
        css`
            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
            animation: fadeIn 0.3s;
        `};
`;

export type FluidImagePropsType = React.ImgHTMLAttributes<HTMLImageElement> & FluidPropsType;
