import { useMemo } from "react";
import { shallowEqual } from "react-redux";
import type { CarFilterDispatchType } from "../../redux/store";
import { useCarFilterSelector } from "../../redux/store";
import { setPage } from "../../redux/actions/CarFiltersActions";
import { useBreakpoint } from "../../../../common-deprecated/themes/common";
import { useDispatch } from "../../../../shared-logic/redux/store";

/**
 * Generates an array of numbers between start and end, inclusive.
 *
 * @param start The start of the range.
 * @param end The end of the range.
 * @returns An array of numbers between start and end, inclusive.
 * @example
 * range(1, 5) // [1, 2, 3, 4, 5]
 */
const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, index) => index + start);
};

export const ELLIPSIS = "ellipsis";

type NavigateToPageType = (desiredPage: number, direction?: "next" | "prev") => void;

export type UsePaginationControlsReturnType = {
    navigateToPage: (page: number) => void;
    navigateToNextPage: () => void;
    navigateToPreviousPage: () => void;
    navigateToFirstPage: () => void;
    navigateToLastPage: () => void;
    canNavigateRight: boolean;
    canNavigateLeft: boolean;
    paginationButtons: (number | typeof ELLIPSIS | null)[];
};

type PaginationConstantsType = {
    ACTIVE_BUFFER: number;
    BOUNDARY_BUFFER: number;
    ACTIVE_BUFFER_MULTIPLIER: number;
    BOUNDARY_BUFFER_MULTIPLIER: number;
};

const paginationConstants: Record<"desktop" | "mobile", PaginationConstantsType> = {
    desktop: {
        ACTIVE_BUFFER: 1, // Amount of pages to show on each side of the active page
        BOUNDARY_BUFFER: 1, // Amount of pages to show on each side of the first and last page
        ACTIVE_BUFFER_MULTIPLIER: 2,
        BOUNDARY_BUFFER_MULTIPLIER: 2,
    },
    mobile: {
        ACTIVE_BUFFER: 1,
        BOUNDARY_BUFFER: 0,
        ACTIVE_BUFFER_MULTIPLIER: 2,
        BOUNDARY_BUFFER_MULTIPLIER: 2,
    },
};

const usePaginationControls = (): UsePaginationControlsReturnType => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const { page, totalPages } = useCarFilterSelector((state) => state.carFilters.pagination, shallowEqual);

    const isMobile = useBreakpoint("down", "sm");
    const paginationContext = isMobile ? "mobile" : "desktop";
    const shouldRenderEllipsis = !isMobile;

    const { ACTIVE_BUFFER, BOUNDARY_BUFFER, ACTIVE_BUFFER_MULTIPLIER, BOUNDARY_BUFFER_MULTIPLIER } =
        paginationConstants[paginationContext];

    const canNavigateLeft = totalPages > 1 && page > 1;
    const canNavigateRight = totalPages > 1 && page < totalPages;

    const navigateToPage: NavigateToPageType = (desiredPage, direction) => {
        let newPage = desiredPage;
        if (direction === "next" && canNavigateRight) {
            newPage = page + 1;
        } else if (direction === "prev" && canNavigateLeft) {
            newPage = page - 1;
        }

        if (newPage >= 1 && newPage <= totalPages && newPage !== page) {
            dispatch(setPage(newPage));
        }
    };

    const paginationButtons = useMemo((): (number | typeof ELLIPSIS | null)[] => {
        const totalPageNumbers =
            // 2 is added to account for the first and last page
            BOUNDARY_BUFFER * BOUNDARY_BUFFER_MULTIPLIER + ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER + 2;

        if (totalPageNumbers >= totalPages) return range(1, totalPages);

        const leftPageIndex = Math.max(page - ACTIVE_BUFFER, BOUNDARY_BUFFER);
        const rightPageIndex = Math.min(page + ACTIVE_BUFFER, totalPages - BOUNDARY_BUFFER);

        const shouldShowLeftDots = leftPageIndex > BOUNDARY_BUFFER + 2;
        const shouldShowRightDots = rightPageIndex < totalPages - (ACTIVE_BUFFER + 1);

        // Desktop: 1 2 3 4 5 ... 10
        // Mobile: 1 2 3 4
        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER + BOUNDARY_BUFFER + 2;

            return [
                ...range(1, leftItemCount),
                shouldRenderEllipsis ? ELLIPSIS : null,
                ...range(totalPages - (BOUNDARY_BUFFER - 1), totalPages),
            ];
        }

        // Desktop: 1 ... 6 7 8 9 10
        // Mobile: 7 8 9 10
        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = BOUNDARY_BUFFER + 1 + ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER;

            // Rendered result will be 1 ... 4 5 6 7 8 9 10
            return [
                ...range(1, BOUNDARY_BUFFER),
                shouldRenderEllipsis ? ELLIPSIS : null,
                ...range(totalPages - rightItemCount, totalPages),
            ];
        }

        // Desktop: 1 ... 4 5 6 ... 10
        // Mobile: 4 5 6
        return [
            ...range(1, BOUNDARY_BUFFER),
            shouldRenderEllipsis ? ELLIPSIS : null,
            ...range(leftPageIndex, rightPageIndex),
            shouldRenderEllipsis ? ELLIPSIS : null,
            ...range(totalPages - BOUNDARY_BUFFER + 1, totalPages),
        ];
    }, [
        totalPages,
        page,
        ACTIVE_BUFFER,
        BOUNDARY_BUFFER,
        ACTIVE_BUFFER_MULTIPLIER,
        BOUNDARY_BUFFER_MULTIPLIER,
        shouldRenderEllipsis,
    ]);

    return {
        navigateToPage,
        navigateToNextPage: () => navigateToPage(page + 1, "next"),
        navigateToPreviousPage: () => navigateToPage(page - 1, "prev"),
        navigateToFirstPage: () => navigateToPage(1),
        navigateToLastPage: () => navigateToPage(totalPages),
        canNavigateRight,
        canNavigateLeft,
        paginationButtons,
    };
};

export default usePaginationControls;
