import type { NotificationActionsType } from "../actions/NotificationActions";
import {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    FADE_NOTIFICATION,
    SET_NOTIFICATION,
    READY_NOTIFICATION,
} from "../actions/NotificationActions";
import type { NotificationType } from "../../types/CommonTypes";
import { getPreviousNotificationIndexes } from "../../utils/notificationUtils";

export type NotificationReducerType = {
    notifications: NotificationType[];
};

export const initialState: NotificationReducerType = {
    notifications: [],
};

const notificationReducer = (
    state: NotificationReducerType = initialState,
    action: NotificationActionsType,
): NotificationReducerType => {
    switch (action.type) {
        // Still here to support some old flows. This overrides all existing notifications. Use ADD_NOTIFICATION instead.
        case SET_NOTIFICATION:
            return { ...state, notifications: action.notification ? [action.notification] : [] };

        case ADD_NOTIFICATION: {
            const previousIndexes = getPreviousNotificationIndexes(state.notifications, action.notifications.length);
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    ...action.notifications.map((notification, index) => {
                        return {
                            displaySettings: {
                                notificationIndex: previousIndexes[index],
                                isReady: false,
                                isAnimating: false,
                                linkedNotificationIndex:
                                    action.notifications.length > 1 && previousIndexes[0]
                                        ? previousIndexes[0]
                                        : undefined,
                            },
                            ...notification,
                        };
                    }),
                ],
            };
        }

        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications:
                    state.notifications.filter(
                        (notification) =>
                            notification.displaySettings?.notificationIndex !==
                            action.notification.displaySettings?.notificationIndex,
                    ) ?? [],
            };

        case FADE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.reduce((notificationArr, newNotification) => {
                    const match = action.notifications.find(
                        (notification) =>
                            notification.displaySettings?.notificationIndex ===
                            newNotification.displaySettings?.notificationIndex,
                    );
                    if (match)
                        return [
                            ...notificationArr,
                            {
                                ...newNotification,
                                displaySettings: {
                                    ...match.displaySettings,
                                    notificationIndex: match.displaySettings?.notificationIndex ?? 0,
                                    isReady: true,
                                    isAnimating: true,
                                },
                            },
                        ];
                    else return [...notificationArr, newNotification];
                }, [] as NotificationType[]),
            };

        // As multiple onTransitionEnd triggers are used, the state needs to know wheter the inital animation is ready.
        case READY_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.reduce((notificationArr, newNotification) => {
                    const match = action.notifications.find(
                        (notification) =>
                            notification.displaySettings?.notificationIndex ===
                            newNotification.displaySettings?.notificationIndex,
                    );
                    if (match)
                        return [
                            ...notificationArr,
                            {
                                ...newNotification,
                                displaySettings: {
                                    ...match.displaySettings,
                                    notificationIndex: match.displaySettings?.notificationIndex ?? 0,
                                    isReady: true,
                                    isAnimating: match.displaySettings?.isAnimating ?? false,
                                },
                            },
                        ];
                    else return [...notificationArr, newNotification];
                }, [] as NotificationType[]),
            };

        default:
            return state;
    }
};

export default notificationReducer;
