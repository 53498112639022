import styled from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import type { ColorType } from "../../themes/common";

/* general icon wrapper that can get a color */
export const IconWrapper = styled.span<{ iconColor?: ColorType } & SpaceProps>`
    display: inline-block;
    line-height: 0.9;
    color: ${({ theme, iconColor }) => (iconColor ? theme.color[iconColor] : theme.color.iconNeutral)} !important;
    ${space};
`;

export const ToggleIconWrapper = styled.div<{ isDown: boolean; iconColor?: ColorType }>`
    display: flex;
    position: relative;
    align-items: center;
    width: 20px;
    height: 20px;
    svg {
        position: absolute;
        color: ${({ theme, iconColor }) => (iconColor ? theme.color[iconColor] : theme.color.primary)};
        transform: ${(props) => (props.isDown ? "rotate(180deg)" : "none")};
        transition:
            transform 0.3s,
            color 0.3s;
    }
`;

export default IconWrapper;
