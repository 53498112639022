import type { PropsWithChildren } from "react";
import React, { memo } from "react";
import type { ButtonVariants } from "./Button.styles";
import ButtonVariant from "./Button.styles";

export type ButtonVariantsType = keyof typeof ButtonVariants;
export type ButtonSizeType = "small" | "fullwidth";
export type ButtonIconAlignment = "left" | "right";

export type ButtonProps = {
    variant: ButtonVariantsType;
    skeleton?: boolean;
    href?: string;
    target?: string;
    rel?: string;
    title?: string;
    size?: ButtonSizeType;
    iconAlignment?: ButtonIconAlignment;
    textUnderline?: boolean;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    stopPropagation?: boolean;
};

const Button: React.FC<PropsWithChildren<ButtonProps>> = (props) => {
    const { variant, skeleton, href, target, rel, onClick, stopPropagation, children, ...rest } = props;
    return (
        <ButtonVariant
            variant={variant}
            as={href ? "a" : "button"}
            href={href}
            type={!href ? "button" : undefined}
            target={href ? target : undefined}
            rel={href && rel}
            skeleton={skeleton}
            onClick={(event: React.MouseEvent) => {
                if (stopPropagation) event.stopPropagation();
                if (onClick) onClick(event);
            }}
            {...rest}
        >
            {children}
        </ButtonVariant>
    );
};

export default memo(Button);
