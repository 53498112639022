import React from "react";
import ModalBaseView from "./ModalBaseView";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as TableStyles from "../../../styles/v2/toyota/globals/Table";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import { IconWrapper } from "../../../styles/v2/toyota/globals/IconWrapper";
import * as Type from "../../../styles/v2/toyota/globals/Type";
import type { ModalFinanceDisclaimerType } from "../../../utils/modalConstants";
import { CommonLabel, useCommonLabel } from "../../../utils/commonLabels";
import type { ModalViewType } from "../../../types/CommonTypes";

const FinanceDisclaimerModal = (props: ModalViewType<ModalFinanceDisclaimerType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { productName } = modalSettings;
    const { table, value } = modalSettings.disclaimer;
    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="md">
            <Modal.Header>
                <Type.Strong>
                    <CommonLabel label="quotes" params={{ productName }} />
                </Type.Strong>
                <Modal.Button type="button" aria-label={closeLabel} onClick={close}>
                    <IconWrapper>
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {!!table && table.length > 0 && (
                    <TableStyles.Base bordered>
                        {table.map((config) => {
                            return (
                                <TableStyles.TR key={config.name}>
                                    <TableStyles.TD>
                                        <Type.Text styleType="headline">{config.name}</Type.Text>
                                    </TableStyles.TD>
                                    <TableStyles.TD>
                                        <Type.Text dangerouslySetInnerHTML={{ __html: config.value }} />
                                    </TableStyles.TD>
                                </TableStyles.TR>
                            );
                        })}
                    </TableStyles.Base>
                )}
                <div dangerouslySetInnerHTML={{ __html: value }} />
            </Modal.Body>
        </ModalBaseView>
    );
};

export default FinanceDisclaimerModal;
