import { getCommonLabel } from "../../common-deprecated/utils/commonLabels";
import type { CommonStateType } from "../../common-deprecated/utils/commonLabels";

export const dualCurrencyDisclaimerSelector = (state: CommonStateType): string => {
    const { commonSettings } = state;

    const { currencyMultiplier } = commonSettings;
    const dualCurrencySymbol = commonSettings.culture.numberFormat?.secondaryCurrency?.symbol || "";
    const label = getCommonLabel(state, "uscDualCurrencyDisclaimer", {
        value: `1 EUR = ${currencyMultiplier} ${dualCurrencySymbol as string}`,
    });

    return label;
};
