import React from "react";
import * as Styles from "./style/CarResultPriceStyles";
import { CommonMonthlyPriceLabelKey } from "../../../../../../common-deprecated/priceUtils";
import { useCarFilterSelector } from "../../../../redux/store";
import {
    getCarFilterAemMonthlyRateFormat,
    showCarFilterAemMonthlyRates,
} from "../../../../utils/carFilterFinanceSettingUtils";
import { formatMonthlyFinancePrice, UscMonthlyPriceLabelKey } from "../../../../../shared-logic/utils/uscPriceUtils";
import DisclaimerIconWrapper from "../../common/DisclaimerIconWrapper";
import { CarFilterDisclaimerType } from "../../../../utils/disclaimerUtils";
import LinkTextDisclaimer from "../../common/LinkTextDisclaimer";
import type { UscFinanceInfoType } from "../../../../../shared-logic/types/UscCommonTypes";
import type { TextVariantsType } from "../../../../../shared-components/toyota/styles/atoms/Text";
import Text from "../../../../../shared-components/toyota/styles/atoms/Text";

type CarResultMonthlyPriceType = {
    vehicleForSaleId: string;
    monthlyPrice: number;
    financeInfo: UscFinanceInfoType | null;
};

const CarResultMonthlyPrice = (props: CarResultMonthlyPriceType): JSX.Element | null => {
    const { monthlyPrice, financeInfo, vehicleForSaleId } = props;
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const context = useCarFilterSelector((state) => state.carFilters.currentFilter);

    const monthlyEnabled = showCarFilterAemMonthlyRates(commonSettings, carFilterSettings, context);

    const useBoldText = carFilterSettings.financeUseBoldText[context];
    const textVariant: TextVariantsType = useBoldText ? "body-regular" : "body-small-regular";

    if (!monthlyEnabled) return null;

    const monthlyPriceFormat = getCarFilterAemMonthlyRateFormat(commonSettings, context);

    const { monthlyFinanceLabels, euriborFinanceLabels } = financeInfo
        ? formatMonthlyFinancePrice(monthlyPrice, commonSettings, financeInfo, monthlyPriceFormat)
        : { monthlyFinanceLabels: [], euriborFinanceLabels: [] };

    return (
        <Styles.MonthlyWrapper>
            {monthlyFinanceLabels.map(({ key, value }) => {
                switch (key) {
                    case CommonMonthlyPriceLabelKey.Price:
                    case UscMonthlyPriceLabelKey.PriceWithBalloon:
                        return (
                            <Styles.Monthly key={key}>
                                <span dangerouslySetInnerHTML={{ __html: value }} />

                                <DisclaimerIconWrapper
                                    vehicleForSaleId={vehicleForSaleId}
                                    type={CarFilterDisclaimerType.Finance}
                                />
                            </Styles.Monthly>
                        );

                    case CommonMonthlyPriceLabelKey.Disclaimer:
                        return (
                            <Styles.LegalText key={key}>
                                <Text variant={textVariant} color="grey-7">
                                    <LinkTextDisclaimer
                                        vehicleForSaleId={vehicleForSaleId}
                                        type={CarFilterDisclaimerType.Finance}
                                    />
                                </Text>
                            </Styles.LegalText>
                        );

                    default:
                        return (
                            <Styles.LegalText key={key}>
                                <Text variant={textVariant} color="grey-7">
                                    <span dangerouslySetInnerHTML={{ __html: value }} />
                                </Text>
                            </Styles.LegalText>
                        );
                }
            })}

            {/* 
                Euribor financeLabels are combined into one label.
                For example - Euribor (12 months on 2023-06-22): 3,60%
            */}
            {euriborFinanceLabels && euriborFinanceLabels.length > 0 && (
                <Styles.LegalText>
                    <Text variant={textVariant} color="grey-7">
                        {euriborFinanceLabels.map(({ value }) => (
                            <span key={value}>{value}</span>
                        ))}
                    </Text>
                </Styles.LegalText>
            )}
        </Styles.MonthlyWrapper>
    );
};

export default CarResultMonthlyPrice;
