import { useEffect, useState } from "react";
import { useCommonSelector } from "../../../common-deprecated/redux/commonStore";
import type { FinanceOptionType } from "../../../common-deprecated/types/CommonTypes";
import { formatPriceIntl } from "../../../common-deprecated/priceUtils";
import useLatestMinMaxValue from "../../../shared-logic/features/filters/hooks/useLatestMinMaxValue";
import type { PriceFilterConfigType } from "../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

type UsePriceFilterType = {
    activeFinanceOption: FinanceOptionType;
    formatPrice: (price: number) => string;
    showToggle: boolean;
    animationPercentage: number;
};

// Shared price filter functionality.
const usePriceFilter = (priceFilter: PriceFilterConfigType, financeOption: FinanceOptionType): UsePriceFilterType => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const cashIsActive = priceFilter.cash.active;
    // Use a separate renderedOption state that defaults to the currently active price filter.
    const [renderedOption, setRenderedOption] = useState<FinanceOptionType>(cashIsActive ? "cash" : "monthly");

    // When the financeOption changes, make sure the renderedOption changes as well as a user would expect to see
    // the selected finance option even though another filter is currently active.
    // TODO If we implement server side rendering of filter options this might cause issues.
    useEffect(() => {
        setRenderedOption(financeOption);
    }, [financeOption]);

    const { currentMaxValue, minValue, maxValue, customMinValue, customMaxValue, currentMinValue } =
        renderedOption === "monthly" ? priceFilter.monthly : priceFilter.cash;

    const clippedMin = customMinValue || minValue;
    const clippedMax = customMaxValue || maxValue;

    const animatedValue = useLatestMinMaxValue(currentMinValue, currentMaxValue, currentMaxValue);

    return {
        activeFinanceOption: renderedOption,
        formatPrice: (price: number = 0): string => {
            return formatPriceIntl(commonSettings, price || 0).primaryPrice;
        },
        showToggle: priceFilter.monthly.show && priceFilter.cash.show,
        animationPercentage: (animatedValue - clippedMin) / ((clippedMax - clippedMin) / 100) || 0,
    };
};

export default usePriceFilter;
