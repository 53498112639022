import React from "react";
import { shallowEqual } from "react-redux";
import type { CarFilterDispatchType } from "../../../../../redux/store";
import { useCarFilterSelector } from "../../../../../redux/store";
import { StockCarFilterId, UscContext, UsedCarFilterId } from "../../../../../../shared-logic/types/UscCommonTypes";
import MCFilterView from "../../multiple-choice/MCFilterView";
import { setSelectMultiFilter } from "../../../../../redux/actions/CarFiltersActions";
import { parseModelList } from "../../../../../../../shared-logic/features/filters/utils/filterLabelUtils";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../../utils/tracking";
import * as Styles from "./styles/BrandModelListStyles";
import { sortMultipleChoiceValues } from "../../../../../../../shared-logic/features/filters/utils/filters";
import { useDispatch } from "../../../../../../../shared-logic/redux/store";

type BrandModelListType = { brandId: string };

// This component specifically required only the brand Id so we can retrieve the most "recent" brand object from state.
// This prevents "desyncs" between updates in brand selection state and the brand as prop.
const BrandModelList = ({ brandId }: BrandModelListType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const brands = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used
            ? state.carFilters[UsedCarFilterId.Brand].values
            : state.carFilters[StockCarFilterId.Brand].values,
    );
    const filterId = useCarFilterSelector((state) =>
        state.carFilters.currentFilter === UscContext.Used ? UsedCarFilterId.Model : StockCarFilterId.Model,
    );
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const models = useCarFilterSelector((state) => state.carFilters[filterId].values);
    const mcValuesSortOption = useCarFilterSelector(
        ({ carFilterSettings, carFilters }) =>
            carFilterSettings.multipleChoiceValueSortOption[carFilters.currentFilter],
    );

    const brand = brands.find((typeBrand) => typeBrand.id === brandId)!;
    const brandModels = models.filter((model) => model.brandId === brand.id);

    const parsedModelList = sortMultipleChoiceValues(parseModelList(brandModels), mcValuesSortOption);

    return (
        <Styles.Wrapper>
            <Styles.BrandTitle>{brand.label}</Styles.BrandTitle>
            <MCFilterView
                id={`all-models-${brandId}`}
                values={parsedModelList}
                columnWidth={1}
                selectValue={(valueId, selected) => {
                    dispatch(setSelectMultiFilter(valueId.split(","), selected, filterId));
                    trackAemMultipleChoiceFilter(trackParams, parsedModelList, valueId);
                }}
            />
        </Styles.Wrapper>
    );
};

export default BrandModelList;
