import type { CarFilterSettingsType } from "../reducers/CarFilterSettingsReducer";

export const CAR_FILTER_SETTINGS_INIT = "carFilterSettings/init";
export const CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE = "carFilterSettings/useGlobalStore";
export const CAR_FILTER_SETTINGS_OVERRIDE = "carFilterSettings/override";

type InitCarFilterSettings = {
    type: typeof CAR_FILTER_SETTINGS_INIT;
    initState: CarFilterSettingsType;
};
export const initCarFilterSettings = (initState: CarFilterSettingsType): InitCarFilterSettings => ({
    type: CAR_FILTER_SETTINGS_INIT,
    initState,
});

type UseGlobalStoreCarFilterSettings = {
    type: typeof CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE;
    useGlobalStore: boolean;
};
export const setUseGlobalStore = (useGlobalStore: boolean): UseGlobalStoreCarFilterSettings => ({
    type: CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE,
    useGlobalStore,
});

/**
 * For now, we're only allowing overriding of the used cars detail page URL in context of UCL Retailer Pages (UC-78).
 * In the future, this could be extended to allow overriding of other settings (or even all settings 🤯)
 */
type OverrideableSettings = Partial<Pick<CarFilterSettingsType, "urlDetailPage">>;
type OverridableSettings = {
    type: typeof CAR_FILTER_SETTINGS_OVERRIDE;
    override: OverrideableSettings;
};
export const overrideCarFilterSettings = (override: OverrideableSettings): OverridableSettings => ({
    type: CAR_FILTER_SETTINGS_OVERRIDE,
    override,
});

export type CarFilterSettingsActionsType =
    | InitCarFilterSettings
    | UseGlobalStoreCarFilterSettings
    | OverridableSettings;
