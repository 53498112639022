import type { FC, PropsWithChildren } from "react";
import React, { createContext, useContext, useEffect, useState } from "react";
import * as DOMUtils from "history/DOMUtils";
import debounce from "lodash/debounce";

type UseWindowDimensionsType = {
    width: number;
    height: number;
    outerWidth: number;
    outerHeight: number;
    viewportWidth: number;
    viewportHeight: number;
};

const getDimensions = (): UseWindowDimensionsType => ({
    width: window.innerWidth,
    height: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight,
    viewportWidth: window.visualViewport?.width || window.innerWidth,
    viewportHeight: window.visualViewport?.height || window.innerHeight,
});

// Create a global context for window dimensions
const WindowDimensionsContext = createContext<UseWindowDimensionsType>({
    width: 0,
    height: 0,
    outerWidth: 0,
    outerHeight: 0,
    viewportWidth: 0,
    viewportHeight: 0,
});

// Use the context provider to manage the window dimensions
export const WindowDimensionsProvider: FC<PropsWithChildren> = (props) => {
    const { children } = props;
    const { canUseDOM } = DOMUtils;
    const [windowDimensions, setWindowDimensions] = useState<UseWindowDimensionsType>(
        canUseDOM
            ? getDimensions()
            : { width: 0, height: 0, outerWidth: 0, outerHeight: 0, viewportWidth: 0, viewportHeight: 0 },
    );

    useEffect(() => {
        if (DOMUtils.canUseDOM) {
            const resizeWindow = debounce((): void => {
                setWindowDimensions(getDimensions());
            }, 100);
            window.addEventListener("resize", resizeWindow);
            window.visualViewport?.addEventListener("resize", resizeWindow);
            return () => {
                window.removeEventListener("resize", resizeWindow);
                window.visualViewport?.removeEventListener("resize", resizeWindow);
            };
        }
        return () => null;
    }, []);

    return <WindowDimensionsContext.Provider value={windowDimensions}>{children}</WindowDimensionsContext.Provider>;
};

// Use the context consumer to access window dimensions
export const useWindowDimensionsContext = (): UseWindowDimensionsType => {
    return useContext(WindowDimensionsContext);
};
