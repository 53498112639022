import styled, { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { getDirection } from "../../../themes/common";
import { theme as toyotaTheme } from "../../../styles/v2/toyota/theme/toyotaTheme";

export const Wrapper = styled.div<SpaceProps & { isActive?: boolean }>`
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    background-color: ${toyotaTheme.colors.primaryWhite};
    border-radius: 4px;
    ${space};

    input {
        width: 100%;
        padding: ${toyotaTheme.space[1]}px ${toyotaTheme.space[5]}px ${toyotaTheme.space[1]}px ${toyotaTheme.space[2]}px;
        font-size: 1.5rem;
        line-height: 2.4rem;
        background-color: ${toyotaTheme.colors.primaryWhite};
        border: 0;
        border-radius: 4px;
        &::placeholder {
            text-overflow: ellipsis;
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        `}

    /* This has no support in Edge and IE :( */
    &:focus-within {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    }
`;

export const InputAppend = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    ${getDirection("right")}: 2px;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    svg {
        color: ${({ theme }) => theme.color.iconNeutral};
    }
`;
