import React, { useRef, useState } from "react";
import { Provider as ReduxProvider } from "react-redux";
import type { CarFilterStateType, CarFilterStoreType } from "./redux/store";
import { carFilterClientStore } from "./redux/store";
import { useInterval } from "../../common-deprecated/hooks/useInterval";

type PropsType = {
    initialState: CarFilterStateType;
    children: React.ReactNode;
};

const StoreWrapper = ({ initialState, children }: PropsType): JSX.Element => {
    const [retry, setRetry] = useState<number>(0);

    // Create a ref to store the store object
    // This is required to prevent the store from being recreated on every render
    const storeRef = useRef<CarFilterStoreType | undefined>(undefined);

    if (!initialState.carFilterSettings.useGlobalStore) {
        // No global store required. Initialize a new store object.
        storeRef.current = carFilterClientStore(
            initialState,
            initialState.commonSettings.component,
        ) as CarFilterStoreType;
    } else if (window?.carFilterStore) {
        storeRef.current = window.carFilterStore;
        delete window.carFilterStore; // Delete the store from the window object to prevent it from being reused
    }

    useInterval(
        () => {
            setRetry(retry + 1);
        },
        storeRef.current ? null : 100,
    );

    // Since react-redux 8, new development checks were introduced against incorrect usages of useSelector, mostly to detect unnecessary rerenders.
    // These checks were disabled for all existing components as these create quite some issues
    // Ideally these checks should be enabled in all components - UC-1220
    // Notice: Make sure to not add the 'stabilityCheck="never"' in new components
    if (storeRef.current)
        return (
            <ReduxProvider store={storeRef.current} stabilityCheck="never">
                {children}
            </ReduxProvider>
        );

    // Return empty fragment if store is not ready, this will be handled by a loading state
    // of the Car Filter component
    return <></>;
};

export default StoreWrapper;
