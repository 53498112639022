import React from "react";
import CarResultMonthlyPrice from "./CarResultMonthlyPrice";
import CarResultCashPrice from "./CarResultCashPrice";
import type { UscCashPriceType } from "../../../../../shared-logic/utils/uscPriceUtils";
import { getUscCashPriceValue } from "../../../../../shared-logic/utils/uscPriceUtils";
import InlineDisclaimer from "../../common/InlineDisclaimer";
import { CarFilterDisclaimerType } from "../../../../utils/disclaimerUtils";
import type { UscFinanceInfoType } from "../../../../../shared-logic/types/UscCommonTypes";

type CarResultPriceType = {
    cashPrice: UscCashPriceType;
    monthlyPrice: number;
    financeInfo: UscFinanceInfoType | null;
    vehicleForSaleId: string;
};

const CarResultPrice = (props: CarResultPriceType): JSX.Element | null => {
    const { cashPrice, monthlyPrice, financeInfo, vehicleForSaleId } = props;
    if (!getUscCashPriceValue(cashPrice) && !monthlyPrice) return null;

    // Cash and monthly are separate components for easier readability and maintenance.
    return (
        <>
            <CarResultCashPrice cashPrice={cashPrice} vehicleForSaleId={vehicleForSaleId} />
            {!!monthlyPrice && (
                <CarResultMonthlyPrice
                    financeInfo={financeInfo}
                    monthlyPrice={monthlyPrice}
                    vehicleForSaleId={vehicleForSaleId}
                />
            )}
            <InlineDisclaimer vehicleForSaleId={vehicleForSaleId} disclaimerType={CarFilterDisclaimerType.Cash} />
            <InlineDisclaimer vehicleForSaleId={vehicleForSaleId} disclaimerType={CarFilterDisclaimerType.Finance} />
        </>
    );
};

export default CarResultPrice;
