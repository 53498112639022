import type { FC, PropsWithChildren } from "react";
import React from "react";
import * as Styles from "../styles/CarFilterStyles";
import { useCarFilterSelector } from "../../../redux/store";

const CarResultsContainerWrapper: FC<PropsWithChildren> = ({ children }) => {
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);

    const { noContainerSpacing } = commonSettings.layoutOptions;

    return <Styles.Container noContainerSpacing={noContainerSpacing}>{children}</Styles.Container>;
};

export default CarResultsContainerWrapper;
