import type { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from "react";
import type { ModelResultType } from "./constants/resultConstants";
import { MODEL_CODE_ALL } from "../../../../common-deprecated/constants";
import type { CarTypeFilterType, SliderButtonType, MultipleChoiceValueType } from "./constants/filterConfigConstants";
import { BUTTON_MAX, BUTTON_MIN } from "./constants/filterConfigConstants";
import type { ErrorLogType, PosType } from "../../../../common-deprecated/types/CommonTypes";
import type { CarFilterApiType } from "../../../../common-deprecated/types/FilterApiType";
import { carFilterModelIsValid, carFilterCarIsValid } from "./formatters/formatterHelpers";

/**
 * Get the amount of new car results.
 */
export const getResultCount = (results: ModelResultType[]): { total: number; results: number } =>
    results.reduce(
        (resultCount, result) => {
            result.cars.forEach((car) => {
                resultCount.total++;
                if (car.show) resultCount.results++;
            });
            return resultCount;
        },
        { total: 0, results: 0 },
    );

export const modelCodeIsIncluded = (modelCodes: string[], modelCode: string): boolean =>
    modelCodes.includes(modelCode) || modelCodes.includes(MODEL_CODE_ALL);

// ----------------------------------------------------------------------
// ButtonSlider RTL helpers
// ----------------------------------------------------------------------

/**
 * Maps the current min and max values to the corresponding rtl values
 *
 */
export const mapButtonSliderMinMaxValuesToRTL = (
    minValue: number,
    maxValue: number,
    currentMin?: number,
    currentMax?: number,
): { minRTL: number; maxRTL: number } => {
    const minDifference = currentMin ? currentMin - minValue : 0;
    const maxDifference = currentMax ? maxValue - currentMax : 0;
    const minRTL = minValue + maxDifference;
    const maxRTL = maxValue - minDifference;
    return { minRTL, maxRTL };
};

/**
 * Creates an RTL wrapper for the getValueLabel function from ButtonSlider
 *
 */
export const getValueLabelRTLWrapper =
    (minValue: number, maxValue: number, getValueLabel?: (max: number, min: number) => string) =>
    (min: number, max: number): string => {
        const { minRTL, maxRTL } = mapButtonSliderMinMaxValuesToRTL(minValue, maxValue, min, max);
        return getValueLabel ? getValueLabel(minRTL, maxRTL) : "";
    };
/**
 * Creates an RTL wrapper for the setValueRTLWrapper function from ButtonSlider
 *
 */
export const setValueRTLWrapper =
    (
        minValue: number,
        maxValue: number,
        currentMinValue: number,
        currentMaxValue: number,
        setValue: (value: number, type: SliderButtonType) => void,
    ) =>
    (newValue: number, type: SliderButtonType): void => {
        const { minRTL, maxRTL } = mapButtonSliderMinMaxValuesToRTL(
            minValue,
            maxValue,
            type === BUTTON_MIN ? newValue : currentMinValue,
            type === BUTTON_MAX ? newValue : currentMaxValue,
        );
        setValue(type === BUTTON_MIN ? maxRTL : minRTL, type === BUTTON_MIN ? BUTTON_MAX : BUTTON_MIN);
    };

/**
 * Helper function to add all errors found in the CarFilter data to the errors array
 */
export const formatCarFilterApiErrors = (apiFilterConfig: CarFilterApiType): ErrorLogType[] => {
    const { _errors } = apiFilterConfig.filters;
    const { models } = apiFilterConfig;

    const errorLogs: ErrorLogType[] = [];

    if (_errors) {
        errorLogs.push(
            ..._errors.map((error) => ({
                title: error.model,
                id: error["model-id"],
                message: error._error.message,
                innerMessage: (error._error.innerError || { message: "" }).message,
            })),
        );
    }

    if (models) {
        models.forEach((model) => {
            if (!carFilterModelIsValid(model)) {
                return errorLogs.push({ title: model.name, id: model.id, message: model._error.message });
            }

            if (model.cars) {
                model.cars.forEach((car) => {
                    if (!carFilterCarIsValid(car)) return;
                    if (car.filters._error) return;

                    if (!car.filters.specs) {
                        errorLogs.push({
                            title: model.name,
                            id: model.id,
                            message: `${car.name} has no specifications in filters`,
                        });
                    }
                });
            }
        });
    }

    return errorLogs;
};

/**
 * Object with a property per carTypeFilter which will be true when it has at least one selected value in that filter
 */
export const getActiveCarTypeFilters = (
    carTypeFilter: CarTypeFilterType,
): { brands: boolean; models: boolean; carType: boolean; fuelType: boolean } => {
    return {
        brands: !!carTypeFilter.brands.filter((value) => value.selected).length,
        models: !!carTypeFilter.models.filter((value) => value.selected).length,
        carType: !!carTypeFilter.carType.filter((value) => value.selected).length,
        fuelType: !!carTypeFilter.fuelType.filter((value) => value.selected).length,
    };
};

/**
 * This will determine if a filter has an active "any" state.
 */
export const filterConfigIsAny = (filterValues: MultipleChoiceValueType[]): boolean => {
    return !filterValues.find((value) => !value.selected);
};

/**
 * Get the position of the first touched item.
 */
export const getTouchPosition = (event: TouchEvent | ReactTouchEvent): PosType => {
    const touchItem = event.targetTouches[0];
    return touchItem ? { x: touchItem.clientX, y: touchItem.clientY } : { x: 0, y: 0 };
};

/**
 * Get the position of a mouse event.
 */
export const getMousePosition = (event: MouseEvent | ReactMouseEvent): PosType => {
    return { x: event.pageX, y: event.pageY - window.pageYOffset };
};

export type OffsetRectType = {
    left: number;
    width: number;
    right: number;
    top: number;
    bottom: number;
    height: number;
};
/**
 * Get an element bounding rect with the top and left values page offsets added.
 * This results in a "static" rect object which describes the static values of the object in the document.
 */
export const getOffsetRect = (element: HTMLElement): OffsetRectType => {
    const { left, width, right, top, bottom, height } = element.getBoundingClientRect();
    return { left: left + window.pageXOffset, width, right, top: top + window.pageYOffset, bottom, height };
};
