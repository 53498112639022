import styled, { css } from "styled-components";
import type { SpaceProps, TextAlignProps, DisplayProps, MaxWidthProps, FlexboxProps, LayoutProps } from "styled-system";
import { space, textAlign, display, maxWidth, compose, layout, flexbox } from "styled-system";
import { getBreakpoint } from "../../themes/common";

const styleProps = compose(space, textAlign, display, maxWidth, flexbox, layout);
type StyleTypes = SpaceProps & TextAlignProps & DisplayProps & MaxWidthProps & FlexboxProps & LayoutProps;

const Container = styled.div<{ halfSize?: boolean } & StyleTypes>`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 ${({ theme }) => (theme.noContainerSpacing ? 0 : theme.spacer)}px;
    ${styleProps};
    ${({ halfSize }) =>
        halfSize &&
        css`
            @media ${getBreakpoint("up", "xl")} {
                max-width: 720px;
            }
        `};
`;

export const Relative = styled(Container)`
    position: relative;
`;

export default Container;
