import { useEffect, type FC, type PropsWithChildren, type ReactElement } from "react";
import React, { createContext, useCallback, useContext, useRef } from "react";
import * as DOMUtils from "history/DOMUtils";

/**
 * Base type that custom registry type will need to implement
 */
export type ComponentRegistryBaseType = Record<string, unknown>;

type ComponentRegistryContextType<RT extends ComponentRegistryBaseType> = {
    get?: () => RT;
};

const enableDebug = DOMUtils.canUseDOM && window.location.href.includes("retailerscreenDebugRegistry=true");

/**
 * Will create registry functions for Provider and useComponentRegistry. Do not use this function directly in your code.
 * You will need to re-export this function with correct types
 */
export const createComponentRegistry = <RT extends ComponentRegistryBaseType>(): {
    ComponentRegistryProvider: ({
        registry,
        children,
    }: React.PropsWithChildren<{
        registry: RT;
    }>) => ReactElement;
    useComponentRegistry: <T extends string & keyof RT>(componentName: T) => RT[T];
} => {
    const Context = createContext<ComponentRegistryContextType<RT>>({});

    const ComponentRegistryProvider = ({ registry, children }: PropsWithChildren<{ registry: RT }>): ReactElement => {
        const componentRegistry = useRef<RT>(registry);

        const get = useCallback(() => {
            if (typeof componentRegistry.current === "undefined") {
                throw new Error("Did you forget to set component registry at root component?");
            }
            return componentRegistry.current;
        }, []);

        useEffect(() => {
            if (enableDebug)
                // eslint-disable-next-line no-console
                console.info("retailerscreenDebugRegistry: all overriden components", Object.keys(registry));
            // Only need to log this once
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <Context.Provider value={{ get }}>{children}</Context.Provider>;
    };

    const useFunction = <T extends string & keyof RT>(componentName: T): RT[T] => {
        const context = useContext<ComponentRegistryContextType<RT>>(Context);

        if (typeof context.get === "undefined") {
            throw new Error(
                "Did you forget to set component registry at root component? Or forgot to wrap root component in <ComponentRegistryProvider>?",
            );
        }

        const Component = context.get()[componentName];
        if (!enableDebug) return Component;

        // For debugging purposes only and used across css modules & styled components, so just using inline styles
        const Wrapper: FC<unknown> = (props) => (
            <div style={{ outline: "1px solid red", position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        zIndex: "99999",
                        color: "white",
                        background: "red",
                        fontSize: "14px",
                    }}
                >
                    {componentName}
                </div>
                {/* @ts-expect-error */}
                <Component {...props} />
            </div>
        );
        return Wrapper as RT[T];
    };

    return {
        ComponentRegistryProvider,
        useComponentRegistry: useFunction,
    };
};
