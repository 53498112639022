import {
    DfEfficiencyClassCode,
    DfEmissionCategoryCode,
    DfEmissionConsumptionTypeCode,
    DfEmissionMeasureCode,
    DfFuelTypeId,
    DfVehicleOperationModeCode,
} from "./dfConstants";
import type {
    FullQuickSpecConfigType,
    QuickSpecConfigType,
    UscEcoQuickSpecs,
    UscVehicleType,
    UscWeightedQuickSpecs,
} from "./uscUtils";
import { ecoNedcQuickSpecs, ecoWltpQuickSpecs, UscQuickSpec, uscWeightedQuickSpecs } from "./uscUtils";
import type { MeasureType, ReferenceType } from "../types/UscCommonTypes";
import type { CommonStateType } from "../../../common-deprecated/utils/commonLabels";
import { getCommonOptionalLabel } from "../../../common-deprecated/utils/commonLabels";
import type {
    MultipleChoiceFilterConfigType,
    MultipleChoiceValueType,
} from "../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import type { EcoLabelPopupLabelType } from "../../../common-deprecated/types/SettingsType";
import { parseSpecificationValue } from "../../../common-deprecated/utils";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import type { UscSpecParamsType } from "./uscSpecUtils";
import type { ValUnitType } from "../../car-filter/utils/constants/filterRequestConstants";
import type { LoadedUsedCompareCarType } from "../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import { formatNumberIntl } from "../../../common-deprecated/settings/utils/commonSettingUtils";

/**
 * Map between DF Eco Icon codes and relative url from resourcePath to the corresponding image
 * This map is similar to how we map colours.
 * This happens in frontend as the current filter data structure doesn't easily allow propagating icon locations with multiple choice values.
 */
export const ecoLabelIconMap: Record<string, { id: string; icon: string }[]> = {
    fr: [
        { id: "EL1", icon: "fr/fr-eco-badge-0.png" },
        { id: "EL2", icon: "fr/fr-eco-badge-1.png" },
        { id: "EL3", icon: "fr/fr-eco-badge-2.png" },
        { id: "EL4", icon: "fr/fr-eco-badge-3.png" },
        { id: "EL5", icon: "fr/fr-eco-badge-4.png" },
        { id: "EL6", icon: "fr/fr-eco-badge-5.png" },
    ],
    es: [
        { id: "EL11", icon: "es/es-eco-badge-0.png" },
        { id: "EL9", icon: "es/es-eco-badge-eco.png" },
        { id: "EL8", icon: "es/es-eco-badge-b.png" },
        { id: "EL7", icon: "es/es-eco-badge-c.png" },
        { id: "EL10", icon: "" },
    ],
    de: [
        { id: "EL1", icon: "" },
        { id: "EL2", icon: "de/de-eco-badge-2.png" },
        { id: "EL3", icon: "de/de-eco-badge-3.png" },
        { id: "EL4", icon: "de/de-eco-badge-4.png" },
    ],
};

// Map DF efficiencyClass codes to the ecoLabel keys used in the aemEcoLabel site setting.
export const aemEcoLabelMap: Record<DfEfficiencyClassCode, string> = {
    [DfEfficiencyClassCode.APlusPlusPlus]: "aPlusPlusPlus",
    [DfEfficiencyClassCode.APlusPlus]: "aPlusPlus",
    [DfEfficiencyClassCode.APlus]: "aPlus",
    [DfEfficiencyClassCode.A]: "a",
    [DfEfficiencyClassCode.B]: "b",
    [DfEfficiencyClassCode.C]: "c",
    [DfEfficiencyClassCode.D]: "d",
    [DfEfficiencyClassCode.E]: "e",
    [DfEfficiencyClassCode.F]: "f",
    [DfEfficiencyClassCode.G]: "g",
};

export enum UscEcoValueMeasureSystem {
    Wltp = "wltp",
    Nedc = "nedc",
}

export type UscEcoMeasureValuesType = {
    combinedCo2: EcoMeasureType | null; // CO2 emission (CO2) WLTP or NEDC Combined
    co2NedcCombined: EcoMeasureType | null; // CO2 emission (CO2) NEDC Combined
    co2WltpCombined: EcoMeasureType | null; // CO2 emission (CO2) WLTP Combined
    co2CsCombined: EcoMeasureType | null; // CO2 emission (CO2) Charge-sustaining (CS) Combined
    fcNedcCombined: EcoMeasureType | null; // Fuel consumption (FE) NEDC Combined
    fcWltpCombined: EcoMeasureType | null; // Fuel consumption (FE) WLTP Combined
    fcCsCombined: EcoMeasureType | null; // Fuel consumption (FE) Charge-sustaining (CS) Combined
    fcInnerCity: EcoMeasureType | null; // Fuel consumption (FE) WLTP [PHEV: Charge-sustaining (CS) | Default: NA] Low
    fcCityOutskirts: EcoMeasureType | null; // Fuel consumption (FE) WLTP [PHEV: Charge-sustaining (CS) | Default: NA] Medium
    fcCountryRoad: EcoMeasureType | null; // Fuel consumption (FE) WLTP [PHEV: Charge-sustaining (CS) | Default: NA] High
    fcHighway: EcoMeasureType | null; // Fuel consumption (FE) WLTP [PHEV: Charge-sustaining (CS) | Default: NA] Extra high
    ecCombined: EcoMeasureType | null; // Electric consumption (EC) WLTP Combined
    ecInnerCity: EcoMeasureType | null; // Electric consumption (EC) WLTP Low
    ecOutskirts: EcoMeasureType | null; // Electric consumption (EC) WLTP Medium
    ecCountryRoad: EcoMeasureType | null; // Electric consumption (EC) WLTP High
    ecHighway: EcoMeasureType | null; // Electric consumption (EC) WLTP Extra high
    eaerCombined: EcoMeasureType | null; // Equivalent All Electric Range (EAER) Combined
    eaerCity: EcoMeasureType | null; // Equivalent All Electric Range (EAER) CITY
    perCombined: EcoMeasureType | null; // Pure electric range (PER) Combined
    perCity: EcoMeasureType | null; // Pure electric range (PER) CITY
};

export type NedcWltpDisclaimersLabelType = {
    nedcDisclaimer: string | null;
    wltpDisclaimer: string | null;
    nedcNotAvailableDisclaimer: string | null;
    efficiencyClassNotAvailableDisclaimer: string | null;
};

export type WeightedSpecsLabelType = Record<UscWeightedQuickSpecs, string>;

// Used for WLTP/NEDC measurements (co2/emission)
export type EcoMeasureType = MeasureType & {
    type: { code: DfEmissionConsumptionTypeCode; description: string };
    measureSystem: { code: DfEmissionMeasureCode; description: string };
    category?: { code: DfEmissionCategoryCode; description: string };
    mode?: { code: DfVehicleOperationModeCode; description: string };
};

// This object is used in both car-filter and USC to standardize the data formatting/reading of 'eco' values.
// This way we can get a centralized way to read/parse eco data across UCL/SCL.
// We parse the car-filter structure into the USC data format as the USC format is easier to read and used more widely.
// This has grown historically because initially we only had the car-filter ES endpoint
export type UscEcoType = {
    label?: ReferenceType;
    euroClass?: string;
    efficiencyClass?: ReferenceType<DfEfficiencyClassCode> | null;
    efficiencyClassCSMode?: ReferenceType<DfEfficiencyClassCode> | null;
    co2Emission?: EcoMeasureType[];
    consumption?: EcoMeasureType[];
    electricRange?: EcoMeasureType[];
};

export const getUscEcoMeasureValue = (
    ecoInfo: UscEcoType | null,
    measure: "co2Emission" | "consumption" | "electricRange",
    consumptionCode: DfEmissionConsumptionTypeCode,
    categoryCode?: DfEmissionCategoryCode,
    modeCode?: DfVehicleOperationModeCode,
): EcoMeasureType | null => {
    if (!modeCode) {
        // We search for weighted specs first. (Unless a specific modeCode was asked)
        // TODO: This logic should be removed in UC-798
        const weightedSpec = ecoInfo?.[measure]?.find((emission: EcoMeasureType) => {
            const correctCategory =
                categoryCode && emission.category?.code ? emission.category?.code === categoryCode : true;
            const correctEmissionType = emission.type.code === consumptionCode;
            const isWLTP = emission.measureSystem.code === DfEmissionMeasureCode.Wltp;

            // If 'emission.mode' is present use it to further validate the spec (see OR-5546).
            if (!emission.mode || !["co2Emission", "consumption"].includes(measure)) return false;

            const isWeighted = isWLTP
                ? emission.mode?.code === DfVehicleOperationModeCode.UfWeighted
                : emission.mode?.code === DfVehicleOperationModeCode.Weighted;

            // In the case of consumption values a "0" value should be hidden entirely (see OR-6725).
            // In other cases a 0 is still valid, for example 0 co2 emissions.
            const validValue = measure === "consumption" ? emission.value !== 0 : true;

            return correctCategory && correctEmissionType && validValue && isWeighted;
        });

        if (weightedSpec) return weightedSpec;
    }

    return (
        // Added EcoMeasureType for easy reference and improved readability.
        ecoInfo?.[measure]?.find((emission: EcoMeasureType) => {
            const correctCategory =
                categoryCode && emission.category?.code ? emission.category?.code === categoryCode : true;
            const correctEmissionType = emission.type.code === consumptionCode;

            let validMode = true;
            // When a modeCode is passed use this to find the specific value
            if (modeCode) validMode = emission.mode?.code === modeCode;

            // In the case of consumption values a "0" value should be hidden entirely (see OR-6725).
            // In other cases a 0 is still valid, for example 0 co2 emissions.
            const validValue = measure === "consumption" ? emission.value !== 0 : true;

            return correctCategory && correctEmissionType && validValue && validMode;
        }) || null
    );
};

// Make sure to align this with getUscEcoMeasureValue when updates happen
const isWeighted = (
    type: UscWeightedQuickSpecs,
    productInfo: UscVehicleType["product"],
    measure: EcoMeasureType | null,
): boolean => {
    // The following 3 edge cases are temporary required for TDG to go live until UC-798 happens
    const isPluginHybrid = productInfo.engine.plugin && productInfo.engine.hybrid;
    // FcCsCombined is theoretically not 'weighted' but adding it here as this also needs a separate label for plugin hybrid
    const isFcCsCombined = type === UscQuickSpec.FcCsCombined;
    // EfficiencyClassText is only 'weighted' for plugin hybrid
    const isEfficiencyClassText = type === UscQuickSpec.EfficiencyClassText;
    // PHEVs show a different label for the ecolabelEcCombined spec, so we treat it as a weighted spec
    // to overwrite the title in these cases
    const isEcolabelEcCombined = type === UscQuickSpec.EcolabelEcCombined;

    if (isPluginHybrid && (isEfficiencyClassText || isFcCsCombined || isEcolabelEcCombined)) return true;

    return (
        measure?.mode?.code === DfVehicleOperationModeCode.Weighted ||
        measure?.mode?.code === DfVehicleOperationModeCode.UfWeighted
    );
};

/**
 * When a spec is weighted a different title will be shown
 * This is currently a TDG edge case - See OR-7864
 * We overwrite the title from the content fragment with the labels from weightedSpecsLabels
 */
export const amendWeightedTitle = (
    productInfo: UscVehicleType["product"],
    config: QuickSpecConfigType<UscEcoQuickSpecs>,
    ecoMeasureValues: UscEcoMeasureValuesType,
    weightedSpecsLabels: WeightedSpecsLabelType | null,
): QuickSpecConfigType<UscEcoQuickSpecs> => {
    if (!weightedSpecsLabels) return config;

    const { combinedCo2, co2NedcCombined, co2WltpCombined, fcNedcCombined, fcWltpCombined, ecCombined } =
        ecoMeasureValues;
    const { fcCsCombined } = ecoMeasureValues;

    const weightedSpecsValues: Record<UscWeightedQuickSpecs, EcoMeasureType | null> = {
        [UscQuickSpec.CombinedCo2]: combinedCo2,
        [UscQuickSpec.Co2NedcCombined]: co2NedcCombined,
        [UscQuickSpec.Co2WltpCombined]: co2WltpCombined,
        [UscQuickSpec.FcNedcCombined]: fcNedcCombined,
        [UscQuickSpec.FcWltpCombined]: fcWltpCombined,
        [UscQuickSpec.FcCsCombined]: fcCsCombined,
        [UscQuickSpec.EcCombined]: ecCombined,
        [UscQuickSpec.EcolabelEcCombined]: ecCombined,
        [UscQuickSpec.EfficiencyClassText]: null, // This is checked separately in the 'isWeighted' function
    };

    // Overwrite config title when spec is weighted
    if (uscWeightedQuickSpecs.includes(config.type as UscWeightedQuickSpecs)) {
        const weightedSpecValue = weightedSpecsValues[config.type as UscWeightedQuickSpecs];
        if (isWeighted(config.type as UscWeightedQuickSpecs, productInfo, weightedSpecValue))
            config.title = weightedSpecsLabels[config.type as UscWeightedQuickSpecs];
    }

    return config;
};

/**
 * Returns all the eco measure values (consumption, emissions and electric ranges)
 * This is used in the SectionConsumption and in the quick specs but as this contains more than just consumption values it was renamed to something more general like eco measure values
 */
export const getUscEcoMeasureValues = (
    ecoInfo: UscEcoType | null,
    isPluginOrHybrid: boolean,
): UscEcoMeasureValuesType => {
    const co2NedcCombined = getUscEcoMeasureValue(
        ecoInfo,
        "co2Emission",
        DfEmissionConsumptionTypeCode.CombinedNedc,
        DfEmissionCategoryCode.Co2,
    );
    const co2WltpCombined = getUscEcoMeasureValue(
        ecoInfo,
        "co2Emission",
        DfEmissionConsumptionTypeCode.CombinedWltp,
        DfEmissionCategoryCode.Co2,
    );

    return {
        combinedCo2: co2WltpCombined || co2NedcCombined,
        co2WltpCombined,
        co2NedcCombined,
        co2CsCombined: getUscEcoMeasureValue(
            ecoInfo,
            "co2Emission",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Co2,
            DfVehicleOperationModeCode.ChargeSustaining,
        ),
        fcWltpCombined: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Fc,
        ),
        fcNedcCombined: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.CombinedNedc,
            DfEmissionCategoryCode.Fc,
        ),
        fcCsCombined: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Fc,
            DfVehicleOperationModeCode.ChargeSustaining,
        ),
        fcInnerCity: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.LowWltp,
            DfEmissionCategoryCode.Fc,
            isPluginOrHybrid ? DfVehicleOperationModeCode.ChargeSustaining : DfVehicleOperationModeCode.NotApplicable,
        ),
        fcCityOutskirts: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.MediumWltp,
            DfEmissionCategoryCode.Fc,
            isPluginOrHybrid ? DfVehicleOperationModeCode.ChargeSustaining : DfVehicleOperationModeCode.NotApplicable,
        ),
        // 🎶 Take me home,
        fcCountryRoad: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.HighWltp,
            DfEmissionCategoryCode.Fc,
            isPluginOrHybrid ? DfVehicleOperationModeCode.ChargeSustaining : DfVehicleOperationModeCode.NotApplicable,
        ),
        fcHighway: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.ExtraHighWltp,
            DfEmissionCategoryCode.Fc,
            isPluginOrHybrid ? DfVehicleOperationModeCode.ChargeSustaining : DfVehicleOperationModeCode.NotApplicable,
        ),
        ecCombined: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Ec,
            isPluginOrHybrid ? DfVehicleOperationModeCode.UfWeighted : DfVehicleOperationModeCode.NotApplicable,
        ),
        ecInnerCity: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.LowWltp,
            DfEmissionCategoryCode.Ec,
            DfVehicleOperationModeCode.NotApplicable,
        ),
        ecOutskirts: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.MediumWltp,
            DfEmissionCategoryCode.Ec,
            DfVehicleOperationModeCode.NotApplicable,
        ),
        ecCountryRoad: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.HighWltp,
            DfEmissionCategoryCode.Ec,
            DfVehicleOperationModeCode.NotApplicable,
        ),
        ecHighway: getUscEcoMeasureValue(
            ecoInfo,
            "consumption",
            DfEmissionConsumptionTypeCode.ExtraHighWltp,
            DfEmissionCategoryCode.Ec,
            DfVehicleOperationModeCode.NotApplicable,
        ),
        eaerCombined: getUscEcoMeasureValue(
            ecoInfo,
            "electricRange",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Eaear,
        ),
        eaerCity: getUscEcoMeasureValue(
            ecoInfo,
            "electricRange",
            DfEmissionConsumptionTypeCode.CityWltp,
            DfEmissionCategoryCode.Eaear,
        ),
        perCombined: getUscEcoMeasureValue(
            ecoInfo,
            "electricRange",
            DfEmissionConsumptionTypeCode.CombinedWltp,
            DfEmissionCategoryCode.Per,
        ),
        perCity: getUscEcoMeasureValue(
            ecoInfo,
            "electricRange",
            DfEmissionConsumptionTypeCode.CityWltp,
            DfEmissionCategoryCode.Per,
        ),
    };
};

const formatCo2Value = (value: number | undefined, allowZeroValue: boolean): string | null => {
    if (value === undefined || (value === 0 && !allowZeroValue)) return null;

    return `${value}`;
};

export const getUscEcoQuickSpecConfig = (
    productInfo: UscVehicleType["product"],
    config: QuickSpecConfigType<UscEcoQuickSpecs>,
    specParams: UscSpecParamsType,
    ecoInfo: UscEcoType | null,
): FullQuickSpecConfigType => {
    const { showZeroCo2SpecValues } = specParams.settings;
    const { nedcWltpDisclaimerLabels, weightedSpecsLabels } = specParams.labels;
    const { commonSettings } = specParams;

    // Created new config to avoid overwriting the original config and state
    let newConfig = { ...config };

    // Nedc/Wltp disclaimer logic:
    // Use the nedc/wltp disclaimer if any are found and the spec matches.
    // Add them as a tooltipReference, this means the disclaimerUtil logic can take care of rendering them consistently as these are rendered elsewhere as well.
    // If no nedc/wltp disclaimer is found fall back to the configured quickSpec disclaimer (see disclaimerUtils)
    let tooltipReference: UscEcoValueMeasureSystem | UscQuickSpec = config.type;
    if (ecoNedcQuickSpecs.includes(config.type) && nedcWltpDisclaimerLabels.nedcDisclaimer) {
        tooltipReference = UscEcoValueMeasureSystem.Nedc;
    }
    if (ecoWltpQuickSpecs.includes(config.type) && nedcWltpDisclaimerLabels.wltpDisclaimer) {
        tooltipReference = UscEcoValueMeasureSystem.Wltp;
    }

    newConfig.tooltipReference = tooltipReference;

    // PHEVs & Hybrids require a different mode to be used for certain specs in the legal section
    const isPluginHybrid = productInfo.engine.plugin && productInfo.engine.hybrid;
    const isHybrid = productInfo.engine.marketingFuelType.code === DfFuelTypeId.Hybrid;
    const ecoMeasureValues = getUscEcoMeasureValues(ecoInfo, isPluginHybrid || isHybrid);
    const { combinedCo2, co2NedcCombined, co2WltpCombined, co2CsCombined } = ecoMeasureValues;
    const { fcWltpCombined, fcNedcCombined, fcCsCombined, ecCombined } = ecoMeasureValues;
    const { eaerCombined, eaerCity, perCombined, perCity } = ecoMeasureValues;
    const { fcInnerCity, fcCityOutskirts, fcCountryRoad, fcHighway } = ecoMeasureValues;
    const { ecInnerCity, ecOutskirts, ecCountryRoad, ecHighway } = ecoMeasureValues;

    // ⚠️ Edge cases - Can be removed in or UC-798
    // 1. The title from the content fragment will be overwritten here when a spec is weighted
    newConfig = amendWeightedTitle(productInfo, newConfig, ecoMeasureValues, weightedSpecsLabels);
    // PDP specs edge cases
    if (isPluginHybrid) {
        // 2. Hide the 'Co2CsCombined' spec when there is a 'co2WltpCombined'
        if (ecoMeasureValues.co2WltpCombined?.value && config.type === UscQuickSpec.Co2CsCombined) {
            return {
                ...newConfig,
                value: "",
                unit: "",
            };
        }
    } else {
        // 3. Hide the 'Co2WltpCombined' spec when there is a 'co2CsCombined'
        if (ecoMeasureValues.co2CsCombined?.value && config.type === UscQuickSpec.Co2WltpCombined) {
            return {
                ...newConfig,
                value: "",
                unit: "",
            };
        }
        // 4. Hide the 'FcWltpCombined' spec when there is a 'fcCsCombined'
        if (ecoMeasureValues.fcCsCombined?.value && config.type === UscQuickSpec.FcWltpCombined) {
            return {
                ...newConfig,
                value: "",
                unit: "",
            };
        }
    }

    /**
     * For all cases _except_ the hydrogen fuel type,
     * we want to show a minimum of 1 decimal for the FC/EC values.
     * However, as the FC values for hydrogen are in kg/100km, we want to show 2 decimals.
     */
    const intlOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: productInfo.engine.marketingFuelType.code === DfFuelTypeId.Hydrogen ? 2 : 1,
    };

    switch (config.type) {
        case UscQuickSpec.CombinedCo2:
            return {
                ...newConfig,
                value: formatCo2Value(combinedCo2?.value, showZeroCo2SpecValues) || "",
                unit: combinedCo2?.unit.description,
            };
        case UscQuickSpec.Co2NedcCombined:
            return {
                ...newConfig,
                value: formatCo2Value(co2NedcCombined?.value, showZeroCo2SpecValues) || "",
                unit: co2NedcCombined?.unit.description,
            };
        case UscQuickSpec.Co2WltpCombined:
            return {
                ...newConfig,
                value: formatCo2Value(co2WltpCombined?.value, showZeroCo2SpecValues) || "",
                unit: co2WltpCombined?.unit.description,
            };
        case UscQuickSpec.Co2CsCombined:
            return {
                ...newConfig,
                value: formatCo2Value(co2CsCombined?.value, showZeroCo2SpecValues) || "",
                unit: co2CsCombined?.unit.description,
            };
        case UscQuickSpec.FcNedcCombined:
            return {
                ...newConfig,
                value: fcNedcCombined?.value
                    ? formatNumberIntl(fcNedcCombined.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcNedcCombined?.unit.description,
            };
        case UscQuickSpec.FcWltpCombined:
        case UscQuickSpec.EcolabelFcWltpCombined:
            return {
                ...newConfig,
                value: fcWltpCombined?.value
                    ? formatNumberIntl(fcWltpCombined.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcWltpCombined?.unit.description,
            };
        case UscQuickSpec.EcolabelFcCsCombined:
        case UscQuickSpec.FcCsCombined:
            return {
                ...newConfig,
                value: fcCsCombined?.value
                    ? formatNumberIntl(fcCsCombined.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcCsCombined?.unit.description,
            };
        case UscQuickSpec.FcInnerCity:
            return {
                ...newConfig,
                value: fcInnerCity?.value
                    ? formatNumberIntl(fcInnerCity.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcInnerCity?.unit.description,
            };
        case UscQuickSpec.FcOutskirts:
            return {
                ...newConfig,
                value: fcCityOutskirts?.value
                    ? formatNumberIntl(fcCityOutskirts.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcCityOutskirts?.unit.description,
            };
        case UscQuickSpec.FcCountryRoad:
            return {
                ...newConfig,
                value: fcCountryRoad?.value
                    ? formatNumberIntl(fcCountryRoad.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcCountryRoad?.unit.description,
            };
        case UscQuickSpec.FcHighway:
            return {
                ...newConfig,
                value: fcHighway?.value
                    ? formatNumberIntl(fcHighway.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: fcHighway?.unit.description,
            };
        case UscQuickSpec.EcCombined:
        case UscQuickSpec.EcolabelEcCombined:
            return {
                ...newConfig,
                value: ecCombined?.value
                    ? formatNumberIntl(ecCombined.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: ecCombined?.unit.description,
            };
        case UscQuickSpec.EcInnerCity:
            return {
                ...newConfig,
                value: ecInnerCity?.value
                    ? formatNumberIntl(ecInnerCity.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: ecInnerCity?.unit.description,
            };
        case UscQuickSpec.EcOutskirts:
            return {
                ...newConfig,
                value: ecOutskirts?.value
                    ? formatNumberIntl(ecOutskirts.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: ecOutskirts?.unit.description,
            };
        case UscQuickSpec.EcCountryRoad:
            return {
                ...newConfig,
                value: ecCountryRoad?.value
                    ? formatNumberIntl(ecCountryRoad.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: ecCountryRoad?.unit.description,
            };
        case UscQuickSpec.EcHighway:
            return {
                ...newConfig,
                value: ecHighway?.value
                    ? formatNumberIntl(ecHighway.value, commonSettings.culture.name, intlOptions)
                    : "",
                unit: ecHighway?.unit.description,
            };
        case UscQuickSpec.EaerCombined:
            return {
                ...newConfig,
                value: `${eaerCombined?.value || ""}`,
                unit: eaerCombined?.unit.description,
            };
        case UscQuickSpec.EaerCity:
            return {
                ...newConfig,
                value: `${eaerCity?.value || ""}`,
                unit: eaerCity?.unit.description,
            };
        case UscQuickSpec.PerCombined:
            return {
                ...newConfig,
                value: `${perCombined?.value || ""}`,
                unit: perCombined?.unit.description,
            };
        case UscQuickSpec.PerCity:
            return {
                ...newConfig,
                value: `${perCity?.value || ""}`,
                unit: perCity?.unit.description,
            };
        case UscQuickSpec.EfficiencyClass:
        case UscQuickSpec.EfficiencyClassText:
            return {
                ...newConfig,
                value: `${ecoInfo?.efficiencyClass?.code || ""}`,
            };
        case UscQuickSpec.EfficiencyClassCSMode:
            return {
                ...newConfig,
                value: `${ecoInfo?.efficiencyClassCSMode?.code || ""}`,
            };
        default:
            return {
                ...newConfig,
                value: "",
                unit: "",
            };
    }
};

export const uscNedcWltpDisclaimersLabelSelector = (state: CommonStateType): NedcWltpDisclaimersLabelType => ({
    nedcDisclaimer: getCommonOptionalLabel(state, "uscNedcDisclaimer"),
    wltpDisclaimer: getCommonOptionalLabel(state, "uscWltpDisclaimer"),
    nedcNotAvailableDisclaimer: getCommonOptionalLabel(state, "uscNedcNotAvailableDisclaimer"),
    efficiencyClassNotAvailableDisclaimer: getCommonOptionalLabel(state, "uscEfficiencyClassNotAvailableDisclaimer"),
});

export const sortEfficiencyClasses = (classes: MultipleChoiceFilterConfigType): MultipleChoiceValueType[] => {
    return classes.values.sort((a, b) => {
        const aPlusCount = (a.label.match(/\+/g) || []).length;
        const bPlusCount = (b.label.match(/\+/g) || []).length;

        // Compare the first letter of the label,
        // if they are the same, compare the number of '+' in the label
        if (a.label[0] === b.label[0]) {
            return bPlusCount - aPlusCount;
        }

        // Otherwise, sort by the letter
        return a.label[0] > b.label[0] ? 1 : -1;
    });
};

/**
 * Returns the emission label when the current spec is an energy efficiency class, the ecoLabelpopup is available in the settings and the CO2 Combined WLTP value is available in the specs
 */
export const getEmissionLabel = (
    currentSpec: ReferenceType<DfEfficiencyClassCode> | null | undefined,
    ecoInfo: UscEcoType | null,
    commonSettings: CommonSettingsType,
): EcoLabelPopupLabelType | null => {
    if (!commonSettings.ecoLabelpopup) {
        return null;
    }

    // isPluginOrHybrid is not relevant here, safe to always pass false.
    const ecoMeasureValues = getUscEcoMeasureValues(ecoInfo, false);
    const { combinedCo2 } = ecoMeasureValues;

    if (!currentSpec || !combinedCo2) return null;
    return {
        letter: currentSpec.code,
        label: parseSpecificationValue(
            { unit: combinedCo2.unit.description, value: `${combinedCo2.value}` },
            commonSettings.culture,
        ),
    };
};
/**
 * This is still fairly basic consumption and emission parsing vs the USC logic.
 * Basically we'll use WLTP consumption/emission values if available and fall back to NEDC if those values are available.
 * This might change over time and technically doesnt work for EVs as those use other consumption values.
 * For now this is how it has been working on PROD and we'll wait for a CR as updating this will likely require design updates as well..
 */

export const getUsedCarCompareEmissionValue = (car: LoadedUsedCompareCarType): ValUnitType | null => {
    const ecoMeasures = getUscEcoMeasureValues(car.product.eco, car.product.engine.plugin || car.product.engine.hybrid);
    if (!ecoMeasures.combinedCo2) return null;

    return { value: ecoMeasures.combinedCo2.value, unit: ecoMeasures.combinedCo2.unit.description };
};

export const getUsedCarCompareConsumptionValue = (car: LoadedUsedCompareCarType): ValUnitType | null => {
    const ecoMeasures = getUscEcoMeasureValues(car.product.eco, car.product.engine.plugin || car.product.engine.hybrid);
    const consumptionValue = ecoMeasures.fcWltpCombined || ecoMeasures.fcNedcCombined;

    if (!consumptionValue) return null;

    return {
        value: consumptionValue.value || 0,
        unit: consumptionValue.unit.description || "",
    };
};
