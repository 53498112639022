import type { CommonFinanceInfoType, PriceType, PromotionType } from "./types/CommonTypes";
import { PromotionTypeEnum } from "./types/CommonTypes";

// These breakpoints corresponds with the breakpoints defined src/css/theme/_variables.scss line 39-44
// These are only relevant for components not using styled components (explore/compare/build and buy/tradein)
export const BREAKPOINT_XS = 0;
export const BREAKPOINT_SM = 544;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;
export const BREAKPOINT_XXL = 1400;

// If cache should be invalidated by the Request class rather than Redis, this timing is used (currently 12 hours) for API calls

export const TOYOTA = "toyota" as const;
export const LEXUS = "lexus" as const;

// CarDB Carconfig versions (used for toggling between interior views: gallery/spin)
export const VERSION_6HD = "6.HD";
export const VERSION_6HD_INT_GALLERY = "6.HD+INTERIOR GALLERY";

// Code used in some logic based on Tridion settings to identify all models instead of one specific.
export const MODEL_CODE_ALL = "all";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";
export const GUID_REGEX = "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

// WLTP certification types.
export const CERTIFICATION_NONE = -1 as const;
export const CERTIFICATION_NEDC = 0 as const;
export const CERTIFICATION_CORRELATED_NEDC = 1 as const;
export const CERTIFICATION_WLTP = 2 as const;
export const NEDC_CODE = "NEDC" as const;
export const CORRELATED_NEDC_CODE = "NEDC_2" as const;
export const WLTP_CODE = "WLTP" as const;

export const certificationMap: Record<string, string> = {
    [CERTIFICATION_NONE]: "none",
    [CERTIFICATION_NEDC]: "NEDC",
    [CERTIFICATION_CORRELATED_NEDC]: "CORRELATED_NEDC",
    [CERTIFICATION_WLTP]: "WLTP",
};

export const consumptionCertificationMap: Record<string, number> = {
    [NEDC_CODE]: CERTIFICATION_NEDC,
    [CORRELATED_NEDC_CODE]: CERTIFICATION_CORRELATED_NEDC,
    [WLTP_CODE]: CERTIFICATION_WLTP,
};

// $Keys doesnt work here, Flow doesnt like objects with numbers as key types => Manually use typeof.
export type CertificationType =
    | typeof CERTIFICATION_NONE
    | typeof CERTIFICATION_NEDC
    | typeof CERTIFICATION_CORRELATED_NEDC
    | typeof CERTIFICATION_WLTP;

export type CacheBodyType = { body: string; expired: boolean };
export type CacheType = {
    set(key: string, value: { body: any; params: any }, expire: number, persistantRedisCache?: number): void;
    getKey(
        identifier: string,
        type: "data" | "render",
        country: string,
        language: string,
        params?: { [index: string]: any } | null,
    ): string;
    get(key: string): Promise<CacheBodyType | null>;
};

export const getDefaultPriceObject = (): PriceType => ({
    cash: 0,
    monthly: 0,
    exclVat: 0,
    discount: 0,
    promotions: [],
    highlight: false,
    licenseFee: 0,
    onlineCashDiscount: 0,
    onlineMonthlyDiscount: 0,
    onlineCashPromotions: [],
    onlineMonthlyPromotions: [],
});

export const defaultFinanceInfoObject: CommonFinanceInfoType = {
    annualInterestRate: "",
    effectiveInterestRate: "",
    productName: "",
    productType: "",
    term: "",
    disclaimer: null,
};

export const defaultPromotionObject: PromotionType = {
    index: 0,
    id: "",
    name: "",
    amount: 0,
    type: PromotionTypeEnum.None,
    shortId: "",
    description: "",
    target: "",
    from: "",
    until: "",
    disclaimer: "",
    customerOptInRequired: false,
    customerOptInRequiredLabel: "",
    onlineMonthlyRate: false,
    onlineCash: false,
    ecoBonus: false,
};

// AEM version: aemFmPriceTypes
export enum FlexMatrixPriceType {
    ExclVat = "ex",
    InclVat = "in",
    InclLicenseFee = "in-license-fee",
    InclHideDiscountInfo = "in-hide-discount-info",
    ExclHideDiscountInfo = "ex-hide-discount-info",
}

// Fuel type ids from CARDB.
export enum NewCarFuelTypeFilterId {
    Hybrid = "h_1",
    FuelCell = "h_2",
    Petrol = "1",
    Diesel = "2",
    BatteryElectric = "3",
    Electric = "electric",
    Plugin = "plugin", // not a true fuelType, this is a custom value in case the plugin flag in c1-car-filter response is true
}

/**
 * Defines which label (only ES for now) should be displayed underneath a motorization
 */
export enum EcoBadgeType {
    ZeroEmissions = "zeroEmissions",
    Hybrid = "hybrid", // generic eco badge, called hybrid for legacy reasons
}

export enum EcolabelType {
    Zero = "0",
    Eco = "ECO",
}

// Winter wheel constants.
export const SOFT_COMPOUND_NORDIC = "SOFT_COMPOUND_NORDIC" as const;
export const SOFT_COMPOUND_EU = "SOFT_COMPOUND_EU" as const;
export const STUDDED = "STUDDED" as const;
export type WinterWheelCategoryType = typeof SOFT_COMPOUND_NORDIC | typeof SOFT_COMPOUND_EU | typeof STUDDED;

// Definition of which calculator the ExternalCalculatorLink should open
export const FINANCE_CALCULATOR = "finance" as const;
export const INSURANCE_CALCULATOR = "insurance" as const;

// Event which is triggered when the color in the page is updated in context of the features and specs page of the grade pages
export const POSTAL_TOPIC_COLOR_CHANGED = "color.changed";
// This is the typed payload received from the website, provided in above event

// Postal channels
export const POSTAL_CHANNEL_T1_FINANCE = "com.toyota.tme.t1.finance";
export const POSTAL_CHANNEL_FQS = "com.toyota.tme.quoteService";
export const POSTAL_CHANNEL_T1_CARCONFIG = "com.toyota.tme.t1.carconfig";
export const POSTAL_CHANNEL_T1_DIALOG = "com.toyota.tme.t1.dialog";
export const POSTAL_CHANNEL_T1_HASH = "com.toyota.tme.t1.hash";
export const POSTAL_CHANNEL_T1_DEALER = "com.toyota.tme.t1.dealer";
export const POSTAL_CHANNEL_T1_FORM = "com.toyota.tme.t1.form";
export const POSTAL_CHANNEL_T1_MODELFILTER = "com.toyota.tme.t1.modelfilter";
export const POSTAL_CHANNEL_T1_COMPARE_V2 = "com.toyota.tme.t1.compare.v2.channel";
export const POSTAL_CHANNEL_T1_INJECTION = "com.toyota.tme.t1.injection";
export const POSTAL_CHANNEL_DXP_ECOLABEL = "com.tme.dxp.ecolabel";
export const POSTAL_CHANNEL_DXP_SAFETYLABEL = "com.tme.dxp.safetylabel";
// Channel for postal event for when primary nav is hidden
export const POSTAL_CHANNEL_T1_NAVIGATION = "com.toyota.tme.t1.navigation";
// Channel used to communicate over in context of the grade pages and components (euquipment/proace) injected in the features and specs page.
export const POSTAL_CHANNEL_T1_GRADE_PAGE_COMPONENTS = "com.toyota.tme.t1.global";

// Postal topics
export const POSTAL_TOPIC_BUY_DISPLAYED = "buy-displayed";
export const POSTAL_TOPIC_HASH_ADD = "hash.add";
// topic for postal event for when primary nav is hidden
export const POSTAL_TOPIC_PRIMARY_NAV_SHOW = "nav.primary.show";
export const POSTAL_TOPIC_PRIMARY_NAV_HIDE = "nav.primary.hide";
export const POSTAL_TOPIC_PRIMARY_NAV = "nav.primary.visibility";
export const POSTAL_TOPIC_PRIMARY_NAV_REQUEST = "nav.primary.visibility.request";
export const POSTAL_TOPIC_ECOLABEL_SHOW = "ecolabel.show";
export const POSTAL_TOPIC_SAFETYLABEL_SHOW = "safetylabel.show";
// CARCONFIG EVENTS
export const POSTAL_TOPIC_CARCONFIG_CODE = "configCode";
export const POSTAL_TOPIC_CARCONFIG_INIT = "carconfig.initialize";
export const POSTAL_TOPIC_CARCONFIG_BUY = "carconfig.buy";
export const POSTAL_TOPIC_CARCONFIG_END = "carconfig.end";
export const POSTAL_TOPIC_CARCONFIG_LOAD = "carconfig.load";
export const POSTAL_TOPIC_CARCONFIG_LOAD_CONFIG = "carconfig.config.load";
export const POSTAL_TOPIC_CARCONFIG_MINI_INIT = "carconfig.mini.init";
export const POSTAL_TOPIC_CARCONFIG_MINI_LOAD = "carconfig.mini.load";
export const POSTAL_TOPIC_CARCONFIG_PERSONAL_CODE = "personalCode";
export const POSTAL_TOPIC_CARCONFIG_RENDER = "carconfig.render";
export const POSTAL_TOPIC_CARCONFIG_SAVE_CONFIG = "carconfig.config.save";
// VARIANT SELECTOR
export const POSTAL_TOPIC_T1_MODELFILTER_OPEN_VARIANTSELECTOR = "variantselector.open";
export const POSTAL_TOPIC_COMPONENT_INJECT = "component.inject";
// MATERIALBOX EVENTS
export const POSTAL_TOPIC_T1_MATERIALBOX_OPEN = "materialBox.open";
export const POSTAL_TOPIC_T1_MATERIALBOX_CLOSE = "materialBox.close";
// FINANCE/QUOTE EVENTS
export const POSTAL_TOPIC_FQS_QUOTE_SET = "quote.set";
export const POSTAL_TOPIC_UPDATE_DISCLAIMERTABLE = "update.disclaimertable";
//DEALER EVENTS
export const POSTAL_TOPIC_DEALERFINDER_OPEN_IN_OVERLAY = "dealerfinder.inoverlayer.open";
// COMPARE EVENTS
export const POSTAL_TOPIC_T1_COMPARE_V2_ADD_CAR = "com.toyota.tme.t1.compare.v2.add.car";
export const POSTAL_TOPIC_T1_COMPARE_V2_OPEN_ONE_CAR = "com.toyota.tme.t1.compare.v2.open.one";
// USC EVENTS
export const POSTAL_TOPIC_USED_CARS_BUY = "usedcars.buy";
export const POSTAL_TOPIC_STOCK_CARS_BUY = "stockcars.buy";
export const POSTAL_TOPIC_USC_FORM_OPEN = "tucl.open.pdp.contact.form";
export const POSTAL_TOPIC_USC_FINANCE = "finance.tucl.financeQuoteId.calculated";

export type PostalChannelType =
    | typeof POSTAL_CHANNEL_T1_FINANCE
    | typeof POSTAL_CHANNEL_FQS
    | typeof POSTAL_CHANNEL_T1_CARCONFIG
    | typeof POSTAL_CHANNEL_T1_DIALOG
    | typeof POSTAL_CHANNEL_T1_HASH
    | typeof POSTAL_CHANNEL_T1_DEALER
    | typeof POSTAL_CHANNEL_T1_FORM
    | typeof POSTAL_CHANNEL_T1_MODELFILTER
    | typeof POSTAL_CHANNEL_T1_COMPARE_V2
    | typeof POSTAL_CHANNEL_T1_INJECTION
    | typeof POSTAL_CHANNEL_DXP_ECOLABEL
    | typeof POSTAL_CHANNEL_DXP_SAFETYLABEL
    | typeof POSTAL_CHANNEL_T1_NAVIGATION
    | typeof POSTAL_CHANNEL_T1_GRADE_PAGE_COMPONENTS;

export type PostalTopicType =
    | typeof POSTAL_TOPIC_BUY_DISPLAYED
    | typeof POSTAL_TOPIC_HASH_ADD
    | typeof POSTAL_TOPIC_PRIMARY_NAV
    | typeof POSTAL_TOPIC_PRIMARY_NAV_SHOW
    | typeof POSTAL_TOPIC_PRIMARY_NAV_HIDE
    | typeof POSTAL_TOPIC_PRIMARY_NAV_REQUEST
    | typeof POSTAL_TOPIC_ECOLABEL_SHOW
    | typeof POSTAL_TOPIC_SAFETYLABEL_SHOW
    | typeof POSTAL_TOPIC_CARCONFIG_CODE
    | typeof POSTAL_TOPIC_CARCONFIG_INIT
    | typeof POSTAL_TOPIC_CARCONFIG_BUY
    | typeof POSTAL_TOPIC_CARCONFIG_END
    | typeof POSTAL_TOPIC_CARCONFIG_LOAD
    | typeof POSTAL_TOPIC_CARCONFIG_LOAD_CONFIG
    | typeof POSTAL_TOPIC_CARCONFIG_MINI_INIT
    | typeof POSTAL_TOPIC_CARCONFIG_MINI_LOAD
    | typeof POSTAL_TOPIC_CARCONFIG_PERSONAL_CODE
    | typeof POSTAL_TOPIC_CARCONFIG_RENDER
    | typeof POSTAL_TOPIC_CARCONFIG_SAVE_CONFIG
    | typeof POSTAL_TOPIC_FQS_QUOTE_SET
    | typeof POSTAL_TOPIC_UPDATE_DISCLAIMERTABLE
    | typeof POSTAL_TOPIC_T1_MATERIALBOX_OPEN
    | typeof POSTAL_TOPIC_T1_MATERIALBOX_CLOSE
    | typeof POSTAL_TOPIC_T1_MODELFILTER_OPEN_VARIANTSELECTOR
    | typeof POSTAL_TOPIC_COMPONENT_INJECT
    | typeof POSTAL_TOPIC_DEALERFINDER_OPEN_IN_OVERLAY
    | typeof POSTAL_TOPIC_T1_COMPARE_V2_ADD_CAR
    | typeof POSTAL_TOPIC_T1_COMPARE_V2_OPEN_ONE_CAR
    | typeof POSTAL_TOPIC_USED_CARS_BUY
    | typeof POSTAL_TOPIC_STOCK_CARS_BUY
    | typeof POSTAL_TOPIC_USC_FORM_OPEN
    | typeof POSTAL_TOPIC_USC_FINANCE;

export const MAIN_NAV_CLASSNAME_TOYOTA = ".maincontent";

// Salesman - Billboard communication constants. See InitializeSalesman for more information
export const SALESMAN_HLIST_PAGE = "hlist-page" as const;
export const SALESMAN_EXTSPIN_LOAD = "extspin-load" as const;
export const SALESMAN_EXTSPIN_ANGLE = "extspin-angle" as const;
export const SALESMAN_3D_INTERIOR_POSITION = "3d-interior-position" as const;
export const SALESMAN_3D_EXTERIOR_POSITION = "3d-exterior-position" as const;
export const SALESMAN_CAREXPLORER_FILTERVALUE = "carexplorer-filtervalue" as const;
export const SALESMAN_QUOTE_CONFIG = "quote-config" as const;
export const SALESMAN_SCROLL_OVERLAY = "scroll-overlay" as const;
export const SALESMAN_CLICK_SELECTOR = "click-selector" as const;

// Gr Sport
export enum GrCode {
    Gr = "GR",
    Gr86 = "GR86",
    GrSport = "GR SPORT",
}

// Model-specific settings should be used in most cases. These are here for:
// - Legacy reasons (build proace filters)
// - Model-specific settings that are not country specific (BZ theme, ecolabels)
// - DevX (trade-in tools)
export enum CarDBModelId {
    // Toyota
    BZ4X = "7fb7f6d1-dbbc-4886-98ea-d1856e3815db",
    Mirai = "ded7608c-09b2-4746-b8cc-6550859538cd",
    PriusPHEV = "d27a100c-76fc-4d03-8ed4-ab4a152b09da",
    RAV4PHEV = "61c17908-5693-4b02-b49e-96c0e527f2e7",
    ProaceEV = "4e532c9d-f6b7-4d24-a0e8-bab43c5f18fb",
    Proace = "456305c2-361e-4c72-beac-1a1abbdad15d",
    ProaceVerso = "b0fec657-68ed-4858-b470-ee998aa53d8a",
    ProaceCity = "c9fe38ba-7298-4411-b85e-996dc0c9e6d1",
    ProaceCityVerso = "74c690bd-868d-47df-9f4f-98277d13615c",
    ProaceMax = "ea59a13e-d471-4e34-9068-c149abd01723",
    Yaris = "09a6531a-c3f1-4d2d-b4d3-eb45cbb35478",

    // Lexus
    ES = "82bb35a9-e41e-4809-bca8-2be543ec22a3",
}

export const THEME_CLASSNAME_BZ = "bz";

export const EFFICIENCY_CLASS = "ENERGY-EFFICIENCY-CLASS";
export const EFFICIENCY_CLASS_TMEF = "ENERGY-EFFICIENCY-CLASS-TMEF";
export const EFFICIENCY_CLASS_LEXUS = "ENERGY-EFFICIENCY-CLASS-LEXUS";
export const EFFICIENCY_CLASS_EMPTY_BATTERY_TMEF = "ENERGY-EFFICIENCY-CLASS-EMPTY-BATTERY-TMEF";

// TECH SPEC CATEGORIES
export enum TechnicalSpecificationCategory {
    EnvironmentalPerformance = "ENVPERF",
    FuelConsumption = "FUEL CONSUMPTION",
    Emission = "EMIS",
    ElectricConsumption = "ELECCOMSUM",
    ElectricRange = "EVRANGE",
    Performance = "PERF",
    NvhPerf = "NVHPERF",
}

export const APH_GRADE_SELECTOR_ID = "aphgradeselector";

// Timeout is set to 300 because maximum processing time server-side is 500
// https://github.com/marlon-be/toyota-online-retailer/pull/7525#discussion_r1686100432
// https://github.com/marlon-be/dxp-nc-webcomponents/blob/develop/core/src/main/java/com/newcars/aem/core/services/AphComponentInjectorServiceConfiguration.java#L41
export const FINANCE_TIMEOUT = 300;
