import styled, { css } from "styled-components";
import { breakpoints, maxBreakpoints } from "../../../../../shared-logic/themes/toyotaTheme";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export const Container = styled.div`
    width: 100%;

    @media screen and (min-width: ${breakpoints.xl}) {
        max-width: 1440px;
    }

    @media screen and (min-width: ${breakpoints.max}) {
        max-width: 1600px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
`;

export const SidebarWrapper = styled.div<{ showMoreFilters?: boolean }>`
    display: ${({ showMoreFilters }) => (showMoreFilters ? "block" : "none")};
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.space[2]}px;
    background-color: ${theme.colors.primaryWhite};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndex.overlay};

    @media screen and (min-width: ${breakpoints.lg}) {
        display: ${({ showMoreFilters }) => (showMoreFilters ? "grid" : "none")};
        position: relative;
        top: auto;
        inset-inline-start: auto;
        align-items: start;
        width: auto;
        height: auto;
        padding: 0;
        background-color: transparent;
        overflow: visible;
        z-index: auto;

        & > div {
            position: sticky;
            top: calc(var(--visibleHeaderHeight) + 122px); /* 122px = size StaticFilterBar */
        }
    }

    /* Hide on mobile */
    @media screen and (max-width: ${maxBreakpoints.md}) {
        display: none;
    }

    @supports not (inset-inline: 0) {
        left: 0;

        html[dir="rtl"] & {
            left: auto;
            right: 0;
        }

        @media screen and (min-width: ${breakpoints.lg}) {
            left: auto;

            html[dir="rtl"] & {
                left: auto;
                right: auto;
            }
        }
    }
`;

export const Content = styled.main<{ showMoreFilters?: boolean }>`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-auto-rows: min-content;
    gap: ${theme.space[3]}px;

    /* vp-width: 768px */
    @media screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column: 1 / span 2;
    }

    /* vp-width: 1024 */
    @media screen and (min-width: ${breakpoints.lg}) {
        ${({ showMoreFilters }) =>
            showMoreFilters
                ? css`
                      grid-template-columns: repeat(2, minmax(0, 1fr));
                      grid-column: 2 / span 2;
                  `
                : css`
                      grid-template-columns: repeat(3, minmax(0, 1fr));
                      grid-column: 1 / span 3;
                  `};
    }

    /* vp-width: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        ${({ showMoreFilters }) =>
            showMoreFilters
                ? css`
                      grid-template-columns: repeat(3, minmax(0, 1fr));
                      grid-column: 2 / span 3;
                  `
                : css`
                      grid-template-columns: repeat(4, minmax(0, 1fr));
                      grid-column: 1 / span 4;
                  `};
    }

    /* vp-width: 1440px */
    @media screen and (min-width: ${breakpoints.xxl}) {
        gap: ${theme.space[4]}px;
    }
`;

export const Grid = styled.div`
    --usc-car-result-subgrid-row: 6; /* This should be equal to the number of rows in the Wrapper of UsedCarResultStyles ! */

    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: ${theme.space[3]}px;
    justify-content: center;
    margin-top: 3px; /* Fix hidden box-shadow of tiles */
    margin-bottom: 40px;
    padding-top: ${theme.space[3]}px;

    /* vp-width: 768px */
    @media screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    /* vp-width: 1024 */
    @media screen and (min-width: ${breakpoints.lg}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    /* vp-width: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    /* vp-width: 1440px */
    @media screen and (min-width: ${breakpoints.xxl}) {
        gap: ${theme.space[4]}px;
    }

    @supports not (gap: 0) {
        grid-column-gap: ${theme.space[4]}px;
        grid-row-gap: ${theme.space[4]}px;
    }
`;
