import {
    getAemMonthlyRateFormat,
    showAemMonthlyRates,
} from "../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import type { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import { UscContext } from "../../shared-logic/types/UscCommonTypes";

/**
 * AEM specific showAemMonthlyRates helper for car-filter.
 * New cars unsupported for now.
 */
export const showCarFilterAemMonthlyRates = (
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    context: UscContext,
): ReturnType<typeof showAemMonthlyRates> => {
    const aemComponent = context === UscContext.Used ? AemFmComponent.CarFilterUsed : AemFmComponent.CarFilterStock;

    const enableFinance = carFilterSettings.enableFinance[context];

    return enableFinance && showAemMonthlyRates(commonSettings, aemComponent, context);
};

/**
 * AEM specific getAemMonthlyRateFormat helper for car-filter.
 * New cars unsupported for now.
 */
export const getCarFilterAemMonthlyRateFormat = (
    commonSettings: CommonSettingsType,
    context: UscContext,
): ReturnType<typeof getAemMonthlyRateFormat> => {
    const aemComponent = context === UscContext.Used ? AemFmComponent.CarFilterUsed : AemFmComponent.CarFilterStock;

    return getAemMonthlyRateFormat(commonSettings, aemComponent, context);
};
