import {
    useDispatch as useReduxDispatch,
    useSelector as useReduxSelector,
    useStore as useReduxStore,
} from "react-redux";

/**
 * @deprecated - Always use the typed useSelector's of the specific component context
 * Make sure to import "useSelector" from "react-redux" when creating component specific typed selectors
 */
export const useSelector = useReduxSelector;
/**
 * @deprecated - Always use the typed useStore's of the specific component context
 * Make sure to import "useStore" from "react-redux" when creating component specific typed selectors
 */
export const useStore = useReduxStore;
/**
 * @deprecated - Always use the typed useDispatch's of the specific component context
 * Make sure to import "useDispatch" from "react-redux" when creating component specific typed selectors
 */
export const useDispatch = useReduxDispatch;
