import type { SyntheticEvent } from "react";
import { showModal } from "../../../common-deprecated/redux/actions/ModalActions";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";
import { getUscUrl } from "../../shared-logic/utils/uscUtils";
import type { CarFilterSettingsType } from "../redux/reducers/CarFilterSettingsReducer";
import type { CarFilterDispatchType } from "../redux/store";
import type { UsedCarResultType } from "../../shared-logic/types/UscCommonTypes";
import { UscContext } from "../../shared-logic/types/UscCommonTypes";
import { getUscVehicleUrlInfoFromResult } from "./helpers";
import { getCarFilterLabel } from "./constants/labels";
import { MODAL_USC_FORM } from "../../shared-logic/utils/integrated-modal/integratedModalConstants";
import { DfVehicleStatus } from "../../shared-logic/utils/dfConstants";
import type { ColorType } from "../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";

export enum CarFilterCtaIcon {
    Dealer = "dealer",
    Phone = "phone",
}

export enum CarFilterCtaTypeEnum {
    ContactDealer = "contactDealer",
    Next = "next",
}

export type CarFilterCtaType = {
    label: string;
    icon?: CarFilterCtaIcon;
    onClick?: (event: React.SyntheticEvent) => void;
    href?: string;
    type?: CarFilterCtaTypeEnum;
};

/**
 * Opens the Contact Dealer form
 */
export const openContactDealerForm = (
    vehicle: UsedCarResultType,
    detailPageUrl: string,
    context: "used" | "stock",
    commonSettings: CommonSettingsType,
    dispatch: CarFilterDispatchType,
    modalTitle: string,
): void => {
    const { dealer } = vehicle;

    const vehicleUrl = getUscUrl(commonSettings, detailPageUrl, context, getUscVehicleUrlInfoFromResult(vehicle));

    // modalLogic will attach additional vehicle/dealer/finance/... information to this event so we dont have to propagate it.
    dispatch(
        showModal({
            type: MODAL_USC_FORM,
            vehicleForSaleId: vehicle.id,
            uscContext: context,
            modalTitle,
            onCarFilter: true,
            externalData: {
                // This will always be "null", as on the Car Filter the user has not had the
                // option to open the Finance/Insurance calculators
                externalPriceInfo: null,
                vehicleUrl,
            },
            dealer: dealer || undefined,
            vehicle,
        }),
    );
};

/**
 * Returns a Contact Dealer CTA to be used on the Car Filter (result cards)
 * Make sure to align the logic in this function with the PDP equivalent.
 */
export const getContactDealerCta = (
    vehicle: UsedCarResultType,
    detailPageUrl: string,
    commonSettings: CommonSettingsType,
    carFilterSettings: CarFilterSettingsType,
    context: "used" | "stock", // retyped for getUscUrl
    dispatch: CarFilterDispatchType,
    localContactDealerClick?: () => void,
): CarFilterCtaType | null => {
    if (
        // Don't show the CTA if the vehicle is sold, as the user should not be able to contact the dealer
        vehicle?.vehicleStatus.code === DfVehicleStatus.Sold ||
        // In the case of a hub car, we won't have a dealer. This is fine, and is handled by sending the dealer as undefined (see openContactDealerForm).
        // For non-hub cars, we _need_ a dealer to show the contact dealer form. If we don't have a dealer, we don't show the CTA.
        (!vehicle?.isHubCar && !vehicle.dealer)
    ) {
        return null;
    }

    const contactLabel = getCarFilterLabel({ carFilterSettings, commonSettings }, "carFilterContactDealerCta");
    const localContactDealerFormUrl = carFilterSettings.localContactDealerFormUrl[context];

    let contactDealerclick: ((event: SyntheticEvent) => void) | null = null;

    if (localContactDealerFormUrl && !commonSettings.query.disableLocalContactForm && !!localContactDealerClick) {
        contactDealerclick = localContactDealerClick;
    } else if (vehicle) {
        contactDealerclick = () => {
            const modalTitle = getCarFilterLabel(
                { carFilterSettings, commonSettings },
                "carFilterContactDealerModalTitle",
            );

            openContactDealerForm(vehicle, detailPageUrl, context, commonSettings, dispatch, modalTitle);
        };
    }

    return contactDealerclick
        ? {
              label: contactLabel,
              onClick: contactDealerclick,
              icon: CarFilterCtaIcon.Dealer,
              type: CarFilterCtaTypeEnum.ContactDealer,
          }
        : null;
};

/**
 * This only applies to Toyota buttons on the Car Filter.
 * UCL uses red primary buttons,
 * SCL uses black primary buttons.
 */
export const getToyotaButtonColor = (uscContext: UscContext): ColorType => {
    return uscContext === UscContext.Used ? "primaryRedHover" : "primaryBlack";
};
