/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";

import type { ModalViewType, QueryObjectType } from "../../../../types/CommonTypes";
import type { ModalToolsType } from "../../../../utils/modalConstants";
import { useCommonSelector } from "../../../../redux/commonStore";
import { useToolsModalEnvs } from "../../../../utils/environments";
import { ENDPOINT_COMPONENT_MAP } from "../../../../../shared-logic/server/components";
import type { QueryType } from "../../../../server/types";
import { IntegratedDebugModal } from "./IntegratedDebugModal";
import type { LinkOnClickType, LinkType } from "../hooks/useToolsModal";
import useToolsModal from "../hooks/useToolsModal";
import { getCombinedToolUrl, redefineComponent } from "../utils/toolsModalUtils";

const Link = ({
    link,
    onClickHandler,
}: {
    link: LinkType;
    onClickHandler: (onClickType: LinkOnClickType) => void;
}): JSX.Element => {
    return (
        <a
            href={link.href || "#"}
            onClick={() => link.onClickLogic && onClickHandler(link.onClickLogic)}
            target={link.redirect || !link.href ? undefined : "_blank"}
            rel="noopener noreferrer"
        >
            {link.title}
        </a>
    );
};

export type QueryBuilderStateType = Partial<Record<keyof QueryType, QueryObjectType>>;

export type ToolsModalItemType =
    | false
    | {
          title?: string;
          links: (false | LinkType)[];
      };

const ToolsModal = (props: ModalViewType<ModalToolsType>): JSX.Element => {
    const { close, show, modalSettings } = props;
    const { extraItems, extraQueryBuilderOptions, componentName } = modalSettings;

    const { onClickHandler, queryBuilderState, handleQueryBuilderChange } = useToolsModal(extraQueryBuilderOptions);
    const standaloneEnvs = useToolsModalEnvs();

    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const { country, language, brand, component } = commonSettings;

    const initialEnvironment =
        standaloneEnvs.find((env) => env.url.includes(window.location.host)) ||
        standaloneEnvs[standaloneEnvs.length - 1]; // If none of the standalone environments match the current environment, use the last one (current environment)
    const [selectedHost, setSelectedHost] = useState(initialEnvironment);

    const redefinedComponent = redefineComponent(component, selectedHost.name);
    const componentString = ENDPOINT_COMPONENT_MAP[redefinedComponent];

    const combinedUrl = getCombinedToolUrl(
        `${selectedHost?.url || ""}${!selectedHost.isIntegrated ? `/${country}/${language}/${componentString}` : ""}`,
        queryBuilderState,
        commonSettings,
        selectedHost?.addBrandParam ? { brand } : undefined,
    );

    return (
        <IntegratedDebugModal props={{ close, show, variant: "tools" }}>
            <div>
                <header>
                    <h1>Tools - {componentName}</h1>
                </header>
                <section>
                    <fieldset>
                        <p>Environment</p>
                        <select
                            onChange={(event) => {
                                const env = standaloneEnvs.find(
                                    (environment) => environment.url === event.target.value,
                                );
                                if (env) setSelectedHost(env);
                            }}
                            value={selectedHost?.url || ""}
                        >
                            <option value="">--- Select environment ---</option>
                            {standaloneEnvs.map((env) => (
                                <option
                                    key={`${env.name.component}${env.name.env ? ` ${env.name.env}` : ""}`}
                                    value={env.url}
                                >
                                    {`${env.name.component}${env.name.env ? ` ${env.name.env}` : ""}`}
                                </option>
                            ))}
                        </select>
                    </fieldset>
                    <fieldset>
                        <div>
                            <table>
                                <tbody>
                                    {Object.entries(queryBuilderState).map(([queryName, option]) => {
                                        if (option.isDropdown) {
                                            return (
                                                <tr key={queryName}>
                                                    <td>
                                                        <button type="button">
                                                            <span>{option.label}</span>
                                                            <select
                                                                value={
                                                                    option.currentValue
                                                                        ? option.currentValue.toString()
                                                                        : ""
                                                                }
                                                                onChange={(event) =>
                                                                    handleQueryBuilderChange(queryName, true, event)
                                                                }
                                                            >
                                                                <option value="">--- Select to override ---</option>
                                                                {option.queryValues.map((value) => (
                                                                    <option key={value} value={value as string}>
                                                                        {value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        }

                                        return (
                                            <tr key={queryName}>
                                                <td>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleQueryBuilderChange(queryName)}
                                                    >
                                                        <input
                                                            onChange={(e) => e.stopPropagation()}
                                                            type="checkbox"
                                                            checked={!!option.currentValue}
                                                        />
                                                        <span>{option.label}</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>

                    <fieldset>
                        <a href={combinedUrl} target="_blank" rel="noreferrer">
                            {combinedUrl}
                        </a>
                    </fieldset>

                    <hr />

                    <fieldset>
                        {extraItems.filter(Boolean).map((item) => item && item.links.filter(Boolean)).length > 0 && (
                            <>
                                <div>
                                    <p>
                                        <strong>Other links: </strong>
                                    </p>
                                </div>

                                {extraItems?.filter(Boolean).map(
                                    (item, index) =>
                                        item &&
                                        item.links.filter(Boolean).length > 0 && (
                                            <div key={index}>
                                                <div>
                                                    {item.links.length > 1 ? (
                                                        <div>
                                                            <table>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>{item.title}</strong>
                                                                        </td>
                                                                        <td />
                                                                    </tr>
                                                                    {item.links.map(
                                                                        (link, linkIndex) =>
                                                                            link && (
                                                                                <tr key={linkIndex}>
                                                                                    <td>
                                                                                        <Link
                                                                                            link={link}
                                                                                            onClickHandler={
                                                                                                onClickHandler
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                    <td />
                                                                                </tr>
                                                                            ),
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    ) : (
                                                        item.links[0] && (
                                                            <Link
                                                                link={item.links[0]}
                                                                onClickHandler={onClickHandler}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ),
                                )}
                            </>
                        )}
                    </fieldset>
                </section>
            </div>
        </IntegratedDebugModal>
    );
};

export default ToolsModal;
