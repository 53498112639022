import React, { useMemo } from "react";
import { useCarFilterSelector } from "../../../redux/store";
import FilterDropdown from "./FilterDropdown";
import * as Styles from "./styles/FilterListStyles";
import FilterWrapper from "./FilterWrapper";
import { getCurrentFilters, isHiddenFilter } from "../../../utils/filters";
import useFilterList from "../../../hooks/filters/useFilterList";

const FilterList = (): JSX.Element => {
    const carFilters = useCarFilterSelector((state) => state.carFilters);

    const { currentFilter } = carFilters;

    const filterIds = getCurrentFilters(currentFilter);

    // Calculate and sort the filters that should be rendered.
    // Car Type is always excluded from this list as it is rendered first and has custom render logic.
    const activeFilters = Object.values(filterIds)
        .filter((filterId) => carFilters[filterId].show && !carFilters[filterId].disabled && !isHiddenFilter(filterId))
        .sort((a, b) => carFilters[a].index - carFilters[b].index);

    const { activeFilterIds, dropdownClick, activeFiltersKey } = useFilterList();

    return useMemo(() => {
        return (
            <Styles.Wrapper>
                <Styles.Inner role="list">
                    {activeFilters.map((filterId) => (
                        <FilterDropdown
                            key={filterId}
                            show={activeFilterIds.includes(filterId)}
                            onClick={dropdownClick(filterId)}
                            title={carFilters[filterId].label}
                            filterId={filterId}
                        >
                            <FilterWrapper filterId={filterId} />
                        </FilterDropdown>
                    ))}
                    <Styles.Inner />
                </Styles.Inner>
            </Styles.Wrapper>
        );
    }, [activeFiltersKey, activeFilterIds, currentFilter]);
};

export default FilterList;
