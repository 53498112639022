import { createLogic } from "redux-logic";
import type { CarFilterLogicType } from "../store";
import type { FetchCarResultsType, FilterCarResultsType, SetCarResultsType } from "../actions/CarResultsActions";
import {
    disableUsedCarResultsPlaceholders,
    FETCH_CAR_RESULTS,
    FILTER_CAR_RESULTS,
    filterCarResults,
    replaceCarResults,
    SET_CAR_RESULTS,
    SET_NEW_CAR_RESULTS_ORDER,
    SET_USED_CAR_RESULTS_ORDER,
    setCarResults,
} from "../actions/CarResultsActions";
import Debug from "../../../../common-deprecated/Debug";
import type { CarFiltersActionsType } from "../actions/CarFiltersActions";
import {
    CAR_FILTER_ACTIONS,
    CAR_FILTER_SET_PAGE,
    setPage,
    setTotalPageCount,
    updateMultiFilterCounts,
} from "../actions/CarFiltersActions";
import { fetchDFCarResults, mainFilterSelector, getContentBlockConfig } from "../../utils/filters";
import { replaceQueryParams } from "../../../../common-deprecated/History";
import type { CarFilterIdType } from "../../../shared-logic/types/UscCommonTypes";
import { getTotalResultCount } from "../../utils/helpers";
import { CarFilterQueryParam, carFilterValuesToParams } from "../../utils/params";
import { generaliseSimilarCarsFilter } from "../../utils/similarCars";
import { shouldShowErrorModal } from "../../../../common-deprecated/utils";
import { MODAL_ERRORS } from "../../../../common-deprecated/utils/modalConstants";
import { COMPONENT_CAR_FILTER } from "../../../../shared-logic/server/components";
import { showModal } from "../../../../common-deprecated/redux/actions/ModalActions";

// Fetch results logic. Triggers when additional results need to be fetched.
const fetchResultLogic = (createLogic as CarFilterLogicType<FetchCarResultsType>)({
    type: FETCH_CAR_RESULTS,
    async process({ getState, action }, dispatch, done) {
        const { commonSettings, carFilterSettings, carFilters, carResults } = getState();
        const { dfCarSortOrder } = carResults;
        const { resultCount, offset, includeActiveAggregations } = action;

        try {
            const result = await fetchDFCarResults(
                commonSettings,
                carFilterSettings,
                carFilters,
                offset,
                resultCount,
                dfCarSortOrder,
                includeActiveAggregations,
            );

            if (result !== null) {
                const { totalResultCount, results, aggregations, errorLogs = [], totalPageCount } = result;
                // Similar car fallback logic, if no cars are found try to reduce the amount of active filters.
                // If this ever gets more complex consider writing custom similar car fetch actions.
                if (carFilters.similarCar.isActive && totalResultCount === 0) {
                    const { filtersUpdated } = generaliseSimilarCarsFilter(dispatch, getState);
                    if (filtersUpdated) {
                        // refetchResultLogic will be triggering a new fetch.
                        done();
                        return;
                    }
                }

                // Show errors if present.
                if (shouldShowErrorModal(commonSettings, errorLogs)) {
                    const modalSettings = {
                        type: MODAL_ERRORS,
                        errors: errorLogs,
                        componentName: COMPONENT_CAR_FILTER,
                    };
                    dispatch(showModal(modalSettings));
                }

                dispatch(setCarResults(carFilters.currentFilter, results, totalResultCount));
                dispatch(updateMultiFilterCounts(aggregations, carFilters.currentFilter));
                dispatch(setTotalPageCount(totalPageCount));
            }
        } catch (e) {
            Debug.error("Failed to retrieve results", e);
        }

        done();
    },
});

// Filter results logic, triggers when a filter is updated or added.
const filterResultsLogic = (createLogic as CarFilterLogicType<FilterCarResultsType>)({
    type: FILTER_CAR_RESULTS,
    // Debounce Added so tracking and logic won't get triggered multiple times when clearing all Filters.
    debounce: 10,
    async process({ getState }, dispatch, done) {
        const { commonSettings, carFilterSettings, carFilters, carResults } = getState();
        const { dfCarSortOrder } = carResults;
        const { sortOptions } = carFilterSettings;
        const { pagination } = carFilters;

        // Add filter query params to state. Add null entries for the inactive filters to make sure they are not in the URL.
        const params = carFilterValuesToParams(carFilters, carFilters.currentFilter) as Record<string, string | null>;
        Object.values(CarFilterQueryParam).forEach((queryParam) => {
            if (!params[queryParam]) params[queryParam] = null;
        });

        // Add the sort order to the query params if it is not the default sort order as
        // the default sort order does not need to be in the URL.
        const defaultSortOrder = sortOptions[carFilters.currentFilter][0];
        params.sortOrder = dfCarSortOrder === defaultSortOrder ? null : dfCarSortOrder;

        params.showSavedCars = carFilters.savedCars.showOnlySavedCars ? "true" : null;
        // Disable query param updates when the filter is used as a similar car filter.
        if (!carFilters.similarCar.isActive) replaceQueryParams(params);

        try {
            // Get the amount of results to fetch. If we are on the first page, 11 results for the ValueProposition component.
            // Any page after that will fetch 12 results.
            const resultCount = getTotalResultCount(commonSettings, carFilters);
            const hasContentBlock = !!getContentBlockConfig(carFilterSettings, carFilters.currentFilter);
            // ElasticSearch offset is 0 based, so subtract 1 from the page number.
            // In case there is a content block, we should subtract 1 from the offset
            let offset;
            if (pagination.page === 1) offset = 0;
            else offset = (pagination.page - 1) * resultCount - (hasContentBlock ? 1 : 0);

            const result = await fetchDFCarResults(
                commonSettings,
                carFilterSettings,
                carFilters,
                offset,
                resultCount,
                dfCarSortOrder,
            );

            if (result === null) return done();

            const { results, totalResultCount, aggregations, errorLogs = [], totalPageCount } = result;

            // Show errors if present.
            if (shouldShowErrorModal(commonSettings, errorLogs)) {
                const modalSettings = {
                    type: MODAL_ERRORS,
                    errors: errorLogs,
                    componentName: COMPONENT_CAR_FILTER,
                };
                dispatch(showModal(modalSettings));
            }

            // Similar car fallback logic, if no cars are found try to reduce the amount of active filters.
            // If this ever gets more complex consider writing custom similar car fetch actions.
            if (carFilters.similarCar.isActive && totalResultCount === 0) {
                const { filtersUpdated } = generaliseSimilarCarsFilter(dispatch, getState);
                if (filtersUpdated) {
                    // refetchResultLogic will be triggering a new fetch.
                    done();
                    return;
                }
            }

            dispatch(replaceCarResults(carFilters.currentFilter, results, totalResultCount));
            dispatch(updateMultiFilterCounts(aggregations, carFilters.currentFilter));
            dispatch(setTotalPageCount(totalPageCount));
        } catch (e) {
            Debug.error("Failed to retrieve results", e);
        }

        done();
    },
});

// Results have been set, disable placeholders and save the result count to session so we know how many placeholders to render when refreshing the page.
const setResultLogic = (createLogic as CarFilterLogicType<SetCarResultsType>)({
    type: SET_CAR_RESULTS,
    async process({ getState, action }, dispatch, done) {
        const { carFilters, carResults } = getState();
        const { totalResultCount } = action;

        const carResultsLength = carResults.dfCarResults.length;

        if (totalResultCount < carResultsLength) dispatch(disableUsedCarResultsPlaceholders(carFilters.currentFilter));

        done();
    },
});

const refetchResultLogic = (createLogic as CarFilterLogicType<CarFiltersActionsType>)({
    type: [...CAR_FILTER_ACTIONS, SET_USED_CAR_RESULTS_ORDER, SET_NEW_CAR_RESULTS_ORDER, CAR_FILTER_SET_PAGE] as any,
    process({ getState, action }, dispatch, done) {
        const { carFilters, commonSettings } = getState();
        const mainFilterIds = mainFilterSelector(getState());
        // Do some TS overrides to determine if the action is done by a "main" filter.
        const { filterId } = action as unknown as { filterId?: CarFilterIdType };
        const isMainFilterAction = filterId ? mainFilterIds.includes(filterId) : false;

        dispatch(
            filterCarResults(
                carFilters.currentFilter,
                // Cast tracking boolean to any as TS complains about the result always being the same but it actually isnt.
                action.type === (SET_USED_CAR_RESULTS_ORDER as any),
                isMainFilterAction,
                // As this action sets the loading/placeholder state of the results, we do not take the VP block into account here
                // and thus we ignore the VP block here
                getTotalResultCount(commonSettings, carFilters, true),
            ),
        );

        done();
    },
});

/**
 * When a filter is updated, check if the page is set to anything other than 1.
 * If so, set the page to the first page, this will trigger a refetch.
 */
const checkPageLogic = (createLogic as CarFilterLogicType<CarFiltersActionsType>)({
    type: [...CAR_FILTER_ACTIONS, SET_USED_CAR_RESULTS_ORDER] as any,
    process({ getState }, dispatch, done) {
        const { pagination } = getState().carFilters;

        if (pagination.page > 1) {
            dispatch(setPage(1));
        }

        done();
    },
});

export default [fetchResultLogic, setResultLogic, refetchResultLogic, filterResultsLogic, checkPageLogic];
