import React from "react";
import { shallowEqual } from "react-redux";
import * as Styles from "./styles/PaginationControls";
import { useCarFilterSelector } from "../../../redux/store";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import usePaginationControls, { ELLIPSIS } from "../../../hooks/car-results/usePaginationControls";
import { getPaginationUrl } from "../../../utils/helpers";
import { useBreakpoint } from "../../../../../common-deprecated/themes/common";
import Icon from "../../../../shared-components/toyota/styles/atoms/Icon";

const PaginationControls = (): JSX.Element => {
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const carFilters = useCarFilterSelector((state) => state.carFilters);
    const { page, totalPages } = useCarFilterSelector((state) => state.carFilters.pagination, shallowEqual);
    const [ariaPagination, ariaPrevious, ariaNext, ariaFirst, ariaLast, ariaPage] = useCarFilterLabel([
        "carFilterAriaPagination",
        "carFilterAriaPreviousPage",
        "carFilterAriaNextPage",
        "carFilterAriaFirstPage",
        "carFilterAriaLastPage",
        "carFilterAriaPage",
    ]);

    const isMobile = useBreakpoint("down", "sm");

    const {
        navigateToPage,
        navigateToNextPage,
        navigateToPreviousPage,
        navigateToFirstPage,
        navigateToLastPage,
        canNavigateLeft,
        canNavigateRight,
        paginationButtons,
    } = usePaginationControls();

    return (
        <Styles.Wrapper aria-label={ariaPagination} role="navigation">
            <Styles.PaginationList role="list">
                {isMobile && (
                    <li>
                        <Styles.Button
                            hasOnlyIcon
                            onClick={navigateToFirstPage}
                            disabled={!canNavigateLeft}
                            aria-disabled={!canNavigateLeft}
                            aria-label={ariaFirst}
                            hidden={!canNavigateLeft}
                        >
                            <Icon variant="double-chevron-left" />
                        </Styles.Button>
                    </li>
                )}
                <li key={0}>
                    <Styles.Button
                        hasOnlyIcon
                        onClick={navigateToPreviousPage}
                        disabled={!canNavigateLeft}
                        aria-disabled={!canNavigateLeft}
                        aria-label={ariaPrevious}
                        hidden={isMobile && !canNavigateLeft}
                    >
                        <Icon variant="chevron-left" />
                    </Styles.Button>
                </li>
                {paginationButtons.map((item, index) =>
                    item === ELLIPSIS ? (
                        // eslint-disable-next-line react/no-array-index-key
                        <Styles.Dots as="li" aria-hidden="true" role="presentation" key={`ellipsis-${index}`}>
                            ...
                        </Styles.Dots>
                    ) : (
                        item !== null && (
                            <li key={item}>
                                <Styles.Button
                                    /**
                                     * For SEO purposes, these controls should be a hrefs. However, we specifically don't want to force
                                     * a page load (= an SSR request) when the user navigates to a different page. This is why we use an a href,
                                     * but prevent the default action and handle the navigation ourselves.
                                     */
                                    as="a"
                                    href={getPaginationUrl(commonSettings, carFilterSettings, carFilters, item)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigateToPage(item);
                                    }}
                                    isActive={item === page}
                                    aria-current={item === page}
                                    aria-label={ariaPage.replace("{value}", String(item))}
                                >
                                    {item}
                                </Styles.Button>
                            </li>
                        )
                    ),
                )}
                <li key={totalPages + 1}>
                    <Styles.Button
                        hasOnlyIcon
                        onClick={navigateToNextPage}
                        disabled={!canNavigateRight}
                        aria-disabled={!canNavigateRight}
                        aria-label={ariaNext}
                        hidden={isMobile && !canNavigateRight}
                    >
                        <Icon variant="chevron-right" />
                    </Styles.Button>
                </li>
                {isMobile && (
                    <li>
                        <Styles.Button
                            hasOnlyIcon
                            onClick={navigateToLastPage}
                            disabled={!canNavigateRight}
                            aria-disabled={!canNavigateRight}
                            aria-label={ariaLast}
                            hidden={!canNavigateRight}
                        >
                            <Icon variant="double-chevron-right" />
                        </Styles.Button>
                    </li>
                )}
            </Styles.PaginationList>
        </Styles.Wrapper>
    );
};

export default PaginationControls;
