import type { AvailabilityType, EcoTagType } from "./CommonTypes";
import type { CertificationType } from "../constants";
import type { LabelTexusType, TechnicalSpecMappingTexusType } from "./TexusTypes";

export type SpecDataType = {
    id: string;
    internalCode: string;
    name: string;
    value: string;
    unit: string;
    description?: string;
    availability: AvailabilityType;
    certification: CertificationType;
    tooltip?: string;
    sortIndex: number;
    shortName?: string;
    linkedSpec?: string;
};

type SpecSubCatType = {
    data?: SpecDataType[];
    title?: string;
    sortIndex?: number;
};

// Formatted is actually a legacy type, OR uses SortedSpecType.
export type FormattedSpecType = {
    data: SpecDataType[];
    disclaimer: string;
    sortIndex: number;
    internalCode: string;
    subCat: { [id: string]: SpecSubCatType };
    title: string;
};

// Same as FormattedSpec but with subCat in an array and id.
export type SortedSpecType = {
    id: string;
    internalCode?: string;
    data?: SpecDataType[];
    disclaimer?: string;
    sortIndex?: number;
    subCat?: SortedSpecType[];
    title?: string;
    tooltip?: string;
};

export type TechnicalSpecCarDBType = {
    ID: string;
    InternalCode: string;
    LocalCode: string;
    Name: string;
    Description: string;
    FootNote: string;
    Labels: LabelTexusType[];
    SortIndex: number;
    ToolTip: string;
};

export type TechnicalSpecMassValidationType = {
    Error: string | null;
    ExceedingKilogram: number;
    Successful: boolean;
};

export type SuffixType = {
    Code?: string;
};

export type TechnicalSpecificationType = TechnicalSpecCarDBType & {
    Comparable: boolean;
    EnergyEfficiencySpecification: boolean;
    Full: boolean;
    Quick: boolean;
    RawValue: string;
    Unit: string;
    Value: string;
    Visibility: string;
    Certification: CertificationType;
    Mapping: TechnicalSpecMappingTexusType;
};

export type TechnicalSpecificationCategoriesType = (TechnicalSpecCarDBType & {
    Categories: null; // TODO Fix
    TechnicalSpecifications: TechnicalSpecificationType[];
})[];

export type FetchTechSpecAndEquipmentBodyType = FetchTechSpecBodyType & {
    certification: number;
    modelCode: string;
    ecoTag?: EcoTagType;
    carShortId?: number;
};

export type FetchTechSpecBodyType = {
    model: string;
    car: string;
    carShortId: number;
    colourCode: string;
    upholsteryCode: string;
    packs: string[];
    options: string[];
    serviceProducts: string[];
    accessories: string[];
};

type WLTPSpecIdCodeType = { id: string; code: string };
type WLTPSpecIdShortIdType = { id: string; shortId: string };

export enum PriceCalculationServiceEndpoint {
    Price = "price",
    Prices = "prices",
}

export type FetchConfigurationSpecificationsBodyType = {
    modelId: string;
    car: WLTPSpecIdShortIdType;
    wheel: WLTPSpecIdShortIdType;
    colour: WLTPSpecIdCodeType;
    upholstery: WLTPSpecIdCodeType;
    packs: WLTPSpecIdShortIdType[];
    options: WLTPSpecIdShortIdType[];
    accessories: WLTPSpecIdShortIdType[];
    serviceProducts: WLTPSpecIdShortIdType[];
    returnSpecificationsMapping: boolean;
    taxLocationId?: string;
    priceCalculationServiceType?: PriceCalculationServiceEndpoint;
};
