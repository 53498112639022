import React from "react";
import * as Styles from "./styles/FilterPillButtonStyles";
import { IconClose } from "../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { useCommonLabel } from "../../../../../common-deprecated/utils/commonLabels";

type FilterPillButtonType = {
    children: React.ReactNode;
    isPrimary?: boolean;
    closeFn?: () => void;
    disabled?: boolean;
};

const FilterPillButton = ({ children, closeFn, isPrimary, disabled }: FilterPillButtonType): JSX.Element => {
    const ariaRemove = useCommonLabel("ariaRemove");
    return (
        <Styles.FilterPill isPrimary={isPrimary} hasIcon={!!closeFn} disabled={!!disabled}>
            <span>{children}</span>
            {closeFn && (
                <Styles.Close onClick={closeFn} aria-label={ariaRemove}>
                    <IconWrapper size="xxs" iconColor="primaryWhite">
                        <IconClose aria-hidden="true" />
                    </IconWrapper>
                </Styles.Close>
            )}
        </Styles.FilterPill>
    );
};

export default FilterPillButton;
