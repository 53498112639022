import type { Request } from "express";
import * as DOMUtils from "history/DOMUtils";
import type { ToolsModalItemType } from "./features/new-cars/debug-modal/components/ToolsModal";
import type { QueryType, RequestType } from "./server/types";
import Debug from "./Debug";
import type { EcolabelType } from "./constants";
import {
    BREAKPOINT_LG,
    CarDBModelId,
    EFFICIENCY_CLASS,
    EFFICIENCY_CLASS_TMEF,
    EMPTY_GUID,
    GrCode,
    GUID_REGEX,
    LEXUS,
    TechnicalSpecificationCategory,
    THEME_CLASSNAME_BZ,
} from "./constants";
import type {
    BooleanStringType,
    ErrorLogType,
    ExtraType,
    FetchAndParseJsonType,
    PricePromotionType,
} from "./types/CommonTypes";
import type { AssetType } from "./types/CommonRequestTypes";
import type { CommonSettingsType } from "./settings/fetchCommonSettings";
import type { CoordinateType } from "./utils/dealerConstants";
import { DealerBrand } from "./utils/dealerConstants";
import type { EquipmentV2StateType } from "../new-cars/original/equipment/redux/store";
import type { DeepPartial } from "../shared-logic/utils/commonUtils";
import { TsBoolean } from "../shared-logic/utils/commonUtils";
import type { FormattedSpecType, SortedSpecType, SpecDataType } from "./types/SpecTypes";
import type { ComponentType } from "../shared-logic/server/components";
import { COMPONENT_BUILDANDBUY, COMPONENT_CAR_CONFIG } from "../shared-logic/server/components";
import { getTizenVersion } from "../shared-logic/features/retailer/utils/utils";
import { sanitizeQueryParams } from "./utils/sanitizer";

/**
 * General util functions
 */

// Sections used for flexibility matrix.
export const SECTION_DEFAULT = "or-default" as const;
export const SECTION_MODELFILTER = "modelfilter" as const;
export const SECTION_ENGINE_GRADE = "or-engine-grade" as const;
export const SECTION_CUSTOMIZE = "or-customize" as const;
export const SECTION_SERVICES = "or-services" as const;
export const SECTION_SUMMARY = "or-summary" as const;
export const SECTION_COMPARE = "equipmentcompare" as const;
export const SECTION_GRADES_CAROUSEL = "grades-carousel" as const; // used on GFIs grade detail page
export const SECTION_USED_CAR_FILTER = "car-filter-used" as const;
export const SECTION_NEW_CAR_FILTER = "car-filter-new" as const;
export const SECTION_STOCK_CAR_FILTER = "car-filter-stock" as const;
export const SECTION_USED_STOCK_CAR = "usc-default" as const;
export const SECTION_MOBILE_FOOTER = "or-mobilefooter" as const;

// This is a contrived way to type the section but it requires the least refactoring.
export type SectionType =
    | typeof SECTION_DEFAULT
    | typeof SECTION_MODELFILTER
    | typeof SECTION_ENGINE_GRADE
    | typeof SECTION_CUSTOMIZE
    | typeof SECTION_SERVICES
    | typeof SECTION_SUMMARY
    | typeof SECTION_COMPARE
    | typeof SECTION_GRADES_CAROUSEL
    | typeof SECTION_USED_CAR_FILTER
    | typeof SECTION_NEW_CAR_FILTER
    | typeof SECTION_STOCK_CAR_FILTER
    | typeof SECTION_USED_STOCK_CAR
    | typeof SECTION_MOBILE_FOOTER;

export const FILTER_POSITION_TOP = "top" as const;
export const FILTER_POSITION_BOTTOM = "bottom" as const;
export type FilterPositionType = typeof FILTER_POSITION_TOP | typeof FILTER_POSITION_BOTTOM;

export enum EnvironmentEnum {
    Production = "production",
    Preview = "preview",
    Acceptance = "acceptance",
    Development = "development",
}

// Environments which can be used to override the current environment.
export enum EnvVarsOverride {
    ProdPreview = "prodpreview", // DXP PROD Preview
    Test = "test", // DXP UAT2
    E2ETest = "e2etest", // Dedicated E2E test environment (utilises Mockoon for API mocking)
}

// Used in pretty urls
export const environmentShortMap: Record<EnvironmentEnum, string> = {
    [EnvironmentEnum.Production]: "",
    [EnvironmentEnum.Preview]: "prev",
    [EnvironmentEnum.Acceptance]: "acc",
    [EnvironmentEnum.Development]: "dev",
};

export enum RenderMethod {
    Server = "server",
    Client = "client",
}

export const SERVICE_PLAN_PREFIX = "-SERVICE";

// Id used to target the ModalBaseView.
// It is technically possible to have overlaying modals but this is usually only in debug edge cases.
export const MODAL_DIALOG_ID = "or-dialog-modal";

/** urlWebsite & detailPageUrl contain a file extension and queryparams for view as published on author */
export const AEM_AUTHOR_URL_SUFFIX = ".html?wcmmode=disabled";

export function objectToQueryString(obj: { [index: string]: any } = {}, prefix: string = ""): string {
    if (Object.keys(obj).length === 0) {
        return "";
    }
    const query = Object.keys(obj).map((key) => {
        let value = obj[key];
        const queryKey = prefix !== "" ? `${prefix}[${key}]` : key;
        // Make sure value isn't null, because typeof null == "object".
        if (value !== null && (typeof value === "object" || Array.isArray(value))) {
            return objectToQueryString(value, queryKey);
        }
        if (typeof value === "number") {
            value = Math.abs(value);
        }
        return `${queryKey}=${value as string}`;
    });

    return query.filter((value) => value.length > 0).join("&");
}

/**
 * Method which formats a query string to the corresponding apheleia / build variant
 */
export function queryStringToCorrectEnv(
    queryString: string,
    currentComponent: ComponentType,
    targetUrl: string,
): string {
    if (targetUrl.includes("localhost")) return queryString;
    const configure = "configure";
    const customize = "customize";

    const model = "model";
    const engine = "engine";

    const apheleia = "aph";
    const newcars = "dxp";

    const getPathName = (url: string): string => {
        const pathIndex = url.indexOf("path=");
        const nextSlashIndex = url.indexOf("/", pathIndex + 5);
        return url.substring(pathIndex + 5, nextSlashIndex);
    };

    const path = getPathName(queryString);

    // Note that the queryString shouldn't update when the currenComponent and targetUrl point to the same environment.
    if (
        currentComponent === COMPONENT_BUILDANDBUY &&
        !targetUrl.includes(newcars) &&
        !(path === model || path === engine) // Apheleia url will only work from the build customize step or above. A model, grade or engine needs to be selected.
    )
        return queryString.replace(path, configure);

    if (currentComponent === COMPONENT_CAR_CONFIG && !targetUrl.includes(apheleia))
        return queryString.replace(path, customize);

    return queryString;
}

/**
 * Method which makes sure an URL always ends without a "/"
 */
export function removeLastSlashAndWhiteSpace(url: string): string {
    return url.replace(/^\s*|\/?\s*$/g, "");
    // https://regexr.com/3r1ks  removes trailing and leading whitespades aswell as last slash if present
}

/**
 * Sanitizes string input by removing whitespaces
 * @param input
 */
export const removeSpaces = (input: string): string => {
    return input.toLowerCase().replace(/\s+/g, "");
};

/**
 * Method which searches for a property in an object ignoring the case
 * Defaults to empty string
 * @param key:String
 * @param obj:Object
 */
export function findKeyInObject(key: string, obj: Record<string, any>): string {
    return Object.keys(obj).reduce((accumulator, prop) => {
        if (prop.toLowerCase() === key.toLowerCase()) return obj[prop];
        return accumulator;
    }, "");
}

/**
 * Method which searches for a string key property in an object; this can be bla.bla.bla for instance.
 * @param key
 * @param obj
 * @param fallback
 */
export function findPathInObject(key: string, obj: Record<string, any>, fallback: any = null): any {
    return key
        .split(".")
        .reduce(
            (accumulator: Record<string, any>, current: string) =>
                accumulator === null
                    ? obj[current]
                    : typeof accumulator === "undefined"
                      ? accumulator
                      : accumulator[current],
            fallback,
        );
}

export function getObjectFromT1(path: string): any {
    return DOMUtils.canUseDOM && window && window.T1 && findPathInObject(path, window.T1);
}

export const parseBooleanString = (string?: BooleanStringType, defaultValue?: boolean): boolean => {
    if (typeof string !== "undefined") return string === "true";
    if (typeof defaultValue !== "undefined") return defaultValue;
    return false;
};

/**
 * Used to parse values in a query string.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function parseValue(value: any): any {
    if (String(parseInt(value, 10)) === value) {
        return parseInt(value, 10);
    }
    if (String(parseFloat(value)) === value) {
        return parseFloat(value);
    }
    if (value === "true" || value === "false") {
        return parseBooleanString(value);
    }
    return value;
}

/**
 * Expanded version of parseValue, this will parse all properties of an object or a value with the parseValue function.
 * Be careful with nested objects as it will keep looping into the properties.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseProp = (item: any): any => {
    if (typeof item === "object" && item !== null) {
        // first clone the original object to stop from editing the values inside the original item argument
        const clone = JSON.parse(JSON.stringify(item));
        Object.keys(clone).forEach((key) => {
            clone[key] = parseProp(clone[key]);
        });
        return clone;
    }
    return parseValue(item);
};

export function queryStringToObject(queryString: string): { [id: string]: any } {
    const qString = queryString[0] === "?" ? queryString.slice(1) : queryString;

    const result: { [id: string]: any } = {};
    if (qString.length === 0) {
        return result;
    }
    qString.split("&").forEach((value) => {
        const pair = value.split("=");
        const match = pair[0].match(/^([a-zA-Z0-9]*)\[\]/i);
        if (match) {
            if (!result[match[1]]) {
                result[match[1]] = [];
            }
            result[match[1]].push(parseValue(decodeURIComponent(pair[1] || "")));
        } else {
            result[pair[0]] = parseValue(decodeURIComponent(pair[1] || ""));
        }
    });

    Object.keys(result).forEach((key) => {
        const match = key.match(/^([a-zA-Z0-9]*)\[([a-zA-Z0-9]*)\]/i);
        if (match) {
            if (!result[match[1]]) {
                // @ts-ignore - TS might be correct here but don't want to add any regressions.
                result[match[1]] = match[2] === parseInt(match[2]) ? [] : {};
            }

            if (Array.isArray(result[match[1]])) {
                result[match[1]].push(parseValue(result[key]));
            } else {
                result[match[1]][match[2]] = parseValue(result[key]);
            }
            delete result[key];
        }
    });

    const sanitizedResults = sanitizeQueryParams(result);

    return JSON.parse(JSON.stringify(sanitizedResults));
}

export function redirect(path: string, query?: { [index: string]: any }): void {
    window.location.href = `${path}${query ? `?${objectToQueryString(query)}` : ""}`;
}

/**
 * Helper function which returns a Promise which will resolve when the image at src is loaded.
 */
export const loadImage = (src: string, srcSet?: string): Promise<void> =>
    new Promise((resolve, reject) => {
        // If we don't have an Image class we're rendering server-side, resolve the Promise.
        if (typeof Image === "undefined") {
            resolve();
            return;
        }

        // This image object is not used for rendering. It's only used to start/track the preloading of images.
        const image = new Image();

        // Image loaded, resolve Promise.
        image.addEventListener("load", () => resolve());

        // Error while loading, reject.
        image.addEventListener("error", () => reject());

        // This will start the loading of the image.
        if (srcSet) image.srcset = srcSet;
        image.src = src;
    });

// These generic typed functions are not formatted as arrow functions because it breaks code colouring in WebStorm for now :(
function findByShortId<T extends { shortId?: number | string }>(queryId: number, data: T[]): T | undefined {
    return data.find((item) => {
        if (!item.shortId) return false;
        const itemShortId = typeof item.shortId !== "number" ? parseInt(item.shortId) : item.shortId;
        return itemShortId === queryId;
    });
}

function findById<T extends { id: string }>(queryId: string, data: T[]): T | undefined {
    return data.find((item) => item.id === queryId);
}

export function find<T extends { id: string; shortId?: string | number }>(
    queryId: string | number,
    data: T[],
): T | undefined {
    return typeof queryId === "number" ? findByShortId(queryId, data) : findById(queryId, data);
}

/**
 * More dangerous version of find.
 *
 * This function expects to find the item with id in the array or will throw an error.
 * Make sure the Error can be caught when using this!
 */
export function findExplicit<T extends { id: string; shortId?: number | string }>(
    queryId: string | number,
    data: T[],
): T {
    const item = typeof queryId === "number" ? findByShortId(queryId, data) : findById(queryId, data);
    if (!item) {
        console.log(`Item with id ${queryId} not found in array:`, data);
        throw new Error("Item not found");
    }
    return item;
}

export const getWeeksFromNow = (date: Date): number => {
    const WEEK = 1000 * 60 * 60 * 24 * 7;
    const diff = Math.abs(date.getTime() - Date.now());
    return Math.ceil(diff / WEEK);
};

/**
 * Map a list of ids to its object and filter out empty values.
 *
 * @param ids
 * @param data
 * @return Array
 */
export function mapIds<T extends { id: string; shortId?: number | string }>(ids: string[], data: T[]): T[] {
    return ids.map((id) => find(id, data)).filter(TsBoolean);
}

/**
 * Make sure the given url has a http(s) protocol.
 * @param url
 */
export const fixUrlProtocol = (url?: string): string => {
    if (!url) return "";
    if (url.indexOf("http:") === -1 && url.indexOf("https:") === -1) {
        url = `https:${url}`;
    }
    return url;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendJsonToSalesman = (data: any, raw: boolean = false): void => {
    // console.log("sendJsonToSalesman", data);
    if (
        typeof window !== "undefined" &&
        (window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.SalesmanCallbackHandler
    ) {
        const message = raw ? data : `json=${JSON.stringify(data)}`;
        (window as any).webkit.messageHandlers.SalesmanCallbackHandler.postMessage(message);
    }
};

/**
 * Execute a JSON fetch request to the provided uri and parses the recieved body to a JSON when successful.
 * Used to be able to load monthly rates both in the backend (PDF) and frontend (Build).
 * Considering these are all POST requests, the optional parameter FetchAndParseJsonType.method is not relevant here.
 */
export const fetchAndParseJson: FetchAndParseJsonType = async (uri, type, body) => {
    try {
        const result = await fetch(uri, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: body && JSON.stringify(body),
        });
        if (result.status === 200) {
            const parsedResult = await result.json();
            return parsedResult;
        }
    } catch (exception) {
        Debug.error(`Failed to fetch ${type}`, exception);
    }
    return undefined;
};

export const isMobileView = (): boolean => {
    return DOMUtils.canUseDOM && window.innerWidth < BREAKPOINT_LG;
};

/**
 * Checks if two arrays of objects differ. This will be calculated based on the 'id' property of every object.
 */
export const checkDiff = <T extends { id: string }>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) return true;
    return (
        arr1
            .map((obj) => obj.id)
            .sort()
            .toString() !==
        arr2
            .map((obj) => obj.id)
            .sort()
            .toString()
    );
};

export const formatSpecificationValue = (value: string, culture: CommonSettingsType["culture"]): string => {
    // Since the value can contain different numeric values (example BORESTROKE -> 80.5 x 88.3) we need to check all strings
    // This might also require some refinement since there can be allot of numeric/string combinations possible
    // The website also takes into account the thousandSeparator, however decided against it to avoid confusion (ex.: 12.399 and 3,916)
    const elements = value.split(" ");
    const decimalSeperator = culture.numberFormat["."] as string;
    // Written with curly brackets for readability
    return elements
        .map((ele) => {
            return Number.isNaN(Number(ele)) || !decimalSeperator ? ele : ele.replace(".", decimalSeperator);
        })
        .join(" ");
};

export const parseSpecificationValue = (
    spec: { value: string; unit: string },
    culture: CommonSettingsType["culture"],
): string => {
    if (spec.value) {
        return spec.unit
            ? `${formatSpecificationValue(spec.value.replace(spec.unit, "").trim(), culture)} ${spec.unit}`
            : formatSpecificationValue(spec.value, culture);
    }
    return "";
};

/**
 * Scroll the given target selector into view.
 * ⚠️ Use this sparingly as this can usually be fixed by using React refs instead of query selectors. ⚠️
 * @param useDomTarget - uses scrollIntoView on the given target. This is used to scroll in the compare because a window scroll is not possible (OR-4934)
 * @param useGetElementById - uses getElementById instead of querySelector.
 * @param scrollBehavior -  "auto" or "smooth", defaults to "smooth"
 */
export const scrollTargetIntoView = (
    target: string,
    siteNavOffset: number,
    useDomTarget?: boolean,
    useGetElementById?: boolean,
    scrollBehavior: ScrollBehavior = "smooth",
): void => {
    const domTarget = useGetElementById ? document.getElementById(target) : document.querySelector(target);
    if (domTarget) {
        // DEBUFFER SCROLL TO TRIGGER TO NEXT animationFrame available instead of trying to run it instantly
        window.requestAnimationFrame(() => {
            if (useDomTarget) domTarget.scrollIntoView({ behavior: scrollBehavior });
            else {
                const rect = domTarget.getBoundingClientRect();
                const { pageYOffset } = window;

                window.scrollTo({
                    top: pageYOffset + rect.top - siteNavOffset,
                    left: 0,
                    behavior: scrollBehavior,
                });
            }
        });
    }
};

// Helper method for the ones defined below.
export const getTagRegExp = (tag: string): RegExp => {
    return new RegExp(`\\[${tag}](.*)\\[\\/${tag}]`, "i");
};

/**
 * Get the content of items between square tags. Example: [TAG]This will be returned[/TAG]
 */
export const getTagContent = (tag: string, template: string): string => {
    const regEx = getTagRegExp(tag);
    const result = regEx.exec(template);
    if (result) return result[1];
    return "";
};

/**
 * Proxy the call for CARDB assets by using the T1.settings.cardbImageHost
 * @param url - The url pointing to the assets as originally retrieved by the Texus service ex.:
 * https://s3-eu-west-1.amazonaws.com/tme-carassets-prod/388e25cd-e0e2-42ad-b172-8950c66eeb07.JPG
 * will transfer to (if available):
 * T1.settings.cardbImageHost + name
 * ->
 * http://t1-carassets.toyota-europe.com/388e25cd-e0e2-42ad-b172-8950c66eeb07.JPG
 */
export const getCARDBPath = (settings: CommonSettingsType, url: string): string => {
    const { cardbImageHost } = settings;

    if (cardbImageHost) {
        const regEx = /(\d|\w){8}-(\d|\w){4}-(\d|\w){4}-(\d|\w){4}-(\d|\w){12}.*/;
        const result = regEx.exec(url);
        if (result) return cardbImageHost + result[0];
        Debug.error("error formatting cardbImageHost URL: ", url);
    }

    return url;
};

export type CarDBAssetType =
    | "IMG_PNG_1200x1200"
    | "IMG_PNG_285x260"
    | "SELECTOR"
    | "GRADE_FEATURES-1"
    | "GRADE_FEATURES-2";

/**
 * Get asset for a specific element.
 * @param selectorPriority - Take priority on the SELECTOR asset type instead of IMG_PNG_285x260. Only enabled when checkSelector is true.
 */
export const getCARDBAsset = (
    settings: CommonSettingsType,
    assets: AssetType[] = [],
    checkSelector: boolean = false,
    selectorPriority: boolean = false,
    assetType: CarDBAssetType = "IMG_PNG_285x260",
): string => {
    const fallbackAssetTypes: CarDBAssetType[] = ["IMG_PNG_1200x1200", "IMG_PNG_285x260"];
    const matchOrder = [assetType];
    if (checkSelector) {
        if (selectorPriority) matchOrder.unshift("SELECTOR");
        else matchOrder.push("SELECTOR");
    }

    const usedAsset = assets.find(
        (asset) =>
            asset.Type === matchOrder[0] ||
            asset.Type === matchOrder[1] ||
            fallbackAssetTypes.includes(asset.Type as CarDBAssetType),
    );

    return usedAsset ? getCARDBPath(settings, usedAsset.Url) : "";
};

/**
 * Method which retrieves the pack image
 */
export const getCARDBPackImage = (settings: CommonSettingsType, assets: AssetType[] = []): string => {
    const usedAsset = assets.find((asset) => asset.Type.toUpperCase() === "IMG_PACK_SELECTOR");
    return usedAsset ? getCARDBPath(settings, usedAsset.Url) : "";
};

/**
 * Merge objects recursivly.
 * Based on: https://gist.github.com/Salakar/1d7137de9cb8b704e48a#gistcomment-2881879
 * @param target - This object will be amended and have properties overwitten with 'source'
 * @param source - The object that will be merged in target
 */
export const deepMergeObject = <T extends Record<string, unknown>>(target: T, source: DeepPartial<T>): T => {
    // If NMSC's forget to add settings in tridion source will be undefined, we will then fallback to default labels and settings.
    if (source) {
        Object.keys(source).forEach((key) => {
            if (typeof source[key] === "object") {
                if (!target[key]) {
                    (target as Record<string, unknown>)[key] = source[key];
                }
                deepMergeObject(target[key] as Record<string, unknown>, source[key] as DeepPartial<T>);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
    return target;
};

/**
 * Returns the tyCode from a query object, defaults to empty string.
 * We use findKeyInObject which is case independent because tycode can contain all sorts of capitalization.
 */
export const getToyotaCode = (query: QueryType): string => {
    return findKeyInObject("tycodepersonal", query) || findKeyInObject("tycode", query);
};

export const parseHexToRGB = (hex: number | string): { r: number; g: number; b: number; rgb: string } => {
    // Normalize hex so all formats work (0xff0000, '#ff0000', 'ff0000', ...
    hex = hex.toString();
    hex = hex.replace("0x", "");
    hex = hex.replace("#", "");

    // Parse HEX to RGB
    const rgb = parseInt(hex, 16);
    const values = {
        r: (rgb & 0xff0000) >> 16,
        g: (rgb & 0xff00) >> 8,
        b: rgb & 0xff,
    };
    return {
        ...values,
        rgb: `${values.r},${values.g},${values.b}`,
    };
};

/**
 * Check if a hex code is a "light colour". This can be used to trigger black text on a light background.
 * Hex can be formatted as: 0xff0000, '#ff0000' or just 'ff0000'
 */
function calculateSaturationAndVue(hex: number | string): { saturation: number; vue: number } {
    const { r, g, b } = parseHexToRGB(hex);
    const vue = Math.max(r, g, b);
    const diff = vue - Math.min(r, g, b);

    const saturation = diff === 0 ? 0 : diff / vue;
    return { saturation, vue };
}

export const isLightColour = (hex: string): boolean => {
    const { saturation, vue } = calculateSaturationAndVue(hex);
    return Math.round(saturation * 100) < 20 && vue > 215;
};

/**
 * Get the value of a setting passed via query string or the body of the request, returns null when no value was set
 * use this method sparsely because body is untyped
 **/
export const getParamFromRequest = <T extends keyof QueryType>(
    req: RequestType | Request,
    param: NonNullable<T>,
): QueryType[T] | null => {
    if (req.query && typeof req.query[param] !== "undefined") {
        return parseValue(req.query[param]);
    } else if (req.body && typeof req.body[param] !== "undefined") {
        return parseValue(req.body[param]);
    }
    return null;
};

/**
 * Function to check if date is in the past and replace with current date if needed.
 */
export const validateETADate = (date: Date, buffer?: number): Date => {
    let usedDate = date;

    // If the date is in the past; return the system date plus the leadttimebuffer
    const currentDate = new Date(Date.now());

    // If the minimum date is older than the current system date, use the current system date
    if (date < currentDate) {
        usedDate = currentDate;
    }

    // Add the lead time buffer
    usedDate.setDate(usedDate.getDate() + Number(buffer || 0));

    return usedDate;
};

/**
 * Work around for Lexus where they have translated the disclaimer to be the actual name of the key
 */
export const wltpValueIsKey = (label: string, key: string): string => {
    const keyWithoutT1 = key.replace("T1.", "");

    return typeof label === "undefined" || label === key || label === keyWithoutT1 ? "" : label;
};

export const getEcoBadgeUrl = (ecoBadge: EcolabelType | false, settings: CommonSettingsType): string | null => {
    if (!ecoBadge || !settings.esEcoLabels) return null;

    const imagePath = settings.esEcoLabels[ecoBadge];
    if (!imagePath) return null;
    return imagePath;
};

/**
 * Initialize the frontend TMEF price calculation function.
 */
export const initializeTmefFunction = (tmefFunction: string): { success: boolean } => {
    let parsedFunction = tmefFunction;

    // Chrome version 56 has a bug that makes code with const in it crash when you run it via eval.
    // In addition, the Tizen screens don't report their chrome version, so we have to check their version instead
    // See https://issues.chromium.org/issues/41276601#comment12 & DR-691
    // Since retailer screens use this exact version there's no other way to make the makolab prices function work :(
    // Hopefully we can replace this with a serverside call in the future
    const tizenVersion = getTizenVersion();
    if (tizenVersion && tizenVersion <= 4.5) {
        const REGEX = new RegExp("\\bconst|let\\b", "g");
        parsedFunction = tmefFunction.replace(REGEX, "var");
    }

    // Yup this is still a thing, skull emoji for consistency 💀
    // @ts-ignore
    window.eval(parsedFunction); // eslint-disable-line no-eval

    if (typeof window.calculate === "undefined") {
        Debug.error("Failed initializing TMEF calculation: Calculate function not present in window object");
        return { success: false };
    } else {
        return { success: true };
    }
};

/**
 * Check if guid is valid
 */

export const isValidGuid = (guid?: string): boolean => {
    return !!guid && guid !== EMPTY_GUID && !!new RegExp(GUID_REGEX).exec(guid);
};

/**
 * Check if a car is a GR or GR sport model to show the correct Logo
 */

export const getGrLogo = (modelName: string, gradeName: string): GrCode | null => {
    const modelAndGradeName = [modelName, gradeName].join(" ").toUpperCase();
    if (modelAndGradeName.includes(GrCode.Gr)) {
        if (modelAndGradeName.includes(GrCode.GrSport)) {
            return GrCode.GrSport;
        }

        return GrCode.Gr;
    }
    return null;
};

export const getFormattedDateToday = (): string => {
    const today = new Date();
    let dd: string | number = today.getDate();
    let mm: string | number = today.getMonth() + 1;
    const yy = today.getFullYear();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    return `${dd}/${mm}/${yy}`;
};

export function findMonthlyRate(monthlyRateQuotes: { [index: string]: any }, id: string | null): number | null {
    if (!monthlyRateQuotes || !monthlyRateQuotes.Quotation) {
        return null;
    }
    let monthlyRateQuote = monthlyRateQuotes.Quotation.UnselectedItems.find((quote: { Id: string }) => quote.Id === id);
    if (!monthlyRateQuote) {
        monthlyRateQuote = monthlyRateQuotes.Quotation.SelectedItems.find((quote: { Id: string }) => quote.Id === id);
    }
    if (!monthlyRateQuote) {
        return null;
    }
    return parseFloat(monthlyRateQuote.Value);
}

export const calculatePromotionPrice = (listPriceWithDiscount: number, promotions: PricePromotionType[]): number => {
    return (
        listPriceWithDiscount -
        promotions.reduce((acc, promo) => {
            acc += promo.amount;
            return acc;
        }, 0)
    );
};

/**
 *
 * @param useDispatcherCache - some routes (like flexmatrix) require a different request url so the response can be cached in AEM dispatchers. In time most of our routes will use this.
 */
export const getAemApiUrl = (
    dxpSettingsService: string,
    endpoint: string,
    country: string,
    language: string,
    brand: string,
    useDispatcherCache?: boolean,
): string => {
    // Readymade url for using locally hosted AEM api, keep in mind you will also need to add a cookie header to fetchAemComponent etc
    // We add admin:admin@ as this authenticates the request to the AEM dispatcher
    // if (endpoint !== "global-settings")
    //     return `http://admin:admin@localhost:4502/bin/api/dxp/${endpoint}${endpoint.includes("?") ? "&" : "?"}country=${country}&language=${language}&brand=${brand}`;

    if (useDispatcherCache)
        return `${dxpSettingsService}/dxp/bin/api/dxp/${endpoint}.${brand}.${country}.${language}.json`;
    return `${dxpSettingsService}/dxp/${brand}/${country}/${language}/bin/api/dxp/${endpoint}`;
};

export const getAceApiUrl = (
    dxpSettingsService: string,
    endpoint: string,
    environment: EnvironmentEnum,
    country: string,
    language: string,
    brand: string,
    useDispatcherCache?: boolean,
): string => {
    if (environment === EnvironmentEnum.Production) {
        // Is cached for an hour
        return `${dxpSettingsService}/dxp-labels/${brand}/${country}/${language}/marlon/${endpoint}/labels.json`;
    } else {
        // Is not cache for an hour
        return getAemApiUrl(
            dxpSettingsService,
            `sys?page=marlon/${endpoint}`,
            country,
            language,
            brand,
            useDispatcherCache,
        );
    }
};

export const getTrinityAemApiUrl = (
    dxpSettingsService: string,
    endpoint: string,
    fullCountry: string,
    language: string,
    brand: string,
): string => {
    return `${dxpSettingsService}/dxp/${brand}/${fullCountry}/${language}/bin/api/dnb/${endpoint}`;
};

/**
 * Use this as a general check if error modals should be shown.
 */
export const shouldShowErrorModal = (settings: CommonSettingsType, errorLogs: ErrorLogType[]): boolean => {
    const { environment, query } = settings;
    return !!(
        errorLogs?.length &&
        (environment !== EnvironmentEnum.Production || query.enableProdDebug) &&
        !query.disableErrorModal
    );
};

/**
 * This should not be used inside React components!
 * Ideally we want to split up logic to a retailer specific variant instead.
 */
export const isRetailer = (settings: CommonSettingsType): boolean => {
    return settings.query.retailerscreen || false;
};

export type RetailerAppsQueryParamsType = {
    salesmanmaster?: boolean;
    salesmanslave?: boolean;
    salesmanslavechrome?: boolean;
    coddealer?: boolean;
    codcustomer?: boolean;
    retailerscreen?: boolean;
    retailerscreenNavBox?: string; // height,left,right,flipNavButtons (example: 154,175,425,false)
    retailerscreenDebugRegistry?: string;
    enableSelectRetailer?: boolean;
};

export const retailerAppsQueryParams: (keyof RetailerAppsQueryParamsType)[] = [
    "salesmanmaster",
    "salesmanslave",
    "salesmanslavechrome",
    "coddealer",
    "codcustomer",
    "retailerscreen",
    "retailerscreenNavBox",
    "retailerscreenDebugRegistry",
    "enableSelectRetailer",
];

export const commonQueryParams: (keyof QueryType)[] = [
    "showDictionaryKeys",
    "wcmmode",
    "disableErrorModal",
    "hideprices",
    "disableTokens",
    ...retailerAppsQueryParams,
];

export const getCommonQueryParams = (query: Record<string, string | unknown>): Partial<QueryType> => {
    return Object.entries(query)
        .filter(([key]) => commonQueryParams.includes(key as keyof QueryType))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};

/**
 * This will create query string that propagates common query params between components
 *
 * @param extra Optional query params to include into the query string
 */
export const propagateCommonQueryParamsAsString = (
    settings: CommonSettingsType,
    extra?: Partial<QueryType>,
): string => {
    const filteredQuery = getCommonQueryParams(settings.query);
    const extraDefined = extra
        ? Object.entries(extra)
              .filter(([, value]) => value !== undefined) // Removes undefined values
              .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
        : {};

    return objectToQueryString({
        ...extraDefined,
        ...filteredQuery,
    });
};

/**
 * Creates a queryString with all current query params related to retailer apps.
 * Can be used to link outside of the build in contrast to Navigation/PROPAGATED_QUERY_TYPES.
 * @returns null in case there are no related queryparams or a string of the form `key=value&key2=value2`
 */
export const getRetailerAppsQueryString = (settings: CommonSettingsType): string | null => {
    const paramEntries = retailerAppsQueryParams
        .map((key) => [key, settings.query[key]])
        .filter(([, value]) => !!value);

    if (paramEntries.length < 1) return null;
    return objectToQueryString(Object.fromEntries(paramEntries));
};

/**
 * Returns brand code for usage in "brand.raw" A2D query based on commonSettings.brand value
 */
export const getA2DDealerBrand = (brand: string): DealerBrand =>
    brand.toLowerCase() === LEXUS ? DealerBrand.Lexus : DealerBrand.Toyota;

type AemImageFitOptions = "fit" | "constrain" | "crop" | "wrap" | "stretch" | "hfit" | "vfit";

// Feel free to add additional image options when necessary.
// See https://experienceleague.adobe.com/docs/dynamic-media-developer-resources/image-serving-api/image-serving-api/http-protocol-reference/command-reference/c-command-reference.html?lang=en
type AemImageOptions = {
    hei?: number; // height
    wid?: number; // width
    bgColor?: string; // background color
    fit?: `${AemImageFitOptions},${0 | 1}` | AemImageFitOptions;
    fmt?: string; // See https://experienceleague.adobe.com/docs/dynamic-media-developer-resources/image-serving-api/image-serving-api/http-protocol-reference/command-reference/r-is-http-fmt.html?lang=en
    scl?: number; // scale
};

type AemImageSmartCropType =
    | "Large-Landscape"
    | "Medium-Landscape"
    | "Small-Landscape"
    | "Large-Portrait"
    | "Medium-Portrait"
    | "Small-Portrait";

/**
 * Generate an url for an image uploaded in AEM / Scene7
 */
export const getAemImageUrl = (
    url: string,
    options: AemImageOptions | null,
    smartcrop?: AemImageSmartCropType,
): string => {
    if (!url) return url;
    const [basePath, queryString] = url.split("?");

    const params = Object.entries(options || {}).reduce(
        (reduceParams, [key, value]) => {
            reduceParams[key] = value;
            return reduceParams;
        },
        queryStringToObject(queryString || ""),
    );

    // params.ts = Date.now(); // scene7 caches images, enable this during development to ignore cache
    return `${basePath}${smartcrop ? `:${smartcrop}` : ""}?${objectToQueryString(params)}`;
};

/**
 * Validate an email address.
 */
export const validateEmail = (input: string): boolean => {
    // This check is very basic, but it seemed overkill to expand this further as proper email validation is very complex.
    return !!new RegExp(/^\S+@\S+\.\S+$/).exec(input)?.[0];
};

/**
 * Returns a tool modal config to toggle between the supplied query params.
 */
export const getDebugToggleLink = (toggleOptions: {
    toggleName: string;
    toggleValues: string[];
    tooltipTexts: string[];
}): ToolsModalItemType => {
    const { toggleName, toggleValues, tooltipTexts } = toggleOptions;
    const location = DOMUtils.canUseDOM ? window.location : { origin: "", pathname: "", search: "" };
    const currentPath = `${location.origin}${location.pathname}`;
    const queryObject = queryStringToObject(location.search);

    const nextToggleValueIndex = (toggleValues.indexOf(String(queryObject[toggleName])) + 1) % toggleValues.length;
    const toggleQuery = objectToQueryString({
        ...queryObject,
        // Opposite value of the current query parameter value
        [toggleName]: toggleValues[nextToggleValueIndex],
    });
    const url = `${currentPath}?${toggleQuery}`;

    return {
        links: [
            {
                title: tooltipTexts[nextToggleValueIndex],
                href: url,
            },
        ],
    };
};

/**
 * Returns a tool modal config to toggle between the retailer and website versions of components.
 */
export const getRetailerDebugToggleLink = (): ToolsModalItemType => {
    return getDebugToggleLink({
        toggleName: "retailerscreen",
        toggleValues: ["true", "false"],
        tooltipTexts: ["Go to retailerscreen variant", "Go to website variant"],
    });
};

/**
 * Limits a number between a min and max value
 * @param value - The value to limit
 * @param min - The minimum value
 * @param max - The maximum value
 * @returns The limited (clamped) value
 */
export const clamp = (value: number, min: number, max: number): number => Math.max(min, Math.min(max, value));

export const getMonthlyPriceSelector =
    (financeItemId: string, monthlyPrice: number) =>
    (state: EquipmentV2StateType): number => {
        if (financeItemId) {
            if (financeItemId === "total") {
                return Number(state.finance.total);
            } else {
                return typeof state.finance.itemRates[financeItemId] !== "undefined"
                    ? Number(state.finance.itemRates[financeItemId])
                    : 0;
            }
        } else {
            return monthlyPrice;
        }
    };

/**
 * Returns the height of the Reach Out Modal on small screen sizes
 */
export const getMobileReachoutModalSize = (): number => {
    const hasMobileReachOut = document.querySelector(".cmp-reachout-mobile");
    return hasMobileReachOut ? hasMobileReachOut.getBoundingClientRect().height : 0;
};

export const getReachoutSidepanelSize = (): number => {
    let hasReachoutSidepanel;
    if (DOMUtils.canUseDOM) hasReachoutSidepanel = document.querySelector(".cmp-reachout-sidepanel");
    return hasReachoutSidepanel ? hasReachoutSidepanel.getBoundingClientRect().width : 0;
};

/**
 * Convert A2D/car-filter lat/lon coordinates to mapbox lat/lng coordinates.
 * Simple helper to avoid having to do this manually all the time.
 */
export const getMapboxLatLng = ({ lat, lon }: CoordinateType): { lat: number; lng: number } => ({
    lat,
    lng: lon,
});

const lexusEcoSpecCategories = [
    TechnicalSpecificationCategory.Emission,
    TechnicalSpecificationCategory.ElectricConsumption,
    TechnicalSpecificationCategory.ElectricRange,
];

// First check if it's present in the ENVPERF category, if not check the FUEL CONSUMPTION category.
export const getEcoSpecs = (
    techSpecs: SortedSpecType[],
    isLexusBrand?: boolean,
    isAlternateFlow?: boolean,
): SortedSpecType[] | null => {
    let ecoSpecs: SortedSpecType[] = [];
    const envPerfSpecs = techSpecs.find(
        (spec) => spec.internalCode === TechnicalSpecificationCategory.EnvironmentalPerformance,
    );
    if (envPerfSpecs) ecoSpecs.push(envPerfSpecs);
    const fuelConsumptionSpecs = techSpecs.find(
        (spec) => spec.internalCode === TechnicalSpecificationCategory.FuelConsumption,
    );
    if (!ecoSpecs.length && fuelConsumptionSpecs) ecoSpecs.push(fuelConsumptionSpecs);

    // FOR LEXUS TDG WANTS TO SEE MORE THAN JUST ENVPERF SPECS IN THE ENVPERF
    // SECTION FOR LEGAL REASONS
    // TOYOTA TDG USES ENVPERF CORRECTLY
    // THIS ADDS THOSE CATEGORIES TO THE ECOSPECS
    if (isLexusBrand && isAlternateFlow && ecoSpecs.length) {
        const ecoSpecCategories = techSpecs.filter((spec) =>
            [
                !ecoSpecs
                    .map(({ internalCode }) => internalCode)
                    .includes(TechnicalSpecificationCategory.FuelConsumption)
                    ? TechnicalSpecificationCategory.FuelConsumption
                    : "",
                ...lexusEcoSpecCategories,
            ].includes(spec.internalCode || ""),
        );
        ecoSpecs.push(...ecoSpecCategories);
    }

    ecoSpecs = ecoSpecs.map((ecoSpec) => {
        // If the ecoSpecs have an object with Eff. Class => filter out the calculated one to prevent display duplicate info
        const hasManualEF = ecoSpec?.data?.find(({ internalCode }) => internalCode === EFFICIENCY_CLASS);
        if (hasManualEF && ecoSpec && ecoSpec.data) {
            return {
                ...ecoSpec,
                data: ecoSpec.data.filter(({ internalCode }) => internalCode !== EFFICIENCY_CLASS_TMEF),
            };
        }
        return ecoSpec;
    });

    return ecoSpecs || null;
};

export const sortAndReformatSpecs = (specs: Record<string, FormattedSpecType>): SortedSpecType[] =>
    // Convert the generated spec object to a sorted array based on the sortIndex property.
    Object.keys(specs)
        // First, sort main categories.
        .sort((a, b) => (specs[a].sortIndex || 0) - (specs[b].sortIndex || 0))
        .map((specKey) => {
            const origSubCat = specs[specKey].subCat;
            const rootSpec: SortedSpecType = {
                data: specs[specKey].data,
                disclaimer: specs[specKey].disclaimer,
                sortIndex: specs[specKey].sortIndex,
                title: specs[specKey].title,
                internalCode: specs[specKey].internalCode,
                id: specKey,
                subCat: [],
            };

            // Sort subCategories if applicable.
            if (origSubCat && Object.keys(origSubCat).length > 0) {
                const sortedSubSpecs = Object.keys(origSubCat)
                    .sort((a, b) => (origSubCat[a].sortIndex || 0) - (origSubCat[b].sortIndex || 0))
                    .map((subSpecKey) => ({ ...origSubCat[subSpecKey], id: subSpecKey }));

                rootSpec.subCat = sortedSubSpecs;
            }

            return rootSpec;
        });
export const formatStandardEquipmentList = (standardEquipment: ExtraType[]): SortedSpecType[] => {
    const reducedEquipment = standardEquipment.reduce(
        (categorizedEquipment: Record<string, FormattedSpecType>, equipment) => {
            const specCategory = equipment.category;

            // Grade featured pack's their categories don't have a root object; hence the following code
            const categoryID = specCategory.root ? specCategory.root.id : specCategory.code;
            const categoryName = specCategory.root ? specCategory.root.name : specCategory.name;
            const categoryCode = specCategory.root ? specCategory.root.code : specCategory.code;
            const categorySortIndex = specCategory.root ? specCategory.root.sortIndex : specCategory.sortIndex;
            let catData = null;

            // Create category if it doesn't exist yet.
            if (!categorizedEquipment[categoryID]) {
                categorizedEquipment[categoryID] = {
                    data: [],
                    title: categoryName,
                    internalCode: categoryCode,
                    disclaimer: "",
                    subCat: {},
                    sortIndex: categorySortIndex,
                };
            }

            // Easier access to category.
            const formattedSpecCategory = categorizedEquipment[categoryID];

            // Add subcategories if necessary, get reference to data property.
            // All exterior and interior categories should be grouped as subcategories of exterior.
            if (
                specCategory.level > 1 ||
                specCategory.code.includes("EXT/") ||
                specCategory.code.includes("INT/") ||
                !specCategory.root
            ) {
                const subCatIndex = specCategory.level > 1 ? specCategory.id : specCategory.code;

                if (!formattedSpecCategory.subCat[subCatIndex]) {
                    formattedSpecCategory.subCat[subCatIndex] = {
                        data: [],
                        title: specCategory.name,
                        sortIndex: specCategory.sortIndex,
                    };
                }
                catData = formattedSpecCategory.subCat[subCatIndex].data;
            } else if (specCategory.level === 1) {
                catData = formattedSpecCategory.data;
            }

            // If we have a data property, add the value and optional unit of the current spec item.
            if (catData) {
                catData.push({
                    name: equipment.name,
                    value: "",
                    unit: "",
                    id: equipment.id,
                    internalCode: equipment.internalCode,
                    description: equipment.description || "",
                    availability: equipment.availability,
                } as unknown as SpecDataType);
            } else {
                Debug.warn("Category mismatch", equipment);
            }
            return categorizedEquipment;
        },
        {},
    );

    return sortAndReformatSpecs(reducedEquipment);
};

/**
 * Returns a censored version of a property.
 * @param property - The property to censor
 * @param start - The number of characters to keep at the start
 * @param end - The number of characters to keep at the end
 * @param censorChar - The character to use for censoring (default is '*')
 * @returns The censored property if possible, otherwise the original property
 * @example Censor all but last 6 chars: censorProperty("1234567890", 0, 6) -> "****567890"
 * @example Censor all but first 4 chars: censorProperty("1234567890", 4, 0) -> "1234******"
 * @example Keep 3 chars at each end: censorProperty("1234567890", 3, 3) -> "123****890"
 */
export const censorProperty = (property: string, start: number, end: number, censorChar: string = "*"): string => {
    if (start + end >= property.length) {
        return property; // If the sum of start and end is greater than or equal to the length of the property, return the property as is
    }

    const censorLength = property.length - start - end;

    return property.slice(0, start) + censorChar.repeat(censorLength) + property.slice(property.length - end);
};

/**
 * Returns true when the provided url is a view as published url on aem author
 */
export const isAemAuthorUrl = (url: string): boolean => url.includes(AEM_AUTHOR_URL_SUFFIX);

/**
 * Returns true when the provided url is a localhost url
 */
export const isLocalUrl = (url: string): boolean => url.includes("//localhost");

/**
 * On AEM, these classnames are added to the body / root responsivegrid element for us.
 * The logic here does not match AEM's logic (via theme page property or specific template),
 * however we need to do it this way as page specific AEM data will not help us on standalone (for retailer apps / screens).
 */
export const getThemeClassname = (modelId: string | undefined): string => {
    // Using a modelId check here as the BZ branding should always be used when applicable (similar to GR logo)
    if (modelId === CarDBModelId.BZ4X) return THEME_CLASSNAME_BZ;
    return "";
};

/**
 * In the debug modals, the values exposed can be provided with the data object as extra information.
 * This util makes sure all object data is formatted in a consistent way.
 * @param value - Any object
 * @returns The object formatted as JSON with 4 spaces
 */
export const formatObjectToJson = (value: unknown): string => JSON.stringify(value, null, 4);

/**
 * Returns the scroll behavior based on the prefers-reduced-motion setting of the user
 */
export const getScrollBehavior = (): ScrollBehavior => {
    // Fetch user preferences for motion
    const reduceMotion = typeof window !== "undefined" && window.matchMedia("(prefers-reduced-motion: reduce)").matches;
    return reduceMotion ? "auto" : "smooth";
};
