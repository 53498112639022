import React from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import type { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import type { CarFilterCarSpecsModalType } from "../../../../utils/modalConstants";
import { getToyotaButtonColor } from "../../../../utils/ctaUtils";
import { getUscUrl, getVehicleTitle } from "../../../../../shared-logic/utils/uscUtils";
import { getUscVehicleUrlInfoFromResult } from "../../../../utils/helpers";
import useUsedCarResultSpecs from "../../../../hooks/car-results/useUsedCarResultSpecs";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import ModalBaseView from "../../../../../shared-components/toyota/styles/organisms/Modal";
import UsedCarResultSpecs from "../../car-results/df-car-result/UsedCarResultSpecs";
import ButtonPrimary from "../../../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import { IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as ModalStyles from "../../../../../shared-components/toyota/styles/organisms/Modal.styles";
import * as UsedCarResultStyles from "../../car-results/df-car-result/styles/UsedCarResultStyles";

const CarSpecsModal = (props: ModalViewType<CarFilterCarSpecsModalType>): JSX.Element | null => {
    const { show, close, modalSettings } = props;
    const { result } = props.modalSettings;
    const { id, product } = result;
    const { versionName, eco } = product;
    const vehicleTitle = getVehicleTitle(product);

    const { allSpecs } = useUsedCarResultSpecs(result);

    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);

    const carUrl = getUscUrl(
        commonSettings,
        carFilterSettings.urlDetailPage[currentFilter],
        currentFilter,
        getUscVehicleUrlInfoFromResult(result),
    );

    const selectCarLabel = useCarFilterLabel("carFilterSelectCar");

    return (
        <ModalBaseView modalIdType={modalSettings.type} show={show} onClose={close} size="medium">
            <ModalStyles.Header bgColour="primary-white">
                <UsedCarResultStyles.Title as="div">
                    {vehicleTitle}&nbsp;{/* Added &nbsp; for a11y reasons (PR#8184) */}
                    <UsedCarResultStyles.SubTitle>{versionName}</UsedCarResultStyles.SubTitle>
                </UsedCarResultStyles.Title>
                <ModalStyles.Button type="button" onClick={close}>
                    <IconClose />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body>
                <UsedCarResultSpecs vehicleForSaleId={id} specs={allSpecs} eco={eco} scrollable />
            </ModalStyles.Body>
            <ModalStyles.Footer>
                <ButtonPrimary color={getToyotaButtonColor(currentFilter)} fullWidth href={carUrl} stopPropagation>
                    {selectCarLabel}
                </ButtonPrimary>
            </ModalStyles.Footer>
        </ModalBaseView>
    );
};

export default CarSpecsModal;
