import React from "react";
import { useCommonSelector } from "../../redux/commonStore";
import type { EcoLabelPopupLabelType } from "../../types/SettingsType";

type PropsType = {
    label: EcoLabelPopupLabelType;
    hideInfoIcon?: boolean;
    styleOverride?: string;
};

const EmissionLabelComponent = ({ label, styleOverride, hideInfoIcon = false }: PropsType): JSX.Element | null => {
    const ecoLabelpopup = useCommonSelector((state) => state.commonSettings.ecoLabelpopup);
    //When retailerscreen=true, there should be no info link
    const retailerscreen = useCommonSelector((state) => state.commonSettings.query.retailerscreen);
    const labels = [...(ecoLabelpopup?.labels || []), label];

    return (
        <dxp-emission-label
            data-thumbnails-count={ecoLabelpopup?.thumbnailsCount}
            data-title={ecoLabelpopup?.title || "&nbsp;"}
            data-more-info-link={retailerscreen ? undefined : ecoLabelpopup?.readMoreLink}
            data-more-info-link-label={retailerscreen ? undefined : ecoLabelpopup?.moreInfoLinkLabel}
            data-labels={JSON.stringify(labels)}
            // For boolean properties they check if the property is added. They don't check the value that was passed
            // By adding undefined the attribute is not added to the web component
            data-hide-info-icon={hideInfoIcon ? "true" : undefined}
            // The web component can read the global settings of the page
            // It will use the property ecoLabelpopup from global settings to provide defaults when the values are not passed through the web component
            // The labels from the property ecoLabelpopup from global settings are automatically merged with the labels passed through the web component
            // To keep control in our component we disable this by adding data-ignore-dxp-settings
            data-ignore-dxp-settings="true"
            data-style={styleOverride}
        />
    );
};

export default EmissionLabelComponent;
