import styled from "styled-components";

export const Button = styled.button.attrs(() => ({
    className: "cmp-btn" as string,
}))``;

export const ButtonWrapper = styled.div.attrs(() => ({
    className: "cmp-btn" as string,
}))``;

export default Button;
