import type { NcPageUrlsModelDataType } from "../../../../../common-deprecated/utils/aemPageUrlTypes";
import type { RetailerLabelType } from "../../utils/labels";
import { retailerLabels } from "../../utils/labels";
import type { RetailerSettingsActionsType } from "../actions/RetailerSettingsActions";
import { RETAILER_SETTINGS_INIT } from "../actions/RetailerSettingsActions";

export type AEMRetailerScreenSettingsType = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    settings: Partial<{}>;
    labels: Partial<Record<RetailerLabelType, string>>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    config: {}; // config is not used, so an actual empty object
};

export type RetailerSettingsType = {
    initialized: boolean;
    labels: Record<RetailerLabelType, string>;
    modelData: NcPageUrlsModelDataType[];
};

export const initialStateRetailerSettings: RetailerSettingsType = {
    initialized: false,
    labels: { ...retailerLabels },
    modelData: [],
};

/**
 * This reducer should only be initialized when loading the retailer variant of a component (?retailerscreen=true)
 */
const RetailerSettingsReducer = (
    state: RetailerSettingsType = initialStateRetailerSettings,
    action: RetailerSettingsActionsType,
): RetailerSettingsType => {
    switch (action.type) {
        case RETAILER_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        default:
            return state;
    }
};

export default RetailerSettingsReducer;
