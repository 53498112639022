import React, { useRef } from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import type { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import type { CarFilterModalType } from "../../../../utils/modalConstants";
import { getCarFilterLabel, useCarFilterLabel } from "../../../../utils/constants/labels";
import useSortFilters from "../../../../hooks/active-filters/useSortFilters";
import { trackEvent } from "../../../../../../common-deprecated/utils/tracking";
import { getAemTrackingDefaults } from "../../../../utils/tracking";
import { getResultsLabel } from "../../../../utils/helpers";
import ModalBaseView from "../../../../../shared-components/toyota/styles/organisms/Modal";
import ButtonPrimary from "../../../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import Radio from "../../styles/molecules/Radio";
import { IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import * as ModalStyles from "../../../../../shared-components/toyota/styles/organisms/Modal.styles";
import * as Styles from "./SortFiltersModal.styles";

const SortFiltersModal = (props: ModalViewType<CarFilterModalType>): JSX.Element | null => {
    const { show, close, modalSettings } = props;
    const popupRef = useRef<HTMLDivElement>(null);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const sortOrder = useCarFilterSelector(({ carResults }) => carResults.dfCarSortOrder);
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const resultCount = useCarFilterSelector((state) => state.carResults.totalResultCount);
    const seeResultsLabel = useCarFilterLabel(getResultsLabel(resultCount), { value: resultCount });
    const titleLabel = useCarFilterLabel("carFilterSort");

    const itemTracking = (value: string): void => {
        trackEvent({
            ...getAemTrackingDefaults(currentFilter),
            action: "sort",
            label: value,
        });
    };

    const openTracking = (opened: boolean): void => {
        if (!opened) return;
        trackEvent({
            ...getAemTrackingDefaults(currentFilter),
            action: "open-sorting-menu",
        });
    };

    const { values } = useSortFilters(popupRef, itemTracking, openTracking);

    if (!values || values.length <= 1) return null;

    return (
        <ModalBaseView modalIdType={modalSettings.type} show={show} onClose={close} size="medium">
            <ModalStyles.Header>
                <Styles.Title>{titleLabel}</Styles.Title>
                <ModalStyles.Button type="button" onClick={close}>
                    <IconClose />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body noSpacing>
                <Styles.ListSortFilter role="list">
                    {values.map(({ labelKey, id, selectFn }) => {
                        const label = getCarFilterLabel({ commonSettings, carFilterSettings }, labelKey);
                        return (
                            <li key={id}>
                                <Radio
                                    id={id}
                                    name="sortfilters"
                                    label={label}
                                    position="right"
                                    checked={id === sortOrder}
                                    onChange={selectFn}
                                />
                            </li>
                        );
                    })}
                </Styles.ListSortFilter>
            </ModalStyles.Body>
            <Styles.ModalFooter>
                <ButtonPrimary color="primaryRed" fullWidth onClick={close}>
                    {seeResultsLabel}
                </ButtonPrimary>
            </Styles.ModalFooter>
        </ModalBaseView>
    );
};

export default SortFiltersModal;
