import type { KeyboardEvent } from "react";
import React from "react";
import * as LocationComboStyles from "./styles/LocationComboStyles";
import { useCarFilterLabel } from "../../../../utils/constants/labels";

type LocationRangeItemPropsType = {
    onClick?: () => void;
    onKeyDown?: (event: KeyboardEvent<HTMLLIElement>) => void;
    range?: number;
    dealerCount?: number;
    bottomBorder?: boolean;
};

const LocationRangeItem = ({
    onClick,
    onKeyDown,
    range,
    dealerCount,
    bottomBorder,
}: LocationRangeItemPropsType): JSX.Element => {
    const [radiusLabel, nationalLabel, dealerCountLabel] = useCarFilterLabel([
        "carFilterLocationRadius",
        "carFilterLocationNational",
        "carFilterLocationDealerCount",
    ]);

    const formattedRange = useCarFilterLabel("carFilterLocationDistance", { value: range });

    return (
        <LocationComboStyles.ComboItem
            bottomBorder={bottomBorder}
            onClick={onClick}
            onKeyDown={onKeyDown}
            role="option"
            // According to WAI-ARIA guidelines, the aria-selected attribute should be set on the option that is currently selected
            // However, we never render the selected option in the dropdown. So we set aria-selected to false for all options
            aria-selected="false"
        >
            <LocationComboStyles.FieldRadius>
                <p>{radiusLabel}:</p>
                <p>{range === 0 ? nationalLabel : formattedRange}</p>
            </LocationComboStyles.FieldRadius>

            <LocationComboStyles.ComboItemResultsText>
                {dealerCountLabel.replace("{value}", String(dealerCount))}
            </LocationComboStyles.ComboItemResultsText>
        </LocationComboStyles.ComboItem>
    );
};

export default LocationRangeItem;
