import type { FC } from "react";
import React from "react";
import EmissionLabel from "../../../../common-deprecated/components/eco-labels/EmissionLabel";
import { useCommonSelector } from "../../../../common-deprecated/redux/commonStore";
import { parseSpecificationValue } from "../../../../common-deprecated/utils";
import type { UscEcoType } from "../../../shared-logic/utils/uscEcoUtils";
import { getEmissionLabel } from "../../../shared-logic/utils/uscEcoUtils";
import EfficiencyClassIcon from "./EfficiencyClassIcon";
import type { DfEfficiencyClassCode } from "../../../shared-logic/utils/dfConstants";

type Props = { ecoInfo: UscEcoType | null; code?: string };

const EfficiencyClassLabel: FC<Props> = ({ ecoInfo, code: fallbackCode = "" }) => {
    const commonSettings = useCommonSelector((state) => state.commonSettings);
    const { efficiencyClass } = ecoInfo || { efficiencyClass: null };
    // Render EmissionLabel instead of value when the current spec is an energy efficiency class, the ecoLabelpopup is available in the settings and the CO2 Combined WLTP value is available in the specs
    const emissionLabel = getEmissionLabel(efficiencyClass, ecoInfo, commonSettings);
    if (emissionLabel) return <EmissionLabel label={emissionLabel} hideInfoIcon={!commonSettings.ecoLabelpopup} />;

    const { code } = efficiencyClass || { code: fallbackCode };
    const value = parseSpecificationValue({ unit: "", value: code }, commonSettings.culture);
    return <EfficiencyClassIcon efficiencyClass={value as DfEfficiencyClassCode} />;
};

export default EfficiencyClassLabel;
