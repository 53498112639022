import React from "react";
import type { SpaceProps } from "styled-system";
import { useCommonOptionalLabel } from "../../../../common-deprecated/utils/commonLabels";
import * as Styles from "./styles/PageFinanceDisclaimerStyles";
import * as Container from "../../../../common-deprecated/styles/v2/toyota/globals/Container";

type PageFinanceDisclaimerPropsType = SpaceProps & {
    showOutline?: boolean;
    maxWidth?: number;
};

const PageFinanceDisclaimer = ({
    showOutline,
    maxWidth,
    ...props
}: PageFinanceDisclaimerPropsType): JSX.Element | null => {
    const financeDisclaimerLabel = useCommonOptionalLabel("uscPageFinanceDisclaimer");

    if (!financeDisclaimerLabel) return null;

    return (
        <Container.default maxWidth={maxWidth} {...props}>
            <Styles.Wrapper variantOutline={showOutline}>{financeDisclaimerLabel}</Styles.Wrapper>
        </Container.default>
    );
};

export default PageFinanceDisclaimer;
