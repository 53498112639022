import styled from "styled-components";
import IconWrapper from "../../../../../../common-deprecated/styles/v2/toyota/globals/IconWrapper";
import { theme } from "../../../../../../common-deprecated/styles/v2/toyota/theme/toyotaTheme";
import { getDirection } from "../../../../../../common-deprecated/themes/common";

export const FilterPill = styled.div<{ isPrimary?: boolean; hasIcon?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    height: 30px;
    padding: ${(props) => (props.hasIcon ? `0 ${theme.space[1]}px 0 ${theme.space[2]}px` : `0 ${theme.space[2]}px`)};
    /* Remove some right padding to make space for the close icon. */
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${({ isPrimary }) => (isPrimary ? theme.colors.primaryWhite : theme.colors.primaryBlack)};
    background: ${({ isPrimary }) => (isPrimary ? theme.colors.primaryBlack : theme.colors.grey1)};
    border: 1px solid ${({ isPrimary }) => (isPrimary ? theme.colors.primaryBlack : theme.colors.grey1)};
    border-radius: 15px;
    box-sizing: border-box;

    span {
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

export const Close = styled.button`
    all: unset;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-${getDirection("left")}: ${theme.space[1] / 2}px;
    ${IconWrapper} {
        width: 20px;
        height: 20px;
        margin-top: 1px;
    }
`;
