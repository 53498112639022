// ----------------------------------------------------------------------
// Slider filters
// ----------------------------------------------------------------------
import { FILTER_PROACE } from "./filterConfigConstants";

export const FILTER_LUGGAGE = "luggage" as const;
export const FILTER_SPEED = "speed" as const;
export const FILTER_CO2 = "co2" as const;
export const FILTER_FUEL_CONSUMPTION = "fuelConsumption" as const;
export const FILTER_POWER_OUTPUT = "powerOutput" as const;
export const FILTER_ETA = "eta" as const;
export const FILTER_TOWING_CAPACITY = "towingCapacity" as const;
export const FILTER_LOAD_VOLUME = "loadVolume" as const;
export const FILTER_VEHICLEDIMENSIONS = "vehicleDimensions" as const;
export const FILTER_VEHICLEDIMENSIONS_LENGTH = "length" as const;
export const FILTER_VEHICLEDIMENSIONS_WIDTH = "width" as const;
export const FILTER_VEHICLEDIMENSIONS_HEIGHT = "height" as const;
export const FILTER_MAXIMUM_PAYLOAD = "maximumPayload" as const;
export type SliderFilterIdType =
    | typeof FILTER_LUGGAGE
    | typeof FILTER_SPEED
    | typeof FILTER_CO2
    | typeof FILTER_FUEL_CONSUMPTION
    | typeof FILTER_ETA
    | typeof FILTER_POWER_OUTPUT;
// ----------------------------------------------------------------------
// Min max slider filters
// ----------------------------------------------------------------------
export const FILTER_PRICE = "price" as const;
// ----------------------------------------------------------------------
// MultipleChoice filters
// ----------------------------------------------------------------------
export const FILTER_CAR_TYPE = "carType" as const;
export const FILTER_FUEL_TYPE = "fuelType" as const;
export const FILTER_TRANSMISSION = "transmission" as const;
export const FILTER_WHEELDRIVE = "wheeldrive" as const;
export const FILTER_EQUIPMENT = "equipment" as const;
// ----------------------------------------------------------------------
// Plus minus filters
// ----------------------------------------------------------------------
export const FILTER_DOORS = "doors" as const;
export const FILTER_SEATS = "seats" as const;
// Map filter constants to values used in the car-filter api.
export const CAR_FILTER_MAPPING = {
    [`${FILTER_PRICE}-cash`]: "PRICE",
    [`${FILTER_PRICE}-monthly`]: "MONTHLY_RATE",
    [FILTER_DOORS]: "CAP_SLIDER_8",
    [FILTER_LUGGAGE]: "BODY_SLIDE_1",
    [FILTER_CO2]: "ENV_SLIDE_1",
    [FILTER_FUEL_CONSUMPTION]: "ENV_SLIDE_2",
    [FILTER_SEATS]: "BODY_SLIDE_2",
    [FILTER_SPEED]: "ENG_SLIDE_1",
    [FILTER_POWER_OUTPUT]: "ENG_SLIDE_2",
    [FILTER_ETA]: "ETA",
    [FILTER_EQUIPMENT]: "EQUIPMENT",
    [`${FILTER_PROACE}-bodyType`]: "TYPE_SLIDER_1",
    [`${FILTER_PROACE}-wheelBase`]: "TYPE_SLIDER_2",
    [FILTER_TOWING_CAPACITY]: "CAP_SLIDER_9",
    [FILTER_LOAD_VOLUME]: "CAP_SLIDER_2",
    [FILTER_VEHICLEDIMENSIONS_LENGTH]: "CAP_SLIDER_10",
    [FILTER_VEHICLEDIMENSIONS_WIDTH]: "CAP_SLIDER_11",
    [FILTER_VEHICLEDIMENSIONS_HEIGHT]: "CAP_SLIDER_12",
    [FILTER_MAXIMUM_PAYLOAD]: "CAP_SLIDER_1",
};
export const DEFAULT_PARENT_ID = "00000000-0000-0000-0000-000000000000";
