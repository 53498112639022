import Debug from "../Debug";
import { useSelector } from "../../shared-logic/redux/store";

/**
 * This hook can be used as a simpler version of the old Message component.
 * It uses some TS generics trickery which allows them to be wrapped with component specific label types.
 * They can be re-exported like so:
 * export const useTypedHook = useLabel as UseLabelType<labelKeys, Record<paramKeys, string>>;
 *
 * The typing in this hook is questionable, but it allows to propagate an array of labels and return an array of translations.
 * This prevents the need to call the hook multiple times with a different label.
 * Support for params and fallback labels is disabled for this array version as that could cause confusion and errors quickly.
 */
export type UseLabelType<Label extends string, Params extends Partial<Record<string, string | number>>> = {
    (label: Label, params?: Params, fallbackLabel?: Label): string;
    (label: Label[]): string[];
};

export type UseOptionalLabelType<Label extends string, Params extends Partial<Record<string, string | number>>> = {
    (label: Label, params?: Params): string | null;
    (label: Label[]): (string | null)[];
};

export type GetLabelType<
    State extends any,
    Label extends string,
    Params extends Partial<Record<string, string | number>>,
> = {
    (state: State, label: Label, params?: Params, fallbackLabel?: Label): string;
    (state: State, label: Label[]): string[];
};

export type GetOptionalLabelType<
    State extends any,
    Label extends string,
    Params extends Partial<Record<string, string | number>>,
> = {
    (state: State, label: Label, params?: Params, fallbackLabel?: Label): string | null;
    (state: State, label: Label[]): (string | null)[];
};

/**
 * Helper to read a label from a label collection. (This is what used to be Settings.getLabel)
 *
 * This isn't typed properly as it is only used by the useLabel hooks, do not use this outside of useLabel context without proper typing.
 */
const parseLabel = (
    labels: Record<string, string>,
    labelKey: string,
    showDictionaryKeys: boolean,
    params?: Partial<Record<string, string | number>>,
    fallbackLabel?: string,
): string => {
    if (showDictionaryKeys) return labelKey;

    if (!labels[labelKey]) {
        if (fallbackLabel) return parseLabel(labels, fallbackLabel, showDictionaryKeys, params);
        Debug.info(`No translation found for key: ${labelKey}. Add key to translations.`);
        return labelKey;
    }

    if (!params) return labels[labelKey];

    return labels[labelKey].replace(/([{%])([a-zA-Z0-9]*)([}%])/g, (replacement: string): string => {
        const key = replacement.match(/([{%])([a-zA-Z0-9]*)([}%])/)![2];
        return (params && String(params[key])) || "";
    });
};

// ⚠️ Do not use this hook without it being wrapped with custom typing, see explanation above ⚠️
export const getUseLabel: <T>(
    getLabelFn: (state: T) => Record<string, string>,
    showDictionaryKeysFn: (state: T) => boolean,
) => UseLabelType<string, Partial<Record<string, string | number>>> =
    (getFn, showDictionaryKeysFn) =>
    (label: string | string[], params?: Partial<Record<string, string | number>>, fallbackLabel?: string) => {
        const labels = useSelector(getFn);
        const showDictionaryKeys = useSelector(showDictionaryKeysFn);

        if (Array.isArray(label)) {
            return label.map((labelKey) =>
                parseLabel(labels, labelKey, showDictionaryKeys, params, fallbackLabel),
            ) as any;
        } else {
            return parseLabel(labels, label, showDictionaryKeys, params, fallbackLabel) as any;
        }
    };

// ⚠️ Do not use this hook without it being wrapped with custom typing, see explanation above ⚠️
// This hook is a basic wrapper for useLabel that returns undefined when a label isn't defined (instead of returning the key)
export const getUseOptionalLabel: <T>(
    getLabelFn: (state: T) => Record<string, string>,
    showDictionaryKeysFn: (state: T) => boolean,
) => UseOptionalLabelType<string, Partial<Record<string, string | number>>> =
    (getLabelFn, showDictionaryKeysFn) =>
    (label: string | string[], params?: Partial<Record<string, string | number>>) => {
        const labels = useSelector(getLabelFn);
        const showDictionaryKeys = useSelector(showDictionaryKeysFn);

        if (Array.isArray(label)) {
            return label.map((labelKey) =>
                labels[labelKey] ? parseLabel(labels, labelKey, showDictionaryKeys, params) : (null as any),
            );
        } else {
            return labels[label] ? parseLabel(labels, label, showDictionaryKeys, params) : (null as any);
        }
    };

// ⚠️ Do not use this function without it being wrapped with custom typing ⚠️
// This function does the same thing as useLabel but doesn't require hook usage. (Same concept as the deprecated Settings.getLabel)
export const getLabelFn: <T>(
    getLabelFn: (state: T) => Record<string, string>,
    showDictionaryKeysFn: (state: T) => boolean,
) => GetLabelType<T, string, Partial<Record<string, string | number>>> =
    (getFn, showDictionaryKeysFn) =>
    (
        state: any,
        label: string | string[],
        params?: Partial<Record<string, string | number>>,
        fallbackLabel?: string,
    ) => {
        const labels = getFn(state);
        const showDictionaryKeys = showDictionaryKeysFn(state);

        if (Array.isArray(label)) {
            return label.map((labelKey) => parseLabel(labels, labelKey, showDictionaryKeys, params)) as any;
        } else {
            return parseLabel(labels, label, showDictionaryKeys, params, fallbackLabel);
        }
    };

// ⚠️ Do not use this function without it being wrapped with custom typing ⚠️
// This hook is a basic wrapper for getLabelFn that returns undefined when a label isn't defined (instead of returning the key)
export const getOptionalLabelFn: <T>(
    getLabelFn: (state: T) => Record<string, string>,
    showDictionaryKeysFn: (state: T) => boolean,
) => GetOptionalLabelType<T, string, Partial<Record<string, string | number>>> =
    (getFn, showDictionaryKeysFn) =>
    (
        state: any,
        label: string | string[],
        params?: Partial<Record<string, string | number>>,
        fallbackLabel?: string,
    ) => {
        const labels = getFn(state);
        const showDictionaryKeys = showDictionaryKeysFn(state);

        if (Array.isArray(label)) {
            return label.map((labelKey) =>
                labels[labelKey] ? parseLabel(labels, labelKey, showDictionaryKeys, params) : (null as any),
            );
        } else {
            return labels[label] ? parseLabel(labels, label, showDictionaryKeys, params, fallbackLabel) : (null as any);
        }
    };
