import type { CommonSettingsType } from "../fetchCommonSettings";
import type { AemFmConfig } from "../../utils/aemFlexibilityMatrixUtils";
import { AemFmComponent, AemFmPriceType } from "../../utils/aemFlexibilityMatrixUtils";
import type { CarModelLabelType, PriceConfigType } from "../../types/CommonTypes";
import { CarModelLabelCodeType } from "../../types/CommonTypes";
import type { FlexMatrixPriceType } from "../../constants";
import { getLicenseFee, showPrice } from "./financeSettingUtilsCommon";
import { formatPrice, hasSecondaryCulture } from "../../Globalize";
import { calculatePriceInclLicenseFee, formatPriceIntl, replaceKeysInFormat } from "../../priceUtils";
import { isIsrael } from "./commonSettingUtils";

/**
 * Retrieve an AEM FM config.
 *
 * Note that this function uses a different concept compared to the Tridion FM where this function returns a single config object vs individual properties in Tridion.
 * Refer to the comments inside this function for more info.
 */
export const getAemFlexibilityMatrixConfig = <
    T extends { aemFlexibilityMatrix?: CommonSettingsType["aemFlexibilityMatrix"] },
>(
    commonSettings: T,
    aemComponent: AemFmComponent,
    // TODO context is not actually supported yet, see OR-5348
    context: "new" | "used" | "stock" = "new", // This is specifically not an enum to maintain compatibility with the various car-filter and USC enums.
    modelCode?: string, // For now only relevant for new cars.
    // Chances are we'll need to add buildStep here as well.
): AemFmConfig | null => {
    const fmData = commonSettings.aemFlexibilityMatrix;

    if (!fmData) return null;

    /**
     * Config logic:
     *
     * Try to find the most specific FM config, keep falling back until we have the least specific/default component.
     *
     * In Tridion FM configs could be overridden on a property level.
     * (For example: price.enabled enabled override on component level and price.format on a model level)
     * In the current AEM implementation this is not possible, once a FM config is found all fields of that config will be used.
     * This means that overrides are on an entire FM config level and not property level.
     * Also note that the current version only supports model and component level configs, not individual sections like Tridion. (TODO Add OR nr here)
     */
    let fmConfig: AemFmConfig | undefined;

    // Custom modelCode + component. Most specific config.
    fmConfig = fmData.custom.find(
        (fmItem) =>
            !!modelCode &&
            fmItem.config.modelCode?.toLowerCase() === modelCode.toLowerCase() &&
            fmItem.component === aemComponent,
    );

    // Custom context + component.
    // Some assumptions in the current logic:
    // - Context is only available in custom configs
    // - Context without a component is unsupported
    // - Context combined with a modelCode is unsupported
    if (!fmConfig) {
        fmConfig = fmData.custom.find(
            (fmItem) => fmItem.config.context === context && fmItem.component === aemComponent,
        );
    }

    // Custom component without modelCode or context.
    // While it is in theory possible to get only a modelCode match in the custom configs this is not a supported use case.
    if (!fmConfig) {
        fmConfig = fmData.custom.find(
            (fmItem) => fmItem.component === aemComponent && !fmItem.config.modelCode && !fmItem.config.context,
        );
    }

    // Default modelCode + component.
    if (!fmConfig) {
        fmConfig = fmData.default.find(
            (fmItem) =>
                !!modelCode && fmItem.config.modelCode === modelCode.toLowerCase() && fmItem.component === aemComponent,
        );
    }

    // Default component it should not contain a modelCode!
    if (!fmConfig) {
        fmConfig = fmData.default.find((fmItem) => fmItem.component === aemComponent && !fmItem.config.modelCode);
    }

    // Default modelcode
    if (!fmConfig) {
        fmConfig = fmData.default.find(
            (fmItem) =>
                !!modelCode &&
                fmItem.config.modelCode === modelCode.toLowerCase() &&
                fmItem.component === AemFmComponent.All,
        );
    }

    // Default. Least specific config it should not contain a modelCode!
    if (!fmConfig)
        fmConfig = fmData.default.find((fmItem) => fmItem.component === AemFmComponent.All && !fmItem.config.modelCode);

    return fmConfig || null;
};

/**
 * Check if finance or insurance is enabled in the flexibility matrix.
 *
 * If it's enabled in the default flexibility matrix, but disabled in a model specific flexibility matrix, this will be returned accordingly.
 */
export const checkAemFlexibilityMatrixFinanceOrInsuranceEnabled = <
    T extends { aemFlexibilityMatrix?: CommonSettingsType["aemFlexibilityMatrix"] },
>(
    financeOrInsurance: "finance" | "insurance",
    commonSettings: T,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new", // This is specifically not an enum to maintain compatibility with the various car-filter and USC enums.
    modelCode?: string, // For now only relevant for new cars.
): string | boolean | undefined => {
    const defaultFlexibilityMatrix = getAemFlexibilityMatrixConfig(
        commonSettings,
        aemComponent,
        context,
        modelCode,
    )?.config;
    const defaultEnabled = defaultFlexibilityMatrix?.[financeOrInsurance].enabled;

    if (defaultEnabled) {
        const customFlexibilityMatrix = getAemFlexibilityMatrixConfig(
            commonSettings,
            aemComponent,
            context,
            modelCode,
        )?.config;

        const customEnabled = customFlexibilityMatrix?.[financeOrInsurance].enabled;

        if (customEnabled === false) return "default: true - custom: false";
    }

    return defaultEnabled;
};

/**
 * Get a priceConfig object used to render a price.
 */
export const getAemPriceConfig = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    modelCode?: string,
): PriceConfigType => {
    const fmConfig = getAemFlexibilityMatrixConfig(commonSettings, aemComponent, "new", modelCode)?.config;

    const priceType: FlexMatrixPriceType | AemFmPriceType = fmConfig?.price?.type || AemFmPriceType.InclVat;
    const priceEnabled = fmConfig?.price.enabled !== undefined ? fmConfig.price.enabled : true;
    const strikethroughDisabled = fmConfig?.price?.strikethroughDisabled || false;
    const priceFormat = fmConfig?.price?.format || "";

    return { priceType, priceFormat, priceEnabled, strikethroughDisabled };
};

/**
 * Returns true when there is a dual currency and 2 prices should be shown.
 * Currently dual currency is only supported in cash prices. This is not supported in monthly or promo prices yet.
 *
 * @param checkGlobalize Defaults to true, set to false to skip the check for the Globalize culture (for example in USC which does not use Globalize)
 */
export const hasDualCurrency = (commonSettings: CommonSettingsType, checkGlobalize = true): boolean => {
    return (
        !!commonSettings.currencyMultiplier &&
        !!commonSettings.culture.numberFormat.secondaryCurrency &&
        (!checkGlobalize || hasSecondaryCulture(commonSettings.culture.name))
    );
};

/**
 * Checks if the provided labels contain a cashPriceDisclaimer and returns it. Used for VAT price disclaimers for Israel.
 *
 * @param labels All the labels of a carModel.
 */
export const getCashPriceDisclaimerLabel = (labels: CarModelLabelType[]): string | undefined => {
    return labels?.find((label) => label.code === CarModelLabelCodeType.CASH_PRICE_DISCLAIMER)?.value;
};

/**
 * Show a price Excl VAT. Based on the "priceType" property in flexibility matrix.
 */
export const showAemPriceExclVAT = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): boolean => {
    const fmConfig = getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode);
    return fmConfig?.config.price.type === AemFmPriceType.ExclVat;
};

/**
 * Get all modelIds where the price needs to be shown excluding VAT.
 */
export const getAemExclVATModelIds = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
): string[] => {
    return Object.values(commonSettings.modelMap).reduce<string[]>((exclVATModels, model) => {
        if (showAemPriceExclVAT(commonSettings, aemComponent, context, model.internalCode))
            exclVATModels.push(model.id);
        return exclVATModels;
    }, []);
};

/**
 * Base version of showAemMonthlyRates.
 * If a components needs more specific logic, create a custom helper. See showBnbMonthlyRates for an example.
 */
export const showAemMonthlyRates = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): boolean => {
    const fmConfig = getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode);

    return (
        showPrice(commonSettings) &&
        !!fmConfig?.config?.finance?.enabled &&
        !showAemPriceExclVAT(commonSettings, aemComponent, context, modelCode)
    );
};

export const promoteAemMonthlyRates = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): boolean => {
    const fmConfig = getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode);
    const showMonthlyRates = showAemMonthlyRates(commonSettings, aemComponent, context, modelCode);

    return showMonthlyRates && !!fmConfig?.config?.finance?.promoted;
};

/**
 * Base version of showAemInsuranceRates.
 * If a components needs more specific logic, create a custom helper.
 */
export const showAemInsuranceRates = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): boolean => {
    return (
        getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode)?.config.insurance.enabled ||
        false
    );
};

export const getAemMonthlyRateFormat = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): string => {
    return getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode)?.config.finance.format || "";
};

export const getAemInsuranceRateFormat = (
    commonSettings: CommonSettingsType,
    aemComponent: AemFmComponent,
    context: "new" | "used" | "stock" = "new",
    modelCode?: string,
): string => {
    return (
        getAemFlexibilityMatrixConfig(commonSettings, aemComponent, context, modelCode)?.config.insurance.format || ""
    );
};

/**
 * Formats a price based on this format:
 * [price]Base price {value}[/price][fee]License fee {value}[/fee][total]Total price {value}[/total]
 * defined in the flexibility matrix
 * when providing 0 as licenseFee, only the [price]Base price {value}[/price] will be formatted and returned
 */
const formatIsraelPrice = (
    cashPrice: number,
    cultureName: string,
    priceConfig: PriceConfigType,
    licenseFee: number,
): string[] => {
    const priceInclLicenseFee = calculatePriceInclLicenseFee(cashPrice, priceConfig, licenseFee);
    const keysToBeReplaced = {
        price: formatPrice(cashPrice, cultureName, true),
        fee: licenseFee ? formatPrice(licenseFee, cultureName, true) : "",
        total: licenseFee ? formatPrice(priceInclLicenseFee, cultureName, true) : "",
    };
    return replaceKeysInFormat(keysToBeReplaced, priceConfig.priceFormat).map(({ value }) =>
        value.replace("<br/>", ""),
    );
};

export type FormatCashPriceFunctionType<T extends string | string[]> = (
    cashPrice: number | string,
    cultureName: string,
    priceConfig?: PriceConfigType,
    licenseFee?: number,
    secondary?: boolean,
) => T;

export const formatAEMCashPrice: FormatCashPriceFunctionType<string> = (
    cashPrice,
    cultureName,
    priceConfig,
    licenseFee,
    secondary,
) => {
    let cashFormatted = formatPrice(cashPrice, cultureName, true, secondary);
    // Don't format if there is none configured
    if (priceConfig && priceConfig.priceFormat) {
        const { priceFormat } = priceConfig;

        cashFormatted = priceFormat.replace("%s", cashFormatted);
    }

    return cashFormatted;
};

export const formatAEMCashPriceIsrael: FormatCashPriceFunctionType<string[]> = (
    cashPrice,
    cultureName,
    priceConfig,
    licenseFee,
) => {
    let cashFormatted = [formatPrice(cashPrice, cultureName, true)];
    // Don't format if there is none configured
    if (priceConfig && priceConfig.priceFormat) {
        const { priceFormat } = priceConfig;
        // For AEM the licenseFees are defined per section, hence we can safely rely that if its present that it should be shown
        // For the sections where no license fee should be shown, the licensefee passed will be always 0.
        // If licensefee is 0 it won't parse it through the priceFormat but rather just return the formatPrice result
        // TODO Not relevant for Israel (yet?), but this does not support prices excluding VAT.
        if (typeof licenseFee !== "undefined" && isIsrael(cultureName)) {
            if (licenseFee > 0) {
                cashFormatted = formatIsraelPrice(Number(cashPrice), cultureName, priceConfig, licenseFee);
            }
        } else {
            cashFormatted = [priceFormat.replace("%s", cashFormatted.join(""))];
        }
    }
    return cashFormatted;
};

/**
 * Calculate the license fee
 */
export const getAEMLicenseFee = <
    T extends {
        aemFlexibilityMatrix?: CommonSettingsType["aemFlexibilityMatrix"];
    },
>(
    settings: T,
    price: number,
    modelCode: string,
    component: AemFmComponent,
): number => {
    const licenseFees = getAemFlexibilityMatrixConfig(settings, component, "new", modelCode)?.config.price.licenseFees;
    if (licenseFees) {
        return getLicenseFee(price, licenseFees);
    }
    return 0;
};

/**
 * Formats a price based on this format:
 * [price]Base price {value}[/price][fee]License fee {value}[/fee][total]Total price {value}[/total]
 * defined in the flexibility matrix
 * when providing 0 as licenseFee, only the [price]Base price {value}[/price] will be formatted and returned
 */
const formatIsraelPriceIntl = (
    cashPrice: number,
    commonSetting: CommonSettingsType,
    priceConfig: PriceConfigType,
    licenseFee: number,
) => {
    const priceInclLicenseFee = calculatePriceInclLicenseFee(cashPrice, priceConfig, licenseFee);
    const keysToBeReplaced = {
        price: formatPriceIntl(commonSetting, cashPrice).primaryPrice,
        fee: licenseFee ? formatPriceIntl(commonSetting, licenseFee).primaryPrice : "",
        total: licenseFee ? formatPriceIntl(commonSetting, priceInclLicenseFee).primaryPrice : "",
    };
    return replaceKeysInFormat(keysToBeReplaced, priceConfig.priceFormat)
        .map((item) => item.value)
        .join("");
};

export type FormatAemCashPriceIntlType = (
    cashPrice: number | string,
    commonSettings: CommonSettingsType,
    priceConfig?: PriceConfigType,
    licenseFee?: number,
    secondary?: boolean,
    formatOverride?: string,
) => { primaryPrice: string; secondaryPrice?: string };

/**
 * function that formats the Price using Intl instead of Globalize
 */
export const formatAEMCashPriceIntl: FormatAemCashPriceIntlType = (
    cashPrice,
    commonSettings,
    priceConfig,
    licenseFee,
    secondary,
    formatOverride,
) => {
    const { primaryPrice, secondaryPrice } = formatPriceIntl(commonSettings, cashPrice, undefined, secondary);
    // Don't format if there is none configured
    if (priceConfig && priceConfig.priceFormat) {
        let priceFormat;

        if (formatOverride) {
            priceFormat = formatOverride;
        } else {
            priceFormat = priceConfig.priceFormat;
        }

        // For AEM the licenseFees are defined per section, hence we can safely rely that if its present that it should be shown
        // For the sections where no license fee should be shown, the licensefee passed will be always 0.
        // If licensefee is 0 it won't parse it through the priceFormat but rather just return the formatPrice result
        // TODO Not relevant for Israel (yet?), but this does not support prices excluding VAT.
        if (typeof licenseFee !== "undefined" && isIsrael(commonSettings.culture.name)) {
            if (licenseFee > 0) {
                return {
                    primaryPrice: formatIsraelPriceIntl(Number(cashPrice), commonSettings, priceConfig, licenseFee),
                };
            }
            return { primaryPrice };
        } else if (secondary && secondaryPrice) {
            return {
                primaryPrice: priceFormat.replace("%s", primaryPrice),
                secondaryPrice: priceFormat.replace("%s", secondaryPrice),
            };
        } else {
            return {
                primaryPrice: priceFormat.replace("%s", primaryPrice),
            };
        }
    }
    return { primaryPrice, secondaryPrice };
};
