import React from "react";
import { shallowEqual } from "react-redux";
import ModalBaseView from "../../../../../shared-components/toyota/styles/organisms/Modal";
import Icon from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCarFilterSelector } from "../../../../redux/store";
import { CarFilterQueryParam, carFilterValuesToParams } from "../../../../utils/params";
import type { ModalViewType } from "../../../../../../common-deprecated/types/CommonTypes";
import type { ConfigHelperModalType } from "../../../../utils/modalConstants";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import * as Status from "../../../../../../common-deprecated/components/modals/status/styles/StatusStyles";
import * as ModalStyles from "../../../../../shared-components/toyota/styles/organisms/Modal.styles";
import * as Styles from "./ConfigHelperModal.styles";

const ConfigHelperModal = (props: ModalViewType<ConfigHelperModalType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const filterParams = useCarFilterSelector(
        (state) => carFilterValuesToParams(state.carFilters, state.carFilters.currentFilter),
        shallowEqual,
    );

    const params = Object.entries(filterParams)
        // Only show the actual filter query params
        .filter(([param]) => (Object.values(CarFilterQueryParam) as string[]).includes(param));

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="medium">
            <ModalStyles.Header>
                <Styles.Title>Filter config helper</Styles.Title>
                <ModalStyles.Button type="button" onClick={close}>
                    <Icon variant="close" />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body>
                <Flex.Row>
                    <Flex.Col mb={2}>
                        Use the configuration variables listed below to reuse the current filter configuration. For more
                        information please refer to the&nbsp;
                        <a href="https://t1-preview-wiki.toyota-europe.com/index.php?title=Car_Filter#Preconfigured_Filters">
                            wiki
                        </a>
                        .
                    </Flex.Col>
                </Flex.Row>
                {params.length > 0 ? (
                    <>
                        <Status.Row>
                            <Flex.Col width={5 / 12}>
                                <strong>Key</strong>
                            </Flex.Col>
                            <Flex.Col width={7 / 12}>
                                <strong>Value</strong>
                            </Flex.Col>
                        </Status.Row>
                        {params.map(([param, paramValue]) => (
                            <Status.Row key={param}>
                                <Flex.Col width={5 / 12}>{param}</Flex.Col>
                                <Flex.Col width={7 / 12}>{paramValue}</Flex.Col>
                            </Status.Row>
                        ))}
                    </>
                ) : (
                    <Status.Row>
                        <Flex.Col>No filters active</Flex.Col>
                    </Status.Row>
                )}
            </ModalStyles.Body>
        </ModalBaseView>
    );
};

export default ConfigHelperModal;
