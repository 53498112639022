import type { WeightedSpecsLabelType } from "../../shared-logic/utils/uscEcoUtils";
import { UscQuickSpec } from "../../shared-logic/utils/uscUtils";
import type { CarFilterStateType } from "../redux/store";
import type { CarFilterLabelStateType } from "./constants/labels";
import { getCarFilterLabel } from "./constants/labels";
import { getCommonLabel } from "../../../common-deprecated/utils/commonLabels";
import type { QuickSpecLabelType } from "../../shared-logic/utils/uscSpecUtils";

export const carFilterWeightedSpecsLabelSelector = (state: CarFilterLabelStateType): WeightedSpecsLabelType => ({
    [UscQuickSpec.CombinedCo2]: getCarFilterLabel(state, "carFilterWeightedCombinedCo2"),
    [UscQuickSpec.Co2NedcCombined]: getCarFilterLabel(state, "carFilterWeightedCo2NedcCombined"),
    [UscQuickSpec.Co2WltpCombined]: getCarFilterLabel(state, "carFilterWeightedCo2WltpCombined"),
    [UscQuickSpec.FcNedcCombined]: getCarFilterLabel(state, "carFilterWeightedFcNedcCombined"),
    [UscQuickSpec.FcWltpCombined]: getCarFilterLabel(state, "carFilterWeightedFcWltpCombined"),
    [UscQuickSpec.FcCsCombined]: getCarFilterLabel(state, "carFilterWeightedFcCsCombined"),
    [UscQuickSpec.EcCombined]: getCarFilterLabel(state, "carFilterWeightedEcCombined"),
    [UscQuickSpec.EfficiencyClassText]: getCarFilterLabel(state, "carFilterWeightedEfficiencyClassText"),
    // Not relevant for Car Filter, but not worth the effort of modifying the type to omit it
    [UscQuickSpec.EcolabelEcCombined]: "",
});

export const carFilterQuickSpecLabelsSelector = ({ commonSettings }: CarFilterLabelStateType): QuickSpecLabelType => ({
    yesLabel: getCommonLabel({ commonSettings }, "uscSpecLabelYes"),
    noLabel: getCommonLabel({ commonSettings }, "uscSpecLabelNo"),
    kmLimitLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyKmLimit"),
    validUntilLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyValidUntil"),
    expiredLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyExpired"),
    noneLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyNone"),
    unspecifiedLabel: getCommonLabel({ commonSettings }, "uscManufacturerWarrantyUnspecified"),
});

export const carFilterSpecsParamsSettingsSelector = (
    state: CarFilterStateType,
): {
    disableMonthInRegistrationSpec: boolean;
    showZeroCo2SpecValues: boolean;
} => {
    const { carFilterSettings, carFilters } = state;
    return {
        disableMonthInRegistrationSpec: carFilterSettings.disableMonthInRegistrationSpec[carFilters.currentFilter],
        showZeroCo2SpecValues: carFilterSettings.showZeroCo2SpecValues[carFilters.currentFilter],
    };
};
