import React from "react";
import type { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import { useCommonLabel } from "../../../../../common-deprecated/utils/commonLabels";
import type { ModalContentType } from "../../../../../common-deprecated/utils/modalConstants";
import Icon from "../../styles/atoms/Icon";
import ModalBaseView from "../../styles/organisms/Modal";
import * as Styles from "../../styles/organisms/Modal.styles";
import Text from "../../styles/atoms/Text";

// Basic modal that shows some plaintext or html content
const UscContentModal = (props: ModalViewType<ModalContentType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { content, useHTML = false, onClose, title, footer } = modalSettings;

    const closeLabel = useCommonLabel("close");

    // combine both manually set close handler and common modal close handler
    const closeHandler = (): void => {
        close();
        if (onClose) {
            onClose();
        }
    };

    return (
        <ModalBaseView
            modalIdType={modalSettings.type}
            onClose={closeHandler}
            show={show}
            size="fitcontent-height"
            dialogLabelId={typeof title === "string" ? title : undefined}
        >
            <Styles.Header noBorder={!title}>
                {title && (
                    <Text tag="h2" variant="h6">
                        {title}
                    </Text>
                )}
                <Styles.Button type="button" aria-label={closeLabel} onClick={closeHandler}>
                    <Icon variant="close" />
                </Styles.Button>
            </Styles.Header>
            <Styles.Body>
                {useHTML && typeof content === "string" ? (
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                    content
                )}
                {footer && (
                    <>
                        <br />
                        {footer}
                    </>
                )}
            </Styles.Body>
        </ModalBaseView>
    );
};

export default UscContentModal;
