import type { RefObject } from "react";
import { useCallback, useEffect, useState } from "react";
import { useBreakpoint } from "../../../../common-deprecated/themes/common";
import { useWindowDimensions } from "../../../../common-deprecated/hooks";

// TO DO: Add tests and remove duplicate ActiveFilterSlider.test from car-filter and model-filter

const useActiveFilterSlider = <T extends string>(
    prerenderRef: RefObject<HTMLDivElement>,
    viewportRef: RefObject<HTMLDivElement>,
    activeFilters: T[],
    activeFiltersKey: string,
): {
    sliderWidth: number;
    viewportWidth: number;
    updateWidth: () => void;
    enableSlider: boolean;
    sliderFilters: T[];
} => {
    const [sliderWidth, setSliderWidth] = useState<number>(0);
    const [viewportWidth, setViewportWidth] = useState<number>(0);
    const [sliderFilters, setSliderFilters] = useState<T[]>([]);
    const enableSlider = useBreakpoint("down", "lg");
    const windowWidth = useWindowDimensions().width;

    // Because of the HTML structure and general HTML/CSS limits we need to manually set the width of the slider to get it to work.
    // To do this we first render the children in a hidden container, gather their widths and render the slider after we have set the widths.
    // This function updates the width of the slider based on the content of the prerender container.
    const updateWidth = useCallback(() => {
        if (prerenderRef.current) {
            let totalWidth = 0;

            // Loop through the prerender container children and calculate the total width of all children.
            Array.from(prerenderRef.current.children).forEach((child) => {
                totalWidth += child.getBoundingClientRect().width;
            });

            if (totalWidth !== sliderWidth) setSliderWidth(totalWidth);
        }
    }, [sliderWidth]);

    useEffect(() => {
        if (prerenderRef.current && enableSlider) {
            updateWidth();
            setSliderFilters(activeFilters);
        }
    }, [activeFiltersKey, updateWidth, enableSlider, activeFilters]);

    // Set the width of the viewport as this can change during window resizes.
    useEffect(() => {
        if (viewportRef.current && enableSlider) {
            const { width } = viewportRef.current.getBoundingClientRect();
            if (width !== viewportWidth) setViewportWidth(width);
        }
    }, [windowWidth, enableSlider, viewportWidth, sliderFilters]);

    // ----------------------------------------------------------------------
    // Pill sorting logic, only used for desktop.
    //
    // The hook below tries to make an optimal sort order for the available pills, so the least amount of space is used.
    // The algorithm isn't completely accurate but should hold up well enough.
    // ----------------------------------------------------------------------
    useEffect(() => {
        // Only run when the slider is disabled
        if (!prerenderRef.current || !viewportRef.current || enableSlider) return;

        // boundingWidth contains the maximum width for one "row" of filter pills.
        const boundingWidth = viewportRef.current.getBoundingClientRect().width;

        // Populate the widthMapping array which contains the width of every filter pill and the index of that filter item.
        const widthMapping: { index: number; width: number }[] = Array.from(prerenderRef.current.children).map(
            (child, index) => ({
                index,
                width: child.getBoundingClientRect().width,
            }),
        );

        // This logic will calculate a matrix which contains one array for each "row" of filter pills.
        // Each array contains one or multiple items of the widthMapping array.
        // The startIndex parameter is used to determine which items should come first.
        const calculateRowMatrix = (startIndex: number): { index: number; width: number }[][] => {
            // Start out with the first item based on the startIndex.
            const rowMatrix: { index: number; width: number }[][] = [[widthMapping[startIndex]]];
            let rowIndex = 0;

            // availableRows contains the items not yet added to the rowMatrix.
            let availableRows = [...widthMapping].filter((map) => map.index !== startIndex);

            // Function below will fill out the rowMatrix array until all items are added.
            // It will call itself recursively until all the available rows are added to the rowMatrix.
            const calculateRow = (): void => {
                let totalRowWidth = 0;

                // If any items are already added to the row, add their width to totalRowWidth.
                rowMatrix[rowIndex].forEach((index) => {
                    totalRowWidth += index.width;
                });

                // Loop through the remaining available rows, if the item width allows it add it to the rowMatrix.
                availableRows.forEach((row) => {
                    if (totalRowWidth + row.width <= boundingWidth) {
                        rowMatrix[rowIndex].push(row);
                        totalRowWidth += row.width;
                    }
                });

                // Remove the items added to the rowMatrix from availableRows.
                availableRows = availableRows.filter(
                    (row) => !rowMatrix[rowIndex].find((rowItem) => rowItem.index === row.index),
                );

                // If there are items left, add a new row and the loop will start over.
                if (availableRows.length) {
                    rowMatrix.push([]);
                    rowIndex++;
                    calculateRow();
                }
            };

            calculateRow();

            return rowMatrix;
        };

        // Calculate row matrixes for each filter item and get the optimal one (which should be the one with the least amount of rows)
        // We do this by executing calculateRowMatrix once for each individual filter item, so we get a calculation where we put each filter item first.
        const matrixes: { index: number; width: number }[][][] = [];
        Array.from(prerenderRef.current.children).forEach((child, index) => {
            matrixes.push(calculateRowMatrix(index));
        });

        const optimalMatrix = matrixes.sort((a, b) => a.length - b.length)[0];

        // Sort the matrix with the least amount of rows: Make sure the rows are sorted from longest to shortest.
        optimalMatrix.sort((a, b) => {
            // Rows with more items should be first.
            if (a.length !== b.length) return b.length - a.length;

            // Item count is the same, sort based on width.
            const totalWidthA = a.reduce((totalWidth, index) => totalWidth + index.width, 0);
            const totalWidthB = b.reduce((totalWidth, index) => totalWidth + index.width, 0);

            return totalWidthB - totalWidthA;
        });

        // Convert the optimal matrix back into an array of filter IDs.
        // CSS will take care of the wrapping of items.
        const sortedSliderFilter: T[] = optimalMatrix.reduce((sortedFilter: T[], matrixItem) => {
            matrixItem.forEach((item) => {
                sortedFilter.push(activeFilters[item.index]);
            });
            return sortedFilter;
        }, []);

        setSliderFilters(sortedSliderFilter);
    }, [activeFiltersKey, enableSlider]);

    return { sliderWidth, viewportWidth, updateWidth, enableSlider, sliderFilters };
};

export default useActiveFilterSlider;
