import React from "react";
import * as ButtonStyles from "../../../styles/v2/toyota/components/Button";
import type { ColorType } from "../../../styles/v2/toyota/theme/toyotaTheme";
import * as Text from "../../../styles/v2/toyota/utilities/Text";

type ButtonProps = {
    href?: string;
    onClick?: (event: React.MouseEvent) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    target?: string;
    rel?: string;
    classNames?: string;
    children: React.ReactNode;
    color?: ColorType;
    stopPropagation?: boolean;
    tabIndex?: number;
    ariaLabel?: string;
};

/**
 * ButtonPrimary is a button with a dark background and a white text
 * @example
 *   <ButtonPrimary>
 *     <Text.Button>Label</Text.Button>
 *   </ButtonPrimary>
 */
const ButtonPrimary = (props: ButtonProps): JSX.Element => {
    const { href, onClick, disabled, fullWidth, children, target, classNames, rel, color } = props;
    const { tabIndex, stopPropagation, ariaLabel } = props;

    return (
        <ButtonStyles.ButtonPrimary fullWidth={fullWidth} color={color} tabIndex={tabIndex}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                href={href && !disabled ? href : undefined}
                type={!href ? "button" : undefined}
                className={[classNames || "", disabled ? "disabled" : ""].join(" ")}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
                target={href ? target : undefined}
                rel={href && rel}
                onClick={(event: React.MouseEvent) => {
                    if (stopPropagation) event.stopPropagation();

                    if (onClick) onClick(event);
                }}
                aria-label={ariaLabel || undefined}
            >
                {children}
            </Text.Button>
        </ButtonStyles.ButtonPrimary>
    );
};

export default ButtonPrimary;
