import type { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import type { CarFilterActionsType } from "../redux/store";
import { setPrice, setPriceActive, setSelectMultiFilter, setSliderValue } from "../redux/actions/CarFiltersActions";
import Debug from "../../../common-deprecated/Debug";
import type { DfCarSortOrder } from "./constants/filterConstants";
import type { CarFilterIdType, UscContext } from "../../shared-logic/types/UscCommonTypes";
import { StockCarFilterId, UsedCarFilterId } from "../../shared-logic/types/UscCommonTypes";
import { decodeQueryValue, minMaxValueIsInvalid } from "../../../shared-logic/features/filters/utils/params";
import { getCurrentFilters, isMinMaxSlider, isMultipleChoice, isPrice } from "./filters";
import { setUsedCarSortOrder } from "../redux/actions/CarResultsActions";

// filterQueryParams contains the available params specifically related to filter values.
// Having a separate enums seems to be the most clean and flexible solution for now (as we'll possibly need to localize this at some point).
export enum CarFilterQueryParam {
    Brand = "brands", // Important that it's plural as otherwise it will interfere with the brand = "lexus" | "toyota" param.
    Model = "model",
    FuelType = "fuelType",
    Category = "category",
    Equipment = "equipment",
    Seats = "seats",
    Doors = "doors",
    PowerOutput = "powerOutput",
    Year = "year",
    Transmission = "transmission",
    Colour = "colour",
    EcoLabel = "ecoLabel",
    Warranty = "warranty",
    License = "license",
    Mileage = "mileage",
    Location = "location",
    Price = "price",
    VehicleStatus = "vehicleStatus",
    EfficiencyClass = "efficiencyClass",
    Deliverable = "deliverable",
    ModelYear = "modelYear",
    BodyStyle = "bodyStyle",
    DriveType = "driveType",
    Ownership = "ownership",
    CarType = "carType",
}

// Mapping for which filterId uses which query param.
export const filterQueryParamsMap: Record<CarFilterQueryParam, { used?: UsedCarFilterId; stock?: StockCarFilterId }> = {
    [CarFilterQueryParam.Equipment]: {
        used: UsedCarFilterId.Equipment,
        stock: StockCarFilterId.Equipment,
    },
    [CarFilterQueryParam.Seats]: {
        used: UsedCarFilterId.Seats,
        stock: StockCarFilterId.Seats,
    },
    [CarFilterQueryParam.Doors]: {
        used: UsedCarFilterId.Doors,
        stock: StockCarFilterId.Doors,
    },
    [CarFilterQueryParam.PowerOutput]: {
        used: UsedCarFilterId.PowerOutput,
        stock: StockCarFilterId.PowerOutput,
    },
    [CarFilterQueryParam.Year]: { used: UsedCarFilterId.Year },
    [CarFilterQueryParam.Transmission]: {
        used: UsedCarFilterId.Transmission,
        stock: StockCarFilterId.Transmission,
    },
    [CarFilterQueryParam.Colour]: { used: UsedCarFilterId.Colour, stock: StockCarFilterId.Colour },
    [CarFilterQueryParam.EcoLabel]: { used: UsedCarFilterId.EcoLabel },
    [CarFilterQueryParam.EfficiencyClass]: { used: UsedCarFilterId.EfficiencyClass },
    [CarFilterQueryParam.Warranty]: { used: UsedCarFilterId.Warranty },
    [CarFilterQueryParam.License]: { used: UsedCarFilterId.License },
    [CarFilterQueryParam.Mileage]: { used: UsedCarFilterId.Mileage, stock: StockCarFilterId.Mileage },
    [CarFilterQueryParam.Location]: { used: UsedCarFilterId.Location, stock: StockCarFilterId.Location },
    [CarFilterQueryParam.Deliverable]: { used: UsedCarFilterId.Deliverable },
    [CarFilterQueryParam.ModelYear]: { used: UsedCarFilterId.ModelYear },
    [CarFilterQueryParam.Price]: {
        used: UsedCarFilterId.Price,
        stock: StockCarFilterId.Price,
    },
    [CarFilterQueryParam.Brand]: {
        used: UsedCarFilterId.Brand,
        stock: StockCarFilterId.Brand,
    },
    [CarFilterQueryParam.Model]: {
        used: UsedCarFilterId.Model,
        stock: StockCarFilterId.Model,
    },
    [CarFilterQueryParam.FuelType]: {
        used: UsedCarFilterId.FuelType,
        stock: StockCarFilterId.FuelType,
    },
    [CarFilterQueryParam.Category]: {
        used: UsedCarFilterId.Category,
        stock: StockCarFilterId.Category,
    },
    [CarFilterQueryParam.VehicleStatus]: {
        used: UsedCarFilterId.VehicleStatus,
        stock: StockCarFilterId.VehicleStatus,
    },
    [CarFilterQueryParam.DriveType]: {
        used: UsedCarFilterId.DriveType,
        stock: StockCarFilterId.DriveType,
    },
    [CarFilterQueryParam.BodyStyle]: { used: UsedCarFilterId.BodyStyle, stock: StockCarFilterId.BodyStyle },
    [CarFilterQueryParam.Ownership]: { used: UsedCarFilterId.Ownership },
    [CarFilterQueryParam.CarType]: { used: UsedCarFilterId.CarType, stock: StockCarFilterId.CarType },
};

/**
 * Helper to do the "reverse mapping" of the carFilterQueryParamsMap object.
 * This will return the relevant carFilterQueryParam for a given filterId.
 */
const getFilterQueryParam = (context: UscContext, filterId: CarFilterIdType): CarFilterQueryParam | null => {
    let result: CarFilterQueryParam | null = null;

    Object.entries(filterQueryParamsMap).forEach((entry) => {
        if (entry[1][context] === filterId) result = entry[0] as CarFilterQueryParam;
    });

    return result;
};
type ParamsType = Partial<Record<CarFilterQueryParam, string>>;

/**
 * Convert the given carFilters state into a param object.
 * Always make sure that the filters object contains the filters for the provided context!
 */
export const carFilterValuesToParams = (filters: Partial<CarFiltersReducerType>, context: UscContext): ParamsType => {
    const result: ParamsType = {};
    Object.values(getCurrentFilters(context))
        .filter((filterId) => filters[filterId]?.active)
        .forEach((filterId) => {
            const queryParam = getFilterQueryParam(context, filterId);
            if (!queryParam) return;

            if (isPrice(filterId)) {
                if (filters[filterId]?.cash.active) {
                    const { currentMinValue, currentMaxValue } = filters[filterId]!.cash;
                    result[queryParam] = `cash:${currentMinValue}-${currentMaxValue}`;
                } else if (filters[filterId]?.monthly.active) {
                    const { currentMinValue, currentMaxValue } = filters[filterId]!.monthly;
                    result[queryParam] = `monthly:${currentMinValue}-${currentMaxValue}`;
                }
            } else if (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) {
                const { dealer, dealerGroup, range, dealerHolding } = filters[filterId]!;
                if (dealer) result[queryParam] = `dealerId:${dealer.id}`;
                else if (dealerGroup) result[queryParam] = `dealerGroupId:${dealerGroup.id}`;
                else if (dealerHolding) result[queryParam] = `dealerHoldingId:${dealerHolding.id}`;
                else if (range) {
                    // Changing any of this will have impact in the parsing of this data in PreFetchDataMiddleware as well.
                    result[queryParam] =
                        `range:${range.coords.lat},${range.coords.lon},${range.range},` +
                        `${encodeURIComponent(range.name)}`;
                }
            } else if (isMultipleChoice(filterId)) {
                const { values } = filters[filterId]!;
                result[queryParam] = values
                    .filter((filterValue) => filterValue.selected)
                    .map((value) => value.id)
                    .join(",");
            } else if (isMinMaxSlider(filterId)) {
                const { currentMinValue, currentMaxValue } = filters[filterId]!;
                result[queryParam] = `${currentMinValue}-${currentMaxValue}`;
            }
        });

    return result;
};

// Supported body params/query strings.
export type CarFilterParamType = Partial<
    Record<CarFilterQueryParam, string> & {
        // Used to determine the initial rendered component.
        carFilter: UscContext;
        // Keeps track of the "open" state of the "more filters" slide-in. Not rendered server-side.
        showMoreFilters: boolean;
        // Sort order of the current filter.
        sortOrder: DfCarSortOrder;
        // State of the "show saved cars" toggle
        showSavedCars: boolean;
        // This enables testing/developing the similar cars functionality on standalone
        similarCarId: string;
        // Used in redirect cases where you want to autoscroll to the car-filter component. (For example redirect from similar cars)
        scrollToCarFilter: boolean;
        // Contains comma separated filterIds or carTypeFilters which should be disabled
        disabledFilters: string;
        // Shows the RetailerSelectModal for first time setup on retailerscreens variant of car-filter
        enableSelectRetailer?: boolean;
        // Enables the use of the global store which is used in the car-filter-header component
        useGlobalStore?: boolean;
        // Pagination
        page?: number;
    }
>;

/**
 * Parse car-filter params propagated through GET or POST into filter actions to an array,
 * so that these can be dispatched at a later time.
 * Note that location filter is not parsed here, this is done specifically in preFetch logic as that is dependent on A2D location data.
 */
export const paramsToCarFilterActions = (
    params: CarFilterParamType,
    carFilters: CarFiltersReducerType,
): CarFilterActionsType[] => {
    const actions: CarFilterActionsType[] = [];

    const { currentFilter } = carFilters;

    Object.values(CarFilterQueryParam)
        .filter((queryParam) => params[queryParam])
        .forEach((queryParam) => {
            const filterId = filterQueryParamsMap[queryParam]?.[currentFilter];
            const filterValue = decodeQueryValue(params[queryParam] || "");
            if (!filterId) {
                Debug.error("Filter in parameters but no filterId found");
                return;
            }

            if (isPrice(filterId)) {
                // Determine the active price filter.
                const [priceKey, priceFilter] = filterValue.split(":");
                if (priceKey !== "cash" && priceKey !== "monthly") return;

                const [min, max] = priceFilter.split("-").map((value) => Number(value));
                const { minValue, maxValue } = carFilters[filterId][priceKey];

                if (minMaxValueIsInvalid(min, max, minValue, maxValue)) return;

                actions.push(setPrice(filterId, "min", min, priceKey));
                actions.push(setPrice(filterId, "max", max, priceKey));
                actions.push(setPriceActive(filterId, priceKey));
            } else if (isMultipleChoice(filterId)) {
                const { values } = carFilters[filterId];
                // Parse selected values and set in state if they are valid (valid == item found in state).
                const newSelectedValues = filterValue
                    .split(",")
                    .filter((paramValue) => values.find((value) => value.id === paramValue));
                if (newSelectedValues.length) actions.push(setSelectMultiFilter(newSelectedValues, true, filterId));

                // Deselect values not present in current params.
                const deSelectedValues = values
                    .filter((value) => value.selected && !newSelectedValues.includes(value.id))
                    .map((value) => value.id);
                if (deSelectedValues.length) actions.push(setSelectMultiFilter(deSelectedValues, false, filterId));
            } else if (isMinMaxSlider(filterId)) {
                const { minValue, maxValue } = carFilters[filterId];
                const [min, max] = filterValue.split("-").map((value) => Number(value));

                if (minMaxValueIsInvalid(min, max, minValue, maxValue)) return;

                actions.push(setSliderValue("min", min, filterId));
                actions.push(setSliderValue("max", max, filterId));
            }
        });

    if (params.sortOrder) actions.push(setUsedCarSortOrder(params.sortOrder as DfCarSortOrder));

    return actions;
};
