import styled, { css, keyframes } from "styled-components";
import type { DisplayProps, MinWidthProps, SpaceProps, TypographyProps, WidthProps } from "styled-system";
import { compose, display, minWidth, position, space, typography, width } from "styled-system";
import type { BreakPointValuesType, ThemeTypedColorProps } from "../../themes/common";
import { getBreakpoint, getDirection } from "../../themes/common";
import * as Type from "./Type";
import { IconAnimatedToggleArrowStyle } from "./AnimatedStyles";
import { IconWrapper } from "./IconWrapper";

const hasIconStyles = css`
    display: inline-flex;
    align-items: center;
    svg {
        margin-${getDirection("right")}: 9px;
    }
    ${IconAnimatedToggleArrowStyle} {
        margin-${getDirection("right")}: 9px;
        svg {
            margin: 0;
        }
    }
`;

const hasIconRightStyles = css`
    display: inline-flex;
    align-items: center;
    svg {
        margin-${getDirection("left")}: 9px;
        margin-${getDirection("right")}: 0;
    }
    ${IconAnimatedToggleArrowStyle} {
        margin-${getDirection("left")}: 9px;
        margin-${getDirection("right")}: 0;
        svg {
            margin: 0;
        }
    }
`;

const hasOnlyIconStyles = css`
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    min-width: 0;
    padding: 11px !important;
    line-height: 2rem;
`;

// LEXUS squeezeAnimation (because animation names can change in the lexus css)
const squeezeAnimation = keyframes`
    0% {
        transform: scaleX(1);
        transform-origin: right;
      }
    50% {
        transform: scaleX(0);
        transform-origin: right;
    }
    50.01% {
        transform: scaleX(0);
        transform-origin: left;
    }
    100% {
        transform: scaleX(1);
        transform-origin: left;
    }

`;

export type ButtonType = {
    size?: BreakPointValuesType;
    hasIcon?: boolean;
    hasIconRight?: boolean;
    hasOnlyIcon?: boolean;
    cursor?: string;
    disabled?: boolean;
    notClickable?: boolean;
} & DisplayProps &
    WidthProps &
    MinWidthProps &
    SpaceProps &
    TypographyProps &
    ThemeTypedColorProps;

const styleProps = compose(display, width, minWidth, space, typography);

/* STANDARD BUTTON STYLES */

export const toyotaButtonStyles = css<ButtonType>`
    min-width: 160px;
    max-width: 100%;
    margin: 0;
    padding: ${(props) => (props.size && props.size === "sm" ? "4px 9px" : "10px 30px")};
    line-height: 1.5;
    color: ${(props) => props.theme.textColor.button};
    white-space: nowrap;
    background-color: ${(props) => props.theme.color.grey1};
    border: none;
    border-radius: 100px;
    ${styleProps};
    ${(props) => props.hasIcon && hasIconStyles};
    ${(props) => props.hasIconRight && hasIconRightStyles};
    ${(props) => props.hasOnlyIcon && hasOnlyIconStyles};
    ${(props) =>
        props.notClickable &&
        css`
            pointer-events: none;
            pointer: auto;
        `};
    ${({ disabled, theme }) =>
        disabled &&
        css`
            background-color: ${theme.color.grey4} !important;
        `};
`;

// Lexus styles are based on the amaze style, this is why rem values were used (to reduce differences)
export const lexusButtonStyles = css<ButtonType>`
    display: inline-block;
    position: relative;
    padding: ${(props) => (props.size && props.size === "sm" ? "13px 13px 10px" : "23px 25px 20px")};
    font-family: ${(props) => props.theme.fontFamily.bold};
    font-size: 0.6875rem;
    letter-spacing: .1rem;
    line-height: 1.6;
    text-transform: uppercase;
    color: ${(props) => props.theme.textColor.button};
    border: 1px solid ${(props) => props.theme.color.grey5};
    border-radius: 0;
    cursor: pointer;
    ${width};
    ${space};
    ${typography};
    ${({ disabled, theme }) =>
        disabled &&
        css`
            background-color: ${theme.color.grey5} !important;
        `};
    @media ${getBreakpoint("up", "md")} {
        padding-${getDirection("right")}: ${(props) => (props.size && props.size === "sm" ? "40px" : "65px")};
        text-align: ${getDirection("left")};
        &::after {
            position: absolute;
            top: 50%;
            ${getDirection("right")}: 24px;
            width: 25px;
            height: 1px;
            content: "";
            background: ${(props) => props.theme.color.dark};
        }
        &:hover::after {
            animation: ${squeezeAnimation} 1s ease 0s infinite normal none;
        }
    }
`;

const Button = styled.button<ButtonType>`
    ${toyotaButtonStyles};
`;

/* BUTTON TYPES */

export type PrimaryButtonType = { disabled?: boolean & TypographyProps & WidthProps & DisplayProps };
export const toyotaButtonPrimaryStyles = css<PrimaryButtonType>`
    color: ${({ disabled, theme }) => (disabled ? theme.color.grey1 : theme.color.lightest)};
    background-color: ${({ disabled, theme }) => (disabled ? theme.color.grey3 : theme.color.primary)};
    transition: background-color 0.3s;
    ${display};
    ${width};
    ${typography};
    ${space};
`;

export const lexusButtonPrimaryStyles = css<PrimaryButtonType>`
    color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    background-color: ${({ disabled, theme }) => (disabled ? "transparent" : theme.color.primary)};
    border-color: ${({ disabled, theme }) => (disabled ? theme.color.grey2 : theme.color.primary)};
    &:visited {
        color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    }
    &::after {
        background: ${(props) => props.theme.color.lightest};
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.color.grey5} !important;
            background-color: transparent !important;
            &::after {
                display: none;
            }
        `};
`;

export const Primary = styled.button<ButtonType & PrimaryButtonType>`
    min-width: 160px;
    max-width: 100%;
    margin: 0;
    padding: ${(props) => (props.size && props.size === "sm" ? "4px 9px" : "10px 30px")};
    line-height: 1.5;
    white-space: nowrap;
    border: none;
    border-radius: 100px;
    ${(props) => props.hasIcon && hasIconStyles};
    ${(props) => props.hasIconRight && hasIconRightStyles};
    ${(props) => props.hasOnlyIcon && hasOnlyIconStyles};
    ${(props) =>
        props.notClickable &&
        css`
            pointer-events: none;
            pointer: auto;
        `};
    ${({ disabled, theme }) =>
        disabled &&
        css`
            background-color: ${theme.color.grey4} !important;
        `};
    ${({ theme }) => (theme.isLexus ? lexusButtonPrimaryStyles : toyotaButtonPrimaryStyles)};
`;

export const toyotaButtonDarkStyles = css<PrimaryButtonType>`
    color: ${(props) => props.theme.color.lightest};
    background-color: ${(props) => props.theme.color.dark};
`;

export const lexusButtonDarkStyles = css<PrimaryButtonType>`
    color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    background-color: ${({ disabled, theme }) => (disabled ? "transparent" : theme.color.primary)};
    border-color: ${({ disabled, theme }) => (disabled ? theme.color.grey2 : theme.color.primary)};
    ${IconAnimatedToggleArrowStyle} {
        position: absolute;
        top: 50%;
        ${getDirection("right")}: 24px;
        transform: translateY(-50%);
        svg {
            top: 0;
        }
    }
    &::after {
        display: none;
    }
`;

export const Dark = styled(Button)`
    ${({ theme }) => (theme.isLexus ? lexusButtonDarkStyles : toyotaButtonDarkStyles)};
`;

// Secondary Button (Core)
export const OutlinePrimary = styled(Button)`
    ${({ theme }) =>
        theme &&
        css`
            color: ${theme.color.primary};
            background-color: ${theme.color.lightest};
            border: 1px solid ${theme.color.primary};

            &:hover {
                border-color: ${theme.color.primaryHover};
                box-shadow: inset 0 0 1px ${theme.color.primaryHover};
                color: ${theme.color.primaryHover};
            }
        `};
`;

export const Lightest = styled(Button)`
    ${({ theme }) =>
        theme &&
        css`
            background-color: ${theme.color.lightest};
            border: 1px solid ${theme.color.grey2};
        `};
`;

export const Negative = styled(Button)<ButtonType>`
    color: ${(props) => props.theme.textColor.button};
    background-color: ${(props) => props.theme.color.lightest};
`;

export const Light = styled(Button)`
    color: ${(props) => props.theme.textColor.button};
    background-color: ${(props) => props.theme.color.grey1};
`;

/* BUTTON ICON STYLES */

const linkHasIconStyles = css`
    display: inline-block;
    position: relative;
    padding-${getDirection("left")}: 28px; /* 20px (icon size) + 8px (spacing) */
    padding-${getDirection("right")}: auto;
    svg {
        position: absolute;
        ${getDirection("left")}: 0;
        top: 50%;
        margin: 1px 0 0;
        transform: translateY(-50%);
    }
    &:hover {
        text-decoration: underline;
    }
`;

const linkHasIconRightStyles = css`
    position: relative;
    padding-${getDirection("left")}: auto;
    padding-${getDirection("right")}: 28px; /* 20px (icon size) + 8px (spacing) */
    svg {
        margin-top: 5px;
    }
    ${IconWrapper} {
        position: absolute;
        ${getDirection("right")}: 0;
        top: 50%;
        transform: translateY(-50%);
        svg {
            margin-top: 2px;
        }
    }
`;

/* BUTTON STYLED AS A LINK */

type LinkType = {
    underline?: boolean;
    negative?: boolean;
    hasIconRight?: boolean;
    inlineIcon?: boolean;
    position?: string;
    hasLightColor?: boolean;
};

export const Link = styled.button<{ hasSmallText?: boolean } & ButtonType & LinkType>`
    min-width: 0;
    max-width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: ${getDirection("left")};
    color: ${({ theme, negative }) => (negative ? theme.color.lightest : theme.color.dark)};
    white-space: nowrap;
    background-color: transparent;
    border: none;
    vertical-align: middle;
    ${position};
    ${space};

    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `};
    ${(props) => props.hasIcon && linkHasIconStyles};
    ${(props) => props.hasIconRight && linkHasIconRightStyles};
    ${({ theme }) =>
        theme.isLexus &&
        css`
            font-family: ${(props) => props.theme.fontFamily.regular};
            font-size: 17px; /* lexus uses px values */
            padding-${getDirection("left")}: 26px;
            border: none;
            text-transform: none;
            &::after {
                display: none;
            }
        `};
    ${({ inlineIcon, theme }) =>
        inlineIcon &&
        css`
            padding-left: 0;
            svg {
                position: absolute;
                left: auto;
                right: auto;
                margin-${getDirection("left")}: ${theme.space[2]}px;
            }
        `};

    /* Fix style disclaimer */
    ${Type.Medium} + svg {
        position: absolute;
        ${getDirection("left")}: 0;
    }

    ${({ hasSmallText }) =>
        hasSmallText &&
        css`
            font-size: 1.3rem;
            line-height: 1.8;
        `};

    ${({ hasLightColor }) =>
        hasLightColor &&
        css`
            color: ${({ theme }) => theme.colors.grey4};
        `};

    &:disabled {
        text-decoration: none;
        background-color: transparent !important;
        opacity: 0.5;
    }
`;

/* REMOVE BUTTON BASED ON BUTTON.LINK */

export const Remove = styled(Link)`
    display: flex;
    justify-content: center;
    padding: 13px;
    color: ${({ theme }) => theme.color.grey5};
    background-color: transparent;
    ${({ theme }) =>
        theme.isLexus
            ? css`
                margin-top: 10px;
                padding-${getDirection("left")}: 26px;
                justify-content: left;
                color: ${(props) => props.theme.color.primary};
                &::after {
                    display: none;
                }
                svg {
                    display: none;
                }
            `
            : css`
                  svg {
                      margin-top: 3px;
                      margin-right: 4px;
                  }
              `};
`;

/* CIRCLED BUTTON */

export const Round = styled.button`
    display: flex;
    position: relative;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;
    text-align: center;
    border: none;
    border-radius: 50%;
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

/* GREY COLOURED BUTTON */

export const Grey = styled(Button)`
    display: flex;
    max-height: ${(props) => (props.size === "sm" ? 36 : 44)}px;
    padding: ${(props) => (props.size === "sm" ? "8px 20px" : "10px 30px")};
    font-size: 1.3rem;
    line-height: 1.5;
    border-radius: 20px;
    ${({ theme }) => css`
        font-family: ${theme.fontFamily.book};
        color: ${theme.color.dark}
        background-color: ${theme.color.grey1};
    `};
`;

/* CTA ICON WRAPPER */

export const CtaIconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-${getDirection("right")}: 12px;
    color: ${({ theme }) => theme.color.lightest};
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 50%;
`;

export const EllipsisText = styled.span<DisplayProps>`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${display}
`;

export const Clamp = styled.span`
    display: block;
    min-width: 75px;
    max-height: 34px;
    line-height: 1;
    white-space: normal;
    @media ${getBreakpoint("down", "lg")} {
        display: block;
        width: 100%;
        min-width: auto;
        overflow: hidden;
        /* stylelint-disable */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* stylelint-enable */
    }
`;

export default Button;
