import { useMemo } from "react";
import type { CarFilterDispatchType } from "../../redux/store";
import { useCarFilterSelector } from "../../redux/store";
import type { LabelReturnType } from "../../../../shared-logic/features/filters/utils/filterLabelUtils";
import type { CarFilterIdType } from "../../../shared-logic/types/UscCommonTypes";
import { getCarFilterLabels, getFilterReset, isFilterActive } from "../../utils/filters";
import { useDispatch } from "../../../../shared-logic/redux/store";

type UseSentenceLabelType = {
    leftLabel: string;
    rightLabel: string;
    midLabel: LabelReturnType;
    removeFilter: () => void;
    isActive: boolean;
    filterCount: number; // Filter count is currently 1 for min max sliders even if both min and max are set. Seems like the most logical solution.
};

/**
 * Get an object containing labels, actions and derived properties related to the filter.
 */
const useFilterLabels = (filterId: CarFilterIdType): UseSentenceLabelType => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilters = useCarFilterSelector((state) => state.carFilters);
    const { leftLabel, rightLabel } = carFilters[filterId].sentenceConfig;
    const isActive = isFilterActive(filterId, carFilters);
    const { midLabel, filterCount } = getCarFilterLabels(filterId, carFilters, commonSettings);

    const removeFilter = (): void => getFilterReset(filterId, dispatch);

    return useMemo(
        () => ({
            leftLabel,
            rightLabel,
            midLabel,
            removeFilter,
            isActive,
            filterCount,
        }),
        [midLabel, isActive, filterCount], // eslint-disable-line react-hooks/exhaustive-deps
    );
};

export default useFilterLabels;
