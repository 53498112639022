export enum RetailerIframeMessageEventType {
    // Outgoing messages (to app)
    IFrameActivity = "dxp-screens-iframe-activity",
    IFrameRegisterUrl = "dxp-screens-iframe-register-url",
    IFrameNuscMetadata = "dxp-screens-iframe-nusc-metadata",
    // Incoming messages (from app)
    GoHome = "dxp-screens-go-home", // dxp-screens v1 only
    OpenDebugModal = "dxp-screens-open-debug-modal",
    UpdateNavBox = "dxp-screens-update-nav-box",
}

export enum RetailerCarConfigTypes {
    Build = "build",
    Aph = "apheleia-carconfig",
    AphLbx = "apheleia-carconfig-lbx",
}
