import styled, { css } from "styled-components";
import { Textload } from "../utils/Skeleton.styles";
import type { TextVariantsType } from "./Text";
import type { UscColorType, UscSpacingType } from "../Variables.types";
import { UscColorProps, UscSpacingProps } from "../Variables.types";

// STYLES
export type SpacingType = {
    m?: UscSpacingType;
    mt?: UscSpacingType;
    mb?: UscSpacingType;
    ml?: UscSpacingType;
    mr?: UscSpacingType;
    mx?: UscSpacingType;
    my?: UscSpacingType;
};

const globalStyle = css<{ color?: UscColorType } & SpacingType>`
    --_font-family: var(--font-book);
    --_text-transform: none;

    margin-block: 0;
    text-transform: var(--_text-transform, none);
    color: var(--color-primary-black);

    @supports not (margin-block) {
        margin-top: 0;
        margin-bottom: 0;
    }

    ${({ color }) =>
        color &&
        css`
            color: var(${UscColorProps[color]});
        `};

    ${(props) =>
        props.m &&
        css`
            margin: var(${UscSpacingProps[props.m]});
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: var(${UscSpacingProps[props.mt]});
        `}
    ${(props) =>
        props.mb &&
        css`
            margin-bottom: var(${UscSpacingProps[props.mb]}) !important;
        `}
    ${(props) =>
        props.ml &&
        css`
            margin-inline-start: var(${UscSpacingProps[props.ml]});
        `}
    ${(props) =>
        props.mr &&
        css`
            margin-inline-end: var(${UscSpacingProps[props.mr]});
        `}
    ${(props) =>
        props.mx &&
        css`
            margin-inline: var(${UscSpacingProps[props.mx]});
        `}
    ${(props) =>
        props.my &&
        css`
            margin-block: var(${UscSpacingProps[props.my]});
        `}
`;

const titleBook = css`
    ${globalStyle};
    /* stylelint-disable-next-line order/order */
    --_text-transform: uppercase;
    font-family: var(--font-book);
    font-size: 4rem;
    line-height: 4.8rem;
`;

const headerSemibold = css`
    ${globalStyle};

    font-family: var(--font-semi-bold);
    font-size: 3.4rem;
    font-weight: 600;
    line-height: 4rem;
`;

const headerBook = css`
    ${globalStyle};

    font-family: var(--font-book);
    font-size: 3.4rem;
    line-height: 4rem;
`;

const headerRegular = css`
    ${globalStyle};

    font-family: var(--font-regular);
    font-size: 2.2rem;
    line-height: 2.8rem;

    @media screen and (min-width: 1440px) {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }
`;

const subheaderRegular = css`
    ${globalStyle};

    font-family: var(--font-regular);
    font-size: 2rem;
    line-height: 2.4rem;

    @media screen and (min-width: 1440px) {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }
`;

const cardHeaderRegular = css`
    ${globalStyle};

    font-family: var(--font-regular);
    font-size: 2rem;
    line-height: 2.4rem;
`;

const cardSubheaderSemibold = css`
    ${globalStyle};

    font-family: var(--font-semi-bold);
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.4rem;
`;

const headerBodyRegular = css`
    ${globalStyle};

    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

const bodyBook = css`
    ${globalStyle};
    font-family: var(--font-book);
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

const bodyRegular = css`
    ${globalStyle};
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 1.5;
`;

const bodySemibold = css`
    ${globalStyle};
    font-family: var(--font-semi-bold);
    font-size: 1.6rem;
    line-height: 2.4rem;
`;

const bodySmallBook = css`
    ${globalStyle};
    font-family: var(--font-book);
    font-size: 1.3rem;
    line-height: 2rem;
`;

const bodySmallRegular = css`
    ${globalStyle};
    font-family: var(--font-regular);
    font-size: 1.3rem;
    line-height: 1.5;
`;

const bodySmallSemibold = css`
    ${globalStyle};
    font-family: var(--font-semi-bold);
    font-size: 1.3rem;
    line-height: 2rem;
`;

const supportSemibold = css`
    ${globalStyle};
    /* stylelint-disable-next-line order/order */
    --_text-transform: uppercase;

    font-family: var(--font-semi-bold);
    font-size: 1.3rem;
    line-height: 2rem;
`;

const buttonBook = css`
    ${globalStyle};
    font-family: var(--font-book);
    font-size: 1.6rem;
    line-height: 1.8rem;
`;

const buttonRegular = css`
    ${globalStyle};
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 1.8rem;
`;

const buttonSemiBold = css`
    ${globalStyle};
    font-family: var(--font-semi-bold);
    font-size: 1.6rem;
    line-height: 1.8rem;
`;

const fuelcell = css`
    ${supportSemibold};
    color: var(--color-indigo);
`;

const hybrid = css`
    ${supportSemibold};
    color: var(--color-blue);
`;

// COMPONENT
export const TextVariants = {
    h1: titleBook,
    "h2-semi-bold": headerSemibold,
    "h2-book": headerBook,
    h3: headerRegular,
    h4: subheaderRegular,
    "h5-semi-bold": cardSubheaderSemibold,
    "h5-regular": cardHeaderRegular,
    h6: headerBodyRegular,
    h7: supportSemibold,
    "body-book": bodyBook,
    "body-regular": bodyRegular,
    "body-semibold": bodySemibold,
    "body-small-book": bodySmallBook,
    "body-small-regular": bodySmallRegular,
    "body-small-semibold": bodySmallSemibold,
    "support-semibold": supportSemibold,
    "button-book": buttonBook,
    "button-regular": buttonRegular,
    "button-semibold": buttonSemiBold,
    fuelcell,
    hybrid,
};

const TextVariant = styled.span<{
    variant: TextVariantsType;
    mobileVariant?: TextVariantsType;
    color?: UscColorType;
    spacing?: SpacingType;
    skeleton?: boolean;
    center?: boolean;
}>`
    ${({ skeleton, color }) =>
        skeleton
            ? css`
                  ${Textload};
                  width: 100%;
                  height: 1em;
              `
            : css`
                  ${color &&
                  css`
                      color: var(${UscColorProps[color]});
                  `}
              `}
    ${({ mobileVariant, variant }) =>
        mobileVariant
            ? css`
                  ${TextVariants[mobileVariant]};

                  @media screen and (min-width: 1440px) {
                      ${TextVariants[variant]};
                  }
              `
            : css`
                  ${TextVariants[variant]};
              `}
    
    ${(props) =>
        props.spacing &&
        props.spacing.m &&
        css`
            margin: var(${UscSpacingProps[props.spacing.m]});
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.mt &&
        css`
            margin-top: var(${UscSpacingProps[props.spacing.mt]});
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.mb &&
        css`
            margin-bottom: var(${UscSpacingProps[props.spacing.mb]}) !important;
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.ml &&
        css`
            margin-inline-start: var(${UscSpacingProps[props.spacing.ml]});
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.mr &&
        css`
            margin-inline-end: var(${UscSpacingProps[props.spacing.mr]});
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.mx &&
        css`
            margin-inline: var(${UscSpacingProps[props.spacing.mx]});
        `}
    ${(props) =>
        props.spacing &&
        props.spacing.my &&
        css`
            margin-block: var(${UscSpacingProps[props.spacing.my]});
        `};
    ${(props) =>
        props.center &&
        css`
            text-align: center;
        `};
`;

export default TextVariant;
