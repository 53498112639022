import styled, { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import type { ColorType } from "../theme/toyotaTheme";
import { theme } from "../theme/toyotaTheme";

export const GreyBackground = styled.div<SpaceProps>`
    background-color: ${theme.colors.grey1};
    ${space};
`;

export const Background = styled.div<{ backgroundColor: ColorType } & SpaceProps>`
    ${space};

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${theme.colors[backgroundColor]};
        `};
`;

// Animation to get a moving loading background. Based on car-filter v1 layout.
export const loadingBackgroundCss = css`
    background: linear-gradient(
        45deg,
        ${(props) => props.theme.color.grey1} 0%,
        ${(props) => props.theme.color.lightest} 33%,
        ${(props) => props.theme.color.grey1} 66%,
        ${(props) => props.theme.color.lightest} 100%
    );
    background-size: 400% 400%;
    animation: AnimationName 2s linear infinite;
    @keyframes AnimationName {
        0% {
            background-position: 100% 0%;
        }
        100% {
            background-position: 15% 100%;
        }
    }
`;

export default Background;
