import styled, { css } from "styled-components";
import type { DisplayProps, SpaceProps, FontSizeProps, LineHeightProps } from "styled-system";
import { compose, color, space, display, fontSize, lineHeight } from "styled-system";
import type { ThemeTypedColorProps } from "../../themes/common";
import { getBreakpoint, getDirection } from "../../themes/common";

type BaseProps = { weight?: string; uppercase?: boolean } & SpaceProps &
    ThemeTypedColorProps &
    DisplayProps &
    FontSizeProps &
    LineHeightProps;

export const Base = css<BaseProps>`
    font-family: ${({ theme }) => theme.fontFamily.displayRegular};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1.1;
    text-align: ${getDirection("left")};
    text-indent: 0;
    text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
    color: ${({ theme }) => theme.color.dark};
    ${({ weight, theme }) =>
        weight === "bold" &&
        css`
            font-family: ${theme.fontFamily.bold};
        `};
`;

const OverrideBase = compose(display, space, fontSize, lineHeight, color);

export const H1 = styled.h1<BaseProps>`
    ${Base};
    font-size: 4.4rem;
    ${OverrideBase};
`;

export const H2 = styled.h2<BaseProps>`
    ${Base};
    font-size: 2.4rem;
    @media ${getBreakpoint("up", "md")} {
        font-size: 3.4rem;
    }
    ${OverrideBase};
`;

export const H3 = styled.h3<BaseProps>`
    ${Base};
    font-size: 2.1rem;
    ${OverrideBase};
`;

export const H4 = styled.h4<BaseProps>`
    ${Base};
    font-size: 1.9rem;
    ${OverrideBase};
`;

export const H5 = styled.h5<BaseProps>`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 1.7rem;
    ${OverrideBase};
`;

export const H6 = styled.h6<BaseProps>`
    ${Base};
    font-size: 1.2rem;
    ${OverrideBase};
`;

export const P = styled.p<BaseProps>`
    ${Base};
    font-size: 1.5rem;
    color: ${({ theme }) => theme.color.grey5};
    ${OverrideBase};
`;

export const Small = styled.p<BaseProps>`
    ${Base};
    font-size: 1.2rem;
    color: ${({ theme }) => theme.color.grey3};
    ${OverrideBase};
`;

export const Medium = styled.p`
    ${Base};
    font-size: 1.3rem;
    color: ${({ theme }) => theme.color.grey3};
    ${OverrideBase};
`;

export const ItemTitle = styled.p<SpaceProps>`
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 1.3rem;
    line-height: 1.1;
    ${space};
`;

export const Strong = styled.strong`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-weight: normal;
    ${OverrideBase}
`;

export const Light = styled.strong`
    ${Base};
    font-family: ${({ theme }) => theme.fontFamily.light};
    ${OverrideBase};
`;

// Specific Header types cfr Styleguide
export const HeaderMedium = styled.h2`
    display: block;
    margin: 32px auto;
    font-family: ${({ theme }) => theme.fonts.book};
    font-size: 2.2rem;
    line-height: 2.8rem;
    text-transform: uppercase;

    @media ${getBreakpoint("up", "md")} {
        margin: 48px auto;
        font-size: 4.4rem;
        line-height: 4.8rem;
    }
`;
