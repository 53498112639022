import React, { useEffect, useMemo } from "react";
import type { CarFilterIdType } from "../../../../../shared-logic/types/UscCommonTypes";
import useFilterLabels from "../../../../hooks/active-filters/useFilterLabels";
import FilterPillButton from "../../common/FilterPillButton";
import * as Styles from "./styles/ActiveFilterSliderStyles";

type ActiveFilterSliderPillType = { filterId: CarFilterIdType; update?: () => void };

const ActiveFilterSliderPill = ({ filterId, update }: ActiveFilterSliderPillType): JSX.Element => {
    const { midLabel, removeFilter } = useFilterLabels(filterId);

    // This is a shortcut to support rerendering the parent container when the size of this component changes.
    // This is required because of the way the slider is implemented, and it seems like this is the easiest way to do it.
    useEffect(() => update && update(), [midLabel, update]);

    return useMemo(
        () => (
            <Styles.PillWrapper>
                <FilterPillButton isPrimary closeFn={removeFilter}>
                    {midLabel.map(({ text, strikethrough }, index) => {
                        const parsedText = text.replace(/\s/g, "&nbsp;");
                        return (
                            <Styles.PillText
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${text}${index}`}
                                strikethrough={strikethrough}
                                dangerouslySetInnerHTML={{ __html: parsedText }}
                                aria-label={text}
                            />
                        );
                    })}
                </FilterPillButton>
            </Styles.PillWrapper>
        ),
        [midLabel],
    );
};

export default ActiveFilterSliderPill;
