import type { FC } from "react";
import React from "react";
import { shallowEqual } from "react-redux";
import { DisclaimerLocation } from "../../../../../common-deprecated/types/CommonTypes";
import type { CarFilterDisclaimerType } from "../../../utils/disclaimerUtils";
import { getDisclaimerSelector } from "../../../utils/disclaimerUtils";
import { useCarFilterSelector } from "../../../redux/store";
import type { TextVariantsType } from "../../../../shared-components/toyota/styles/atoms/Text";
import Text from "../../../../shared-components/toyota/styles/atoms/Text";

type Props = { disclaimerType: CarFilterDisclaimerType; vehicleForSaleId: string; reference?: string };
const InlineDisclaimer: FC<Props> = ({ disclaimerType, vehicleForSaleId, reference }) => {
    const disclaimers = useCarFilterSelector(getDisclaimerSelector(vehicleForSaleId), shallowEqual);
    const inlineDisclaimer = disclaimers.find(
        (disclaimer) =>
            disclaimer.location === DisclaimerLocation.Inline &&
            disclaimer.type === disclaimerType &&
            disclaimer.reference === reference,
    );

    const useBoldText = useCarFilterSelector(
        ({ carFilterSettings, carFilters }) => carFilterSettings.financeUseBoldText[carFilters.currentFilter],
    );

    const textVariant: TextVariantsType = useBoldText ? "body-regular" : "body-small-regular";

    if (!inlineDisclaimer) return null;

    return (
        <Text
            tag="div"
            color="grey-7"
            variant={textVariant}
            spacing={{ mb: "spacing-2", mt: useBoldText ? "spacing-0" : "spacing-2" }}
        >
            <span dangerouslySetInnerHTML={{ __html: inlineDisclaimer.value }} />
        </Text>
    );
};

export default InlineDisclaimer;
