import type { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import type { Dispatch, ReducersMapObject } from "redux";
import type { CommonSettingsActionsType } from "./actions/CommonSettingsActions";
import type { ModalReducerType } from "./reducers/ModalReducer";
import type { CommonSettingsType } from "../settings/fetchCommonSettings";
import type { UtilStateType } from "./reducers/UtilReducer";
import type { ModalActionsType } from "./actions/ModalActions";
import type { UtilActionsType } from "./actions/UtilActions";
import type { NotificationReducerType } from "./reducers/NotificationReducer";
import type { NotificationActionsType } from "./actions/NotificationActions";
import CommonSettingsReducer from "./reducers/CommonSettingsReducer";
import UtilReducer from "./reducers/UtilReducer";
import ModalReducer from "./reducers/ModalReducer";
import notificationReducer from "./reducers/NotificationReducer";
import { getInitStoreByReducers } from "./storeHelpers";

export type CommonActionsType =
    | CommonSettingsActionsType
    | UtilActionsType
    | ModalActionsType
    | NotificationActionsType;

export type CommonDispatchType = Dispatch<CommonActionsType>;

export type CommonReducerType = {
    commonSettings: CommonSettingsType;
    utils: UtilStateType;
    modal: ModalReducerType;
    notification?: NotificationReducerType;
};

export const commonReducers: ReducersMapObject<CommonReducerType, any> = {
    commonSettings: CommonSettingsReducer,
    utils: UtilReducer,
    modal: ModalReducer,
    notification: notificationReducer,
};

export const useCommonDispatch = useDispatch<CommonDispatchType>;
export const useCommonSelector: TypedUseSelectorHook<CommonReducerType> = useSelector;

/**
 * ⚠️ This should only be used in common tests ⚠️
 */
export const commonClientStore = getInitStoreByReducers(commonReducers);
