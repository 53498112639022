// Modals shared across multiple components
import type { ReactNode } from "react";
import type { ErrorLogType, FinanceInfoDisclaimerType } from "../types/CommonTypes";
import type { QueryBuilderStateType, ToolsModalItemType } from "../features/new-cars/debug-modal/components/ToolsModal";

// Modals shared across multiple components
export const MODAL_FINANCE_DISCLAIMER = "financeDisclaimer" as const;

// Generic modals
export const MODAL_OK = "ok" as const;
export const MODAL_CONTENT = "content" as const;

// Status modals
export const MODAL_VERSION = "version" as const;
export const MODAL_REQUEST_LOG = "reqLog" as const;
export const MODAL_CACHE_CLEAR = "cacheClear" as const;
export const MODAL_ERRORS = "errors" as const;
export const MODAL_TOOLS = "tools" as const;

export type CommonModalIdsType =
    | typeof MODAL_FINANCE_DISCLAIMER
    | typeof MODAL_OK
    | typeof MODAL_CONTENT
    | typeof MODAL_ERRORS;

export type DebugModalIdsType =
    | typeof MODAL_VERSION
    | typeof MODAL_TOOLS
    | typeof MODAL_REQUEST_LOG
    | typeof MODAL_CACHE_CLEAR;

export type ModalFinanceDisclaimerType = {
    type: typeof MODAL_FINANCE_DISCLAIMER;
    disclaimer: FinanceInfoDisclaimerType;
    productName: string;
};

export type ModalContentType = {
    type: typeof MODAL_CONTENT;
    content: string | ReactNode; // TODO Is this working as intended? React tree is being propagated through an action, which will probably crash redux dev tools
    useHTML?: boolean;
    onClose?: () => void;
    title?: string | JSX.Element;
    footer?: string | JSX.Element;
};

export type ModalOkType = { type: typeof MODAL_OK; contentLabel: string; titleLabel: string; okLabel: string };

export type ModalCacheClearType = { type: typeof MODAL_CACHE_CLEAR };
export type ModalErrorsType = {
    type: typeof MODAL_ERRORS;
    componentName: string;
    errors: ErrorLogType[];
};
export type ModalReqLogType = { type: typeof MODAL_REQUEST_LOG };
export type ModalToolsType = {
    type: typeof MODAL_TOOLS;
    componentName: string;
    extraItems: ToolsModalItemType[];
    extraQueryBuilderOptions?: QueryBuilderStateType;
};

export type ModalVersionType = { type: typeof MODAL_VERSION };

export type CommonModalsType = ModalFinanceDisclaimerType | ModalOkType | ModalContentType | ModalErrorsType;

export type DebugModalsType = ModalVersionType | ModalToolsType | ModalReqLogType | ModalCacheClearType;
