import React from "react";
import * as Modal from "../../../styles/v2/toyota/globals/Modal";
import * as Styles from "./styles/ErrorsModal";
import ModalBaseView from "../aem/ModalBaseView";
import type { ModalErrorsType } from "../../../utils/modalConstants";
import type { ModalViewType } from "../../../types/CommonTypes";
import Icon from "../../../styles/v2/toyota/globals/Icon";
import IconWrapper from "../../../styles/v2/toyota/globals/IconWrapper";
import { useCommonLabel } from "../../../utils/commonLabels";

const ErrorsModal = (props: ModalViewType<ModalErrorsType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { errors, componentName } = modalSettings;
    const closeLabel = useCommonLabel("close");

    return (
        <ModalBaseView
            modalIdType={modalSettings.type}
            onClose={close}
            show={show}
            size="md"
            // Focus trap is disabled here because this crashes when the error modal is rendered server side
            focusTrap={false}
        >
            <Modal.Header hasBackground>
                <Modal.Title>Errors - {componentName}</Modal.Title>
                <Modal.Button type="button" aria-label={closeLabel} onClick={close}>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>

            <Modal.Body>
                {/* This is a bit of a hacky way to display more information for certain errors,
                    in case more descriptions are ever needed, consider doing this differently. */}
                {!!errors.find((error) =>
                    error.message.includes("One or more technical specifications were missing for the vehicle"),
                ) && (
                    <Styles.Description>
                        Missing technical specifications will break all filters for a specific model.
                        <br />
                        These issues should be fixed in CarDB.
                    </Styles.Description>
                )}
                <Styles.UnorderedList>
                    {errors.map((error, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <Styles.ListItem key={index}>
                                <strong>{error.title}</strong>
                                <Styles.HiddenModelId>{error.id}</Styles.HiddenModelId>
                                <hr />
                                {Array.isArray(error.message) ? (
                                    <Styles.UnorderedList>
                                        {error.message.map((errorMessage, errorIndex) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <li key={errorIndex}>{errorMessage}</li>
                                        ))}
                                    </Styles.UnorderedList>
                                ) : (
                                    error.message
                                )}
                                <br />
                                <em>{error.innerMessage}</em>
                            </Styles.ListItem>
                        );
                    })}
                </Styles.UnorderedList>
            </Modal.Body>
        </ModalBaseView>
    );
};

export default ErrorsModal;
