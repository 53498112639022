import type { DisclaimerType } from "../../../common-deprecated/types/CommonTypes";
import { DisclaimerLocation } from "../../../common-deprecated/types/CommonTypes";
import type { CarFilterStateType } from "../redux/store";
import { getCommonOptionalLabel } from "../../../common-deprecated/utils/commonLabels";
import {
    getUscCashPriceFromVehicle,
    showUscInlinePriceDisclaimer,
    uscPriceIsExclVat,
    uscPriceIsInclVat,
} from "../../shared-logic/utils/uscPriceUtils";
import { showCarFilterAemMonthlyRates } from "./carFilterFinanceSettingUtils";
import { UscEcoValueMeasureSystem, uscNedcWltpDisclaimersLabelSelector } from "../../shared-logic/utils/uscEcoUtils";
import { getDisclaimerLinkLabel } from "../../shared-logic/utils/uscUtils";
import { AemFmComponent } from "../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import { UscContext } from "../../shared-logic/types/UscCommonTypes";
import { getUscLegalSpecConfigs } from "../../shared-logic/utils/uscSpecUtils";

export enum CarFilterDisclaimerType {
    Cash = "cash",
    Finance = "finance",
    Spec = "spec",
}
/**
 * Returns a list of disclaimers that need to be referenced.
 *
 * Because Car-Filter has a dynamic list of items and we render 1 set of disclaimers for each car individually
 * we specifically use a wrapper for the disclaimer selector.
 * Do not blindly copy paste this for other use cases, try to use disclaimer references instead (see Compare USC).
 */
export const getDisclaimerSelector =
    (vehicleForSaleId: string) =>
    (state: CarFilterStateType): DisclaimerType<CarFilterDisclaimerType>[] => {
        const { commonSettings, carResults, carFilters, carFilterSettings } = state;
        const { country } = commonSettings;
        const wltpDisclaimers = uscNedcWltpDisclaimersLabelSelector(state);

        const carResult = carResults.dfCarResults.find((result) => result.result?.id === vehicleForSaleId);
        if (!carResult?.result) return [];

        const dropdownSpecConfigs = carFilterSettings.dropdownSpecConfig[carFilters.currentFilter];
        const legalSpecConfigs = getUscLegalSpecConfigs(
            carFilterSettings.legalSpecConfig[carFilters.currentFilter],
            carResult.result.product.engine.marketingFuelType.code,
        );

        const disclaimers: DisclaimerType<CarFilterDisclaimerType>[] = [];

        const { financeInfo, monthlyPrice } = carResult.result;
        const cashPrice = getUscCashPriceFromVehicle(carResult.result);
        const monthlyDisclaimer = financeInfo?.disclaimer?.value;

        // Cash disclaimer
        const cashDisclaimer = uscPriceIsExclVat(cashPrice)
            ? getCommonOptionalLabel(state, "uscExclVatCashPriceDisclaimer")
            : uscPriceIsInclVat(cashPrice)
              ? getCommonOptionalLabel(state, "uscInclVatCashPriceDisclaimer")
              : getCommonOptionalLabel(state, "uscCashPriceDisclaimer");

        if (cashDisclaimer) {
            let location = DisclaimerLocation.InfoIcon;
            if (showUscInlinePriceDisclaimer(country) && !monthlyDisclaimer) location = DisclaimerLocation.Inline;
            disclaimers.push({ type: CarFilterDisclaimerType.Cash, value: cashDisclaimer, location });
        }

        // Finance disclaimer
        if (
            showCarFilterAemMonthlyRates(commonSettings, carFilterSettings, carFilters.currentFilter) &&
            monthlyDisclaimer
        ) {
            const disclaimerLabel = financeInfo
                ? getDisclaimerLinkLabel(
                      commonSettings,
                      carFilters.currentFilter === UscContext.Used
                          ? AemFmComponent.CarFilterUsed
                          : AemFmComponent.CarFilterStock,
                      monthlyPrice,
                      financeInfo,
                      carFilters.currentFilter,
                  )
                : "";

            let location = DisclaimerLocation.InfoIcon;
            if (disclaimerLabel) location = DisclaimerLocation.InfoText;
            if (showUscInlinePriceDisclaimer(country)) location = DisclaimerLocation.Inline;

            disclaimers.push({
                type: CarFilterDisclaimerType.Finance,
                value: monthlyDisclaimer,
                containsHtml: true,
                location,
                infoText: disclaimerLabel,
            });
        }

        // WLTP/NEDC disclaimers
        if (wltpDisclaimers.nedcDisclaimer) {
            disclaimers.push({
                type: CarFilterDisclaimerType.Spec,
                reference: UscEcoValueMeasureSystem.Nedc,
                value: wltpDisclaimers.nedcDisclaimer,
                location: DisclaimerLocation.InfoIcon,
            });
        }
        if (wltpDisclaimers.wltpDisclaimer) {
            disclaimers.push({
                type: CarFilterDisclaimerType.Spec,
                reference: UscEcoValueMeasureSystem.Wltp,
                value: wltpDisclaimers.wltpDisclaimer,
                location: DisclaimerLocation.InfoIcon,
            });
        }

        // Spec disclaimers
        // Loop over all dropdown and legal specs and add the tooltip as a disclaimer.
        [...dropdownSpecConfigs, ...legalSpecConfigs].forEach(
            (spec) =>
                spec.tooltip &&
                disclaimers.push({
                    type: CarFilterDisclaimerType.Spec,
                    reference: spec.tooltipReference,
                    value: spec.tooltip,
                    location: DisclaimerLocation.InfoIcon,
                    containsHtml: true,
                }),
        );

        return disclaimers;
    };

/**
 * Wrapper for the disclaimer selector, allows to easily retrieve a specific disclaimer config.
 */
export const getCarFilterDisclaimerSelector =
    (vehicleForSaleId: string, type: CarFilterDisclaimerType, reference?: string | null) =>
    (state: CarFilterStateType): DisclaimerType<CarFilterDisclaimerType> | null => {
        const disclaimers = getDisclaimerSelector(vehicleForSaleId)(state);

        return disclaimers.find((disclaimer) => disclaimer.type === type && disclaimer.reference === reference) || null;
    };
