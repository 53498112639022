import { useEffect } from "react";
import type { CarFilterParamType } from "../utils/params";
import { getSavedCars } from "../utils/localStorage";
import { setSavedCars, showOnlySavedCars } from "../redux/actions/CarFiltersActions";
import type { CarFilterDispatchType } from "../redux/store";
import { useCarFilterSelector } from "../redux/store";
import { replaceQueryParams } from "../../../common-deprecated/History";
import { useDispatch } from "../../../shared-logic/redux/store";

/**
 * Used in CarFilter.
 */
const useCarFilter = (): void => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const showSavedCars = useCarFilterSelector((state) => state.carFilters.savedCars.showOnlySavedCars);
    const { page } = useCarFilterSelector((state) => state.carFilters.pagination);

    // Read saved cars from LocalStorage.
    // If the user has saved cars, also read showSavedCars state from query/body and set if required.
    useEffect(() => {
        const { country, language, query } = commonSettings;
        const body = commonSettings.body as CarFilterParamType;
        const carIds = getSavedCars({ context: currentFilter, country, language });
        if (carIds.length) {
            dispatch(setSavedCars(currentFilter, carIds));
            if (!showSavedCars && (body.showSavedCars || query.showSavedCars)) dispatch(showOnlySavedCars(true));
        }
    }, []);

    // Check if the pagination page in the reducer has changed from the query.
    // This can happen when the page query param contained an invalid value (negative number/NaN).
    useEffect(() => {
        const { page: queryPage } = commonSettings.query;

        if (page !== queryPage) replaceQueryParams({ page: page > 1 ? page : null });
    }, [page, commonSettings.query]);
};
export default useCarFilter;
