import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { useBreakpoint } from "../themes/common";
import { useWindowDimensionsContext } from "../containers/WindowDimensionsProvider";

export type UseShouldShowModalInsteadType = {
    tooltipVisible: boolean;
    modalVisible: boolean;
};

/**
 * Determine if a modal should be shown for the given tooltip ref.
 * Returns booleans for both the modal and tooltip being shown.
 *
 * @param tooltipRef - A ref to the tooltip
 * @param disable - When true the modal will never be shown
 */
export const useShouldShowModal = (
    tooltipRef: RefObject<HTMLElement>,
    disable = false,
    forceShowModal?: boolean,
): UseShouldShowModalInsteadType => {
    const [visibleType, setVisibleType] = useState<"modal" | "tooltip">("tooltip");
    const windowHeight = useWindowDimensionsContext().height;
    const isMobile = useBreakpoint("down", "sm");

    useEffect(() => {
        const showModal = (): void => {
            if (disable) {
                setVisibleType("tooltip");
                return;
            }

            setVisibleType("modal");
        };

        if (isMobile || forceShowModal) {
            showModal();
        } else if (tooltipRef.current) {
            // Show a modal if the tooltip is bigger than half of the available window height.
            const boundingClientRect = tooltipRef.current.getBoundingClientRect();
            if (windowHeight / boundingClientRect.height < 2) showModal();
            else setVisibleType("tooltip"); // element flashes when default value is true
        } else {
            setVisibleType("tooltip");
        }
    }, [windowHeight, disable, isMobile]); //eslint-disable-line react-hooks/exhaustive-deps

    return { tooltipVisible: visibleType === "tooltip", modalVisible: visibleType === "modal" };
};
