import type { CarFilterIdType, UsedCarResultType } from "../../shared-logic/types/UscCommonTypes";
import { StockCarFilterId, UsedCarFilterId, UscContext } from "../../shared-logic/types/UscCommonTypes";
import type { CarFiltersReducerType } from "../redux/reducers/CarFiltersReducer";
import { getCurrentFilters, isMinMaxSlider, isMultipleChoice, mainFilterSelector } from "./filters";
import Debug from "../../../common-deprecated/Debug";
import type { AEMTrackingDataLayerType, AEMTrackingShortEventType } from "../../../common-deprecated/utils/tracking";
import { trackEvent } from "../../../common-deprecated/utils/tracking";
import { COMPONENT_NAME_MODEL_FILTER } from "../../../common-deprecated/AnalyticsConstants";
import type { CarFilterStateType } from "../redux/store";
import type { MultipleChoiceValueType } from "../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

// ----------------------------------------------------------------------
// Types and mappings
// ----------------------------------------------------------------------
type CarFilterTrackType = {
    cartype: UscContext;
    carcategory?: string;
    budget?: string;
    location?: string;
    fuel?: string;
    transmission?: string;
    equipment?: string;
    ecolabel?: string;
    warranty?: string;
    "license-plate"?: string;
    seats?: string;
    doors?: string;
    horsepower?: string;
    year?: string;
    mileage?: string;
    colour?: string;
};

const trackMappings: Record<string, keyof Omit<CarFilterTrackType, "cartype">> = {
    [UsedCarFilterId.Transmission]: "transmission",
    [UsedCarFilterId.Equipment]: "equipment",
    [UsedCarFilterId.Colour]: "colour",
    [UsedCarFilterId.EcoLabel]: "ecolabel",
    [UsedCarFilterId.Warranty]: "warranty",
    [UsedCarFilterId.License]: "license-plate",
    [UsedCarFilterId.Seats]: "seats",
    [UsedCarFilterId.Doors]: "doors",
    [UsedCarFilterId.PowerOutput]: "horsepower",
    [UsedCarFilterId.Year]: "year",
    [UsedCarFilterId.Mileage]: "mileage",
    [UsedCarFilterId.Location]: "location",
    [UsedCarFilterId.Price]: "budget",
    [StockCarFilterId.Transmission]: "transmission",
    [StockCarFilterId.Equipment]: "equipment",
    [StockCarFilterId.Colour]: "colour",
    [StockCarFilterId.Seats]: "seats",
    [StockCarFilterId.Doors]: "doors",
    [StockCarFilterId.PowerOutput]: "horsepower",
    [StockCarFilterId.Location]: "location",
    [StockCarFilterId.Price]: "budget",
};

// ----------------------------------------------------------------------
// Helper functions
// ----------------------------------------------------------------------
export const getCarFilterTrackingObject = (carFilters: CarFiltersReducerType): CarFilterTrackType => {
    const trackObject: CarFilterTrackType = { cartype: carFilters.currentFilter };

    Object.values(getCurrentFilters(carFilters.currentFilter))
        .filter((filterId) => carFilters[filterId].active)
        .forEach((filterId) => {
            if (
                (filterId === UsedCarFilterId.Price || filterId === StockCarFilterId.Price) &&
                trackMappings[filterId]
            ) {
                if (carFilters[filterId].cash.active) {
                    const { currentMinValue, currentMaxValue } = carFilters[filterId].cash;
                    trackObject[trackMappings[filterId]] = `cash-${currentMinValue}-${currentMaxValue}`;
                }
                if (carFilters[filterId].monthly.active) {
                    const { currentMinValue, currentMaxValue } = carFilters[filterId].monthly;
                    trackObject[trackMappings[filterId]] = `monthly-${currentMinValue}-${currentMaxValue}`;
                }
            } else if (
                (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) &&
                trackMappings[filterId]
            ) {
                const { dealerGroup, dealerHolding, dealer, range } = carFilters[UsedCarFilterId.Location];
                if (dealerGroup) trackObject[trackMappings[filterId]] = dealerGroup.name;
                if (dealerHolding) trackObject[trackMappings[filterId]] = dealerHolding.name;
                else if (dealer) trackObject[trackMappings[filterId]] = dealer.name;
                else if (range) trackObject[trackMappings[filterId]] = range.range !== 0 ? range.name : "national";
            } else if (isMultipleChoice(filterId) && trackMappings[filterId]) {
                trackObject[trackMappings[filterId]] = carFilters[filterId].values
                    .filter((filterValue) => filterValue.selected)
                    .map((filterValue) => filterValue.label)
                    .join("|");
            } else if (isMinMaxSlider(filterId) && trackMappings[filterId]) {
                const { currentMinValue, currentMaxValue } = carFilters[filterId];
                trackObject[trackMappings[filterId]] = `${currentMinValue}-${currentMaxValue}`;
            } else {
                Debug.error(`Untracked filter: ${filterId}`);
            }
        });

    return trackObject;
};

// ----------------------------------------------------------------------
// AEM
// ----------------------------------------------------------------------
export const getAemTrackingDefaults = (currentFilter: UscContext): Omit<AEMTrackingShortEventType, "action"> => ({
    component: COMPONENT_NAME_MODEL_FILTER, // used for both new, used & stock model/car filter
    group: `${currentFilter}-cars`,
});

export const getAemProductTrackingInfo = (
    currentFilter: UscContext,
    result: UsedCarResultType,
): Required<AEMTrackingDataLayerType["product"]> => [
    {
        category: "cars",
        subCategory: `${currentFilter}-cars`,
        productInfo: {
            productId: result.id,
            productName: result.trackingProductName,
        },
    },
];

/**
 * Track the click to open the specifications overlay (show more button).
 */
export const trackAemShowMoreCta = (
    currentFilter: UscContext,
    result: UsedCarResultType,
    action: "expand" | "reduce",
    isSimilarCar?: boolean,
): void => {
    if (isSimilarCar) {
        trackEvent({
            name: "componentevent",
            component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
            group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
            action: `similar-cars-${action}-details`,
            label: result.id,
        });
    } else {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                name: "componentevent",
                action: `${action}-search-result`,
                label: result.id,
            },
            {
                product: getAemProductTrackingInfo(currentFilter, result),
            },
        );
    }
};

type AemFilterSearchParams = {
    isMainFilter: boolean;
    context: UscContext;
    totalResultCount: number;
    filterId: string;
};

/**
 * Helper selector to retrieve helper params used for AEM car filter value tracking (see trackAemCarFilterValue).
 */
export const getAemCarFilterTrackParamsSelector =
    (filterId: CarFilterIdType) =>
    (state: CarFilterStateType): AemFilterSearchParams => ({
        filterId,
        totalResultCount: state.carResults.totalResultCount,
        isMainFilter: mainFilterSelector(state).includes(filterId),
        context: state.carFilters.currentFilter,
    });

/**
 * Track a filter value change in the AEM car-filter.
 *
 * More specifically this is used for the refine-search/standard and refine-search/advanced values.
 */
export const trackAemCarFilterValue = (params: AemFilterSearchParams, filterValue: string): void => {
    const { isMainFilter, context, filterId, totalResultCount } = params;

    trackEvent(
        {
            ...getAemTrackingDefaults(context),
            name: "searchevent",
            action: "search",
            label: isMainFilter ? "refine-search/standard" : "refine-search/advanced",
        },
        {
            workflow: {
                name: context === UscContext.Used ? "used-cars-search" : "stock-cars-search",
                conversion: 1,
                step: "refine-search",
                variant: isMainFilter ? "refine-search/standard" : "refine-search/advanced",
            },
            search: {
                type: context === UscContext.Used ? "used-cars-search" : "stock-cars-search",
                results: totalResultCount > 0 ? 1 : 0,
                query: filterValue,
                filters: filterId,
            },
        },
    );
};

// ----------------------------------------------------------------------
// Default filter tracking
// ----------------------------------------------------------------------
export const trackAemMultipleChoiceFilter = (
    trackParams: AemFilterSearchParams,
    values: MultipleChoiceValueType[],
    valueId: string,
): void => {
    const valueLabel = values.find((value) => value.id === valueId)?.label || "";
    trackAemCarFilterValue(trackParams, valueLabel);
};

export const trackAemMinMaxSliderFilter = (
    trackParams: AemFilterSearchParams,
    valueType: "min" | "max",
    value: number,
): void => {
    trackAemCarFilterValue(trackParams, `${valueType}-${value}`);
};

export const trackAemSingleValueSliderFilter = (trackParams: AemFilterSearchParams, value: number): void => {
    trackAemCarFilterValue(trackParams, String(value));
};

export const trackDeliverableCarsFilterClick = (context: UscContext): void =>
    trackEvent({
        component: "model-filter",
        group: `${context === UscContext.Used ? "used" : "stock"}-cars`,
        name: "componentevent",
        action: "include-car-deliverable",
    });

/**
 * Tracks clicking a result for car-filter and similar cars
 */
export const trackResultClick = (
    currentFilter: UscContext,
    result: UsedCarResultType,
    isSimilarCar?: boolean,
): void => {
    if (isSimilarCar) {
        // we don't use usc tracking utils here because this will include a bunch of usc specific code
        trackEvent({
            name: "ctaevent",
            component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
            group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
            action: "click-similar-cars",
            label: result.id,
        });
    } else {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                name: "workflowevent",
                action: "click-search-results",
                label: result.id,
            },
            {
                product: getAemProductTrackingInfo(currentFilter, result),
                workflow: {
                    name: currentFilter === UscContext.Used ? "used-cars-search-results" : "stock-cars-search-results",
                    step: "click-search-results",
                    conversion: 1,
                },
            },
        );
    }
};

export const trackResultImageInteraction = (
    currentFilter: UscContext,
    result: UsedCarResultType,
    isSimilarCar?: boolean,
    src?: string,
): void => {
    if (isSimilarCar) {
        trackEvent({
            name: "componentevent",
            component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
            group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
            action: "similar-cars-browse-gallery",
            label: src,
        });
    } else {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                action: "click-gallery",
                label: result.id,
            },
            { product: getAemProductTrackingInfo(currentFilter, result) },
        );
    }
};

/**
 * Track click on Contact Dealer CTA.
 */
export const trackContactDealerCta = (
    currentFilter: UscContext,
    result: UsedCarResultType,
    isSimilarCar?: boolean,
): void => {
    if (isSimilarCar) {
        trackEvent({
            name: "ctaevent",
            component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
            group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
            action: "click-similar-cars",
            label: "uc-vehicle-request",
        });
    } else {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                name: "ctaevent",
                action: "click-cta",
                label: "contact-dealer",
            },
            {
                product: getAemProductTrackingInfo(currentFilter, result),
            },
        );
    }
};

export const trackAddFavouriteClick = (
    currentFilter: UscContext,
    result: UsedCarResultType,
    isSimilarCar?: boolean,
): void => {
    if (isSimilarCar) {
        trackEvent({
            name: "ctaevent",
            component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
            group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
            action: "click-similar-cars",
            label: `${currentFilter === UscContext.Used ? "uc" : "sc"}-add-favourite-${result.product.model}`,
        });
    } else {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                action: "add-to-favourite",
                label: result.id,
            },
            {
                product: [
                    {
                        category: "cars",
                        subCategory: `${currentFilter}-cars`,
                        productInfo: {
                            productId: result.id,
                            productName: result.trackingProductName,
                        },
                    },
                ],
            },
        );
    }
};

export const trackSeeAllSimilarCarsClick = (currentFilter: UscContext): void => {
    trackEvent({
        name: "componentevent",
        component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
        group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
        action: "see-all-similar-cars",
    });
};

export const trackSimilarCarsBrowseSimilarCars = (currentFilter: UscContext): void => {
    trackEvent({
        name: "componentevent",
        component: currentFilter === UscContext.Used ? "uc-detail-page" : "sc-detail-page",
        group: currentFilter === UscContext.Used ? "used-cars" : "stock-cars",
        action: "browse-similar-cars",
    });
};
