import React, { useMemo } from "react";
import type { UseLabelType } from "../../hooks/useLabel";

/**
 * This component can be used as a simpler version of the old Message component.
 * It uses some TS generics trickery which allows them to be wrapped with component specific label types.
 * They can be re-exported like so:
 * export const TypedLabel = Label as LabelComponentType<labelKeys, Record<paramKeys, string>>;
 */

type LabelType<Label extends string, Params extends Partial<Record<string, string | number>>> = {
    label: Label;
    params?: Params;
    fallbackLabel?: Label;
    useHTML?: boolean;
};

export type LabelComponentType<
    Label extends string,
    Params extends Partial<Record<string, string | number>>,
> = React.FC<LabelType<Label, Params>>;

// ⚠️ Do not use this component without it being wrapped with custom typing, see explanation above ⚠️
export const getLabelComponent: <Label extends string, Params extends Partial<Record<string, string | number>>>(
    useLabelFn: UseLabelType<Label, Params>,
) => LabelComponentType<Label, Params> =
    (useLabelFn) =>
    ({ label, params, fallbackLabel, useHTML = false }): JSX.Element => {
        const actualLabel = useLabelFn(label, params, fallbackLabel);
        return useHTML ? <span dangerouslySetInnerHTML={{ __html: actualLabel }} /> : <span>{actualLabel}</span>;
    };

// ⚠️ Do not use this component without it being wrapped with custom typing, see explanation above ⚠️
export const getMemoLabelComponent: <Label extends string, Params extends Partial<Record<string, string | number>>>(
    useLabelFn: UseLabelType<Label, Params>,
) => LabelComponentType<Label, Params> =
    (useLabelFn) =>
    ({ label, params, fallbackLabel, useHTML = false }): JSX.Element => {
        const actualLabel = useLabelFn(label, params, fallbackLabel);
        return useMemo(() => {
            return useHTML ? <span dangerouslySetInnerHTML={{ __html: actualLabel }} /> : <span>{actualLabel}</span>;
        }, [actualLabel, useHTML]);
    };
