import type { CarResultsActionsType } from "../actions/CarResultsActions";
import {
    FETCH_CAR_RESULTS,
    FILTER_CAR_RESULTS,
    REPLACE_CAR_RESULTS,
    SET_CAR_RESULT_PLACEHOLDERS,
    SET_CAR_RESULTS,
    SET_USED_CAR_RESULTS_ORDER,
    DISABLE_CAR_RESULTS_PLACEHOLDERS,
} from "../actions/CarResultsActions";
import type { UsedCarResultType } from "../../../shared-logic/types/UscCommonTypes";
import { UscContext } from "../../../shared-logic/types/UscCommonTypes";
import { DfCarSortOrder } from "../../utils/constants/filterConstants";

// Each car has an individual loading state which is used to determine if a car is being feched.
// This is used when the component is rendered with car placeholders as we don't load all placeholder cars by default.
export type CarResultItemType<T> = { result?: T; loading: boolean; placeholder: boolean };
export type DfCarResultItemType = CarResultItemType<UsedCarResultType>;

export type CarResultsReducerType = {
    initialized: boolean;
    // loadingMore indicates additional cars are being fetched, either as an initial render (0 cars and no placeholders)
    // or by having clicked the "load more" button.
    loadingMore: boolean;
    totalResultCount: number;
    dfCarSortOrder: DfCarSortOrder;
    // TODO split up for used and stock?
    dfCarResults: DfCarResultItemType[];
};

export const initialState: CarResultsReducerType = {
    initialized: false,
    loadingMore: true,
    totalResultCount: 0,
    dfCarSortOrder: DfCarSortOrder.Published,
    dfCarResults: [],
};

const resultKeyMap: Record<UscContext, "dfCarResults"> = {
    [UscContext.Used]: "dfCarResults",
    [UscContext.Stock]: "dfCarResults",
};

const CarResultsReducer = (
    state: CarResultsReducerType = initialState,
    action: CarResultsActionsType,
): CarResultsReducerType => {
    switch (action.type) {
        case FETCH_CAR_RESULTS: {
            const { resultCount, forPlaceholders, context } = action;
            const resultKey = resultKeyMap[context];
            const newResults = [...state[resultKey]];

            for (let i = 0; i < resultCount; i++) {
                newResults[i] = { loading: true, placeholder: forPlaceholders };
            }

            return { ...state, [resultKey]: newResults, loadingMore: !forPlaceholders };
        }

        case SET_CAR_RESULTS: {
            const { results, totalResultCount, context } = action;
            const resultKey = resultKeyMap[context];
            const newResults = [...state[resultKey]];

            for (let i = 0; i < results.length; i++) {
                newResults[i] = { loading: false, result: results[i], placeholder: false };
            }

            return { ...state, [resultKey]: newResults, initialized: true, loadingMore: false, totalResultCount };
        }

        case REPLACE_CAR_RESULTS: {
            const { results, totalResultCount } = action;

            return {
                ...state,
                totalResultCount,
                dfCarResults: (results as UsedCarResultType[]).map((result) => ({
                    loading: false,
                    result,
                    placeholder: false,
                })),
                loadingMore: false,
            };
        }

        case DISABLE_CAR_RESULTS_PLACEHOLDERS: {
            const { context } = action;
            const resultKey = resultKeyMap[context];
            const newResults = [...state[resultKey]];

            newResults.forEach((element, i) => {
                newResults[i] = { ...newResults[i], loading: false, placeholder: false };
            });

            return { ...state, [resultKey]: newResults };
        }

        case SET_CAR_RESULT_PLACEHOLDERS: {
            const { context, count } = action;
            const results = [];

            for (let i = 0; i < count; i++) {
                results[i] = { loading: false, placeholder: true };
            }

            return { ...state, [resultKeyMap[context]]: results, initialized: true, loadingMore: false };
        }

        case SET_USED_CAR_RESULTS_ORDER: {
            return { ...state, dfCarSortOrder: action.order };
        }

        case FILTER_CAR_RESULTS: {
            const { context, resultCount } = action;

            const resultKey = resultKeyMap[context];

            const newResults = [...state[resultKey]];

            for (let i = 0; i < resultCount; i++) {
                newResults[i] = { loading: true, placeholder: true };
            }

            return { ...state, [resultKey]: newResults, loadingMore: true };
        }

        default:
            return state;
    }
};

export default CarResultsReducer;
