import type { UsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import type { UsedCarCompareExternalDataActionsType } from "../actions/UsedCarCompareExternalDataActions";
import {
    USED_CAR_COMPARE_ADD_CARS,
    USED_CAR_COMPARE_CLEAR_ALL,
    USED_CAR_COMPARE_REMOVE_CARS,
    USED_CAR_COMPARE_SET_PARSED_CARS,
} from "../actions/UsedCarCompareExternalDataActions";

export type UsedCarCompareExternalDataReducerType = {
    cars: UsedCompareCarType[];
};

export const initialUsedCarCompareExternalDataState: UsedCarCompareExternalDataReducerType = {
    cars: [],
};

// See CompareReducer for more information
export const replaceObjectsInArray = <T>(array: T[], newObjects: T[], index: number): T[] => {
    return [...array.slice(0, index), ...newObjects, ...array.slice(index + newObjects.length)];
};

const usedCarCompareExternalDataReducer = (
    state: UsedCarCompareExternalDataReducerType = initialUsedCarCompareExternalDataState,
    action: UsedCarCompareExternalDataActionsType,
): UsedCarCompareExternalDataReducerType => {
    switch (action.type) {
        case USED_CAR_COMPARE_CLEAR_ALL: {
            return initialUsedCarCompareExternalDataState;
        }

        case USED_CAR_COMPARE_ADD_CARS: {
            return {
                ...state,
                cars: replaceObjectsInArray(state.cars, action.cars, action.index),
            };
        }
        case USED_CAR_COMPARE_SET_PARSED_CARS:
            return {
                ...state,
                cars: replaceObjectsInArray(state.cars, action.cars, action.index),
            };

        case USED_CAR_COMPARE_REMOVE_CARS:
            const indexesToRemove = action.index;
            // Map the state and then filter the false values to prevent index from changing when filtering through cars
            const filteredCars = state.cars
                .map((car, index) => !indexesToRemove.includes(index) && car)
                .filter((car) => car);
            return {
                ...state,
                cars: filteredCars as UsedCompareCarType[],
            };
        default:
            return state;
    }
};
export default usedCarCompareExternalDataReducer;
