import React, { useState } from "react";
import type { ModalViewType, RequestStatus } from "../../../../types/CommonTypes";
import { RequestStatusEnum } from "../../../../types/CommonTypes";
import type { ModalCacheClearType } from "../../../../utils/modalConstants";
import { IntegratedDebugModal } from "./IntegratedDebugModal";
import { useCommonSelector } from "../../../../redux/commonStore";
import type { ClearCacheBodyType } from "../../../../server/v2/endpoints/ClearCacheEndpoint";

const ClearCacheModal = (props: ModalViewType<ModalCacheClearType>): JSX.Element => {
    const { close, show } = props;
    const country = useCommonSelector((state) => state.commonSettings.country);
    const language = useCommonSelector((state) => state.commonSettings.language);
    const resourcePath = useCommonSelector((state) => state.commonSettings.resourcePath);

    const [status, setStatus] = useState<RequestStatus>(RequestStatusEnum.Idle);
    const [response, setResponse] = useState<string>();

    return (
        <IntegratedDebugModal props={{ show, close, variant: "cache" }}>
            <div>
                <header>
                    <h1>
                        Clear the cache for {country}-{language}
                    </h1>
                </header>
                <section>
                    <button
                        type="button"
                        disabled={status === RequestStatusEnum.Fetching}
                        onClick={() => {
                            if (status !== RequestStatusEnum.Fetching) {
                                setStatus(RequestStatusEnum.Fetching);
                                setResponse(undefined);
                                fetch(`${resourcePath}/clear-cache`, {
                                    method: "POST",
                                    headers: { "Content-Type": "application/json" },
                                    body: JSON.stringify({ country, language } as ClearCacheBodyType),
                                })
                                    .then((res) => res.json())
                                    .then((json) => {
                                        setResponse(JSON.stringify(json));
                                        setStatus(RequestStatusEnum.Complete);
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        setStatus(RequestStatusEnum.Error);
                                    });
                            }
                        }}
                    >
                        Clear cache
                    </button>
                    <div>
                        {status === RequestStatusEnum.Fetching && <p>Clearing cache</p>}
                        {status === RequestStatusEnum.Complete && <p>Cache cleared: {response}</p>}
                        {status === RequestStatusEnum.Error && <p>Cache clear failed, you can try again</p>}
                    </div>
                </section>
            </div>
        </IntegratedDebugModal>
    );
};

export default ClearCacheModal;
