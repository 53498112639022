import type { NotificationType } from "../types/CommonTypes";

export const getPreviousNotificationIndexes = (notifications: NotificationType[], amountToAdd: number): number[] => {
    const previousIndexes: number[] = [];
    if (notifications.length) {
        const previousIndex = notifications[notifications.length - 1].displaySettings?.notificationIndex;
        if (previousIndex !== undefined) previousIndexes.push(previousIndex + 1);
    } else previousIndexes.push(0);

    for (let index = 0; index < amountToAdd - 1; index++) {
        previousIndexes.push(previousIndexes[previousIndexes.length - 1] + 1);
    }
    return previousIndexes;
};
