import type { FC, ForwardRefExoticComponent, PropsWithChildren } from "react";
import { createComponentRegistry } from "../../../../shared-logic/features/ComponentRegistry";
import type { UsedCarResultProps } from "./car-results/df-car-result/UsedCarResult";
import type { ScrollIndicatorProps } from "../../../../common-deprecated/components/scroll-indicator/ScrollIndicatorUp";

const { ComponentRegistryProvider, useComponentRegistry } = createComponentRegistry<{
    FilterSavedCars: FC;
    MobileStaticFilterBar: FC;
    StaticFilterBar: FC;
    UsedCarResult: ForwardRefExoticComponent<UsedCarResultProps>;
    IntegratedUsedCompareWrapper: FC;
    CarResultsContainerWrapper: FC<PropsWithChildren>;
    PageFinanceDisclaimer: FC;
    ScrollIndicatorUp: FC<ScrollIndicatorProps>;
    TopFilters: FC;
}>();

export { ComponentRegistryProvider, useComponentRegistry };
