import type {
    CarTypeFilterModelType,
    CarTypeFilterType,
    MinMaxSliderFilterConfigType,
    MultipleChoiceValueType,
    PlusMinusFilterConfigType,
    SentenceConfigType,
    SliderFilterConfigType,
} from "./constants/filterConfigConstants";
import { CarTypeFilterEnum } from "./constants/filterConfigConstants";
import { filterConfigIsAny } from "./helpers";
import { formatNumber } from "../../../../common-deprecated/Globalize";

// This used to be either a string or an array containing the text/strikethrough object.
// For consistency sake this has been changed to always be the array version as it caused too many unnecessary bugs.
export type LabelReturnType = { text: string; strikethrough: boolean }[];

/**
 * Get a label for a multiple choice sentence.
 *
 * @param filterValues - Array of the possible filter values.
 * @param sentenceConfig - Sentence config to be used to generate the string
 * @param disableAny - Disable the logic of using the "any" label when all values are selected. Only used in grade-explorer.
 */
export const getMCSentenceLabel = (
    filterValues: MultipleChoiceValueType[],
    sentenceConfig: SentenceConfigType,
    disableAny: boolean = false,
): LabelReturnType => {
    const { finalValueSeparator, valueSeparator, valueLabel, mergeIdenticalValues } = sentenceConfig;

    const useAnyText = disableAny ? !filterValues.find((value) => value.selected) : filterConfigIsAny(filterValues);

    if (useAnyText) {
        return [{ text: sentenceConfig.anyLabel, strikethrough: false }];
    } else {
        // Get the currently active values. Also filter the children which parents are active. As pnly the parent should be shown then.
        let values = filterValues.filter((value) => value.selected);

        if (mergeIdenticalValues) {
            const labelSet = new Set<string>();
            values = values.filter((value) => {
                if (labelSet.has(value.label)) {
                    return false;
                } else {
                    labelSet.add(value.label);
                    return true;
                }
            });
        }

        // Create the value label, use the correct separators.
        if (values.length === 1) {
            return [{ strikethrough: !values[0].selectable, text: valueLabel.replace("{value}", values[0].label) }];
        } else {
            const sentenceArray = values
                // Current requirements say that there should be a counter once a mc filter exceeds 2 values.
                .slice(0, 2)
                .reduce((valueArray: { text: string; strikethrough: boolean }[], value, index) => {
                    const parsedValue = valueLabel.replace("{value}", value.label);

                    // Add separator if necessary.
                    let separator = "";
                    if (index + 1 === values.length && finalValueSeparator) separator = finalValueSeparator;
                    else if (index !== 0) separator = valueSeparator;

                    if (separator) valueArray.push({ text: separator, strikethrough: false });

                    valueArray.push({ text: parsedValue, strikethrough: !value.selectable });

                    return valueArray;
                }, []);

            // Add counter if required.
            if (values.length > 2) {
                sentenceArray.push({ text: `${valueSeparator} +${values.length - 2}`, strikethrough: false });
            }

            return sentenceArray;
        }
    }
};

export const getPlusMinusSentenceLabel = (filter: PlusMinusFilterConfigType): LabelReturnType => [
    { text: filter.sentenceConfig.valueLabel.replace("{value}", String(filter.current)), strikethrough: false },
];

export const getSliderSentenceLabel = (filter: SliderFilterConfigType, cultureName: string): LabelReturnType => {
    let currentValue = filter.current;
    if (filter.customMin && filter.current < filter.customMin) currentValue = filter.customMin;
    if (filter.customMax && filter.current > filter.customMax) currentValue = filter.customMax;

    return [
        {
            text: filter.sentenceConfig.valueLabel.replace("{value}", formatNumber(currentValue, cultureName)),
            strikethrough: false,
        },
    ];
};

export const getMinMaxSentenceLabel = (filter: MinMaxSliderFilterConfigType): LabelReturnType => {
    const { sentenceConfig, currentMaxValue, currentMinValue, customMinValue, customMaxValue } = filter;
    const { valueLabel, finalValueSeparator, valueSeparator } = sentenceConfig;

    const minValueString = String(Math.max(currentMinValue, customMinValue));
    const maxValueString = String(Math.min(currentMaxValue, customMaxValue > 0 ? customMaxValue : currentMaxValue));

    // Legacy handling. (used mostly in grade-explorer)
    if (valueLabel.includes("{value}")) {
        const minValue = valueLabel.replace("{value}", minValueString);
        const maxValue = valueLabel.replace("{value}", maxValueString);

        const separator = finalValueSeparator || valueSeparator;
        return [{ text: `${minValue}${separator}${maxValue}`, strikethrough: false }];
    }

    // Min-max handling
    return [
        { text: valueLabel.replace("{min}", minValueString).replace("{max}", maxValueString), strikethrough: false },
    ];
};

/**
 * Support the {multiply:value} parameter in label descriptions.
 * Used for luggage where litres are converted to amounts of bags (360 liters => about 5 bags)
 */
export const getSliderDescriptionLabel = (label: string, value: number): string => {
    const multiplyCalc = /{multiply:([\d.]*)}/.exec(label);

    if (multiplyCalc && multiplyCalc[1]) {
        return label.replace(/{multiply:([\d.]*)}/, String(Math.floor(Number(multiplyCalc[1]) * value)));
    }
    return label;
};

/**
 * Helper function which removes duplicate model entries and merges them in to a comma separated ID.
 * See OR-3909.
 */
export const parseModelList = (modelList: CarTypeFilterModelType[]): CarTypeFilterModelType[] => {
    return modelList.reduce<CarTypeFilterModelType[]>((parsedList, listItem) => {
        const matchedModelIndex = parsedList.findIndex((parsedListItem) => parsedListItem.label === listItem.label);
        if (matchedModelIndex === -1) {
            parsedList.push(listItem);
        } else {
            // If a model with the same label is found add the id to the current list of ids.
            parsedList[matchedModelIndex] = {
                ...parsedList[matchedModelIndex],
                id: `${parsedList[matchedModelIndex].id},${listItem.id}`,
                resultCount: parsedList[matchedModelIndex].resultCount! + listItem.resultCount!,
            };
        }

        return parsedList;
    }, []);
};

/**
 * Merges filter choices that are equal by label (eg.: transmission)
 * resultCount is added & selected/selectable states are equalized
 */
export const mergeMultipleChoiceValueTypeByLabel = (values: MultipleChoiceValueType[]): MultipleChoiceValueType[] => {
    const trim = (string: string): string => string.trim().toLowerCase();
    return [...new Set(values.map((value) => trim(value.label)))].map((key) => {
        let acc: MultipleChoiceValueType | undefined;
        values.forEach((value) => {
            if (trim(value.label) !== key) return;
            if (acc) {
                acc = {
                    ...acc,
                    id: `${acc.id}|${value.id}`,
                    selected: acc.selected || value.selected,
                    selectable: acc.selectable || value.selectable,
                    resultCount: (acc.resultCount ?? 0) + (value.resultCount ?? 0),
                };
            } else {
                acc = { ...value };
            }
        });
        return acc!;
    });
};

/**
 * Get filterCount and midLabel properties for car-type filters.
 */
export const getCarTypeValues = (
    carTypeFilter: CarTypeFilterType,
    isNewCarFilter: boolean,
    mergeTransmissions: boolean = false,
): {
    filterCount: number;
    midLabel: LabelReturnType;
} => {
    // Parse the model values array and update the label to contain the car manufacturer.
    const modelValues = !isNewCarFilter
        ? carTypeFilter.models
              // Filter models part of an already selected brand as that should be covered by the selected brand.
              .filter((model) => {
                  const modelBrand = carTypeFilter.brands.find((brand) => brand.id === model.brandId);
                  return modelBrand ? !modelBrand.selected : false;
              })
              .map((model) => {
                  const brand = carTypeFilter.brands.find((filterBrand) => filterBrand.id === model.brandId);
                  if (brand) return { ...model, label: `${brand.label} ${model.label}` };
                  else return model;
              })
        : carTypeFilter.models;

    // Array with all the carTypeFilterValues for generating the label shown in the filter
    // We use also use this array for calculating the filterCount.
    // Prefilled but disabled filters should not be shown and counted
    const activeValues = [];
    if (
        !(
            carTypeFilter.disabledCarTypeFilters.includes(CarTypeFilterEnum.Brands) ||
            carTypeFilter.disabledCarTypeFilters.includes(CarTypeFilterEnum.Models)
        )
    ) {
        const models = parseModelList(modelValues) as MultipleChoiceValueType[];
        activeValues.push(...models);
        activeValues.push(...carTypeFilter.brands);
    }

    if (!carTypeFilter.disabledCarTypeFilters.includes(CarTypeFilterEnum.CarType)) {
        activeValues.push(...carTypeFilter.carType);
    }
    if (!carTypeFilter.disabledCarTypeFilters.includes(CarTypeFilterEnum.FuelType)) {
        activeValues.push(...carTypeFilter.fuelType);
    }
    if (carTypeFilter.transmission) {
        // Merge transmissions w/ same label
        if (mergeTransmissions) {
            activeValues.push(...mergeMultipleChoiceValueTypeByLabel(carTypeFilter.transmission));
        } else {
            activeValues.push(...carTypeFilter.transmission);
        }
    }

    // Active filter count
    const filterCount = activeValues.filter((value) => value.selected).length;
    const midLabel = getMCSentenceLabel(activeValues, carTypeFilter.sentenceConfig, true);

    return { filterCount, midLabel };
};
