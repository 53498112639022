import type { Ref } from "react";
import React from "react";
import * as Tooltip from "../../../styles/v2/toyota/components/Tooltip";
import { IconClose } from "../../../styles/v2/toyota/globals/Icon";
import type { PopoverTooltipType } from "../../../hooks/usePopoverTooltip";
import { usePopOverTooltip } from "../../../hooks/usePopoverTooltip";
import { CommonLabel, useCommonLabel } from "../../../utils/commonLabels";

const PopoverTooltip = React.forwardRef((props: PopoverTooltipType, forwardedRef?: Ref<HTMLElement>): JSX.Element => {
    const {
        small,
        children,
        hide,
        onClose,
        useHTML = false,
        content = "",
        openedOnHover = false,
        zIndex,
        forceShowModal,
        id,
        role = "tooltip",
        tabIndex,
        onKeyDown,
    } = props;

    const { tooltipVisible, tooltipRef, modalVisible, pointOffset, show, offsetWithArrowLocation } = usePopOverTooltip(
        props,
        forwardedRef,
        forceShowModal,
    );

    const ariaClose = useCommonLabel("ariaClose");

    return (
        <Tooltip.BoxWrapper onClick={(event) => event.stopPropagation()} hide={!show} aria-hidden={!show}>
            <Tooltip.Wrapper
                id={id}
                role={role}
                ref={tooltipRef}
                absolute
                visible={!hide && show && !modalVisible && (tooltipVisible || !!children)}
                point={pointOffset}
                offset={offsetWithArrowLocation}
                small={small}
                disabled={modalVisible}
                aria-hidden={!show}
                zIndex={zIndex}
                data-ignore-outside-clicks
                tabIndex={tabIndex}
                onKeyDown={onKeyDown}
            >
                {content && useHTML ? <span dangerouslySetInnerHTML={{ __html: content }} /> : content}
                {children}
                {!openedOnHover && (
                    <Tooltip.Close
                        onClick={(event) => {
                            event.stopPropagation();
                            onClose();
                        }}
                        aria-label={ariaClose}
                    >
                        <IconClose /> <CommonLabel label="close" />
                    </Tooltip.Close>
                )}
            </Tooltip.Wrapper>
        </Tooltip.BoxWrapper>
    );
});

export default PopoverTooltip;
