import type { AriaRole, ReactNode } from "react";
import React from "react";
import * as Styles from "../../../styles/toyota/Modal";
import type { BreakPointValuesType } from "../../../themes/common";
import useModalBaseView from "../../../hooks/useModalBaseView";
import { useCommonSelector } from "../../../redux/commonStore";
import type { ModalIdsType } from "../../../types/CommonTypes";

type ModalBaseViewType = {
    modalIdType: ModalIdsType;
    // eslint-disable-next-line react/no-unused-prop-types
    onClose: () => void;
    onTransitionEnd?: () => void;
    show: boolean;
    children: ReactNode;
    size: BreakPointValuesType;
    contentClass?: string;
    // eslint-disable-next-line  react/no-unused-prop-types
    disableOutsideClick?: boolean;
    maxWidth?: string;
    rounded?: boolean;
    role?: AriaRole;
};

/**
 * This wrapper should be used for every Modal component
 * It contains required modal wrappers and the overlay background.
 * @deprecated use the aem variant instead
 */
const ModalBaseView = (props: ModalBaseViewType): JSX.Element => {
    const { modalIdType, show, children, size, maxWidth, contentClass, rounded, role } = props;
    const { onClose, disableOutsideClick, onTransitionEnd } = props;
    const modalRef = useModalBaseView({ onClose, disableOutsideClick, show, modalIdType });

    // AEM Author - modals should not cover other components and should be top aligned so editors see them immediately
    const isAuthor = useCommonSelector((state) => state.commonSettings?.query?.authorPreview || false);

    return (
        <Styles.Wrapper contained rounded={rounded} onTransitionEnd={onTransitionEnd} isAuthor={isAuthor} role={role}>
            <Styles.Dialog
                ref={modalRef}
                contained
                active={show}
                size={size}
                maxWidth={maxWidth}
                rounded={rounded}
                isAuthor={isAuthor}
            >
                <Styles.Content className={contentClass}>{children}</Styles.Content>
            </Styles.Dialog>
            <Styles.Overlay isAuthor={isAuthor} />
        </Styles.Wrapper>
    );
};

export default ModalBaseView;
